export const createPowerPlantGeoJson = (powerplants = []) => ({
  type: 'geojson',
  data: {
    type: 'FeatureCollection',
    features: powerplants.map(pp => ({
      type: 'Feature',
      id: pp.id,
      properties: {
        id: pp.id,
        name: pp.name,
        imageUrl: pp.image && pp.image.thumb && pp.image.thumb.url ? pp.image.thumb.url : false,
        selected: false,
        county: pp.county,
        price_area: pp.price_area
      },
      geometry: {
        type: 'Point',
        coordinates: [pp.lng, pp.lat]
      }
    }
  ))}
})

export const defaultLayer = {
  id: 'defaultLayer',
  type: 'symbol',
  layout: {
    'text-field': ['get', 'name'],
    'text-offset': [0, 1],
    'icon-image': 'hydro',
    'text-anchor': 'top',
    'text-justify': 'auto',
    'icon-allow-overlap': true,
    'text-allow-overlap': false
  },
  paint: {
    'icon-color': '#0070f3',
    'text-color': '#FFFFFF'
  }
}
