<script>
const initialDisplayCount = 15
export default {
  props: {
    current_company: Object,
    current_user: Object,
    companies: Array,
  },
  data() {
    return {
      menu_opened: null,
      query: '',
      show_child_companies: this.companies.length < initialDisplayCount,
      current_company_key: 0,
      displayedItems: this.companies.slice(0, initialDisplayCount),
      paginationCount: initialDisplayCount,
    }
  },
  computed: {
    showLoadMoreButton() {
      let totalFilteredCompanies = this.companies.filter(x =>
          x.name.toLowerCase().includes(this.query.toLowerCase()) &&
          (this.show_child_companies || x.root)
      ).length;
      return totalFilteredCompanies > this.paginationCount;
    },
    filterable() {
      return this.companies.length > 10
    },
    filtered_companies() {
      let result = this.companies.filter(x =>
          x.name.toLowerCase().includes(this.query.toLowerCase()) &&
          (this.show_child_companies || x.root)
      )
      return result.length <= this.paginationCount ? result : result.slice(0, this.paginationCount)
    },
    current_company_name() {
      return this.current_company ? this.current_company.name : 'Global'
    },
    selected_company() {
      // We need this key to force an update of the list group, seems like some kind of bug
      // ref: https://github.com/vuetifyjs/vuetify/issues/11405#issuecomment-917656780
      this.current_company_key++
      return this.filtered_companies.findIndex(company => company?.id === this.current_company?.id)
    },
  },
  methods: {
    focusInput() {
      setTimeout(()=>{
        this.$refs.query_tag.focus()
      },600)
    },
    async changeCompany(company_id) {
      const data = {
        company_id: company_id
      }
      const url = `/users/${this.current_user.id}/change_current_company.js`
      const response = await this.axios.put(url, data)

      if (response.status === 200) {
        const currentUrl = new URL(window.location.href)
        const periodParam = currentUrl.searchParams.get('period')
        let newPath = window.location.pathname
        if (periodParam) {
          newPath += `?period=${periodParam}`
        }
        window.location.href = newPath
      }
    },
    loadMoreItems() {
      let nextItems = this.companies
          .filter(x =>
              x.name.toLowerCase().includes(this.query.toLowerCase()) &&
              (this.show_child_companies || x.root)
          )
          .slice(this.paginationCount, this.paginationCount + initialDisplayCount)

      this.displayedItems.push(...nextItems)
      this.paginationCount += nextItems.length
      this.show_child_companies = this.displayedItems.length < initialDisplayCount
    },
  },
  watch: {
    menu_opened() {
      if(this.menu_opened && this.filterable) {
        this.focusInput()
      } else {
        this.query = ''
      }
    },
    query(newVal, oldVal) {
      const filtered = this.companies.filter(x =>
          x.name.toLowerCase().includes(newVal.toLowerCase())
      )

      this.paginationCount = initialDisplayCount
      this.show_child_companies = filtered.length < initialDisplayCount
      this.displayedItems = filtered.slice(0, this.paginationCount)
    },
  }
}
</script>
<template lang="pug">
v-menu(v-model="menu_opened")
  template(v-slot:activator="{ on }")
    a(v-on="on")
      v-chip.pointer.ma-2#company(color="light-blue" text-color="white")
        | {{ current_company_name }}
        v-icon(right) mdi-star
  v-card(style="max-height: 90vh;")
    v-list
      v-list-item(v-if="filterable" @click.stop="")
        v-list-item-title
          v-text-field(v-model="query" type="text" ref="query_tag" :placeholder="$t('general.search')" dense)
          v-checkbox(v-model="show_child_companies" :label="$t('general.show_subsidiaries')" dense)
      v-list-item
        v-list-item-title.pointer(@click="changeCompany(null)") Global
      v-list-item-group(:key="current_company_key", :value="selected_company")
        v-list-item(v-for="(company, index) in filtered_companies" :key="index" @click="changeCompany(company.id)")
          v-list-item-title {{ company.name }}
        v-list-item(v-if="showLoadMoreButton", @click.native.stop="loadMoreItems")
          v-list-item-action
            v-btn(text color="primary")
              | {{  $t('general.click_to_load_more') }}
              v-icon(right) mdi-chevron-down
</template>
