<script>
export default {
  props: {
    prices: Object,
    currency_code: String,
    y_title: String,
    unit: {type: String, default() { return '' }},
  },
  data() {
    return {
      chart_options: {
        title: {
          text: this.$t('help_texts.market_prices.chart_exchange_rate')
        },
        xAxis: {
          type: 'datetime',
        },
        yAxis: [{
          title: {
            text: this.y_title
          },
          labels: {
            format: `{value}${this.unit}`,
          },
        }],
        legend: {
          enabled: true
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          series: {
            marker: {
              radius: 1
            }
          }
        },
        series: Object.keys(this.prices).map(currency => {
          return {
            name: `${currency}/${this.currency_code}`,
            type: 'line',
            data: this.prices[currency],
            visible: (currency === 'EUR' || (this.currency_code === 'EUR' && currency === 'NOK')),
            tooltip: {
              xDateFormat: '%b %e %Y',
            },
          }
        })
      }
    }
  }
}
</script>

<template lang="pug">
  v-highcharts(:options="chart_options")
</template>
