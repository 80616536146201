<script>
export default {
  props: {
    powerPlantId: Number
  },
  data () {
    return {
      article: false,
      url: null,
    }
  },
  methods: {
    async searchArticles () {
      this.article = false
      this.url = null
      const { data } = await this.axios.get(`/power_plants/${this.powerPlantId}/wikipedia_info`)
      this.article = data.article
      this.url = data.url
    }
  },
  watch: {
    powerPlantId () { this.searchArticles() }
  },
  mounted () {
    this.searchArticles()
  }
}
</script>

<template lang="pug">
  .white--text(v-if="article")
    a.pl-4.white--text.nostyle(:href="url" target="_blank") Wikipedia
    .bg2.pa-4 {{ article }}
</template>
