<script>
import ValueMixin from './value_mixins.vue'
import { DateTime } from 'luxon'

function hashCode(str) {
  let hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
    let char = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

export default {
  props: {
    fall_lease_contract: Object,
  },
  mixins: [ValueMixin],
  data() {
    return {
      local_fall_lease_contract: this.fall_lease_contract,
    }
  },
  computed: {
    computed_values() {
      return JSON.parse(this.local_fall_lease_contract.computed_value) || {};
    },
    data_table_header() {
      let headers = Object.keys(this.computed_values).map(
          (v) => ({text: v, value: hashCode(v).toString()})
      );

      headers.splice(0, 0, {
        text: this.$t("general.date"),
        value: 'date',
        sort: (a, b) => {
          return (
            DateTime.fromFormat(a, 'dd.L.yyyy', { locale: 'nb-NO' }) -
            DateTime.fromFormat(b, 'dd.L.yyyy', { locale: 'nb-NO' })
          )
        }
      })

      return headers
    },
    data_table() {
      return this.transform_data(this.computed_values);
    },
  },
  methods: {
   summaryColumnFilter(header) {
      let markers = ['[NOK]', '[EUR]'];
      return markers.some(slug => header.includes(slug));
    },
    summaryColumnSum(key) {
      let total = 0
      const sum = this.data_table.reduce((acc, cur) => {
        return (total += +cur[key])
      }, 0)
      return sum.toFixed(2);
    },
    transform_data(data) {
      let result = Object.keys(data).reduce(function (r, k) {
        Object.keys(data[k]).forEach(function (l) {
          r[l] = r[l] || {};
          r[l][k] = parseFloat(data[k][l]).toFixed(2);
        });
        return r;
      }, {});

      let return_array = [];
      for (let key1 in result) {
        let local_obj = {date: new Date(parseInt(key1)).toLocaleDateString("nb-NO")};
        for (let key2 in result[key1]) {
          let new_key = hashCode(key2).toString();
          local_obj[new_key] = result[key1][key2];
        }
        return_array.push(local_obj);
      }

      return return_array;
    },
    formatNumbersForTable(value, header) {
      if (!this.isNumeric(value))
        return value;

      if (header.text.includes('[%]') || header.text.includes('[MWh]')) {
        return this.formatNumber(value, 1)
      }

      return this.formatNumber(value, 0)
    },
  },
  watch: {
    fall_lease_contract(val) {
      this.local_fall_lease_contract = val;
    },
  },
}
</script>

<template lang="pug">
  
v-container
  v-card
    v-card-title.headline.grey.lighten-2
      | {{ this.$t('fall_lease.fall_lease_summary') }}
    v-card-text
      v-data-table(
        :items="data_table"
        :headers="data_table_header"
        :items-per-page="15"
        dense
      )
        template(v-for="header in data_table_header" v-slot:[`item.${header.value}`]="{ header, value }")
          div(class="text-right") {{ formatNumbersForTable(value, header) }}
        template(v-slot:[`body.append`]="{headers}")
          tr(class="summary")
            th(v-for="(header, i) in headers" :key="i")
              p(class="text-left text-subtitle-2" v-if="i===0") {{ $t('fall_lease.total') }}
              p(class="text-right text-subtitle-2" v-if="summaryColumnFilter(header.text)") {{ noDecimal(summaryColumnSum(header.value)) }}

    v-card-actions#fall_lease_summary_actions
      slot
</template>