<script>
export default {
  props: ['item'],
  data() {
    return {
      query: '',
    }
  },
  computed: {
    filteredItems() { return this.item.sub_items.filter( (x) => x.name.toLowerCase().includes(this.query.toLowerCase()) ) },
    searchable() { return this.item.sub_items.length > 10 }
  },
  
}
</script>
<template lang="pug">
v-list-group(
  no-action
)
  template(v-slot:activator)
    v-list-item-icon(v-if="item.icon")
      v-icon {{ item.icon }}
    v-list-item-content
      v-list-item-title {{ item.title }}
  v-list-item(v-if="searchable")
    v-text-field(v-model="query" ref="query" placeholder="Filter list" :persistent-placeholder="true" autofocus)
  v-list-item(
    v-for="sub_item in filteredItems"
    :key="sub_item.id"
    :href="sub_item.href"
  )
    v-list-item-content
      v-list-item-title {{ sub_item.name }}
</template>
