<script>
import OMDashboardHelpers from '../../mixins/om_dashboard_helpers'

export default {
  props: {
    title: String,
    values: Array,
    additionalNames: Array,
    showAlarmLegend: Boolean,
    tech: String
  },
  mixins: [OMDashboardHelpers],
  data() {
    return {
      statusMap: this.values || []
    }
  },
  methods: {
    colorForStatus(status) {
      return this.operationalStatusTypes[status].color
    },
    outlineForStatus(status) {
      return this.operationalStatusTypes[status].outline
    },
    sortCompared() {
      if (this.additionalNames) {
        let sorted = []
        this.statusMap.forEach((entry) => {
          const foundIndex = this.additionalNames.indexOf(entry.title)
          if (foundIndex > -1) sorted[foundIndex + 1] = entry
          else sorted[0] = entry
        })
        this.statusMap = sorted
      }
    },
    hideIfWind(value) {
      return ''
    },
    tooltipDate(timestamp) {
      if (this.hasTimes) {
        const time = new Date(timestamp)
        return time.toDateString() + ` ${time.getHours()}:00`
      } else return this.formatDateFromTimestamp(timestamp / 1000)
    }
  },
  computed: {
    isWind() {
      return this.tech === 'wind'
    },
    hasTimes() {
      let timestamps = []
      for (let i = 0; i < 3; i += 1) {
        let date = new Date(this.statusMap[0].values[i].timestamp)

        let day = date.getDate()
        let month = date.getMonth()
        let shortYear = date.getFullYear().toString().slice(-2)
        timestamps.push(`${day}. ${this.$t('date.abbr_month_names')[month + 1]}. ${shortYear}`)
      }
      return timestamps[0] === timestamps[1] && timestamps[1] === timestamps[2]
    },
    alarmIcons() {
      if (this.isWind) return this.windAlarms
      else return this.solarAlarms
    },
    candybarHeight() {
      return this.isWind ? Math.max(parseInt(60 / this.values.length), 40) : this.showAlarmLegend ? 40 : 20
    },
    candybarMargin() {
      if (this.showAlarmLegend) return this.isWind ? 14 : 42
      else return this.isWind ? 14 : 2
    },
    candybarBottomMargin() {
      return this.isWind
        ? Math.max(parseInt(60 / this.values.length), this.candybarMargin)
        : this.candybarMargin
    },
    hasData() {
      return this.statusMap.length > 0
    },
    steppedTimestamps() {
      if (this.statusMap.length === 0) return []

      let timestamps = []
      for (let i = 0; i < this.statusMap[0].values.length; i += 8) {
        let date = new Date(this.statusMap[0].values[i].timestamp)

        let day = date.getDate()
        let month = date.getMonth()
        let shortYear = date.getFullYear().toString().slice(-2)
        let time = date.getHours()
        timestamps.push(`${day}. ${this.$t('date.abbr_month_names')[month + 1]}. ${shortYear}`)
      }
      return timestamps.filter((v, i, a) => a.indexOf(v) === i)
    },
    cellMargin() {
      return '2px'
    },
    cellWidth() {
      if (this.statusMap.length > 0) {
        const longestSeries = Math.max.apply(
          Math,
          this.statusMap.map((sm) => sm.values.length)
        )
        return 100 / longestSeries
      } else return 0
    },
    cellWidthPercentage() {
      return `max(5px, calc(${this.cellWidth}% - (${this.cellMargin} * 2)))`
    },
    labelHeight() {
      return this.candybarHeight + (this.showAlarmLegend ? 24 : 4)
    },
    timestampWidthPercentage() {
      return `calc(max(5px + (${this.cellMargin} * 2), ${this.cellWidth}%) * 8)`
    }
  },
  watch: {
    values: {
      handler() {
        this.statusMap = this.values
      },
      deep: true
    }
  }
}
</script>

<template lang="pug">
div(:class="showAlarmLegend ? 'alarm_chart' : 'status_map'")
  div(v-if="!hasData")
    v-progress-circular(indeterminate)
  .candybar_chart(v-else)
    .d-flex()
      div.mr-5(style="align-self: flex-end;" v-if="title")
        | {{ title }}
    v-row(no-gutters style="flex-wrap: nowrap; overflow: none;")
      v-col(style="flex: 0 0 0;")
        .mr-1(v-for="row, row_index in statusMap" :key="`${row_index}a`" no-gutters :style="`height: ${labelHeight}px;`")
          div.text-no-wrap.text-caption.text--secondary(:style="`text-align: right; padding-top: ${(labelHeight - 18) / 2}px`") {{row.title}}

      v-col(style="")
        v-row(v-for="row, row_index in statusMap" :key="row_index" no-gutters style="flex-wrap: nowrap; border-left: 2px solid #ECECEE;")
          v-col(v-for="col, col_index in row.values" :key="`${row_index}, ${col_index}`" :style="`flex: 0 0 ${cellWidthPercentage}; min-width: ${cellWidthPercentage}; max-width: ${cellWidthPercentage}; margin: ${cellMargin};`" v-if="new Date().valueOf() > col.timestamp")
            div
              div(v-if="showAlarmLegend")
                div.candy_bar.alarm_icon.mb-1(v-if="col.alarm_category_severity >= 20" :style="`height: 16px; filter: brightness(0) saturate(100%); background-position: center center; background-image: url('${alarmIcons[col.alarm_category_severity]?.icon}');`")
                div.candy_bar.mb-1(v-else-if="col.alarm_category_severity === 0 || col.value === 50" :style="`height: 16px; filter: brightness(0) saturate(100%); background-position: center center;`")
                div.candy_bar.mb-1(v-else :style="`height: 16px; filter: brightness(0) saturate(100%); background-position: center center;`")

              v-tooltip(bottom)
                template(v-slot:activator="{ on }")
                  .rounded-sm(v-on="on" :style="`height: ${candybarHeight}px; outline: ${outlineForStatus(col.value)}; background-color: ${colorForStatus(col.value)}; ${hideIfWind(col.value)}`")
                | {{ tooltipDate(col.timestamp) }}
                br
                span(v-if="col.alarm_category_severity")
                  span(v-if="col.alarm_category_severity > 20")
                    | {{ $t('om_dashboard.alarm.alarm_category') }}: {{ alarmIcons[col.alarm_category_severity]?.title }}
                    br
                | {{ $t('om_dashboard.alarm.operational_status') }}: {{ $t(`om_dashboard.status.${tech}.${operationalStatusTypes[col.value].title}`) }}

        div(style="padding-left: 2px; border-top: 2px solid #ECECEE;")
          v-row(no-gutters style="justify-items: center; flex-wrap: nowrap;")
            div.text-caption.text--secondary(v-for="ts in steppedTimestamps" :key="ts" :style="`min-width: ${timestampWidthPercentage}; width: ${timestampWidthPercentage}; max-width: ${timestampWidthPercentage}`") {{ ts }}
    .mt-0.d-flex.legend.float-right
      div(v-for="(status, severity) in operationalStatusTypes" style="float:left" v-if="severity !== 55")
        div(:style="`width: 15px; height: 15px; outline: ${status.outline}; background-color: ${status.color}; border-radius: 15px; margin-right: 6px; align-self: center; float: left; margin: 4px 8px 0 10px`")
        .text-no-wrap.pr-8.label {{ $t(`om_dashboard.status.${tech}.${status.title}`)}}
    .mt-2.d-flex.legend.float-right(v-if="showAlarmLegend")
      div.d-flex(v-for="alarmIcon in alarmIcons")
        div(:style="`margin-right: 6px; width: 20px; height: 20px; background-image: url('${alarmIcon.icon}'); height: 16px; filter: brightness(0) saturate(100%); background-position: center center;  align-self: center;`")
        .text-no-wrap.mr-5.label {{ alarmIcon.title }}
    br(clear="both")
</template>
