<script>
import EditDowntime from './edit_downtime.vue'
import EditScheduledDowntime from './edit_scheduled_downtime.vue'
import { DateTime } from 'luxon'

export default {
  components: {
    EditDowntime,
    EditScheduledDowntime
  },
  props: {
    power_plant: Object,
    power_plants: Array,
    downtime_period: Object,
    scheduled_downtime_period: Object,
    value: Boolean,
    reasons_scheduled: Array,
    selectedAreaFrom: Number,
    selectedAreaTo: Number,
    edit_mode: Boolean
  },
  data() {
    return {
      is_scheduled_downtime_period: false,
      rawMinDate: null,
      rawMinTime: null,
      rawMaxDate: null,
      rawMaxTime: null,
      powerPlantSelectorValue:
        this.downtime_period.power_plant_id || this.scheduled_downtime_period.power_plant_id || null,
      comment: '',
      reason: 'uncategorized',
      component: '',
      errors: [],
      reason_items: [],
      components_items: [],
      noWater: null,
      powerPlant: this.power_plant || null,
      dataLoaded: false
    }
  },
  mounted() {
    this.setPowerPlant()
    this.setTime()
    this.setFields()
    this.set_reasons()
    const urlParams = new URLSearchParams(window.location.search)
    this.noWater = urlParams.get('no_water') === 'true'
    this.minDateFormatted = this.rawMinDate ? this.rawMinDate.toFormat('yyyy-MM-dd') : null
    this.minTimeFormatted = this.rawMinTime
    this.maxDateFormatted = this.rawMaxDate ? this.rawMaxDate.toFormat('yyyy-MM-dd') : null

    this.onMaxTimeChange(this.rawMaxTime)

    this.maxTimeFormatted = this.rawMaxTime ? this.rawMaxTime.substring(0, 3) + '59' : null

    this.dataLoaded = true

    this.is_scheduled_downtime_period = Boolean(this.scheduled_downtime_period.id)
  },
  methods: {
    newDate(int) {
      let date = int ? new Date(int) : new Date()
      date.setMinutes(0)
      date.setSeconds(0)
      date.setUTCMilliseconds(0)
      return DateTime.fromJSDate(date)
    },
    setFields() {
      if (this.downtime_period.id || this.scheduled_downtime_period.id) {
        const period = this.downtime_period.id ? this.downtime_period : this.scheduled_downtime_period
        this.reason = period.reason
        this.component = period.component
        this.comment = period.comment
      } else {
        this.comment = ''
        this.component = ''
        this.reason = ''
      }
    },
    setTime() {
      if (this.downtime_period.id || this.scheduled_downtime_period.id) {
        let period = this.downtime_period.id ? this.downtime_period : this.scheduled_downtime_period
        const [minDate, minTime] = period.starts_at.split(' ')
        const [maxDate, maxTime] = period.ends_at.split(' ')
        this.rawMinDate = DateTime.fromFormat(minDate, 'yyyy-MM-dd')
        this.rawMinTime = DateTime.fromFormat(`${minDate} ${minTime}`, 'yyyy-MM-dd HH:mm').toFormat('HH:mm')
        this.rawMaxDate = DateTime.fromFormat(maxDate, 'yyyy-MM-dd')
        this.rawMaxTime = DateTime.fromFormat(`${maxDate} ${maxTime}`, 'yyyy-MM-dd HH:mm').toFormat('HH:mm')
      } else {
        const starts_at = this.newDate(this.selectedAreaFrom)
        const ends_at = this.newDate(this.selectedAreaTo)
        const timeZone = 'Europe/Oslo'
        this.rawMinDate = starts_at
        this.rawMinTime = starts_at.toFormat('H:00', { zone: timeZone }) || '00:00'
        this.rawMaxDate = ends_at
        this.rawMaxTime = ends_at.toFormat('HH:59', { zone: timeZone }) || '00:59'
      }
    },
    sharedProps(field) {
      const errorMessages = this.errors ? this.errors[field] || [] : []

      return {
        readonly: false,
        filled: false,
        dense: true,
        outlined: true,
        hideDetails: 'auto',
        label: this.$t(`activerecord.attributes.downtime_period.${field}`),
        name: `downtime_period_${field}`,
        errorMessages
      }
    },
    filterReasons(reasons) {
      if (!reasons) return []

      return reasons.filter((reason) => {
        return !(this.noWater && reason.value === 'no_water')
      })
    },
    setPowerPlant() {
      if (this.power_plant.id) {
        this.powerPlant = this.power_plant
      } else if (this.power_plants.length > 0 && this.powerPlantSelectorValue) {
        this.powerPlant = this.power_plants.find((pp) => pp.id === this.powerPlantSelectorValue)
      } else {
        this.powerPlant = null
      }
    },
    set_reasons() {
      if (this.powerPlant?.id) {
        this.reason_items = this.is_scheduled_downtime_period
          ? this.reasons_scheduled
          : this.filterReasons(this.powerPlant.reasons_translation)
        this.components_items = this.powerPlant.components_translation
      }
    },
    onMinDateChange(value) {
      this.minDateFormatted = value
    },
    onMinTimeChange(value) {
      this.minTimeFormatted = value
    },
    onMaxDateChange(value) {
      this.maxDateFormatted = value
    },
    onMaxTimeChange(value) {
      if (value) {
        // Ensure the time ends with :59
        this.maxTimeFormatted = value.endsWith(':59') ? value : value.substring(0, 3) + '59'
      } else {
        this.maxTimeFormatted = null
      }
    },
    handleCheckboxChange() {
      this.showScheduledDowntimeComponent = this.is_scheduled_downtime_period
      this.showDowntimeComponent = !this.is_scheduled_downtime_period
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    showScheduledDowntimeComponent: {
      get() {
        return this.is_scheduled_downtime_period
      },
      set(value) {
        // Implement the setter logic if needed
      }
    },
    showDowntimeComponent: {
      get() {
        return !this.is_scheduled_downtime_period
      },
      set(value) {
        // Implement the setter logic if needed
      }
    },
    minDateFormatted: {
      get() {
        return this.rawMinDate ? this.rawMinDate.toFormat('yyyy-MM-dd') : null
      },
      set(value) {
        this.rawMinDate = DateTime.fromFormat(value, 'yyyy-MM-dd')
      }
    },
    minTimeFormatted: {
      get() {
        return this.rawMinTime
      },
      set(value) {
        this.rawMinTime = value
      }
    },
    maxDateFormatted: {
      get() {
        return this.rawMaxDate ? this.rawMaxDate.toFormat('yyyy-MM-dd') : null
      },
      set(value) {
        this.rawMaxDate = value ? DateTime.fromFormat(value, 'yyyy-MM-dd') : null
      }
    },
    maxTimeFormatted: {
      get() {
        return this.rawMaxTime
      },
      set(value) {
        this.rawMaxTime = value
      }
    }
  },
  watch: {
    is_scheduled_downtime_period(newVal) {
      this.showScheduledDowntimeComponent = newVal
      this.showDowntimeComponent = !newVal
      this.reason_items = newVal
        ? this.reasons_scheduled
        : this.filterReasons(this.powerPlant?.reasons_translation)
    },
    powerPlantSelectorValue() {
      this.setPowerPlant()
    },
    'downtime_period.power_plant_id'() {
      this.powerPlantSelectorValue = this.downtime_period.power_plant_id
    },
    'scheduled_downtime_period.power_plant_id'() {
      this.powerPlantSelectorValue = this.scheduled_downtime_period.power_plant_id
    },
    powerPlant() {
      this.set_reasons()
    }
  }
}
</script>

<template lang="pug">
v-dialog(v-model="show" width="500")
  v-card.downtime_period_dialog
    v-card-title(class="headline grey lighten-2")
      span(v-if="downtime_period.id || scheduled_downtime_period.id") {{ $t('general.edit') }}
      span(v-else) {{ $t('general.create') }}
      span.lowercase &nbsp;{{ $t('downtime_periods.model_name') }}

    v-card-text.pb-1
      v-alert(type="error" v-if="errors['base']" v-for="error in errors['base']" :key="error")
        | {{ error }}
      v-row(v-if="power_plants.length")
        v-col
          v-select(
            v-model="powerPlantSelectorValue"
            :items="power_plants"
            item-value="id"
            item-text="name"
            v-bind="sharedProps('power_plant_id')"
          )

      v-row
        v-col.d-flex
          v-checkbox.mb-2(
            v-model="is_scheduled_downtime_period",
            :label="$t('downtime_periods.scheduled_downtime_period')"
            hide-details="auto"
            :readonly="(scheduled_downtime_period.id || downtime_period.id) ? true : false"
            @change="handleCheckboxChange"
          )
      div(v-if="dataLoaded")
        v-row
          v-col
            v-date-picker-input(
              :value="minDateFormatted"
              @input="onMinDateChange"
              prepend-icon="mdi-calendar"
              v-bind="sharedProps('starts_at')"
              ignoreReadonlyStyle
            )
          v-col
            v-time-picker-input(
              :value="minTimeFormatted"
              @input="onMinTimeChange"
              prepend-icon="mdi-clock"
              v-bind="sharedProps('starts_at_time')"
              label=""
              :allowed-minutes="[0]"
              readonly
              ignore-readonly-style
            )
        v-row
          v-col
            v-date-picker-input(
              :value="maxDateFormatted"
              @input="onMaxDateChange"
              prepend-icon="mdi-calendar"
              v-bind="sharedProps('ends_at')"
              ignoreReadonlyStyle
            )

          v-col
            v-time-picker-input(
              :value="maxTimeFormatted"
              @input="onMaxTimeChange"
              prepend-icon="mdi-clock"
              v-bind="sharedProps('ends_at_time')"
              label=""
              :allowed-minutes="[59]"
              readonly
              ignore-readonly-style
            )

        v-row
          v-col
            v-select(
              v-model="reason"
              :no-data-text="$t('downtime_periods.no-data-text')"
              :items="reason_items"
              prepend-icon="mdi-help"
              v-bind="sharedProps('reason')"
            )

        v-row
          v-col
            v-select(
              v-model="component"
              :no-data-text="$t('downtime_periods.no-data-text')"
              :items="components_items"
              prepend-icon="mdi-hammer-wrench"
              v-bind="sharedProps('component')"
            )

        v-row
          v-col
            v-textarea(
              v-model="comment"
              prepend-icon="mdi-comment"
              v-bind="sharedProps('comment')"
              :auto-grow="true"
              rows="2"
            )

        EditScheduledDowntime(
          v-if="showScheduledDowntimeComponent"
          :edit_mode="edit_mode"
          :powerPlant="powerPlant"
          :comment="comment"
          :reason="reason"
          :component="component"
          :scheduled_downtime_period="scheduled_downtime_period"
          :minDate="minDateFormatted"
          :maxDate="maxDateFormatted"
          :minTime="minTimeFormatted"
          :maxTime="maxTimeFormatted"
          :fetchRevenue="showScheduledDowntimeComponent"
          @error="e => errors = e"
          @cancel="show = false"
        )

        EditDowntime(
          v-if="showDowntimeComponent"
          :edit_mode="edit_mode"
          :powerPlant="powerPlant"
          :comment="comment"
          :reason="reason"
          :component="component"
          :downtime_period="downtime_period"
          :minDate="minDateFormatted"
          :maxDate="maxDateFormatted"
          :minTime="minTimeFormatted"
          :maxTime="maxTimeFormatted"
          :fetchRevenue="showScheduledDowntimeComponent"
          @error="e => errors = e"
          @cancel="show = false"
        )
</template>
