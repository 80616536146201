<script>
const quantile = (arr, q) => {
  const sorted = arr;
  const pos = (sorted.length - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (sorted[base + 1] !== undefined) {
    return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
  } else {
    return sorted[base];
  }
};

export default {
  props: {
    durations: Object,
    y_title_left: String,
    y_title_right: String,
    selected_price_area: {
      type: String,
      default: "SP1",
    },
  },
  data() {
    return {
      selectedPriceArea: this.selected_price_area,
      price_areas: [
        { value: "SP1", text: "SYS" },
        { value: "NO1", text: "NO1" },
        { value: "NO2", text: "NO2" },
        { value: "NO3", text: "NO3" },
        { value: "NO4", text: "NO4" },
        { value: "NO5", text: "NO5" },
        { value: "DK1", text: "DK1" },
        { value: "DK2", text: "DK2" },
        { value: "SE1", text: "SE1" },
        { value: "SE2", text: "SE2" },
        { value: "SE3", text: "SE3" },
        { value: "SE4", text: "SE4" }
      ],
      chart_options: {
        chart: {
          type: "column",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: null,
        },
        xAxis: [{}],
        yAxis: [
          {
            min: 0,
            title: {
              text: this.y_title_left,
              enabled: true,
            },
            plotLines: [
              {
                color: "green",
                value: quantile(this.durations[this.selected_price_area].left, 0.1),
                width: 1,
                label: {
                  align: "right",
                  y: 4,
                  useHTML: true,
                  formatter: function () {
                    return `<span style='background-color: green; color: white;'>P90: ${this.options.value.toFixed(
                      2
                    )}</span>`;
                  },
                },
                zIndex: 5,
              },
              {
                color: "green",
                value: quantile(this.durations[this.selected_price_area].left, 0.25),
                width: 1,
                label: {
                  align: "right",
                  y: 4,
                  useHTML: true,
                  formatter: function () {
                    return `<span style='background-color: green; color: white;'>P75: ${this.options.value.toFixed(
                      2
                    )}</span>`;
                  },
                },
                zIndex: 5,
              },
              {
                color: "red",
                value: quantile(this.durations[this.selected_price_area].left, 0.5),
                width: 2,
                label: {
                  align: "right",
                  y: 4,
                  useHTML: true,
                  formatter: function () {
                    return `<span style='background-color: red; color: white;'>Median: ${this.options.value.toFixed(
                      2
                    )}</span>`;
                  },
                },
                zIndex: 5,
              },
              {
                color: "green",
                value: quantile(this.durations[this.selected_price_area].left, 0.75),
                width: 1,
                label: {
                  align: "right",
                  y: 4,
                  useHTML: true,
                  formatter: function () {
                    return `<span style='background-color: green; color: white;'>P25: ${this.options.value.toFixed(
                      2
                    )}</span>`;
                  },
                },
                zIndex: 5,
              },
              {
                color: "green",
                value: quantile(this.durations[this.selected_price_area].left, 0.9),
                width: 1,
                label: {
                  align: "right",
                  style: {
                    fill: "rgba(255, 0, 20, 0.75)",
                    color: "white",
                  },
                  y: 4,
                  useHTML: true,
                  formatter: function () {
                    return `<span style='background-color: green; color: white;'>P10: ${this.options.value.toFixed(
                      2
                    )}</span>`;
                  },
                },
                zIndex: 5,
              },
            ],
          },
          {
            title: {
              text: "Pareto",
              enabled: false,
              visible: false,
            },
            minPadding: 0,
            maxPadding: 0,
            opposite: true,
            labels: {
              format: "{value}%",
            },
          },
        ],
        tooltip: {
          borderWidth: 0,
          backgroundColor: "rgba(255,255,255,0)",
          borderRadius: 3,
          shadow: false,
          useHTML: true,
          className: "market-outlook-duration-tooltip",
          formatter: function () {
            let s = "";
            const series = this.points[0].series.chart.series;
            const key = this.points[0].key;
            series.forEach((e, i) => {
              if (e) {
                const val = Array.isArray(e.options.data[key])
                  ? e.options.data[key][1]
                  : e.options.data[key];
                const suffix = e.tooltipOptions.valueSuffix
                  ? e.tooltipOptions.valueSuffix
                  : "";
                const valueDecimals = e.tooltipOptions.valueDecimals 
                  ? e.tooltipOptions.valueDecimals 
                  : 0;
                s += `<span style="color:${e.color}">●</span><b> ${e.name
                  }</b>: ${val.toFixed(valueDecimals)}${suffix}<br/>`;
              }
            });

            return s;
          },
          shared: true,
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
          series: {
            events: {
              legendItemClick: function () {
                return this.chart.series.some((s) => {
                  return this === s ? !s.visible : s.visible;
                });
              },
            },
          },
        },
        series: this.setPriceArea(this.selected_price_area)
      },
    };
  },
  methods: {
    setPriceArea: function (price_area) {
      return [
        {
          name: this.$t("general.price"),
          data: this.durations[price_area].left,
          tooltip: {
            valueDecimals: 2,
            pointFormat: "{series.name}: {point.y} EUR/MWh<br>",
          },
        },
        {
          name: this.$t("general.hours"),
          data: [...Array(this.durations[price_area].left.length + 1).keys()].map(i => i + 1),
          tooltip: {
            valueDecimals: 0,
          },
          showInLegend: false,
          visible: false
        },
        {
          type: "pareto",
          name: "Pareto",
          yAxis: 1,
          baseSeries: 0,
          data: this.durations[price_area].right,
          tooltip: {
            valueDecimals: 0,
            valueSuffix: "%",
          },
          visible: false,
        },
      ]
    },
    calcQuantiles: function(price_area) {
      return [0.1, 0.25, 0.5, 0.75, 0.9].map(q => quantile(this.durations[price_area].left, q))
    }
  },
  watch: {
    selectedPriceArea: {
      handler(newPriceArea) {
        this.chart_options.series = this.setPriceArea(newPriceArea.value);
        [this.chart_options.yAxis[0].plotLines[0].value, this.chart_options.yAxis[0].plotLines[1].value, this.chart_options.yAxis[0].plotLines[2].value, this.chart_options.yAxis[0].plotLines[3].value, this.chart_options.yAxis[0].plotLines[4].value] = this.calcQuantiles(newPriceArea.value)
        this.chart_options.tooltip = {
          borderWidth: 0,
          backgroundColor: "rgba(255,255,255,0)",
          borderRadius: 3,
          shadow: false,
          useHTML: true,
          className: "market-outlook-duration-tooltip",
          formatter: function () {
            let s = "";
            const series = this.points[0].series.chart.series;
            const key = this.points[0].key;
            series.forEach((e, i) => {
              if (e) {
                const val = Array.isArray(e.options.data[key])
                  ? e.options.data[key][1]
                  : e.options.data[key];
                const suffix = e.tooltipOptions.valueSuffix
                  ? e.tooltipOptions.valueSuffix
                  : "";
                const valueDecimals = e.tooltipOptions.valueDecimals 
                  ? e.tooltipOptions.valueDecimals 
                  : 0;
                s += `<span style="color:${e.color}">●</span><b> ${e.name
                  }</b>: ${val.toFixed(valueDecimals)}${suffix}<br/>`;
              }
            });

            return s;
          },
          shared: true,
        }
      },
    },
  },
};
</script>

<template lang="pug">
span
  v-row
    v-col.justify-center.align-center
      span(style="margin-right: auto")
    v-col.justify-center.align-center
      v-card-title 
        span {{ $t('market_outlook.duration') }}
    v-col.justify-center.align-center
      v-select.ml-auto(
        v-model="selectedPriceArea",
        :items="price_areas",
        :label="$t('general.price_area')",
        outlined,
        return-object,
        @change
      )
  v-highcharts(:options="chart_options")
</template>
