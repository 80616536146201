export default {
  methods: {
    validateDateFormat(dateString) {
      const regex = /^\d{4}-\d{2}-\d{2}$/;
      if (!regex.test(dateString)) {
        return null;
      }

      const [year, month, day] = dateString.split('-').map(Number);
      if (month < 1 || month > 12 || day < 1 || day > 31) {
        return null;
      }

      return dateString;
    },
  }
}
