<script>
export default {
  props: {
    data: Array,
    series_name: String,
  },
  data() {
    return {
      chart_options: {
        chart: {
          polar: true,
          type: 'line'
        },
        title: {
          text: 'KPIs',
        },

        pane: {
          size: '80%'
        },

        xAxis: {
          categories: this.$t('power_plants.spider_categories'),
          tickmarkPlacement: 'on',
          lineWidth: 0
        },

        yAxis: {
          gridLineInterpolation: 'polygon',
          gridLineColor: '#CCCCCC',
          lineWidth: 0,
          labels: {
            enabled: false
          }
        },
        plotOptions: {
          series: {
            animation: false
          }
        },
        tooltip: {
          shared: true,
          pointFormat: '<span style="color:{series.color}"><b>{point.y}%</b><br/>'
        },

        legend: {
          align: 'right',
          verticalAlign: 'middle',
          layout: 'vertical'
        },
        credits: {
          enabled: false
        },
        series: [{
          name: this.series_name,
          showInLegend: false,
          data: this.data,
          pointPlacement: 'on'
        //}, {
          //name: '',
          //type: 'line',
          //data: [100,100,100,100,100,100],
          //color: '#00E300',
          //lineWidth: 1,
          //marker: {
            //radius: 0
          //}
        }],
      }
    }
  }
}
</script>

<template lang="pug">
v-highcharts(:options="chart_options")
</template>
