<script>
import { syncCharts } from '../../../charts/sync_charts.js'

export default {
  name: 'ChartsOmDashboardVolume',
  props: {
    data: {
      type: Array,
      required: true
    },
    rated_power_mw: {
      type: Number,
      required: true
    },
    series_color: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      chart_options: {
        time: {
          timezoneOffset: new Date().getTimezoneOffset()
        },
        credits: {
          enabled: false
        },
        rangeSelector: {
          enabled: false
        },
        scrollbar: {
          enabled: false
        },
        navigator: {
          enabled: false
        },
        chart: {
          zooming: {
            mouseWheel: {
              enabled: false
            }
          },
          zoomType: 'x',
          margin: [0, 0, 30, 0],
          height: 200
        },
        yAxis: [
          {
            max: this.rated_power_mw * 1.1,
            min: 0,
            tickAmount: 6,
            gridZIndex: 4,
            startOnTick: false,
            endOnTick: false,
            opposite: false,
            labels: {
              align: 'left',
              x: 0,
              formatter() {
                return this.value + 'MW'
              }
            },
            plotLines: [
              {
                color: '#888888',
                width: 1,
                dashStyle: 'LongDash',
                zIndex: 5,
                label: {
                  text: `${this.$t('activerecord.attributes.power_plant.plant_effect_mw').replace(
                    '[MW]',
                    ''
                  )} ${this.rated_power_mw}MW`,
                  align: 'right',
                  x: 0,
                  style: {
                    color: '#444444',
                    fontSize: '0.9em'
                  }
                },
                value: this.rated_power_mw
              }
            ]
          }
        ],
        series: [
          {
            name: 'Production',
            data: this.data,
            type: 'areaspline',
            threshold: null,
            tooltip: {
              pointFormat: '{series.name} {point.y} MW',
              valueDecimals: 2
            },
            lineWidth: 1,
            color: this.series_color[0],
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [
                [0, this.series_color[1]],
                [1, this.series_color[2]]
              ]
            }
          }
        ],
        tooltip: {
          distance: 80,
          style: {
            padding: '10px'
          }
        }
      }
    }
  },
  mounted() {
    syncCharts()
  }
}
</script>

<template lang="pug">
  v-highcharts(:options="chart_options" constructor-type="stockChart")
</template>
