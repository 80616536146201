<script>
export default {
  props: {
    portfolio: Array,
    industry: Array,
    portfolio_color: String,
    industry_color: String,
    portfolio_name: String,
  },
  data() {
    return {
      chart_options: {
        title: {
          text: null,
        },
        chart: {
          height: 300,
        },
        xAxis: {
          type: 'datetime',
        },
        yAxis: {
          min: 0,
          title: null,
          labels: {
            formatter: function () {
              return this.value + '%';
            }
          },
          plotLines: [{
            color: '#E3AAAA',
            width: 2,
            value: 100
          }]
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [{
          name: this.portfolio_name,
          type: 'line',
          data: this.portfolio,
          color: this.portfolio_color,
        }, {
          name: 'Industry BM',
          type: 'line',
          data: this.industry,
          color: this.industry_color,
        }],
      }
    }
  },
}
</script>

<template lang="pug">
  v-highcharts(:options="chart_options")
</template>
