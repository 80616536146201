<script>
export default {
  props: {
    contracts: Array,
    power_plants: Array,
    admin: Boolean,
  },
  data() {
    return {
      inner_header: [],
      inner_data: [],
      inner_loading: [],
      expanded: [],
      query: '',
      bool_null_filter_options: [
        {'text': this.$t('general.all'), 'val': null},
        {'text': this.$t('general.yes'), 'val': true},
        {'text': this.$t('general.no'), 'val': false},
      ],
      computable_filter_options: [
        {'text': this.$t('general.all'), 'val': 'all'},
        {'text': this.$t('general.yes'), 'val': true},
        {'text': this.$t('general.no'), 'val': false},
        {'text': this.$t('general.unknown'), 'val': null},
      ],
      filter_computable: 'all',
      filter_confirmed: null,
      filter_active: null,
      footerProps: {
        'items-per-page-options': [200]
      },
      headers: [
        {
          text: this.$t('fall_lease.power_plant_name'),
          value: 'power_plant_names',
          width: '12%',
          class: 'col-power_plant_names',
          cellClass: 'col-power_plant_names'
        },
        {
          text: this.$t('fall_lease.contract_id'),
          value: 'id',
          class: 'col-id',
          cellClass: 'col-id'
        },
        {
          text: this.$t('fall_lease.start_date'),
          value: 'start_date',
          width: '6%',
          class: 'col-start_date',
          cellClass: 'col-start_date'
        },
        {
          text: this.$t('fall_lease.end_date'),
          value: 'end_date',
          width: '6%',
          class: 'col-end_date',
          cellClass: 'col-end_date'
        },
        {
          text: this.$t('fall_lease.model_type'),
          value: 'fall_lease.model_type',
          width: '8%',
          class: 'col-model_type',
          cellClass: 'col-model_type'
        },
        {
          text: this.$t('fall_lease.model_rate'),
          value: 'fall_lease.model_rate',
          width: '7%',
          class: 'col-model_rate',
          cellClass: 'col-model_rate'
        },

        {
          text: this.$t('fall_lease.currency'),
          value: 'fall_lease.currency',
          class: 'col-currency',
          cellClass: 'col-currency'
        },
        {
          text: this.$t('fall_lease.payment_interval'),
          value: 'fall_lease.payment_interval',
          class: 'col-payment_interval',
          cellClass: 'col-payment_interval'
        },
        {
          text: this.$t('fall_lease.threshold_type'),
          value: 'fall_lease.threshold_type',
          class: 'col-threshold_type',
          cellClass: 'col-threshold_type'
        },
        {
          text: this.$t('fall_lease.threshold_method'),
          value: 'fall_lease.threshold_method',
          class: 'col-threshold_method',
          cellClass: 'col-threshold_method'
        },
        {
          text: this.$t('fall_lease.threshold_value'),
          value: 'fall_lease.threshold_value',
          class: 'col-threshold_value',
          cellClass: 'col-threshold_value'
        },
        {
          text: this.$t('fall_lease.threshold_rate'),
          value: 'fall_lease.threshold_rate',
          class: 'col-threshold_rate',
          cellClass: 'col-threshold_rate'
        },
        {
          text: this.$t('fall_lease.calculation_interval'),
          value: 'fall_lease.averaging_interval',
          class: 'col-averaging_interval',
          cellClass: 'col-averaging_interval'
        },
        {
          text: this.$t('fall_lease.kpi_adjustment'),
          value: 'fall_lease.kpi_adjustment',
          class: 'col-kpi_adjustment',
          cellClass: 'col-kpi_adjustment'
        },
        {
          text: this.$t('fall_lease.kpi_ref_date'),
          value: 'fall_lease.kpi_ref_date',
          class: 'col-kpi_ref_date',
          cellClass: 'col-kpi_ref_date'
        },
        {
          text: this.$t('fall_lease.kpi_end_date_type'),
          value: 'fall_lease.kpi_end_date_type',
          class: 'col-kpi_end_date',
          cellClass: 'col-kpi_end_date'
        },
        {
          text: this.$t('fall_lease.payment_dates'),
          value: 'fall_lease.payment_dates',
          class: 'col-payment_dates',
          cellClass: 'col-payment_dates'
        },
        {
          text: this.$t('fall_lease.min_price'),
          value: 'fall_lease.min_price',
          class: 'col-min_price',
          cellClass: 'col-min_price'
        },
        {
          text: this.$t('fall_lease.fixed_price'),
          value: 'fall_lease.fixed_price',
          class: 'col-fixed_price',
          cellClass: 'col-fixed_price'
        },
        {
          text: this.$t('fall_lease.total_costs'),
          value: 'fall_lease.total_costs',
          class: 'col-total_costs',
          cellClass: 'col-total_costs'
        },
        {
          text: this.$t('fall_lease.elcert'),
          value: 'fall_lease.elcert',
          class: 'col-elcert',
          cellClass: 'col-elcert'
        },
        {
          text: this.$t('fall_lease.elcert_share'),
          value: 'fall_lease.elcert_share',
          class: 'col-elcert_share',
          cellClass: 'col-elcert_share'
        },
        {
          text: this.$t('fall_lease.goos'),
          value: 'fall_lease.goos',
          class: 'col-goos',
          cellClass: 'col-goos'
        },
        {
          text: this.$t('fall_lease.goos_share'),
          value: 'fall_lease.goos_share',
          class: 'col-goos_share',
          cellClass: 'col-goos_share'
        },
        {
          text: this.$t('fall_lease.revenue'),
          value: 'fall_lease.revenue',
          class: 'col-revenue',
          cellClass: 'col-revenue'
        },
        {
          text: this.$t('fall_lease.fall_lease'),
          value: 'fall_lease.fall_lease',
          width: '8%',
          class: 'col-fall_lease',
          cellClass: 'col-fall_lease'
        },
        {
          text: this.$t('fall_lease.computable'),
          value: 'fall_lease.computable',
          width: '6%',
          class: 'col-computable',
          align: 'center',
          cellClass: 'col-computable'
        },
        {
          text: this.$t('fall_lease.confirmed'),
          value: 'fall_lease.confirmed',
          width: '6%',
          class: 'col-confirmed',
          align: 'center',
          cellClass: 'col-confirmed'
        },
        {
          text: this.$t('fall_lease.locked'),
          value: 'fall_lease.locked',
          width: '6%',
          class: 'col-locked',
          align: 'center',
          cellClass: 'col-locked'
        },
        {
          text: this.$t('fall_lease.active'),
          value: 'active',
          width: '5%',
          class: 'col-active',
          align: 'center',
          cellClass: 'col-active'
        },
        {
          text: this.$t('fall_lease.actions'),
          value: 'menu',
          disabled: true,
          width: '6%',
          class: 'col-actions',
          cellClass: 'col-actions'
        },
      ],
      expandedHeaders: [],
      expandedData: [],
      selectedHeadersValues: [],
      selectedHeaders: [],
      selectedHeadersDefault: [
        "power_plant_names",
        "start_date",
        "end_date",
        "fall_lease.model_type",
        "fall_lease.model_rate",
        "fall_lease.total_costs",
        "fall_lease.goos",
        "fall_lease.elcert",
        "fall_lease.revenue",
        "fall_lease.fall_lease",
        "fall_lease.computable",
        "fall_lease.confirmed",
        "fall_lease.locked",
        "active",
        "menu"
      ],
    }
  },
  computed: {
    showHeaders: function () {
      return this.headers.filter(h => this.selectedHeadersValues.some(sh => h.value === sh))
    },
    countPowerPlants() {
      return this.contracts.length;
    },
    countAllPowerPlants() {
      return this.power_plants.length;
    },
    sumFallLease() {
      return this.contracts.reduce((accum, item) => accum + item.fall_lease.fall_lease, 0);
    },
    contracts_and_power_plants() {
      let items = [];
      let power_plant_ids_included = [];

      this.contracts.forEach(function (contract) {
        power_plant_ids_included.push(contract.power_plant_id);
        items.push(contract);
      }, this);

      this.power_plants.forEach(function (power_plant) {
        if (!power_plant_ids_included.includes(power_plant.id)) {
          items.push({
            "id": null,
            "power_plant_id": power_plant.id,
            "power_plant_names": power_plant.name,
            "active": null,
            "counterpart": null,
            "end_date": null,
            "start_date": null,
            "fall_lease": {
              "id": null,
              "model_type": null,
              "model_rate": null,
              "averaging_interval": null,
              "threshold_type": null,
              "threshold_method": null,
              "threshold_value": null,
              "threshold_rate": null,
              "total_costs": null,
              "revenue": null,
              "min_price": null,
              "fixed_price": null,
              "kpi_adjustment": null,
              "kpi_ref_date": null,
              "kpi_end_date_type": null,
              "payment_interval": null,
              "payment_dates": null,
              "computed_value": null,
              "confirmed": null,
              "locked": null,
              "notes": null,
              "currency": "NOK",
              "elcert": null,
              "elcert_share": null,
              "goos": null,
              "goos_share": null,
              "fall_lease": null,
              "computable": null
            }
          })
        }
      }, this);

      const filtered_items = items.filter(function (contract) {
        return (
            (this.filter_computable === "all" || this.filter_computable === contract.fall_lease.computable) &&
            (this.filter_confirmed === contract.fall_lease.confirmed || this.filter_confirmed === null) &&
            (this.filter_active === contract.active || this.filter_active === null)
        )
          }
          , this);

      return filtered_items;
    }
  },
  methods: {
    preview_table({item, value}) {
      this.inner_header = [];
      this.inner_data = [];
      this.inner_loading = true;
      this.axios.get('/admin/fall_leases/' + item.id + '/preview_table.json')
          .then(response => {
            this.inner_data = response.data;
            this.inner_header = Object.keys(response.data[0]).map((el) => ({text: el, value: el, align: 'center'}));
            this.inner_loading = false;
          })
    },
    loadLocalStorageHeaders() {
      let header_values = JSON.parse(localStorage.getItem('fall_lease_table_headers'));
      if (header_values && header_values.length > 0) {
        this.selectedHeadersValues = header_values;
      } else {
        this.selectedHeadersValues = this.selectedHeadersDefault;
      }
      this.selectedHeaders = this.headers.filter(h => this.selectedHeadersValues.some(sh => h.value === sh))
    },
    summaryColumnFilter(header) {
      let markers = ['[NOK]', '[EUR]'];
      return markers.some(slug => header.includes(slug));
    },
    summaryColumnSum(key) {
      let total = 0
      const sum = this.inner_data.reduce((acc, cur) => {
        return (total += +cur[key])
      }, 0)
      return sum.toFixed(2);
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    noDecimal(value) {
      if (this.isNumeric(value)) {
        return Number(value).toLocaleString('no-NO', {maximumFractionDigits: 0})
      }
      return value
    },
    formatPercent(value) {
      if (this.isNumeric(value)) {
        return Number(value / 100).toLocaleString('no-NO', {style: 'percent', minimumFractionDigits: 1});
      }
      return value
    },
    newContractLink(item) {
      let url = new URL("/admin/contracts/new?type=Contract::FallLease", window.location.origin);

      const params = new URLSearchParams(window.location.search);
      const return_to = "/admin/fall_leases";
      const year = params.get("year");

      let start_date = "";
      let end_date = "";
      let back = "";

      if (year !== null && !isNaN(year)) {
        start_date = `${year}-01-01`;
        end_date = `${year}-12-31`;
        back = `${return_to}?year=${year}`;
      } else {
        const current_year = new Date().getFullYear();
        start_date = `${current_year}-01-01`;
        end_date = `${current_year}-12-31`;
        back = `${return_to}?year=${current_year}`;
      }

      url.searchParams.append('start_date', start_date);
      url.searchParams.append('end_date', end_date);
      url.searchParams.append('back', back);
      url.searchParams.append('power_plant', item.power_plant_id);

      return url.href;
    }
  },
  watch: {
    selectedHeaders: function (newState, oldState) {
      this.selectedHeadersValues = this.selectedHeaders.map(h => h.value);
      localStorage.setItem('fall_lease_table_headers', JSON.stringify(this.selectedHeadersValues));
    }
  },
  created() {
    this.loadLocalStorageHeaders();
  },
}
</script>

<template lang="pug">
  div
    v-row
      v-col(cols="3")
        v-card(
          variant="outlined"
          elevation="2"
        )
          v-card-text
            v-row
              v-col {{this.$t('general.number_of_power_plants')}}: {{ countPowerPlants }} / {{ countAllPowerPlants }}
            v-row
              v-col {{this.$t('fall_lease.total_fall_leases')}}: {{ noDecimal(sumFallLease) }} NOK
      v-col(cols="2")
        v-select(
          v-model="filter_computable"
          :items="computable_filter_options"
          :label="$t('fall_lease.computable')"
          item-text="text"
          item-value="val"
          outlined
          dense
        )
      v-col(cols="2")
        v-select(
          v-model="filter_confirmed"
          :items="bool_null_filter_options"
          :label="$t('fall_lease.confirmed')"
          item-text="text"
          item-value="val"
          outlined
          dense
        )
      v-col(cols="2")
        v-select(
          v-model="filter_active"
          :items="bool_null_filter_options"
          :label="$t('fall_lease.active')"
          item-text="text"
          item-value="val"
          outlined
          dense
        )
      v-col(cols="3")
        v-select(
          v-model="selectedHeaders"
          :items="headers"
          :label="$t('fall_lease.select_columns_to_show')"
          multiple
          outlined
          dense
          return-object
        )
          template(v-slot:selection="{item, index}")
        v-text-field(
          v-model="query"
          :label="$t('fall_lease.filter_data')"
          append-icon="mdi-magnify"
          hide-details
          outlined
          dense
        )
    v-row
      v-col
        v-data-table#fall_lease_index(
          :headers="showHeaders"
          :items="contracts_and_power_plants"
          :search="query"
          :footer-props="footerProps"
          class="elevation-4"
          dense
          show-expand
          :single-expand="true"
          :expanded.sync="expanded"
          @item-expanded="preview_table"
          fixed-header
          height="calc(100vh - 290px)"
        )
          template(v-slot:item.fall_lease.model_rate="{item, value}") {{ formatPercent(value) }}
          template(v-slot:item.fall_lease.elcert="{item, value}")
            div(class="text-right") {{ noDecimal(value) }}
          template(v-slot:item.fall_lease.elcert_share="{item, value}") {{ formatPercent(value) }}
          template(v-slot:item.fall_lease.goos="{item, value}")
            div(class="text-right") {{ noDecimal(value) }}
          template(v-slot:item.fall_lease.goos_share="{item, value}") {{ formatPercent(value) }}
          template(v-slot:item.fall_lease.revenue="{item, value}")
            div(class="text-right") {{ noDecimal(value) }}
          template(v-slot:item.fall_lease.total_costs="{item, value}")
            div(class="text-right") {{ noDecimal(value) }}
          template(v-slot:item.fall_lease.fixed_price="{item, value}")
            div(class="text-right") {{ noDecimal(value) }}
          template(v-slot:item.fall_lease.fall_lease="{item, value}")
            div(class="text-right")
              span(v-if="item.fall_lease.notes === null") {{ noDecimal(value) }}
              v-tooltip(v-else bottom)
                template(v-slot:activator="{ on, attrs }")
                  v-chip(v-bind="attrs" v-on="on" color="warning" small) {{ noDecimal(value) }}
                span {{ item.fall_lease.notes }}
          template(v-slot:item.active="{item, value}")
            span(v-if="value === null")
            v-chip(v-else-if="value" color='green' dark x-small) {{ $t('general.yes') }}
            v-chip(v-else color='error' dark x-small) {{ $t('general.no') }}
          template(v-slot:item.fall_lease.confirmed="{item, value}")
            span(v-if="value === null")
            v-chip(v-else-if="value" color='green' dark x-small) {{ $t('general.yes') }}
            v-chip(v-else color='error' dark x-small) {{ $t('general.no') }}
          template(v-slot:item.fall_lease.locked="{item, value}")
            span(v-if="value === null")
            v-chip(v-else-if="value" color='green' dark x-small) {{ $t('general.yes') }}
            v-chip(v-else color='error' dark x-small) {{ $t('general.no') }}
          template(v-slot:item.fall_lease.computable="{item, value}")
            v-tooltip(v-if="value === false" bottom)
              template(v-slot:activator="{ on, attrs }")
                v-chip(v-bind="attrs" v-on="on" color='error' dark x-small) {{ $t('general.no') }}
              span {{ item.fall_lease.notes }}
            v-chip(v-else-if="value === true" color='green' dark x-small) {{ $t('general.yes') }}
            span(v-else-if="value === null && item.fall_lease.id !== null")
              v-chip(color="grey" dark x-small) ?
          template(v-slot:item.menu="{item}")
            v-menu(v-if="item.id !== null")
              template(v-slot:activator="{ on, attrs }")
                v-btn(
                  :href="`/admin/fall_leases/${item.id}`"
                  v-bind="attrs"
                  v-on="on"
                  x-small
                  dark
                  fab
                  color="indigo darken-2"
                  class="action-compute"
                )
                  v-icon mdi-magnify
            v-menu(v-if="item.id === null")
              template(v-slot:activator="{ on, attrs }")
                v-btn(
                  :href="newContractLink(item)"
                  v-bind="attrs"
                  v-on="on"
                  x-small
                  dark
                  fab
                  color="light-blue"
                )
                  v-icon mdi-plus
          template(v-slot:expanded-item="{ headers, item }")
            td(:colspan="headers.length")
              v-data-table(
                :headers="inner_header"
                :items="inner_data"
                :footer-props="footerProps"
                :loading="inner_loading"
                class="table-striped elevation-0 ma-4 preview-table"
                dense
              )
                template(v-for="header in inner_header" v-slot:[`item.${header.value}`]="{ header, value }")
                  div(class="text-right") {{ noDecimal(value) }}
                template(v-slot:[`body.append`]="{headers}")
                  tr(class="summary")
                    th(v-for="(header, i) in headers" :key="i")
                      p(class="text-left text-subtitle-2" v-if="i===0") {{ $t('fall_lease.total') }}
                      p(class="text-right text-subtitle-2" v-if="summaryColumnFilter(header.text)") {{ noDecimal(summaryColumnSum(header.value)) }}

          template(v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }")
            v-icon(
              v-if="item.fall_lease.confirmed"
              :class="['v-data-table__expand-icon',{ 'v-data-table__expand-icon--active': isExpanded },]"
              @click.stop="expand(!isExpanded)"
            ) $expand
</template>
