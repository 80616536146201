<script>
export default {
  props: {
    contracts: Array,
    admin: Boolean,
  },
  data() {
    return {
      query: '',
      footerProps: {
        'items-per-page-options': [200]
      },
      headers: [
        {text: 'CID#', value: 'id'},
        {text: this.$t("general.contract_type"), value: 'type_name'},
        {text: this.$t("general.power_plant_names"), value: 'power_plant_names'},
        {text: this.$t("general.start_date"), value: 'start_date'},
        {text: this.$t("general.end_date"), value: 'end_date'},
        {text: this.$t("general.active"), value: 'active'},
        {text: '', value: 'menu'},
      ]
    }
  },
  mounted() {
    this.$refs.power_plant_query.focus();
  },
  methods: {
    async destroy_contract(contract_id) {
      if(!window.confirm("Are you sure?")) return
      await this.axios.delete(`/admin/contracts/${contract_id}`)
      window.location.reload()
    }
  }
}
</script>

<template lang="pug">
  div
    v-text-field(v-model="query" ref="power_plant_query" append-icon="mdi-magnify" :label="$t('general.search')" single-line hide-details)
    v-data-table(:headers="headers" :items="contracts" :search="query" :footer-props="footerProps" class="elevation-4" dense)
      template(v-slot:item.active="{item, value}")
        v-chip(v-if="item.active" color='green' dark x-small) {{ $t('general.yes') }}
        v-chip(v-else color='error' dark x-small) {{ $t('general.no') }}
      template(v-slot:item.power_plant_names="{item, value}")
        v-chip.mr-1.my-1(v-for="name in value" :key="name" small) {{name}}
      template(v-slot:item.fall_lease.confirmed="{item, value}")
        v-chip(v-if="item.fall_lease.confirmed" color='green' dark x-small) {{ $t('general.yes') }}
        v-chip(v-else color='error' dark x-small) {{ $t('general.no') }}
      template(v-slot:item.fall_lease.locked="{item, value}")
        v-chip(v-if="item.fall_lease.locked" color='green' dark x-small) {{ $t('general.yes') }}
        v-chip(v-else color='error' dark x-small) {{ $t('general.no') }}
      template(v-slot:item.menu="{item}")
        v-btn(small depressed :href="`/admin/contracts/${item.id}/edit`" color="primary") {{ $t('general.edit') }}
        v-btn.ml-2(small depressed @click="destroy_contract(item.id)") {{ $t('general.delete') }}
</template>
