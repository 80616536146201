<script>
import NewsWidget from '../news_items/news_widget.vue'

export default {
  components: {
    NewsWidget
  },
  props: {
    toggleNewsOpen: Function,
    newsOpen: Boolean,
    news: Array
  }
}
</script>

<template lang="pug">
  v-card(class="news-card" width="400")
    v-toolbar(color="pink" dark)
      v-toolbar-title {{ $t('overview_map.news') }}
      v-spacer
      v-btn(icon @click="toggleNewsOpen")
        v-icon {{ newsOpen ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
    v-list(v-if="newsOpen")
      template(v-for="(newsItem, i) in news")
        NewsWidget(:news_item="newsItem" :comments_enabled="false" :news_item_read="true")
          v-list-item(:key="i" class="news-list-item")
            v-list-item-avatar(v-if="newsItem.image_url")
              v-img(:src="newsItem.image_url")
            v-list-item-content {{ newsItem.title }}
        v-divider
</template>
