<script>
import { DateTime } from 'luxon'
import OMDashboardHelpers from '../../../../mixins/om_dashboard_helpers'
export default {
  props: {
    plantId: Number,
    values: Array
  },
  async mounted() {
    // await this.loadHeatmapData()
  },
  mixins: [OMDashboardHelpers],
  data() {
    return {
      loading: true,
      selectedField: null,
      solarFields: [],
      visibleData: [],
      xLabels: [],
      yLabels: [],
      organized: []
    }
  },
  methods: {
    changeField(field) {
      this.loading = true
      this.selectedField = field
      this.organizeData(this.values)
    },
    organizeData(data) {
      this.solarFields = [...new Set(data.map((i) => i.module_field_name))]
      this.selectedField = this.selectedField ? this.selectedField : this.solarFields[0]
      this.visibleData = data.filter((v) => v.module_field_name === this.selectedField)
      this.yLabels = [...new Set(this.visibleData.map((i) => i.solar_mppt_name))]
      const numDays = this.visibleData.length / this.yLabels.length
      const dateFormat = numDays > 365 ? 'd MMM yyyy' : 'd MMM'
      this.xLabels = [
        ...new Set(
          this.visibleData.map((i) => DateTime.fromFormat(i.date, 'yyyy-MM-dd').toFormat(dateFormat))
        )
      ]
      this.organized = []
      const days = this.xLabels.length
      const mppts = this.yLabels.length
      let outer = 0

      for (let i = 0; i < days; i++) {
        for (let l = 0; l < mppts; l++) {
          if (this.visibleData[outer])
            this.organized.push({
              x: i,
              y: l,
              value: this.visibleData[outer].full_load_hours,
              mppt: this.visibleData[outer].solar_mppt_name,
              date: this.visibleData[outer].date
            })
          outer++
        }
      }
      this.loading = false
    }
  },
  watch: {
    values: {
      handler(newVals, oldVals) {
        if (newVals.length) {
          if (
            !oldVals.length ||
            (newVals[0].date !== oldVals[0].date && newVals.at(-1).date !== oldVals.at(-1).date)
          ) {
            this.organizeData(this.values)
            this.loading = false
          }
        }
      }
    }
  },
  computed: {
    chart_options() {
      return {
        chart: {
          type: 'heatmap',
          backgroundColor: '#F5FAFF',
          // events: {
          //   render: function () {
          //     this.reflow()
          //   }
          // },
          marginTop: 40,
          marginBottom: 80,
          plotBorderWidth: 1,
          scrollablePlotArea: {
            minWidth: 1024,
            opacity: 1
          }
        },
        colorAxis: {
          reversed: false,
          gridLineWidth: 0,
          stops: [
            [0, '#360498'],
            [0.3, '#D4556E'],
            [1, '#F7DB1B']
          ],
          labels: {
            enabled: true,
            formatter: function () {
              return this.value + ' kWh/kWp'
            }
          }
        },
        yAxis: {
          categories: this.yLabels,
          title: null,
          reversed: true,
          labels: {
            style: {
              fontSize: '0.6rem'
            }
          }
        },

        xAxis: {
          categories: this.xLabels
        },
        tooltip: {
          formatter: (p) => {
            let point = p.chart.hoverPoint
            if (point.value >= 0 && typeof point.value == 'number')
              return `<strong>${p.chart.yAxis[0].categories[point.y]}</strong><br /><em>${
                p.chart.xAxis[0].categories[point.x]
              }</em><br /><br />${point.value.toFixed(2)} kWh/kWp`
            else return this.$t('om_dashboard.status.solar.no_data')
          }
        },
        boost: {
          enabled: true
        },
        legend: {
          align: 'right',
          layout: 'vertical',
          margin: 0,
          verticalAlign: 'middle',
          width: 80,
          y: 0,
          symbolHeight: 280,
          enabled: true
        },
        accessibility: {
          point: {
            descriptionFormatter: function (point) {
              return point
            }
          }
        },
        credits: {
          enabled: false
        },
        title: false,
        exporting: {
          ...this.isExportable(this.$t('om_dashboard.drilldown.export_menu.filenames.mppt_heatmap')),
          ...{ align: 'left' }
        },
        series: [
          {
            boostThreshold: 0,
            borderWidth: 0,

            data: this.organized,
            dataLabels: {
              enabled: false
            },
            turboThreshold: Number.MAX_VALUE
          }
        ]
      }
    }
  }
}
</script>

<template lang="pug">
v-container.mppt_heatmap
  v-row
    v-col.graph_title {{ $t('om_dashboard.drilldown.all_mppts') }}
  v-row
    v-col.graph_subtitle {{ $t('om_dashboard.drilldown.full_load_hours') }}    
    v-col.d-flex(cols="2" v-if="values.length > 0")
      v-select(:items="solarFields" v-model="selectedField" @change="changeField" :label="$t('om_dashboard.drilldown.choose_field')" outlined)

  v-highcharts(v-if="values.length > 0" :options="chart_options")
  v-row(v-else)
    v-col 
</template>
