<script>

import ChartsPercent from './chart_percent.vue'
import ChartsCount from './chart_count.vue'
import ChartsLine from './chart_line.vue'
import ChartsSpline from './chart_spline.vue'
import ScopeCard from './scope_card.vue'

export default {
  components: {
    ChartsPercent,
    ChartsCount,
    ChartsLine,
    ChartsSpline,
    ScopeCard,
  },
  props: {
    portfolio_name: String,
    price_area: String,
    date_scope: String,
    benchmarking_type: String,
  },
  data() {
    return {
      data_set: 'production',
      portfolio: {},
      industry: {},
      data_points: [],
    }
  },
  computed: {
    ready() { return this.data_points.length > 0 },
    showPercentChart() { return this.ready && ['no_production', 'operational', 'production', 'runoff', 'price_factor', 'full_load'].includes(this.data_set) },
    showCountChart() { return this.ready && ['downtime'].includes(this.data_set) },
    charts_column_size() { return this.portfolio.station_count > 40 ? 12 : 6 },
    scope_column_size() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 12
        case 'sm': return 12
        case 'md': return 6
        case 'lg': return 4
        case 'xl': return 2
      }
    },
  },
  methods: {
    async loadData() {
      try {
        this.portfolio = {}
        this.industry = {}
        this.data_points = []
        let url = `/benchmarking/summary.json?date_scope=${this.date_scope}&price_area=${this.price_area}&benchmarking_type=${this.benchmarking_type}`
        let response = await this.axios.get(url)
        this.portfolio = response.data.portfolio
        this.industry = response.data.industry
        this.loadSelectedDetails()
      } catch (e) {
        alert('An unexpected error occurred')
      }
    },
    async loadSelectedDetails() {
      this.data_points = []
      this.$nextTick(() => {
        this.data_points = this.portfolio[`${this.data_set}_data`] || []
      });
    },
  },
  watch: {
    data_set() { this.loadSelectedDetails() },
  },
  mounted() {
    this.loadData()
  },
}
</script>

<template lang="pug">
#benchmarking
  v-container(v-if="!ready")
    v-row
      v-col(align="center")
        v-progress-circular(indeterminate=true class="mr-2")
        | {{ $t('benchmarking.loading_please_wait') }}

  .d-inline-block(v-if="ready")
    h1
      v-badge(color='purple' content='BETA' :overlap='true')
        | {{ $t('benchmarking.heading') }} 

  .d-inline-block(v-if="ready")
    .group-info(v-for="group in [portfolio, industry]")
      .cell
        v-avatar(:color="group.color" size="12")
          span
        |
        | {{ group.name }}

      .cell
        | {{ group.station_count }}
        span.weak.lowercase {{ $t('general.power_plants') }}

      .cell
        | {{ group.mw }}
        span.weak MW

      .cell
        | {{ group.gwh }}
        span.weak GWh

  #pick-data-set(v-if="ready")
    v-row
      v-col(:cols="scope_column_size")
        .change-data-set(@click="data_set = 'production'")
          tooltip(:tip="$t('benchmarking.tooltips.production')" :bottom="true")
            scope-card(:name="$t('general.production')" :portfolio="portfolio.production_total" :industry="industry.production_total" :active="data_set == 'production'" unit="%" :portfolio_name="portfolio_name" icon="mdi-flash")
      v-col(:cols="scope_column_size" v-if="benchmarking_type != 'wind'")
        .change-data-set(@click="data_set = 'runoff'")
          tooltip(:tip="$t('benchmarking.tooltips.runoff')" :bottom="true")
            scope-card(:name="$t('general.runoff')" :portfolio="portfolio.runoff_total" :industry="industry.runoff_total" :active="data_set == 'runoff'" unit="%" :portfolio_name="portfolio_name" icon="mdi-water")
      v-col(:cols="scope_column_size")
        .change-data-set(@click="data_set = 'operational'")
          tooltip(:tip="$t('benchmarking.tooltips.operational')" :bottom="true")
            scope-card(:name="$t('benchmarking.operational_hours')" :portfolio="portfolio.operational_total" :industry="industry.operational_total" :active="data_set == 'operational'" :portfolio_name="portfolio_name" icon="mdi-battery-charging-50" unit="%")
      v-col(:cols="scope_column_size")
        .change-data-set(@click="data_set = 'full_load'")
          tooltip(:tip="$t('benchmarking.tooltips.full_load')" :bottom="true")
            scope-card(:name="$t('benchmarking.full_load')" :portfolio="portfolio.full_load_total" :industry="industry.full_load_total" :active="data_set == 'full_load'" :portfolio_name="portfolio_name" icon="mdi-battery-charging-100" unit="%")
      v-col(:cols="scope_column_size")
        .change-data-set(@click="data_set = 'no_production'")
          tooltip(:tip="$t('benchmarking.tooltips.no_production')" :bottom="true")
            scope-card(:name="$t('benchmarking.no_load_hours')" :portfolio="portfolio.no_production_total" :industry="industry.no_production_total" :active="data_set == 'no_production'" :portfolio_name="portfolio_name" icon="mdi-battery-charging-outline" unit="%")
      v-col(:cols="scope_column_size")
        .change-data-set(@click="data_set = 'price_factor'")
          tooltip(:tip="$t('benchmarking.tooltips.price_factor')" :bottom="true")
            scope-card(:name="$t('benchmarking.price_factor')" :portfolio="portfolio.price_factor_total" :industry="industry.price_factor_total" :active="data_set == 'price_factor'" unit="%" :portfolio_name="portfolio_name" icon="mdi-currency-eur")

  v-row(v-if="ready")
    v-col(cols="12" :lg="charts_column_size" v-if="ready")
      tooltip(:tip="$t('help_texts.benchmarking.chart_bar')" bottom="")
        charts-percent.chart(v-if="showPercentChart" :data='data_points')
        charts-count.chart(v-else-if="showCountChart" :data='data_points')

    v-col(cols="12" :lg="charts_column_size" v-if="ready")
      tooltip(:tip="$t('help_texts.benchmarking.chart_line')" bottom="")
        charts-line.chart(v-if="data_set == 'production'" key="production" :portfolio="portfolio.production_monthly" :industry="industry.production_monthly" :portfolio_color="portfolio.color" :industry_color="industry.color" :portfolio_name="portfolio_name")
        charts-line.chart(v-if="data_set == 'runoff'" key="runoff" :portfolio="portfolio.runoff_monthly" :industry="industry.runoff_monthly" :portfolio_color="portfolio.color" :industry_color="industry.color" :portfolio_name="portfolio_name")
        charts-spline.chart(v-if="data_set == 'no_production'" key="no_production" :portfolio="portfolio.no_production_daily" :industry="industry.no_production_daily" :portfolio_color="portfolio.color" :industry_color="industry.color" :portfolio_name="portfolio_name")
        charts-spline.chart(v-if="data_set == 'operational'" key="operational" :portfolio="portfolio.operational_daily" :industry="industry.operational_daily" :portfolio_color="portfolio.color" :industry_color="industry.color" :portfolio_name="portfolio_name")
        charts-spline.chart(v-if="data_set == 'full_load'" key="full_load" :portfolio="portfolio.full_load_daily" :industry="industry.full_load_daily" :portfolio_color="portfolio.color" :industry_color="industry.color" :portfolio_name="portfolio_name")
        charts-spline.chart(v-if="data_set == 'price_factor'" key="price_factor" :portfolio="portfolio.price_factor_daily" :industry="industry.price_factor_daily" :portfolio_color="portfolio.color" :industry_color="industry.color" :portfolio_name="portfolio_name")
        //-charts-spline.chart(v-if="data_set == 'downtime'" key="downtime" :portfolio="portfolio.downtime_hours_daily" :industry="industry.downtime_hours_daily" :portfolio_color="portfolio.color" :industry_color="industry.color" :portfolio_name="portfolio_name")

</template>
