<script>
import ValueMixin from './value_mixins.vue'
export default {
  props: {
    value: Boolean,
    fall_lease_contract: Object,
    contract: Object,
    selected_account_type: String,
    selected_codes: String,
    disabled: Boolean
  },
  mixins: [ValueMixin],
  data() {
    return {
      localSelectedCodes: this.selected_codes || '',
      form_patch_request_headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      ...this.defaultData()
    
    }
  },
  computed: {
    account_items() {
      if(this.isEmpty(this.selected_account_type)) {
        return []
      }
      return Object.values(this.account_data[this.selected_account_type])
    },
    selected_owners() {
      if(this.isEmpty(this.selected_account_type)) {
        return ''
      }
      return this.fall_lease_contract[this.selected_account_type + "_owners"] || ''
    },
    sumTotalFormatted() {
      return this.formatMoney(this.accounts_computed_sum)
    }
  },
  methods: {
    defaultData() {
      return {
        accounts_dialog_display: this.value,
        account_data: {
          'total_costs': {},
          'revenue': {},
          'elcert': {},
          'goos': {}
        },

        account_search: '',
        account_codes: this.localSelectedCodes,
        account_owners: this.selected_owners || '',
        accounts_loading: true,
        accounts_selected: [],
        select_override: false,
        accounts_selected_override: [],

        account_headers: [
          {text: this.model_label('period'), value: 'period'},
          {text: this.model_label('year'), value: 'year'},
          {text: this.model_label('month'), value: 'month'},
          {text: this.model_label('code'), value: 'code'},
          {text: this.model_label('owner'), value: 'owner_description'},
          {text: this.model_label('description'), value: 'description'},
          {text: this.model_label('amount'), value: 'amount', align: 'right'},
          {text: '', value: 'data-table-select'}
        ],

        accounts_computed_sum: 0,
      }
    },
    formatMoney(value) {
      return parseFloat(value).toLocaleString('no-NO', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })
    },
    model_label(field) {
      return this.$t(`activerecord.attributes.fall_lease.${field}`)
    },
    async getAccountData(accountType) {
      const xledgerAccountRefMap = {
        revenue: 'revenue',
        elcert: 'revenue',
        goos: 'revenue',
        total_costs: 'cost'
      }

      const url = `/admin/fall_leases/${this.contract.id}/xledger?account_ref=${xledgerAccountRefMap[accountType]}&account_type=${this.selected_account_type}`
      try {
        const response = await this.axios.get(url)
        return response.data
      } catch (error) {
        alert(error.response.data)
      }
    },

    async fetchAccountData() {
      if (this.isEmpty(this.selected_account_type)) {
        return
      }

      const accountType = this.selected_account_type

      this.account_codes = this.localSelectedCodes
      this.account_owners = this.selected_owners

      this.accounts_loading = true
      try {
        const processedData = await this.getAccountData(accountType)
        this.processAccountData(accountType, processedData)
      } catch (error) {
        alert(error.response.data.description)
      }
    },

    processAccountData(accountType, data) {
      if (!this.account_data[accountType]) {
        this.$set(this.account_data, accountType, {})
      }

      this.account_data[accountType] = {
        ...this.account_data[accountType],
        ...data
      }

      if (this.account_data && this.account_data[accountType]) {
        this.accounts_loading = false
      }
    },
    sumTotal(values_selected) {
      const selected_rows = values_selected.map((value) => (parseFloat(value.amount)))

      this.accounts_computed_sum = selected_rows.reduce((a, b) => a + b, 0)
    },
    closeModal() {
      this.accounts_dialog_display = false
    },
    async saveModal() {
      this.fall_lease_contract[this.selected_account_type + "_codes"] = this.account_codes
      this.fall_lease_contract[this.selected_account_type + "_owners"] = this.account_owners

      if (!this.accounts_loading) {
        this.fall_lease_contract[this.selected_account_type] = this.accounts_computed_sum

        let accounts_selected_combined = this.accounts_selected
          .map(obj => ({ ...obj, selected: true }))
          .filter(el => !this.accounts_selected_override.some(els => els.id === el.id))
          .concat(this.accounts_selected_override)

        await this.axios.post(
            `/admin/fall_leases/${this.contract.id}/xledger_data_set`,
            {"data": accounts_selected_combined, "type": this.selected_account_type}
        )
      }

      await this.axios.patch(
          `/admin/fall_leases/${this.contract.fall_lease.id}`,
          {
            [this.selected_account_type + "_codes"]: this.account_codes,
            [this.selected_account_type + "_owners"]: this.account_owners,
            [this.selected_account_type]: this.accounts_computed_sum
          }
      )

      this.accounts_dialog_display = false
    },
    filtersChanged() {
      let codes = this.account_codes.split(/[ ,]+/).filter(item => !this.isEmpty(item))
      let owners = String(this.account_owners || '').trim().toLowerCase().replace(/\s+/g, '')

      this.accounts_selected = this.account_items.filter(item => {
        const formattedOwner = item.owner_description.toLowerCase().replace(/\s+/g, '')
        const codeFilter = codes.length > 0 ? codes.includes(item.code) : true
        const ownerFilter = owners.length > 0 ? formattedOwner.includes(owners) : true

        if (codes.length === 0 && owners.length === 0) {
          return item.selected_override === true
        }

        return codeFilter && ownerFilter
      })
    },
    toggleCheck() {
      if(this.accounts_selected.length > 0) {
        this.accounts_selected = []
      } else {
        this.accounts_selected = this.account_items
      }
    },
    checkboxIndeterminate() {
      return this.accounts_selected.length !== 0 && this.accounts_selected.length !== this.account_items.length
    },
    itemSelected(event) {
      let item = event.item
      item.selected_override = event.value

      this.accounts_selected_override = this.accounts_selected_override.filter(el => el.id !== item.id)

      let was_selected = !!this.accounts_selected.find(obj => obj.id === event.item.id)
      // in case when item was not selected the item.selected is null
      if (item.selected === null) {
        item.selected = was_selected
      }
      
      this.accounts_selected_override.push(item)      
    },
    itemRowStyle(item) {
      return item.selected_override !== null? 'selected_override' : ''
    }
  },
  watch: {
    selected_codes(newCodes) {
      this.localSelectedCodes = newCodes
    },
    async value(new_value) {
      // Reset data
      Object.assign(this.$data, this.defaultData())

      if(!new_value) {
        return
      }

      await this.fetchAccountData(this.selected_account_type)
      this.filtersChanged()
    },
    accounts_dialog_display() {
      this.$emit('input', this.accounts_dialog_display)
    },
  }
}
</script>

<template lang="pug">
div
  v-dialog(
    v-model="accounts_dialog_display"
    max-width="1137"
    scrollable
  )
    v-card
      v-card-text
        v-container
          v-data-table(
            v-model="accounts_selected"
            :headers="account_headers"
            :items="account_items"
            :sort-by="['year', 'month', 'code']"
            :sort-desc="[false, false, false]"
            :loading="accounts_loading"
            :loading-text="$t('fall_lease.loading_please_wait')"
            :item-class="itemRowStyle"
            show-select
            item-key="id"
            :search="account_search"
            @input="sumTotal"
            @item-selected="itemSelected"
            :items-per-page="15"
            dense
          )
            template(v-slot:item.amount="{ item }")
              | {{ formatMoney(item.amount) }}
            template(v-slot:header.data-table-select)
              v-simple-checkbox(
                :value="accounts_selected.length != 0"
                :indeterminate="checkboxIndeterminate()"
                @click="toggleCheck"
              )
            template(v-slot:top)
              v-row
                v-text-field(
                  id="account_codes"
                  v-model="account_codes"
                  @input="filtersChanged"
                  :label="$t(`fall_lease.${selected_account_type}_codes`)"
                  class="mx-4"
                )
                v-text-field(
                  id="account_owners"
                  v-model="account_owners"
                  @input="filtersChanged"
                  :label="$t(`fall_lease.${selected_account_type}_owners`)"
                  class="mx-4"
                )
                v-text-field(
                  id="account_search"
                  v-model="account_search"
                  :label="$t('general.search')"
                  class="mx-4"
                )
            template(slot="body.prepend")
              tr(class="summary")
                th(class="text-subtitle-2 text-left") {{ this.$t('fall_lease.total') }}
                th(colspan="5")
                th(
                  id="accounts_computed_sum"
                  v-text="sumTotalFormatted"
                  class="text-subtitle-2 text-right"
                )
                th()
      v-card-actions
        v-btn(
          @click="closeModal"
          color="blue darken-1"
          text
        ) {{ this.$t('general.close') }}
        v-spacer
        v-btn(
          @click="saveModal"
          color="blue darken-1"
          text
        ) {{ this.$t('general.save') }}
</template>
