<script>
export default {
  props: {
    value: Array,
    form_data: Object,
    errors: Object,
  },
  data () {
    return {
      generators: this.value,
    }
  },
  computed: {
    writable() {
      return this.form_data.permitted_attributes.find(a => a.asset_class_attributes).asset_class_attributes.find(a => a.generators_attributes).generators_attributes
    },
  },
  methods: {
    sharedProps(field) {
      return {
        name: `power_plant[asset_class_attributes][generators_attributes][${field}]`,
        id: `power_plant_asset_class_attributes_generators_attributes_${field}`,
        errorMessages: this.errors[`asset_class.generators.${field}`],
        readonly: false,
        label: this.label(field),
        dense: true,
        outlined: true,
        persistentHint: true,
        hideDetails: 'auto',
        class: this.writable.includes(field) ? '' : 'd-none',
      }
    },
    label(field) {
      return this.$t(`activerecord.attributes.hydro/generator.${field}`)
    },
    addNew() {
      this.generators.push({
        installed_capacity_mva: null,
        power_factor_percent: null,
        effect_limitation: null,
        efficiency_percent: null,
        _destroy: false,
      })
    },
    destroy(generator) {
      if(generator.id) {
        generator._destroy = !generator._destroy
      } else {
        this.generators.splice(this.generators.indexOf(generator), 1)
      }
    },
  },
}
</script>
<template lang="pug">
#hydro-generators
  .mb-2
    .overline.d-inline-block Generators
    v-btn.ml-4(@click="addNew" small)
      v-icon(left) mdi-plus
      | Add
  .d-flex.mb-2(v-for="generator in generators" :key="generator.id" :class="{'opacity-50': generator._destroy}")
    v-text-field.ml-1(v-model="generator.installed_capacity_mva" v-bind="sharedProps('installed_capacity_mva')")
    v-text-field.ml-1(v-model="generator.power_factor_percent" v-bind="sharedProps('power_factor_percent')" suffix="%" type="number" step=".1")
    v-text-field.ml-1(v-model="generator.efficiency_percent" v-bind="sharedProps('efficiency_percent')" suffix="%" type="number" step=".1")
    v-text-field.ml-1(v-model="generator.effect_limitation" v-bind="sharedProps('effect_limitation')" suffix="MW")
    v-icon(color="error" @click="destroy(generator)") mdi-delete
</template>
