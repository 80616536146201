<script>
export default {
  methods: {
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    isEmpty(value) {
      return value === null || value === '' || value === undefined
    },
    noDecimal(value) {
      return this.formatNumber(value, 0)
    },
    formatNumber(value, decimal = 0) {
      if (this.isNumeric(value))
        return Number(value).toLocaleString('no-NO', {maximumFractionDigits: decimal})
      return value
    },
    parseFormattedNumber(formattedValue) {
      if (formattedValue === '-') return '-'
      if (this.isEmpty(formattedValue)) return 0
      const value = parseFloat(formattedValue.replace(/\s+/g, '').replace(',', '.'))
      return isNaN(value) ? 0 : value
    },
  }
}
</script>
<template></template>