<script>
export default {
  props: {
    results: Array,
    budgets: Array,
    names: Array,
    title: String,
    units: Array
  },
  data() {
    return {
      point_width: this.results.length > 100 ? 14 : 20
    }
  },
  computed: {
    y_unit () {
      if(this.divider === 0.001) {
        return this.units[0]
      } else if(this.divider === 1) {
        return this.units[1]
      } else {
        return this.units[2]
      }
    },
    divider () {
      if(this.max > 1000) {
        return 1000
      } else if(this.max > 1) {
        return 1
      } else {
        return 0.001
      }
    },
    max () {
      const merged = [...this.results, ...this.budgets]
      return Math.max(...merged)
    },
    chart_options () {
      const self = this
      return {
        chart: {
          backgroundColor: 'transparent',
          scrollablePlotArea: {
            minWidth: (this.names.length * 20) + 100,
          }
        },
        title: {
          text: this.title,
        },
        xAxis: {
          categories: this.names,
        },
        yAxis: [{
          title: {
            text: null
          },
          labels: {
            format: `${this.y_unit},${this.divider}`,
            formatter() {
              const format = this.axis.defaultLabelFormatter.call(this).split(",")
              const unit = format[0]
              const divider = Number(format[1])
              return `${this.value/divider}${unit}`
            }
          },
        }],
        credits: {
          enabled: false
        },
        plotOptions: {
          column: {
            grouping: false,
            shadow: false,
            borderWidth: 0
          }
        },
        tooltip: {
          formatter: function() {
            const unit = this.series.userOptions.unit || '';
            const divider = this.series.userOptions.divider || 1;
            const name = self.names[this.point.index];  // Use 'self' to access 'names'
            const roundedValue = (this.y / divider).toFixed(2);
            return `<b>${name}</b><br/><b>${this.series.name}:</b> ${roundedValue}${unit}`;
          }
        },
        series: [{
          name: this.$t('general.budget'),
          type: 'column',
          data: this.budgets,
          color: '#6E9ED7',
          pointWidth: this.point_width,
          unit: this.y_unit,
          divider: this.divider
        }, {
          name: this.$t('general.actual'),
          type: 'column',
          data: this.results,
          color: '#38506c',
          pointWidth: this.point_width / 2,
          unit: this.y_unit,
          divider: this.divider
        }]
      }
    }
  }
}
</script>

<template lang="pug">
  v-highcharts(:options="chart_options")
</template>
