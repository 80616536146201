<script>
export default {
  props: {
    series: Array,
    title: String,
    max: Number,
    y_text: String,
    x_text: String,
    x_tooltip_text: {
      type: String,
      default: 'X-value'
    },
    y_tooltip_text: {
      type: String,
      default: 'Y-value'
    }
  },
  methods: {
    createTooltipFormatter() {
      const xText = this.x_tooltip_text;
      const yText = this.y_tooltip_text;
      return function() {
        return `${xText}: ${this.x}<br/>${yText}: ${this.y}`;
      };
    }
  },
  data() {
    return {
      chart_options: {
        chart: {
          type: 'scatter',
          backgroundColor: 'transparent'
        },
        tooltip: {
          formatter: this.createTooltipFormatter()
        },
        title: {
          text: this.title,
        },
        xAxis: {
          title: {
            text: this.x_text,
          },
          min: 0,
          max: this.max,
        },
        yAxis: {
          title: {
            text: this.y_text,
          },
          min: 0,
          max: this.max,
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: this.series
      }
    }
  }
}
</script>

<template lang="pug">
v-highcharts(:options="chart_options")
</template>
