<script>
import { DateTime } from 'luxon'

export default {
  name: 'WeatherForecast',
  props: {
    powerPlant: {
      type: Object,
      required: true
    },
    currentRange: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      weatherForecast: [],
      today: DateTime.now().toFormat('yyyy-MM-dd'),
      headers: [
        { text: this.$t('general.date'), value: 'date', align: 'center' },
        { text: this.$t('om_dashboard.drilldown.weather'), value: 'weather', align: 'center' },
        { text: this.$t('general.temperature'), value: 'temperature', align: 'center' },
        { text: this.$t('general.precipitation'), value: 'precipitation', align: 'center' }
      ],
      loading: false
    }
  },
  async created() {
    await this.load()
  },
  methods: {
    async load() {
      this.loading = true
      try {
        await this.getWeatherForecast()
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async getWeatherForecast() {
      try {
        let response = await this.axios.get(`/om_dashboard/weather_forecast/${this.powerPlant.id}`, {
          params: {
            start_time: this.currentRange[0],
            end_time: this.currentRange[1]
          }
        })
        this.weatherForecast = response.data.map(forecast => ({
          date: this.formatDate(forecast.timestamp || forecast.date, 'MMM dd') +
              (forecast.timestamp ? ` ${this.formatDate(forecast.timestamp, 'HH:mm')}` : '') +
              (forecast.date === this.today ? ` ${this.$t('today')}` : ''),
          weather_symbol: forecast.weather_symbol,
          temperature: `${forecast.temperature_celcius ?? forecast.temperature}°C`,
          precipitation: `${forecast.precipitation_mm ?? forecast.precipitation}mm`
        }))
      } catch (error) {
        console.error('Failed to fetch weather forecasts', error)
      }
    },
    formatDate(date, format) {
      return DateTime.fromISO(date).toFormat(format)
    }
  }
}
</script>

<template lang="pug">
v-container
  div.heading
    | {{ $t('om_dashboard.drilldown.weather_forecast') }}
  v-data-table(
    :headers="headers"
    :items="weatherForecast"
    class="elevation-1"
  )
    template(v-slot:item.date="{ item }")
      td.text-center {{ item.date }}
    template(v-slot:item.weather="{ item }")
      td.text-center
        v-img(v-if="item.weather_symbol" :src="item.weather_symbol" height="28" width="28" class="mx-auto")
        span(v-else) {{ $t('general.no_image_available') }}
    template(v-slot:item.temperature="{ item }")
      td.text-center {{ item.temperature }}
    template(v-slot:item.precipitation="{ item }")
      td.text-center {{ item.precipitation }}
</template>
