<script>
const PORTRAIT_DIVISOR = 20
const LANDSCAPE_DIVISOR = 20
const MIN_SIZE = 75
const MAX_SIZE = 110

export default {
  props: {
    color: String,
    rotate: Number,
    value: String | Number
  },
  data() {
    return {
      size: MIN_SIZE
    }
  },
  mounted() {
    this.addResizeListener()
    this.updateSize()
  },
  beforeDestroy() {
    this.removeResizeListener()
  },
  methods: {
    addResizeListener() {
      window.addEventListener('resize', this.updateSize)
    },
    removeResizeListener() {
      window.removeEventListener('resize', this.updateSize)
    },
    updateSize() {
      const viewportHeight = document.documentElement.clientHeight
      const viewportWidth = document.documentElement.clientWidth
      let newSize

      if (viewportHeight > viewportWidth) {
        newSize = viewportHeight / PORTRAIT_DIVISOR
      } else {
        newSize = viewportWidth / LANDSCAPE_DIVISOR
      }

      this.size = Math.min(Math.max(newSize, MIN_SIZE), MAX_SIZE)
    }
  },
  computed: {
    width() {
      return this.size / 4
    }
  }
}
</script>

<template lang="pug">
  v-progress-circular(:color="color" :rotate="rotate" :size="size" :width="width" :value="value")
    slot
</template>
