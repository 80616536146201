<script>
import UserCard from '../common/user_card.vue'

export default {
  components: {
    UserCard,
  },
  props: {
    id: Number,
    soft_deleted: Boolean,
    editable: Boolean,
    soft_deletable: Boolean,
    created_at: String,
    content: String,
    user: Object

  },
  data() {
    return {
      editing: false,
      updated_content: this.content,
    }
  },
  computed: {
    endpoint() { return `/comments/${this.id}` }
  },
  methods: {
    cancelEdit() {
      this.editing = false
      this.updated_content = this.content
    },
    async destroy() {
      if(!confirm('Are you sure?')) return
      const response = await this.axios.delete(this.endpoint)
      this.processResponse(response)
    },
    async update() {
      const data = {
        comment: {
          content: this.updated_content
        }
      }
      const response = await this.axios.put(this.endpoint, data)
      this.editing = false
      this.processResponse(response)
    },
    processResponse(response) {
      if(response.status === 200) {
        this.$emit('comments-updated')
      } else {
        alert('Something went wrong. Please try again or notify the staff if the problem persists.')
      }
    }
  }
}
</script>

<template lang="pug">
.comment(:class="{'soft-deleted': soft_deleted}")
  v-row
    v-col(cols="1").mr-2
      v-avatar
        img(:src="user.avatar_url")
    v-col(cols="10")
      strong {{ user.name }} &nbsp
      span.timestamp {{ created_at }}
      .content
        .float-left(v-if="!soft_deleted")
          v-btn(v-if="editable" @click="editing = true" x-small).mr-2 {{ $t('general.edit') }}
          v-btn(v-if="soft_deletable" @click="destroy()" x-small) {{ $t('general.delete') }}
  v-row.pl-2
    v-col
      div.pre-line(v-if="!editing") {{ content }}
      div(v-else)
        v-row
          v-textarea(v-model="updated_content" hide-details="auto" placeholder="Add a comment" outlined :auto-grow="true")
        v-row.py-2(justify="start")
          v-btn(color="primary" :disabled="this.updated_content.length === 0" @click="update" small).mr-2 {{ $t('general.save') }}
          v-btn(@click="cancelEdit()" small) {{ $t('general.cancel') }}
</template>
