<script>
import NewsWidget from './news_widget.vue'
import NewsItemIcons from './icons.vue'

export default {
  components: {
    NewsWidget,
    NewsItemIcons,
  },
  props: {
    limit: Number,
    power_plant_id: Number,
    comments_enabled: Boolean,
    allow_community_posts: Boolean,
  },
  data () {
    return {
      community: true,
      news_items: [],
    }
  },
  computed: {
    archive_path () {
      let url = '/news_items'
      if(this.power_plant_id) url += `?power_plant_id=${this.power_plant_id}`
      return url
    },
  },
  methods: {
    async load_news_items () {
      let url = `/news_items.json?limit=${this.limit}`
      if(this.power_plant_id) url += `&power_plant_id=${this.power_plant_id}`
      if(!this.community) url += '&scope=internal'
      const response = await this.axios.get(url)
      this.news_items = response.data
    },
  },
  mounted() {
    this.load_news_items()
  },
  watch: {
    community () {
      this.load_news_items()
    },
  },
}
</script>

<template lang="pug">
v-card.news-item-list(v-if="news_items.length > 0 || !power_plant_id")
  v-card-title
    | {{ $t('news_items.latest_posts') }}
    v-checkbox(v-if="!power_plant_id && allow_community_posts" v-model="community" :label="$t('general.community')" color="teal" :hide-details="true")
    v-spacer
    a(:href="archive_path" class='nostyle' title='Show all posts')
      v-icon(color='light-blue') mdi-archive
  v-card-text.no-padding
    .news-item(v-for="news_item in news_items" :key="news_item.id")
      news-widget(:news_item="news_item" :news_item_read="news_item.read" :comments_enabled="news_item.allow_comments && comments_enabled")
        .element
          v-list-item-avatar
            v-img(:src="news_item.image_url")
        .element.weak.text-no-wrap {{ news_item.date }}
        .element
          | {{ news_item.title }}
          news-item-icons(:news_item="news_item" :comments_enabled="news_item.allow_comments && comments_enabled")
</template>
