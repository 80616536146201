<script>
export default {
  props: {
    value: Array,
    form_data: Object,
    errors: Object,
  },
  data () {
    return {
      intakes: this.value,
    }
  },
  computed: {
    writable() {
      return this.form_data.permitted_attributes.find(a => a.asset_class_attributes).asset_class_attributes.find(a => a.intakes_attributes).intakes_attributes
    },
  },
  methods: {
    sharedProps(field) {
      return {
        name: `power_plant[asset_class_attributes][intakes_attributes][${field}]`,
        id: `power_plant_asset_class_attributes_intakes_attributes_${field}`,
        errorMessages: this.errors[`asset_class.intakes.${field}`],
        readonly: false,
        label: this.label(field),
        dense: true,
        outlined: true,
        persistentHint: true,
        hideDetails: 'auto',
        class: this.writable.includes(field) ? '' : 'd-none',
      }
    },
    label(field) {
      return this.$t(`activerecord.attributes.hydro/intake.${field}`)
    },
    addNew() {
      this.intakes.push({
        intake_type: 'traditional',
        masl: null,
        lat: null,
        lng: null,
        dam_width_streamwise: null,
        dam_length: null,
        upstream_depth: null,
        _destroy: false,
      })
    },
    destroy(intake) {
      if(intake.id) {
        intake._destroy = !intake._destroy
      } else {
        this.intakes.splice(this.intakes.indexOf(intake), 1)
      }
    },
  },
}
</script>
<template lang="pug">
#hydro-intakes
  .mb-2
    .overline.d-inline-block {{ $t('power_plants.intakes') }}
    v-btn.ml-4(@click="addNew" small)
      v-icon(left) mdi-plus
      | {{ $t('general.add') }}
  .mb-2(v-for="intake in intakes" :key="intake.id" :class="{'opacity-50': intake._destroy}")
    .d-flex.flex-wrap
      v-select.ml-1.mb-2(v-model="intake.intake_type" v-bind="sharedProps('intake_type')" :items="form_data.intake_types || []")
      v-text-field.ml-1.mb-2(v-model="intake.masl" v-bind="sharedProps('masl')")
    .d-flex.flex-wrap
      v-text-field.ml-1.mb-2(v-model="intake.lat" v-bind="sharedProps('lat')")
      v-text-field.ml-1.mb-2(v-model="intake.lng" v-bind="sharedProps('lng')")
    .d-flex.flex-wrap
      v-text-field.ml-1.mb-2(v-model="intake.upper_alert_limit" v-bind="sharedProps('upper_alert_limit')")
      v-text-field.ml-1.mb-2(v-model="intake.lower_alert_limit" v-bind="sharedProps('lower_alert_limit')")
    .d-flex.flex-wrap
      v-text-field.ml-1.mb-2(v-model="intake.dam_width_streamwise" v-bind="sharedProps('dam_width_streamwise')" suffix="m")
      v-text-field.ml-1.mb-2(v-model="intake.dam_length" v-bind="sharedProps('dam_length')" suffix="m")
      v-text-field.ml-1.mb-2(v-model="intake.upstream_depth" v-bind="sharedProps('upstream_depth')" suffix="m")
    .d-flex.flex-wrap
      v-checkbox.ml-1.mb-2(v-model="intake.trash_rack_cleaner" v-bind="sharedProps('trash_rack_cleaner')")
      v-icon(color="error" @click="destroy(intake)") mdi-delete
</template>
