<script>
export default {
  props: {
    total_utilization: Number,
    current_effect: Number,
    rated_power_mw: Number
  },
  data() {
    return {}
  }
}
</script>

<template lang="pug">
v-col.top-widget(cols="12" sm="2")
  .widget-wrapper
    //- tooltip{tip: t('help_texts.dashboard.utilization'), bottom: ""}
    strong MW
    br/
    dynamic-progress-circular(color="blue" :rotate="-90" :value="total_utilization.toString()")
      | {{ parseInt(total_utilization) }}%
    .description
      | {{  current_effect.toFixed(1) }} / {{ rated_power_mw.toFixed(1) }}
</template>
