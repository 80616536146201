<script>
export default {
  props: ['item', 'tag', 'unit', 'isLoss', 'textAlign'],
  computed: {
    hasAdjustedIncome() {
      return this.item?.adjusted_income
    },
    hasLostIncome() {
      return this.item?.lost_revenue
    },
    isOutage() {
      const hasProdStatus = this.item?.prod_status?.table
      const isZeroLoad = this.item && this.item.load === '0'
      const isZeroFactor =
        this.item &&
        this.item.load &&
        !isNaN(parseInt(this.item.load.load_factor_percent)) &&
        parseInt(this.item.load.load_factor_percent) === 0

      return hasProdStatus ? this.item.prod_status.table.status === 'outage' : isZeroLoad || isZeroFactor
    }
  }
}
</script>

<template lang="pug">
component(:is="tag" class="revenue" :class="{'col': tag === 'div', 'no-padding': tag === 'div'}" :style="{ textAlign: textAlign }")
  v-chip.no_data(v-if="item.adjusted_income === null") {{  item.adjusted_income }}
  v-chip.outage(v-if="isLoss && hasLostIncome")  {{ parseInt(item.lost_revenue) }} {{ unit }}
  v-chip.light-green(v-else-if="!isLoss && hasAdjustedIncome") {{ parseInt(item.adjusted_income) }} {{ unit }}
  v-chip.outage(v-else-if="!isLoss && isOutage") 0 {{ unit }}
</template>
