<script>
export default {
  props: {
    value: Object,
    form_data: Object,
    errors: Object,
  },
  data () {
    return {
      hydro_asset: this.value,
    }
  },
  computed: {
    writable() {
      return this.form_data.permitted_attributes.find(a => a.asset_class_attributes).asset_class_attributes
    },
  },
  methods: {
    sharedProps(field) {
      return {
        name: `power_plant[asset_class_attributes][${field}]`,
        id: `power_plant_asset_class_attributes_${field}`,
        errorMessages: this.errors[`asset_class.${field}`],
        readonly: false,
        label: this.label(field),
        dense: true,
        outlined: true,
        persistentHint: true,
        hideDetails: 'auto',
        class: `mb-2 ${this.writable.includes(field) ? '' : 'd-none'}`,
      }
    },
    label(field) {
      return this.$t(`activerecord.attributes.hydro/asset.${field}`)
    },
  },
}
</script>
<template lang="pug">
#hydro-asset
  v-checkbox(v-model="hydro_asset.pipe_rupture_valve" v-bind="sharedProps('pipe_rupture_valve')")
  v-checkbox(v-model="hydro_asset.bypass_valve" v-bind="sharedProps('bypass_valve')")
  v-checkbox(v-model="hydro_asset.winter_closed" v-bind="sharedProps('winter_closed')")
  v-text-field(v-model="hydro_asset.river_name" v-bind="sharedProps('river_name')")
  v-date-picker-input(v-model="hydro_asset.concession_date" v-bind="sharedProps('concession_date')" :filled="false" hint="Overrides NVE-value")
</template>
