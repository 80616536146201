<script>
export default {
  props: {
    value: Boolean,
    landownerUsers: Array,
    currentShare: Number,
  },
  data() {
    return {
      landowner: { user_id: 0, share: 0 },
      show: this.value,
      shareErrorMessage: null,
      landownerErrorMessage: null,
    }
  },
  methods: {
    add() {
      this.landownerErrorMessage = null;
      this.shareErrorMessage = null;

      if(this.landowner.user_id == null || this.landowner.user_id == undefined || this.landowner.user_id == 0) {
        this.landownerErrorMessage = this.$t('fall_lease.counterpart_fields.landowner_error')
        return;
      }
      if(parseFloat(this.landowner.share) + parseFloat(this.currentShare) > 100) {
        this.shareErrorMessage = this.$t('fall_lease.counterpart_fields.share_error')
        return;
      }

      this.$emit('add', { user_id: this.landowner.user_id, share: parseFloat(this.landowner.share) });
      this.show = false;
    }
  },
  watch: {
    value(val) {
      this.landowner = { user_id: 0, share: 0 };
      this.show = val;
    },
    show(val) {
      this.$emit('input', val);
    }
  },
}
</script>

<template lang="pug">
v-dialog(v-model="show" width="500")
  v-card
    v-card-title
      h4 {{ this.$t('fall_lease.counterpart_fields.add') }}
    v-card-text
      v-select(:label="$t('fall_lease.counterpart_fields.landowner')" :items="landownerUsers" v-model="landowner.user_id" :error-messages="landownerErrorMessage")
      v-text-field(:label="$t('fall_lease.counterpart_fields.share')" v-model="landowner.share" type="number" min="0" max="100" step="0.01" :error-messages="shareErrorMessage")
    v-card-actions
      v-btn(@click="show = false" color="red" text) {{ this.$t('general.cancel') }}
      v-spacer
      v-btn(@click="add" color="primary") {{ this.$t('general.add') }}

</template>