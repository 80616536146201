<script>
export default {
  props: {
    this_month: Array,
    last_month: Array,
  },
  data() {
    return {
    }
  },
  computed: {
    chart_options() {
      return {
        chart: {
          backgroundColor: 'transparent',
        },
        title: {
          text: this.$t('power_plants.chart_load.title')
        },
        subtitle: {
          text: this.$t('power_plants.chart_load.subtitle')
        },
        xAxis: {
          categories: this.$t('power_plants.chart_load.categories')
        },
        yAxis: [{
          title: {
            text: null
          },
          labels: {
            formatter: function () {
              return this.value + '%';
            }
          },
        }],
        credits: {
          enabled: false
        },
        plotOptions: {
          series: {
            animation: false
          }
        },
        series: [{
          name: this.$t('power_plants.chart_load.last_month'),
          type: 'column',
          color: '#9aa8b5',
          data: this.last_month,
        }, {
          name: this.$t('power_plants.chart_load.this_month'),
          type: 'column',
          color: '#38506c',
          pointPlacement: -0.2,
          data: this.this_month,
        }]
      }
    },
  }
}
</script>

<template lang="pug">
  v-highcharts(:options="chart_options")
</template>
