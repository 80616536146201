<script>

export default {
  props: {
    headers: Array,
    rows: Array,
    total: Object,
    highlight: Boolean,
  },
  data() {
    return {
    }
  },
  methods: {
    getColor (bud_dev) {
      if (bud_dev < 90) return 'red'
      else if (bud_dev < 100) return 'orange'
      else return 'green'
    },
    getDark (bud_dev) {
      if (bud_dev < 90) return true
      else if (bud_dev < 100) return false
      else return true
    },
    RowClass () {
      return this.highlight ? 'highlight' : ''
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    formatNumber (value) {
      if (this.isNumeric(value)) return Number(value).toLocaleString('no-NO')
      else return value
  },
}
}
</script>

<template lang="pug">
  div
    v-data-table(
      dense
      :headers="headers"
      :items="rows"
      :item-class="RowClass"
      sort-by="total"
      :sort-desc="true"
      :items-per-page="10"
    )
      template(v-for="header in headers" v-slot:[`item.${header.value}`]="{ header, value }")
        span {{ formatNumber(value) }}

      template(v-slot:item.bud_dev="{ item }")
        v-chip(:color="getColor(item.bud_dev)" :dark="getDark(item.bud_dev)") {{ item.bud_dev }}

      template(v-if="total" slot="body.append")
        tr(class="highlight")
          template(v-for="header in headers")
            td(class="text-start" v-if="header.value === 'name'")
              span {{ total.name }}
            td(class="text-end" v-else-if="header.value !== 'bud_dev'")
              span {{ formatNumber(total[header.value]) }}
            td(class="text-start" v-else)
              v-chip(:color="getColor(total.bud_dev)" :dark="getDark(total.bud_dev)") {{ total.bud_dev }}
</template>
