<script>
export default {
  props: {
    power_plant: Object,
    cardWidth: Number,
    max_tyde_sensors: Number
  },
  data() {
    return {
      productionPaneWidth: this.cardWidth - 150 * this.max_tyde_sensors,
      historyChartOptions: {
        chart: {
          height: 100,
          margin: 0
        },
        title: {
          text: null
        },
        xAxis: {
          type: 'datetime',
          tickLength: 0,
          max: new Date(),
          labels: {
            enabled: false
          }
        },
        yAxis: {
          title: null,
          gridLineWidth: 0,
          opposite: true,
          tickLength: 0,
          units: this.power_plant.unit,
          min: 0,
          max: this.historyMax(),
          tickPositions: [0, this.historyMax()],
          labels: {
            enabled: false
          },
          plotLines: [
            {
              value: 0,
              width: 2
            },
            {
              value: this.power_plant.rated_power_mw * 1000,
              width: 2
            }
          ]
        },
        credits: {
          enabled: false
        },
        series: [
          {
            name: 'Production',
            type: 'line',
            data: this.power_plant.recent_production_values,
            yAxis: 0,
            visible: true,
            tooltip: {
              valueSuffix: ' ' + this.power_plant.unit
            }
          }
        ],
        legend: {
          enabled: false
        }
      }
    }
  },
  methods: {
    historyMax() {
      return Math.max(
        this.power_plant.rated_power_mw * 1000,
        ...this.power_plant.recent_production_values.map((v) => v[1])
      )
    },
    tydeSensorChartOptions(tyde_sensor) {
      const limits = [tyde_sensor.upper_alert_limit, tyde_sensor.lower_alert_limit].filter(Number)
      const chartValues = [tyde_sensor.latest_value, ...limits]
      const minChartValue = Math.min(...chartValues)
      const maxChartValue = Math.max(...chartValues)
      const plotOffset = (maxChartValue - minChartValue) * 0.1 || minChartValue
      const plotLines =
        limits.length == 2
          ? [
              {
                value: tyde_sensor.upper_alert_limit,
                width: 2,
                zIndex: 5,
                label: {
                  text: tyde_sensor.upper_alert_limit.toString(),
                  align: 'right',
                  x: 0
                }
              },
              {
                value: tyde_sensor.lower_alert_limit,
                width: 2,
                zIndex: 5,
                label: {
                  text: tyde_sensor.lower_alert_limit.toString(),
                  align: 'right',
                  x: 0,
                  y: 12
                }
              }
            ]
          : []

      const options = {
        chart: {
          type: 'column',
          height: 150,
          margin: 0
        },
        title: null,
        tooltip: {
          enabled: false
        },
        yAxis: {
          min: minChartValue - plotOffset,
          max: maxChartValue + plotOffset,
          plotLines: plotLines
        },
        series: [
          {
            data: [tyde_sensor.latest_value]
          }
        ],
        credits: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          series: {
            pointWidth: 23
          }
        }
      }

      return options
    },
    tydeSensorChartHistoryOptions(tyde_sensor) {
      const limits = [tyde_sensor.upper_alert_limit, tyde_sensor.lower_alert_limit].filter(Number)
      const chartValues = [...tyde_sensor.recent_values.map((pair) => pair[1]), ...limits]
      const plotLines = limits.map((limit) => {
        return { value: limit, width: 2 }
      })
      const minChartValue = Math.min(...chartValues)
      const maxChartValue = Math.max(...chartValues)
      const plotOffset = (maxChartValue - minChartValue) * 0.1 || minChartValue

      const options = {
        chart: {
          height: 100,
          type: 'line',
          margin: 0
        },
        title: {
          text: null
        },
        credits: {
          enabled: false
        },
        series: [
          {
            name: '',
            type: 'line',
            data: tyde_sensor.recent_values,
            yAxis: 0,
            visible: true
          }
        ],
        legend: {
          enabled: false
        },
        xAxis: {
          type: 'datetime',
          tickLength: 0,
          max: new Date(),
          labels: {
            enabled: false
          }
        },
        yAxis: {
          title: null,
          tickLength: 0,
          labels: {
            enabled: false
          },
          min: minChartValue - plotOffset,
          max: maxChartValue + plotOffset,
          plotLines: plotLines
        }
      }

      return options
    }
  }
}
</script>
<template lang="pug">
  v-container.mt-1
    v-card(flat :raised="false").d-flex
      v-card.align-self-start(fluid flat :raised="false" :width="productionPaneWidth")
        v-row
          v-col.col-2.py-1
            v-list-item-avatar(rounded="0" height="70" width="70")
              v-img(:src="power_plant.image_url")
          v-col.col-10
            v-row
              v-col.col-6.pb-0
                .effect
                  | {{ power_plant.current_effect_human || 0 }} / {{ power_plant.installed_effect_human }}
              v-col.col-6.pb-0(align="right")
                .income
                  | {{ power_plant.current_income_human }}
            v-row
              v-col.col-12.pt-0
                v-progress-linear(:color="this.power_plant.current_effect > 0 ? '#38506c' : 'red'" :value="100 * this.power_plant.current_effect / this.power_plant.rated_power_mw" :height="36")
        v-row
          v-col.col-2.pa-1
            div(v-if="power_plant.turbine_count && power_plant.turbine_type")
              .subscript
                | {{ power_plant.turbine_count }} x {{ this.$t('power_plants.turbine_types.' + power_plant.turbine_type) }}
          v-col.col-10
            v-highcharts(:options="historyChartOptions")
        v-row.align-center
          v-col.col-6.pa-1
            span(v-if="power_plant.intake_masl")
              v-icon mdi-download
              | {{this.$t("activerecord.attributes.hydro/intake.masl")}}:
              | {{ power_plant.intake_masl }}
          v-col.weather.col-6.pa-1(v-if="power_plant.forecast")
            v-list-item-avatar.ml-0(rounded="0")
              v-img(:src="power_plant.forecast.met_icon" height="40" width="32")
            | {{ (power_plant.forecast.temperature_celcius !== null) ? power_plant.forecast.temperature_celcius.toFixed(1) + " °C" : ""}}
            | {{ power_plant.forecast.precipitation_mm }} mm
            span.subscript
              | &nbsp;{{ this.$t("overview.48hrs") }}
        v-row.align-center
          v-col.col-6.pa-1
            span.center(v-if="power_plant.turbine_level_masl")
              v-icon mdi-home
              | {{ this.$t("activerecord.attributes.power_plant.turbine_level_masl") }}:
              | {{ power_plant.turbine_level_masl }}
          v-col.weather.col-6.pa-1
            v-list-item-avatar(rounded="0")
              v-img(:src="power_plant.met_icon" height="40" width="32")
            | {{ (power_plant.met_temp !== null) ? power_plant.met_temp.toFixed(1) + " °C" : ""}}
      v-container.align-self-start.ml-auto.tyde-sensor(v-for="tyde_sensor in power_plant.tyde_sensors" :key="tyde_sensor.id")
        v-row
          v-col.col-12.pt-0.px-1
            v-highcharts(:options="tydeSensorChartOptions(tyde_sensor)")
        v-row
          v-col.col-12.py-0(align="center" justify="center" :style="{background: tyde_sensor.alert_latest ? 'red' : 'white', color: tyde_sensor.alert_latest ? 'white' : 'black'}") {{ tyde_sensor.latest_value }} {{ tyde_sensor.unit }}
        v-row
          v-col.col-6.pr-0.py-0(align="center" justify="center" :style="{background: tyde_sensor.alert_7d ? 'red' : 'white', color: tyde_sensor.alert_7d ? 'white' : 'black'}") 7d
          v-col.col-6.pl-0.py-0(align="center" justify="center" :style="{background: tyde_sensor.alert_24h ? 'red' : 'white', color: tyde_sensor.alert_24h ? 'white' : 'black'}") 24h
        v-row
          v-col.col-12.py-0.px-1
            v-highcharts(:options="tydeSensorChartHistoryOptions(tyde_sensor)")
        v-row
          v-col.col-12.pa-0.tyde-sensor-name {{ tyde_sensor.keyword }}
</template>
