<script>
export default {
    props: {
        power_plants: Array,
        companies: Array,
        selected_accesses: Array,
        community: Boolean,
        portfolio: Boolean,
        internal_post_landowner_access: Boolean,
        can_see_community_posts: Boolean,
        accesses_error: Array,
        current_company: Object,
    },
    data() {
        return {
          model: this.selected_accesses,
          portfolio_model: this.portfolio,
          community_model: this.community,
          internal_post_landowner_access_model: this.internal_post_landowner_access,
          search: null,
        }
    },
    computed: {
        options: function () {
            return this.portfolio_model ? this.companies : this.power_plants;
        },
        title: function () {
            return this.portfolio_model ? this.$t('activerecord.attributes.news_item.companies')
                                        : this.$t('activerecord.attributes.news_item.power_plants')
        },
    },
    methods: {
        resetSelected: function() {
            if (this.community_model) {
                this.portfolio_model = false
                this.internal_post_landowner_access_model = false
            }
            if(!this.portfolio_model)
                this.model = []
        },
        selected_has_children: function() {
            let result = false
            this.model.forEach((item) => {
                if(item.child_count > 0)
                    result = true
            })

            return result
        },
    }
}
</script>

<template lang="pug">
div
    input(type="hidden", name="news_item[community_post]" v-model="community_model")
    input(type="hidden" name="news_item[portfolio_access]" v-model="portfolio_model")
    input(type="hidden" name="news_item[internal_post_landowner_access]" v-model="internal_post_landowner_access_model")

    div(v-for="item in model")
        input(type="hidden" name="news_item[accesses_attributes][][accessible_by_type]" v-model="item.type")
        input(type="hidden" name="news_item[accesses_attributes][][accessible_by_id]" v-model="item.id")

    div(v-if="can_see_community_posts")
        v-checkbox(
        id="news_item_community_post"
        v-model="community_model"
        :label="$t('activerecord.attributes.news_item.community_access')"
        v-on:change="resetSelected")

    div(v-if="!community_model")
        input(type="hidden", name="news_item[commit]" value="publish_internally")

        v-label {{ this.$t('activerecord.attributes.news_item.accessible_by') }}

        v-checkbox(
            id="news_item_portfolio_access"
            v-model="portfolio_model"
            :label="$t('activerecord.attributes.news_item.portfolio_access')"
            v-on:change="resetSelected")
        v-row
            v-col(col="12" sm="6")
                div(v-if="portfolio_model")
                    v-combobox(
                        v-model="model"
                        :items="options"
                        :search-input.sync="search"
                        :label="title"
                        :small-chips="true"
                        item-text='name'
                        item-value='name'
                        :error="accesses_error.length > 0"
                        :error-messages="accesses_error"
                        multiple=''
                   )
            v-col
                div(v-if="selected_has_children()")
                    v-alert(type='info') {{ this.$t('news_items.portfolio_warning') }}


        v-checkbox(
            v-model="internal_post_landowner_access_model"
            :label="$t('activerecord.attributes.news_item.internal_post_landowner_access')")
    div(v-else="community_model")
        input(type="hidden", name="news_item[commit]" value="publish_community")
        v-alert(type="info") {{ this.$t('news_items.community_warning') }}
</template>
