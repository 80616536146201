<script>
export default {
  props: {
    solar_data: Array,
    days: Array,
    inverters: Array
  },
  data() {
    return {
    }
  },
  computed: {
    chart_options() {
      return {

        chart: {
          type: 'heatmap',
          marginTop: 40,
          marginBottom: 80,
          plotBorderWidth: 1,
          scrollablePlotArea: {
            minWidth: 1024,
          },
        },
        colorAxis: {
          stops: [
            [0, '#360498'],
            [0.3, '#D4556E'],
            [1, '#F7DB1B']
          ]
        },
        yAxis: {
          categories: this.inverters,
          title: null,
          reversed: true
        },
        xAxis: {
          type: 'datetime',
          categories: this.days,
          showLastLabel: false,
          tickLength: 16,
          labels: {
            formatter: function () {
              return new Date(this.value).toLocaleString("nb-NO", { day: 'numeric', month: 'short', year: 'numeric' })
            }
          }
        },
        tooltip: {
          formatter: (p) => {
            let point = p.chart.hoverPoint
            return `<em>${this.inverters[point.y]}:<br  />${p.chart.xAxis[0].categories[point.x].toLocaleString("nb-NO", { day: 'numeric', month: 'short', year: 'numeric' })}<br />${point.value.toFixed(2)} hours</em>`
          }
        },
        legend: {
          align: 'right',
          layout: 'vertical',
          reversed: true,
          margin: 0,
          verticalAlign: 'top'
        },
        accessibility: {
          point: {
            descriptionFormatter: function (point) {
              return point.value
            }
          }
        },
        credits: {
          enabled: false,
        },
        title: {
          text: this.$t('power_plants.solar_inverter_production'),
        },
        series: [{
          boostThreshold: 100,
          borderWidth: 0,

          data: this.solar_data,
          dataLabels: {
            enabled: false
          },
          turboThreshold: Number.MAX_VALUE
        }]
      }
    }
  }
}
</script>

<template lang="pug">
v-highcharts(:options="chart_options")
</template>
    
