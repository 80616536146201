<script>
import KPI from '../elements/kpi.vue'
import CandybarChart from '../candybar_chart.vue'
import PowerCurve from '../power_curve.vue'
import MpptProduction from './components/mppt_production.vue'
import MpptHeatmap from './components/mppt_heatmap.vue'
import KpiLosses from '../elements/kpi_losses.vue'
export default {
  name: 'OmSolarMppts',
  components: {
    KPI,
    CandybarChart,
    PowerCurve,
    MpptProduction,
    MpptHeatmap,
    KpiLosses
  },
  props: {
    inverters: Array,
    plantId: Number,
    currentRange: Array,
    selectedInverter: Object,
    activeMpptProp: Object,
    comparingFromUrl: Array
  },
  async mounted() {
    if (!this.comparingMppts.length) await this.load()
  },
  created() {
    this.syncMpptComparisons()
  },
  data() {
    return {
      copiedRange: this.currentRange,
      activeInverter: this.selectedInverter,
      activeMppt: this.activeMpptProp,
      comparingMppts: [],
      tmpComparison: [],
      kpis: {},
      alarmChart: [[]],
      powerCurve: {},
      comparingPowerCurves: [],
      currentPowerVoltage: [{ title: '', values: [] }],
      currentPowerVoltageNominal: {},
      heatmapData: []
    }
  },
  methods: {
    syncMpptComparisons() {
      if (this.comparingFromUrl.length) {
        this.comparingMppts = this.inverters
          .map((i) => {
            return i.solar_mppts.filter(
              (s) => this.comparingFromUrl.includes(s.id) && this.activeMppt.id !== s.id
            )
          })
          .flat()
          .sort((a, b) => a.name.localeCompare(b.name))
        this.tmpComparison = this.inverters
          .map((i) => {
            return i.solar_mppts.map((m) => {
              if (this.comparingFromUrl.includes(m.id))
                return { id: m.id, input_number: m.input_number, name: m.name }
            })
          })
          .flat()
          .filter((val) => val)
      } else {
        this.comparingMppts = []
        this.tmpComparison = []
      }
    },
    reset() {
      this.heatmapData = []
      this.kpis = {}
      this.alarmChart = [[]]
      this.currentPowerVoltage = [{ title: '', values: [] }]
      this.currentPowerVoltageNominal = {}
      this.powerCurve = {}
    },
    syncComparisons() {
      if (this.tmpComparison.length > 5) this.tmpComparison = this.tmpComparison.slice(0, 5)
      const comparedIds = this.tmpComparison.map((tc) => tc.id)
      this.comparingMppts = this.inverters
        .map((i) => {
          return i.solar_mppts.filter((s) => comparedIds.includes(s.id))
        })
        .flat()
      this.$emit(
        'changedComparisons',
        this.comparingMppts.map((i) => i.id)
      )
    },
    async load() {
      this.reset()
      await this.loadHeatmapData()
      await this.loadKPIs()
      await this.loadAlarmChart()
      await this.loadCurrentPowerVoltage()
      await this.loadCurrentPowerVoltageNominal()
      if (this.isComparing) {
        let powerCurveCache = []
        this.comparingPowerCurves = []
        for await (const comparingMppt of this.comparingMppts) {
          if (comparingMppt.id) {
            const pcData = await this.loadPowerCurve(comparingMppt.id)
            powerCurveCache.push({ id: comparingMppt.id, data: pcData })
          }
        }
        this.comparingPowerCurves = powerCurveCache.map((i) => i.data)
      }
      this.powerCurve = await this.loadPowerCurve(this.activeMppt.id)
    },
    async loadHeatmapData() {
      const response = await this.axios.get(
        `/om_dashboard/mppt_heatmap/${this.plantId}?start_time=${this.currentRange[0]}&end_time=${this.currentRange[1]}`
      )
      this.heatmapData = response.data
    },
    async loadKPIs() {
      const response = await this.axios.get(
        `/om_dashboard/mppt_kpis/${this.activeMppt.id}?start_time=${this.currentRange[0]}&end_time=${this.currentRange[1]}`
      )
      this.kpis = response.data
    },
    activeMpptIdsUrl() {
      let id1 = `mppt_ids[]=${this.activeMppt.id}`
      let addOn = '&'
      if (this.isComparing) {
        this.comparingMppts.forEach((cm) => {
          addOn += `mppt_ids[]=${cm.id}&`
        })
      }

      return id1 + addOn.replace(/\&$/, '')
    },
    async loadAlarmChart() {
      let response = await this.axios.get(
        `/om_dashboard/alarm_chart_mppt?${this.activeMpptIdsUrl()}&start_time=${
          this.currentRange[0]
        }&end_time=${this.currentRange[1]}`
      )
      this.alarmChart = response.data
    },
    async loadPowerCurve(mppt) {
      let response = await this.axios.get(
        `/om_dashboard/power_curve_for_mppt/${mppt}?start_time=${this.currentRange[0]}&end_time=${this.currentRange[1]}`
      )
      return response.data
    },
    async loadCurrentPowerVoltage() {
      let response = await this.axios.get(
        `/om_dashboard/mppt_current_power_voltage?${this.activeMpptIdsUrl()}&start_time=${
          this.currentRange[0]
        }&end_time=${this.currentRange[1]}`
      )

      this.currentPowerVoltage = [response.data.find((i) => i.title === this.activeMppt.name)]
      if (response.data.length > 1) {
        const others = response.data
          .filter((i) => i.title !== this.activeMppt.name)
          .sort((a, b) => a.title.localeCompare(b.title))
        this.currentPowerVoltage.push(...others)
      }
    },
    async loadCurrentPowerVoltageNominal() {
      let response = await this.axios.get(
        `/om_dashboard/mppt_current_power_voltage_nominal?${this.activeMpptIdsUrl()}`
      )
      this.currentPowerVoltageNominal = response.data
    },
    limiter(e) {
      const selectRef = this.$refs.comparisonSelect
      if (selectRef) selectRef.blur()
      if (e.length > 5) e.pop()
    }
  },
  computed: {
    otherMppts() {
      return this.inverters
        .map((i) => {
          return i.solar_mppts.map((m) => {
            if (m.id !== this.activeMppt.id) return { id: m.id, input_number: m.input_number, name: m.name }
          })
        })
        .flat()
    },
    assembledNames() {
      return this.comparingMppts.map((m) => m.name)
    },
    isComparing() {
      return this.comparingMppts.filter((e) => Object.keys(e).length).length !== 0
    },
    comparingLength() {
      return this.comparingMppts.filter((e) => Object.keys(e).length).length
    },
    TurbinePowerCurveHasData() {
      return Object.keys(this.powerCurve).length > 0
    },
    comparingCurvesReady() {
      return (
        this.comparingPowerCurves.length > 0 &&
        this.comparingPowerCurves.every((curve) => Object.keys(curve).length > 0)
      )
    }
  },
  watch: {
    async currentRange() {
      if (this.currentRange[0] !== this.copiedRange[0] || this.currentRange[1] !== this.copiedRange[1]) {
        this.copiedRange = this.currentRange
        await this.load()
      }
    },
    async activeMpptProp() {
      this.activeInverter = this.selectedInverter
      this.activeMppt = this.activeMpptProp
      await this.load()
    },
    async comparingFromUrl() {
      this.syncMpptComparisons()
    },
    comparingMppts: {
      async handler(newValue) {
        if (newValue.length) await this.load()
      }
    },
    activeInverter: {
      handler(newInverter, oldInverter) {
        if (newInverter) {
          this.activeInverter = newInverter
          this.activeMppt = this.activeMpptProp
        }
      }
    }
  }
}
</script>

<template lang="pug">
div.asset_wrapper
  MpptHeatmap(:plantId="plantId" :values="heatmapData")
  v-container.comparison_selector
    v-row
      v-col.d-flex(cols="1")
        div(style="align-self:center") {{ $t('om_dashboard.drilldown.compare_with') }}
      v-col
        v-col.d-flex(cols="4")
          v-select.compare_mppt(:items="otherMppts" ref="comparisonSelect" item-value="index" item-text="name" multiple v-model="tmpComparison" hide-details="auto" @blur="syncComparisons" return-object outlined )

  v-container
    .kpi_wrapper.mppt(v-if="Object.keys(kpis).length !== 0")
      v-row
        v-col.graph_title {{ $t('om_dashboard.drilldown.key_performance_for', { name: activeMppt.input_number }) }}
      v-row
        v-col(cols="6")
          KPI(:kpiLabel="$t('om_dashboard.drilldown.energy_produced')" :kpis="kpis.energy_produced" field="energy_produced_mwh" unit=" MWh" :showPercentage="true" )
        v-col(cols="6")
          KPI(:kpiLabel="$t('om_dashboard.drilldown.energy_losses')" :kpis="kpis.losses" field="energy_loss_mwh" :invertArrows="true" unit=" MWh")

  v-container
    v-row
      v-col.graph_title
        | {{ $t('om_dashboard.drilldown.operational_status_of_mppt', { name: activeMppt.name }) }}
        span(v-if="comparingMppts.length") &nbsp;+ {{ comparingMppts.length }}
    v-row
      v-col
    CandybarChart(:values="alarmChart" :showAlarmLegend="true" :additionalNames="assembledNames" tech="solar")

  v-container
    v-row
      v-col.graph_title {{ $t('om_dashboard.drilldown.power_current_or_voltage') }}
    v-row
      v-col
    MpptProduction(:values="currentPowerVoltage" :nominalValues="currentPowerVoltageNominal" :additionalNames="assembledNames")

  v-container(v-if="TurbinePowerCurveHasData")
    v-row
      v-col.left-aligned(cols="6")
        v-row
          v-col.graph_title {{ $t('om_dashboard.drilldown.power_curve_for', { name: activeInverter.name + ' - ' + activeMppt.input_number }) }}
        v-row(v-if="Object.keys(kpis).length > 0")
          KpiLosses.graph_subtitle(:losses="kpis.losses.requested.energy_loss_mwh")
        v-row
          v-col
            PowerCurve(
              :values="powerCurve"
              :x_title="$t('om_dashboard.drilldown.power_curve_mppt_x_title')"
              :y_title="$t('om_dashboard.drilldown.power_curve_y_title')"
              :unit="$t('om_dashboard.drilldown.power_curve_unit_solar')"
              tech="solar"
            )
      v-col.right-aligned(cols="6" v-if="TurbinePowerCurveHasData && isComparing && comparingCurvesReady")
        v-row
          v-col.graph_title(class="text-right")
            | {{ $t('om_dashboard.drilldown.compared_power_curves') }}
            | {{ activeInverter.name }} - {{ activeMppt.input_number }}
            span(v-if="comparingLength === 1") &nbsp;&&nbsp; {{ assembledNames[0] }}
            span(v-else) &nbsp;+ {{ comparingLength }}
        v-row.graph_subtitle
          v-col &nbsp;
        v-row
          v-col
            PowerCurve(
              :values="powerCurve"
              :name="activeInverter.name + ' - ' + activeMppt.input_number"
              :additionalNames="assembledNames"
              :additionalValues="comparingPowerCurves"
              :x_title="$t('om_dashboard.drilldown.power_curve_mppt_x_title')"
              :y_title="$t('om_dashboard.drilldown.power_curve_y_title')"
              :unit="$t('om_dashboard.drilldown.power_curve_unit_solar')"
              tech="solar"
            )
</template>
