<script>
export default {
  name: 'KpiLosses',
  props: {
    losses: {
      type: Number,
      default: null
    }
  },
  computed: {
    displayValue() {
      return this.losses === null
        ? this.$t('om_dashboard.drilldown.power_curve_subtitle', { losses: '--' })
        : this.$t('om_dashboard.drilldown.power_curve_subtitle', { losses: this.losses.toFixed(2) })
    }
  }
}
</script>

<template lang="pug">
v-col {{ displayValue }}
</template>
