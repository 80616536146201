import Vue from "vue"
import * as Sentry from "@sentry/vue";


if (window.sentry_dsn !== '') {
  Sentry.init({
    Vue,
    dsn: window.sentry_dsn,
    environment: window.sentry_environment,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      Sentry.replayCanvasIntegration(),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
  });

  Sentry.setUser({
    id: window.user_id,
    email: window.user_email,
    first_name: window.user_first_name,
    last_name: window.user_last_name,
  });
}



