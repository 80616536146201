<script>
export default {
  props: {
    news_item: Object,
    comments_enabled: Boolean,
  }
}
</script>

<template lang="pug">
span
  v-icon.mdi-rotate-270(v-if="news_item.attachment_url" color='light-blue' small) mdi-attachment
  v-icon(v-if="news_item.comments_exists && comments_enabled" color="purple darken-2" small) mdi-comment-text-outline
  v-chip(v-if="!news_item.draft && news_item.community_post" color='teal' x-small outlined)
    span {{ $t('general.community').toLowerCase() }}
</template>
