<script>
import { DateTime } from 'luxon'
export default {
  props: ['item'],
  computed: {
    displayData() {
      if (!this.item)
        return {
          showTooltip: false,
          timestamp: null,
          percentage: null,
          power: null
        }

      let percentage,
        power,
        timestamp,
        showTooltip = false

      if (this.item.load_percent && this.item.load_percent.timestamp) {
        percentage = parseInt(this.item.load_percent.load_factor_percent)
        power = this.formatPower(this.item.load_percent.load_kw)
        timestamp = this.formatTimestamp(this.item.load_percent.timestamp)
        showTooltip = true
      } else if (this.item.asset_type !== 'Solar' && this.item.current_effect && this.item.rated_power_mw) {
        percentage = parseInt((this.item.current_effect / this.item.rated_power_mw) * 100)
        power = this.formatPower(this.item.current_effect * 1000)
        if (this.item.current_effect.timestamp)
          timestamp = this.formatTimestamp(this.item.current_effect.timestamp)

        showTooltip = true
      } else {
        percentage = null
        power = null
        timestamp = null
      }

      return { percentage, power, showTooltip, timestamp }
    }
  },
  methods: {
    formatTimestamp(timestamp) {
      const formatted = DateTime.fromISO(timestamp)
      return formatted.toFormat('yyyy-MM-dd HH:mm')
    },
    loadPercentage(plant) {
      if (!plant) return null

      if (plant.asset_type !== 'Solar' && plant.current_effect && plant.rated_power_mw) {
        return parseInt((plant.current_effect / plant.rated_power_mw) * 100)
      }

      if (plant.load_percent && plant.load_percent.timestamp) {
        return parseInt(plant.load_percent.load_factor_percent)
      }
      return null
    },
    formatPower(power) {
      if (power >= 1000) {
        return `${(power / 1000).toFixed(2)} MW`
      } else {
        return `${power} kW`
      }
    }
  }
}
</script>

<template lang="pug">
div
  .d-flex(v-if="displayData.percentage !== null && displayData.power !== null")
    v-tooltip(v-if="displayData.showTooltip" top)
      template(v-slot:activator="{ on }")
        span(v-on="on") {{ displayData.percentage }}%
      .tooltip-content
        div {{ displayData.power }}
        div(v-if="displayData.timestamp") {{ $t('om_dashboard.overview.last_update') }}: {{ displayData.timestamp }}
    span(v-else) {{ loadPercentage(item) }}
    div(v-if="item.asset_type !== 'Solar' && item.current_effect && item.rated_power_mw")
      v-spacer(v-if="item.current_effect > 0")
      v-icon(v-if="item.trend_24h === 'positive'") mdi-trending-up
      v-icon(v-else-if="item.trend_24h === 'negative'") mdi-trending-down
      v-icon(v-else-if="item.trend_24h === 'neutral'") mdi-trending-neutral
  .d-flex 
    v-progress-linear(:value="loadPercentage(item)" color="#53B1FD")
</template>
