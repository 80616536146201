<script>
import { DateTime } from 'luxon'
export default {
  props: ['item'],
  methods: {
    formatLossPercent(lossPercent) {
      if (isNaN(lossPercent)) return '--'
      else return lossPercent
    },
    formatTimestamp(timestamp) {
      const formatted = DateTime.fromISO(timestamp)
      return formatted.toFormat('yyyy-MM-dd HH:mm')
    },
    loadPercentage(plant) {
      if (plant.current_effect && plant.asset_type === 'Hydro') {
        return parseInt((plant.current_effect / plant.rated_power_mw) * 100)
      }
    },
    loadColour(item) {
      if (item.operational_status.severity) {
        switch (item.operational_status.severity) {
          case 10:
            return '#53B1FD'
          case 20:
            return '#45a861'
          case 25:
            return '#A4D5FD'
          case 30:
            return '#1D2939'
          case 40:
            return '#FDB022'
          case 50:
            return '#98A2B3'
          case 60:
            return '#F97066'
          default:
            return '#98A2B3'
        }
      } else {
        return item.prod_status.table.progress_color
      }
    }
  }
}
</script>

<template lang="pug">
div
  .d-flex(v-if="item && item.losses")
    v-tooltip(v-if="item.losses && item.losses.timestamp" top)
      template(v-slot:activator="{ on  }")
        span(v-on="on") {{ formatLossPercent(parseInt(item.losses.loss_factor_percent)) }}%
      span(v-if="item.losses && item.losses.timestamp") {{ formatTimestamp(item.losses.timestamp) }}
    span(v-else) {{ item.losses }}%
    div(v-if="item && item.asset_type === 'Hydro'")
      v-spacer
      v-icon mdi-trending-down
  .d-flex(v-if="item && item.losses && item.losses.timestamp")
    v-progress-linear(v-if="!isNaN(parseInt(item.losses.loss_factor_percent))" :value="parseInt(item.losses.loss_factor_percent)" :color="loadColour(item)")

  .d-flex(v-else)
    v-progress-linear(v-if="item && item.losses" :value="item.losses" color="amber")
</template>
