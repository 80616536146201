import Vue from 'vue'
import axios from 'axios'

let token_tag = document.head.querySelector('meta[name="csrf-token"]')
if(token_tag) {
  axios.defaults.headers.common['X-CSRF-TOKEN'] = token_tag.content
  Vue.prototype.csrf_token = token_tag.content
}

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest' // Needed for request.xhr? to work in controllers

Vue.prototype.axios = axios

export default axios
