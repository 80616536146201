<script>
export default {
  props: ['downtime_period', 'scheduled_downtime_period', 'value'],
  data() {
    return {
      errorMessage: false,
      loading: false
    }
  },
  methods: {
    async update() {
      this.loading = true

      const url = this.downtime_period.id ? `/downtime_periods/${this.downtime_period.id}` : `/scheduled_downtime_periods/${this.scheduled_downtime_period.id}`

      try {
        const { data } = await this.axios.delete(url)
        this.show = false
        this.loading = false
        window.location.reload()
      } catch (error) {
        this.errorMessage = error.message
        this.loading = false
      }
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<template lang="pug">
  v-dialog(
    v-model="show"
    width="500"
  )
    v-card
      v-card-title(class="headline grey lighten-2")
        | {{ $t('general.delete') }}
        span.lowercase &nbsp;{{ $t('downtime_periods.model_name') }}
      v-alert(v-if="errorMessage" type="error")
        | {{ errorMessage }}
      v-card-text
        | {{ $t('general.are_you_sure') }}?

      v-card-actions
        v-btn(color="error" @click="update" :loading="loading") {{ $t('general.delete') }}
        v-spacer
        v-btn(text @click="show = false") {{ $t('general.cancel') }}
</template>
