<script>
export default {
  props: {
    alarms: Array
  },
  data () {
    return {
      keyword: null,
      items_per_page: 15,
      headers: [
        { text: this.$t('general.description'), value: 'description' },
        { text: this.$t('om_dashboard.alarm.sensor_type'), value: 'sensor_type' },
        { text: this.$t('om_dashboard.alarm.start'), value: 'starts_at' },
        { text: this.$t('om_dashboard.alarm.end'), value: 'ends_at' },
        { text: this.$t('om_dashboard.alarm.duration'), value: 'duration', align: 'end' }
      ]
    }
  },
  computed: {
    footer_props () {
      return { 'items-per-page-options': [this.items_per_page] }
    },
    keywords () {
      const distinct = [ ...new Set(this.alarms.map(a => a.description))] // Set return distinct values
      return distinct.sort().map(k => { return { text: k, value: k } })
    },
    filtered_alarms () {
      if (!this.keyword) return this.alarms
      return this.alarms.filter(a => a.description == this.keyword)
    }
  }
}
</script>
<template lang="pug">
div
  .heading.d-inline-block {{ filtered_alarms.length }} recent alarms
  v-autocomplete.d-inline-block.pa-0.ma-0.ml-8(v-if="keywords.length > 1" v-model="keyword" :placeholder="$t('om_dashboard.drilldown.alarm_filter')" :items="keywords" clearable hide-details)
  v-data-table(:headers="headers" :items="filtered_alarms" :disable-sort="true" :footer-props="footer_props" :hide-default-footer="filtered_alarms.length <= items_per_page")
</template>
