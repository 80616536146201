<script>
import UserCard from '../common/user_card.vue'
import CommentCard from '../common/comment_card.vue'
import CommentForm from '../common/comment_form.vue'

export default {
  components: {
    UserCard,
    CommentCard,
    CommentForm,
  },
  props: {
    news_item: Object,
    comments_enabled: Boolean,
  },
  data() {
    return {
      comments: []
    }
  },
  computed: {
    pluralize_comments() { return this.comments.length.toString() + ' ' + (this.comments.length == 1 ? 'comment' : 'comments') }
  },
  methods: {
    scrollToComments() {
      let container = this.$refs.main_content
      container.scrollTop = container.scrollHeight
    },
    async loadComments() {
      const url = `/comments?commented_on_type=NewsItem&commented_on_id=${this.news_item.id}`
      let response = await this.axios.get(url)
      this.comments = response.data
    },
  },
  mounted() { this.loadComments() }
}
</script>

<template lang="pug">
  v-card.show-news-item
    v-card-title.headline.grey.lighten-2(primary-title='')
      | {{ news_item.title }}
      v-spacer
      .chips
        v-chip.mr-2.pointer(v-if="comments_enabled" color="purple" text-color="white" @click="scrollToComments") {{ pluralize_comments }}
        v-chip.mr-2(color="white") {{ news_item.category }}
        v-chip(color="white") {{ news_item.date }}
    v-card-text(ref="main_content")
      img.main-image(:src="news_item.image_url" v-if="news_item.image_url" :class="news_item.image_position")
      div(v-html="news_item.body_html")
      br

      div(v-if="news_item.attachment_url")
        a.nostyle(:href="news_item.attachment_url" target="_blank")
          v-chip.pointer(v-if="news_item.attachment_url" color="light-blue" text-color="white")
            v-icon.mdi-rotate-270(left) mdi-attachment
            | {{ news_item.attachment_filename }}

        br
        br

      user-card(v-if="news_item.author" :user="news_item.author")
        v-chip.pointer(pill outlined)
          v-avatar(left)
            img(:src="news_item.author.avatar_url")
          | Author: {{ news_item.author.name }}
      br
      br

      .comments(v-if="comments_enabled")
        h2 {{ pluralize_comments }}
        br
        comment-card(v-for="comment in comments" v-bind="comment" v-on:comments-updated="loadComments" :key="comment.id")

      comment-form(v-if="comments_enabled && !news_item.draft" item_type="NewsItem" :item_id="news_item.id" v-on:comments-updated="loadComments")
    slot
</template>
