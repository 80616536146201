<script>
export default {
  props: {
    power_plant: Object,
  },
}
</script>
<template lang="pug">
v-row
  v-col.element.left.name.flex-nowrap.pl-0
    v-list-item-avatar.mr-1.ml-0(size="1em")
      v-img(eager :src="power_plant.flag_url")
    strong {{ power_plant.name }}
  v-col.element.right.flex-nowrap.mt-1.mr-1
    v-tooltip(v-if="power_plant.water_icons.length" v-for="water_icon in power_plant.water_icons" :key="water_icon.icon" bottom)
      template(v-slot:activator="{ on, attrs  }")
        v-icon(:color="power_plant.water_color" v-bind="attrs" v-on="on") {{ water_icon.icon }}
      span {{ water_icon.tooltip }}
    v-tooltip(bottom)
      template(v-slot:activator="{ on, attrs  }")
        v-icon(:color="power_plant.bell_color" v-bind="attrs" v-on="on") {{ power_plant.bell_icon }}
      span {{ power_plant.bell_tooltip }}
</template>

