<script>
import OMDashboardHelpers from '../../mixins/om_dashboard_helpers'
export default {
  props: {
    values: Array
  },
  mixins: [OMDashboardHelpers],
  data() {
    return { energyPerformance: this.values }
  },
  watch: {
    values() {
      this.energyPerformance = this.values
    }
  },
  computed: {
    energy_performance_index_series() {
      return this.energyPerformance.map((item) => {
        return [item.timestamp, item.energy_performance_index]
      })
    },
    hasData() {
      return this.energy_performance_index_series.length > 0
    },
    performance_ratio_series() {
      return this.energyPerformance.map((item) => {
        return [item.timestamp, item.performance_ratio]
      })
    },
    options() {
      let series = [
        {
          type: 'line',
          name: this.$t('om_dashboard.drilldown.energy_performance_index'),
          color: '#434348',
          dashStyle: 'dash',
          marker: { enabled: false },

          data: this.energy_performance_index_series
        },
        {
          type: 'column',
          name: this.$t('om_dashboard.drilldown.performance_ratio'),
          color: '#53B1FD',
          data: this.performance_ratio_series
        }
      ]
      return {
        title: { text: null },
        credits: { enabled: false },
        xAxis: [
          {
            type: 'datetime'
          }
        ],
        yAxis: [
          {
            type: 'linear',
            title: { text: null }
          }
        ],
        plotOptions: {
          column: {
            pointWidth: 36
          }
        },
        exporting: this.isExportable(
          this.$t('om_dashboard.drilldown.export_menu.filenames.energy_performance')
        ),
        tooltip: {
          pointFormat: '{series.name}: <b>{point.y}%</b>'
        },
        series: series
      }
    }
  }
}
</script>

<template lang="pug">
div(v-if="hasData")
  v-highcharts(:options="options")
div(v-else)
  v-progress-circular(indeterminate)
</template>
