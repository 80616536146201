<script>
export default {
  props: {
    data: Array,
    names: Array,
    title: String,
  },
  data() {
    return {
      chart_options: {
        chart: {
          backgroundColor: 'transparent',
          scrollablePlotArea: {
            minWidth: (this.names.length * 20) + 100,
          }
        },
        title: {
          text: this.title
        },
        xAxis: {
          categories: this.names,
        },
        yAxis: [{
          title: {
            text: null
          },
          labels: {
            formatter: function () {
              return this.value + '%';
            }
          },
          plotLines: [{
            color: '#E3AAAA',
            width: 2,
            value: 100
          }]
        }],
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [{
          name: this.$t('general.volume'),
          type: 'column',
          color: '#38506c',
          maxPointWidth: 20,
          data: this.data,
        }]
      }
    }
  }
}
</script>

<template lang="pug">
  v-highcharts(:options="chart_options")
</template>
