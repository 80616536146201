<script>
import VDatePickerInput from './v_date_picker_input_enhanced.vue'

export default {
  props: {
    start_date: String,
    end_date: String,
    currency_options: Array,
    default_currency: String,
    report_types: Array,
    plants: Array
  },
  components: {
    VDatePickerInput,
  },
  data() {
    return {
      from_date: this.start_date,
      until_date: this.end_date,
      price_areas: this.plants.map((p) => p.price_area)
          .filter((item, i, ar) => ar.indexOf(item) === i)
          .sort(),
      selectedCurrency: this.default_currency,
      selectedReportTypes: [this.report_types[0].value, this.report_types[1].value],
      selectedPlants: [],
      errors: [],
      selectedPriceAreas: {},
      sendByEmail: false
    }
  },
  methods: {
    async submit() {
      const formData = new FormData()
      this.errors = []
      formData.append('data_export_form[from_date]', this.from_date)
      formData.append('data_export_form[to_date]', this.until_date)
      formData.append('data_export_form[currency]', this.selectedCurrency)
      this.selectedReportTypes.forEach((rt) => formData.append('data_export_form[report_types][]', rt))
      this.selectedPlants.forEach((plant) =>
        formData.append('data_export_form[power_plant_ids][]', plant.value)
      )
      try {
        const spreadsheet = await this.axios.post('/data_export', formData, {
          responseType: 'blob'
        })
        if (spreadsheet.data.type === 'application/json') this.sendByEmail = true
        else {
          const url = window.URL.createObjectURL(new Blob([spreadsheet.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'data_export.xlsx')
          document.body.appendChild(link)
          link.click()
        }
      } catch (error) {
        this.errors.push(error.response.statusText)
      }
    },
    addPriceArea(priceArea) {
      if (priceArea === 'All') {
        this.selectedPriceAreas = {};
        this.price_areas.forEach(area => {
          this.selectedPriceAreas[area] = true;
          this.addOrRemovePlants(area, true);
        });
        return;
      }

      if (priceArea === 'None') {
        this.selectedPriceAreas = {};
        this.selectedPlants = [];
        return;
      }

      this.addOrRemovePlants(priceArea, !this.selectedPriceAreas[priceArea]);
      this.selectedPriceAreas = { ...this.selectedPriceAreas, [priceArea]: !this.selectedPriceAreas[priceArea] };
    },
    addOrRemovePlants(priceArea, add) {
      const plantsToAddOrRemove = this.plants.filter(plant => plant.price_area === priceArea);

      if (add) {
        plantsToAddOrRemove.forEach((plant) => {
          if (!this.selectedPlants.some((i) => i.value === plant.value)) {
            this.selectedPlants.push(plant);
          }
        });
      } else {
        this.selectedPlants = this.selectedPlants.filter((plant) =>
            !plantsToAddOrRemove.includes(plant)
        );
      }
    }
  }
}
</script>

<template lang="pug">
v-container
  v-row
    v-col(cols="12" sm="12" offset-sm="0" md="12" offset-md="0" lg="10" offset-lg="1")
      v-card.elevation-4
        v-card-title {{  $t('data_export.heading') }}
        v-card-text.input-column
          v-container
            v-row(v-if="errors.length > 0") {{  errors  }}
            v-row
              v-col.input-column(cols="12" sm="12" md="4")
                v-date-picker-input#data_export_form_from_date(
                  v-model="from_date"
                  type="date"
                  name="from_date"
                  :label="this.$t('data_export.from_date')"
                  :readonly="false"
                  )
              v-col.input-column(cols="12" sm="12" md="4")
                v-date-picker-input#data_export_form_to_date(
                  v-model="until_date"
                  type="date"
                  name="to_date"
                  :label="this.$t('data_export.to_date')"
                  :readonly="false"
                )
              v-col.input-column(cols="12" sm="12" md="4")
                v-select(v-model="selectedCurrency" :items="currency_options" name="currency" :readonly="false" :clearable="false")
            v-row
              v-col(cols="12")
                v-multi-select(v-model="selectedReportTypes"  :items="report_types" :readonly="false")
            v-row
              v-col(cols="12")
                label {{ $t('data_export.price_areas') }}
            v-row
              v-col(cols="9")
                v-btn(
                  v-for="area in price_areas"
                  :key="area"
                  @click="addPriceArea(area)"
                  :class="{'selected-area': selectedPriceAreas[area]}"
                )
                  | {{ area }}
              v-col(cols="3" class="text-right")
                v-btn(@click="addPriceArea('None')" class="mr-1") {{ $t('general.none') }}
                v-btn(@click="addPriceArea('All')") {{ $t('general.all') }}
            v-row
              v-col#power_plant_selector(cols="12")
                v-searchable-multi-select(
                  v-model="selectedPlants"
                  :errorMessages="errors"
                  :readonly="false"
                  :items="plants"
                  :label="$t('data_export.individual_power_plants')"
                  :showNoneAllButtons="false"
                )
        v-card-actions
          v-btn(color="'primary'" type="'submit'" @click="submit" :disabled="selectedPlants.length === 0 ||  selectedReportTypes.length === 0") {{ $t('general.download') }}
      v-card-title(v-if="sendByEmail") {{ $t('data_export.pending_email') }}
</template>
