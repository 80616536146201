<script>

export default {
  props: {
    headers: Array,
    plants: Array,
  },
  data() {
    return {
      query: '',
      footerProps: {
        'items-per-page-options': [100]
      }
    }
  },
}
</script>

<template lang="pug">
  div
    v-text-field(v-model="query" append-icon="mdi-magnify" :label="$t('general.search')" single-line hide-details)
    v-data-table(:headers="headers" :items="plants" :search="query" :footer-props="footerProps" class="elevation-4" dense)
      template(v-slot:item.navn="{item, header, value}")
        a(:href="`/admin/nve_plants/${item.id}}`") {{ value }}
      template(v-slot:item.imp="{item, header, value}")
        span(v-if="value" class="green--text") Ja
        span(v-else) Nei
</template>
