<script>
export default {
  props: {
    name: String,
    portfolio: Number,
    industry: Number,
    active: Boolean,
    unit: String,
    portfolio_name: String,
    icon: String
  },
  data() {
    return {
      selected_class: 'light-blue white--text',
    }
  },
  computed: {
    ready() { return this.portfolio != null && this.industry != null },
    diff() { return this.portfolio - this.industry },
    diff_text() {
      let val = parseFloat(this.diff.toFixed(1))
      if(val > 0) val = '+' + val
      val += this.unit == '%' ? ' pp' : ` ${this.$t('general.hours').toLowerCase()}`
      return val
    },
    color() { return this.diff < 0 ? 'red' : 'green' },
  },
}
</script>

<template lang="pug">
span(v-if="ready")
  v-list-item
    v-list-item-content
      .card-heading.overline {{ name }}
      v-list-item-title(class="headline mb-1") {{ diff_text }}
      v-list-item-subtitle
        | {{ portfolio }}{{ unit }} {{ portfolio_name }}
        br
        | {{ industry }}{{ unit }} Industry BM

    v-list-item-avatar(tile size="80" :color="active ? 'light-blue' : '#e0e0e0'")
      v-icon(:dark="active" large left) {{ icon }}
</template>
