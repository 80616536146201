<script>
import OMDashboardHelpers from '../../mixins/om_dashboard_helpers'
export default {
  props: {
    tech: String,
    values: Array
  },
  mixins: [OMDashboardHelpers],
  name: 'AlarmFrequency',
  data() {
    return {
      groupByCategory: this.tech === 'solar',
      hideNormalHours: false
    }
  },

  computed: {
    hasData() {
      return this.alarmFrequency.length > 0
    },
    alarmFrequency() {
      if (this.groupByCategory) {
        let summed = {}
        this.values.forEach((value) => {
          if (summed[value[3]]) summed[value[3]] += parseFloat(value[1])
          else summed[value[3]] = parseFloat(value[1])
        })
        if (this.hideNormalHours) {
          if (this.tech === 'wind') delete summed[10]
          if (this.tech === 'solar') delete summed[0]
        }

        const data = Object.keys(summed)
          .map((category) => {
            return {
              y: summed[category],
              name: ' ',
              hours: parseInt(summed[category]),
              minutes: this.calculateMinutes(summed[category]),
              alarm_name: this.alarmIcons[category]?.title,
              alarm_icon: null,
              category: this.alarmIcons[category]?.icon,
              isNormal: this.tech === 'wind' ? category === '10' : category === '0',
              color:
                this.tech === 'solar'
                  ? this.alarmIcons[category].opStatColor
                  : parseInt(category) === 10
                  ? '#53B1FD'
                  : this.operationalStatusTypes[60].color
            }
          })
          .sort(function (a, b) {
            if (a.isNormal === b.isNormal) return a.hours - b.hours
            return a.isNormal ? -1 : 1
          })

        const totalHours = data.map((i) => i.y).reduce((a, b) => a + b)
        return [
          data,
          {
            y: totalHours,
            isSum: true,
            category: this.hideNormalHours
              ? this.$t('om_dashboard.drilldown.time_with_active_alarms')
              : this.$t('power_plants.total_hours') + (this.tech === 'solar' ? '*' : ''),
            alarm_name: '',
            hours: parseInt(totalHours),
            minutes: this.calculateMinutes(totalHours),
            color: '#999'
          }
        ].flat()
      } else
        return this.values
          .filter((i) => i[3] > 10)
          .map((af) => {
            return {
              y: af[1],
              name: this.alarmIcons[af[3]]?.title,
              hours: parseInt(af[1]),
              minutes: this.calculateMinutes(af[1]),
              alarm_name: af[2],
              alarm_icon: this.alarmIcons[af[3]]?.icon,
              category: af[0],
              color:
                this.tech === 'wind'
                  ? this.operationalStatusTypes[60].color
                  : this.alarmIcons[af[3]]?.opStatColor
            }
          })
    },
    alarmIcons() {
      if (this.tech === 'wind')
        return {
          ...this.windAlarms,
          ...{
            10: {
              icon: '/om_dashboard/alarm_category/normal.svg',
              title: this.$t('om_dashboard.drilldown.hours_without_alarms'),
              color: '#53B1FD'
            }
          }
        }
      else
        return {
          ...this.solarAlarms,
          ...{
            0: {
              icon: '/om_dashboard/alarm_category/normal.svg',
              title: this.$t('om_dashboard.alarm_status.normal_operation'),
              opStatColor: '#53B1FD'
            },
            10: {
              icon: '/om_dashboard/alarm_category/no_data.svg',
              title: this.$t('om_dashboard.alarm_status.no_data'),
              opStatColor: '#505050'
            }
          }
        }
    },

    options() {
      let series = [
        {
          type: this.groupByCategory ? 'waterfall' : 'column',
          name: this.$t('om_dashboard.drilldown.most_frequent_alarms'),
          data: this.alarmFrequency,
          pointWidth: this.groupByCategory ? 64 : 32,
          tooltip: {
            pointFormat: ' {point.alarm_name}<br/><i>{point.hours}h {point.minutes}m</>'
          }
        }
      ]
      return {
        title: { text: null },
        credits: { enabled: false },
        xAxis: {
          type: 'category',
          categories: this.alarmFrequency.map((af) => af.category),
          title: { text: this.$t('om_dashboard.drilldown.alarm_codes') },
          reversed: this.groupByCategory,
          labels: {
            formatter: function () {
              if (this.value.match(/^\d*$/)) return this.value
              else if (this.value.match(/^\/om_dashboard/))
                return (
                  '<div class="xaxis_label"><img width="20" height="20" src="' + this.value + '" /></div>'
                )
              else return this.value
            },
            useHTML: true
          }
        },
        yAxis: [
          {
            type: 'linear',
            title: { text: this.$t('om_dashboard.drilldown.accumulated_hours') }
          }
        ],
        legend: { enabled: false },

        plotOptions: {
          waterfall: {
            pointWidth: 64,
            dataLabels: {
              enabled: true,
              useHTML: true,
              format: ''
            },
            colorByPoint: true
          },
          column: {
            pointWidth: 32,
            dataLabels: {
              enabled: true,
              useHTML: true,
              format: '<div><img src="{point.alarm_icon}" />&nbsp;</div>'
            }
          }
        },
        series: series
      }
    }
  }
}
</script>

<template lang="pug">
div.alarm_frequency_chart(v-if="hasData")
  .d-flex
    .legend(style="width: 50%")
      div(style="float:left; height: 40px" v-if="tech === 'wind'")
        v-switch(v-model="groupByCategory")
      div(style="float:left; height: 40px; padding-top: 4px; padding-left: 5px" v-if="tech === 'wind'")
        p {{ $t('om_dashboard.drilldown.group_by_category') }}
      div(style="float:left; height: 40px" v-if="groupByCategory" :class="tech === 'wind' ? 'ml-8' : ''")
        v-switch(v-model="hideNormalHours")
      div(style="float:left; height: 40px; padding-top: 4px; padding-left: 5px" v-if="groupByCategory")
        p {{ $t('om_dashboard.drilldown.show_only_time_with_active_alarms') }}
  v-highcharts(:options="options")
  p.small(v-if="tech === 'solar'")
    br/
    | * {{ $t('om_dashboard.drilldown.alarm_frequency_disclaimer') }}
div(v-else)
  v-progress-circular(indeterminate)
</template>
