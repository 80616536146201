<script>
export default {
  props: {
    volume_series: Object,
    water_series: Array,
    rated_power_mw: Number,
    max_effect: Number,
    setRange: Function,
    zoomFrom: Number,
    zoomTo: Number,
    unit: String,
    name: String,
    upper_threshold: Number,
    lower_threshold: Number,
    series_color: { type: Array, default: () => ['#97A4B8', '#D6DDE2', '#FFFFFF'] }
  },
  data() {
    return {
      xAxis: null,
      ready: false,
      tech_downtime: [],
      production: [],
      scheduled_downtime_periods: [],
      water_levels: []
    }
  },
  methods: {
    hasWaterLevelData() {
      return this.water_series && this.water_series.some(d => d[1] !== null);
    },
    getMaxDowntimePeriod() {
      let max_tech = Math.max(...this.tech_downtime.map(t => t.y))
      let max_production = Math.max(...this.production.map(t => t[1]))
      let downtime_max = max_tech + max_production
      let scheduled_downtime_max = Math.max(...this.scheduled_downtime_periods.reduce((a, b) => [...a, ...b.result.map(b1 => b1.y)], [])) + 1
      return Math.max(downtime_max, scheduled_downtime_max)
    },
    setXaxis(event) {
      this.xAxis = event.target.xAxis[0]
    },
    setDate(event) {
      this.setRange(event)
    },
    setZoom() {
      const padding = 5 * 3600 * 1000 // 5 hours
      const valFrom = this.zoomFrom ? this.zoomFrom - padding : null
      const valTo = this.zoomTo ? this.zoomTo + padding : null
      this.xAxis.setExtremes(valFrom, valTo)
    },
    async loadData() {
      this.ready = false
      this.tech_downtime = this.volume_series.tech_downtime
      this.production = this.volume_series.production
      this.scheduled_downtime_periods = this.volume_series.scheduled_downtime_periods || []
      if (this.water_series && this.water_series.length > 0) {
        this.water_levels = this.water_series.map(d => [new Date(d[0]).getTime(), d[1]])
      }
      this.ready = true
    },
    yAxisOptions() {
      let hasWaterData = this.hasWaterLevelData()
      let options = [
        {
          title: {
            enabled: false
          },
          labels: {
            formatter() {
              return this.value.toFixed(1) + 'MW'
            }
          },
          top: '0%',
          height: hasWaterData ? '50%' : '100%',
          offset: 0,
          resize: {
            enabled: true
          },
          opposite: false,
          dateTimeLabelFormats: {
            hour: '%b %e %Y %H:%M'
          },
          min: 0,
          max: this.getMaxDowntimePeriod(),
          plotLines: [
            {
              color: '#888888',
              width: 1,
              dashStyle: 'LongDash',
              label: {
                text: `${this.$t('activerecord.attributes.power_plant.rated_power_mw')} ${
                    this.rated_power_mw
                }MW`,
                style: {
                  color: '#444444',
                  fontSize: '0.9em'
                },
                align: 'right'
              },
              value: this.rated_power_mw
            },
            {
              color: '#00adf1',
              width: 1,
              dashStyle: 'LongDash',
              label: {
                text: `${this.$t('activerecord.attributes.power_plant.max_effect')} ${this.max_effect}MW`,
                style: {
                  color: '#444444',
                  fontSize: '0.9em'
                }
              },
              value: this.max_effect
            }
          ]
        }
      ]

      if (hasWaterData) {
        // Calculate chart Y-axis min max to make sure the threshold line is always visible
        const values = Array.isArray(this.water_series) ? this.water_series.map((d) => d[1]).filter((n) => n) : []
        const max = values.length > 0 ? Math.max(...values) : 0
        const min = values.length > 0 ? Math.min(...values) : 0
        const margin = (max - min) * 0.05
        const y_max = Math.max(...[max, this.upper_threshold, this.lower_threshold].filter((n) => n)) + margin
        const y_min = Math.min(...[min, this.upper_threshold, this.lower_threshold].filter((n) => n)) - margin
        const unit = this.unit

        options.push({
          title: {text: this.name},
          labels: {
            formatter() {
              return `${this.value.toFixed(2)}${unit}`
            }
          },
          min: y_min,
          max: y_max,
          top: '50%',
          height: '50%',
          offset: 0,
          opposite: false,
          resize: {
            enabled: true
          },
          plotLines: [
            {
              color: '#888888',
              width: 1,
              dashStyle: 'LongDash',
              zIndex: 5,
              label: {
                text: `${this.$t('general.limit')} ${this.upper_threshold}${this.unit}`,
                align: 'right',
                x: 0,
                style: {
                  color: '#444444',
                  fontSize: '0.9em'
                }
              },
              value: this.upper_threshold
            },
            {
              color: '#888888',
              width: 1,
              dashStyle: 'LongDash',
              zIndex: 5,
              label: {
                text: `${this.$t('general.limit')} ${this.lower_threshold}${this.unit}`,
                align: 'right',
                x: 0,
                style: {
                  color: '#444444',
                  fontSize: '0.9em'
                }
              },
              value: this.lower_threshold
            }
          ]
        })
      }

      return options
    },
  },
  computed: {
    chartHeight() {
      return this.hasWaterLevelData() ? 600 : 500
    },
    scheduled_downtime_support_series() {
      return this.scheduled_downtime_periods.map(period => ({
        name: period.power_plant,
        type: 'areaspline',
        color: '#8E24AA',
        data: period.result,
        yAxis: 0,
        showInNavigator: false,
        tooltip: {
          pointFormat: `${this.$t(
              'downtime_periods.scheduled_downtime_for'
          )} {series.name}: {point.y} MW<br />${this.$t(
              'activerecord.attributes.downtime_period.comment'
          )}: {point.comment}`,
          valueDecimals: 2,
          style: {
            padding: '10px'
          }
        },
        turboThreshold: period.result.length
      }))
    },
    getSeries() {
      let hasWaterData = this.hasWaterLevelData()
      let series = [
        {
          name: this.$t('downtime_periods.tech_downtime_short'),
          type: 'areaspline',
          color: '#EF5350',
          data: this.tech_downtime,
          yAxis: 0,
          showInNavigator: false,
          tooltip: {
            pointFormat: `{series.name} {point.y} MW<br />${this.$t(
                'activerecord.attributes.downtime_period.comment'
            )}: {point.comment}`,
            valueDecimals: 2,
            style: {
              padding: '10px'
            }
          },
          turboThreshold: this.tech_downtime.length,
          // the turboThreshold option is set because highcharts was throwing this error: https://assets.highcharts.com/errors/12/
          dataGrouping: {
            approximation: 'low'
          }
        },
        {
          name: this.$t('general.production'),
          type: 'areaspline',
          color: '#38506c',
          data: this.production,
          yAxis: 0,
          showInNavigator: true,
          tooltip: {
            pointFormat: '{series.name} {point.y} MW',
            valueDecimals: 3,
            style: {
              padding: '10px'
            }
          },
          turboThreshold: this.production.length
        },
        ...this.scheduled_downtime_support_series,
      ]

      if (hasWaterData) {
        series.push({
          name: this.$t('downtime_periods.water_level'),
          type: 'areaspline',
          data: this.water_levels,
          yAxis: 1,
          tooltip: {
            pointFormat: `{series.name} {point.y} ${this.unit}`,
            valueDecimals: 2,
            style: {
              padding: '10px'
            }
          },
          lineWidth: 1,
          color: this.series_color[0],
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [0, this.series_color[1]],
              [1, this.series_color[2]]
            ]
          },
        })
      }

      return series
    },
    chart_options() {
      return {
        chart: {
          zooming: {
            mouseWheel: {
              enabled: false
            }
          },
          zoomType: 'x',
          height: this.chartHeight,
          events: {
            selection: this.setDate,
            load: this.setXaxis
          }
        },
        time: {
          timezoneOffset: new Date().getTimezoneOffset()
        },
        title: {
          text: this.$t('downtime_periods.chart_volume_heading'),
          margin: 40,
          style: {
            fontSize: '18px'
          }
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            hour: '%b %e %Y %H:%M'
          }
        },
        yAxis: this.yAxisOptions(),
        series: this.getSeries,
        credits: {
          enabled: false
        },
        rangeSelector: {
          enabled: false
        },
        scrollbar: {
          enabled: false
        },
        navigator: {
          enabled: this.zoomFrom != null
        },
        plotOptions: {
          series: {
            stacking: 'normal'
          }
        },
        tooltip: {
          distance: 80,
          style: {
            padding: '10px'
          }
        }
      }
    }
  },
  mounted() {
    this.loadData()
  },
  watch: {
    zoomFrom() {
      this.setZoom()
    },
    zoomTo() {
      this.setZoom()
    }
  }
}
</script>

<template lang="pug">
div
  v-highcharts(v-show="ready" :options="chart_options" constructor-type="stockChart")
  v-progress-linear.ma-4(v-if="!ready" indeterminate)
</template>
