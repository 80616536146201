<script>

export default {
  props: {
    value: Object,
    sharedProps: Function,
    strategies: Array,
    limitTypes: Array,
  },
  data() {
    return {
      hedging: {...this.value, limit_value: this.value.limit_value || []},
    }
  },
  computed: {
    monthsInStrategy() {
      if (this.hedging.strategy === 'monthly') {
        return 12
      } else if (this.hedging.strategy === 'quarterly') {
        return 4
      } else if (this.hedging.strategy === 'yearly') {
        return 1
      } else {
        return 0
      }
    }
  },
  watch: {
    value(newValue) {
      this.hedging = newValue
    },
    'hedging.strategy'(newValue) {
      // Truncate limit_value to the new strategy
      if (newValue === 'monthly') {
        this.hedging.limit_value = Array(12).fill(0)
      } else if (newValue === 'quarterly') {
        this.hedging.limit_value = Array(4).fill(0)
      } else if (newValue === 'yearly') {
        this.hedging.limit_value = Array(1).fill(0)
      } else {
        this.hedging.limit_value = []
      }
    },
    hedging: {
      handler() {
        this.$emit("input", this.hedging)
      },
      deep: true
    }
  }
}
</script>

<template lang="pug">
  v-row(v-if="hedging")
    v-col(cols="12" sm="3")
      v-select(v-model="hedging.strategy" :items="strategies" v-bind="sharedProps('hedging_data.strategy')")
      v-text-field(v-model="hedging.price" v-bind="sharedProps('hedging_data.price')" suffix="EUR/MWh")
    v-col(cols="12" sm="3")
      v-select(v-model="hedging.limit_type" :items="limitTypes" v-bind="sharedProps('hedging_data.limit_type')")
      div(v-if="hedging.limit_type === 'percent'")
        v-text-field(v-model="hedging.limit_value[0]" v-bind="sharedProps('hedging_data.limit_value')" suffix="%")
      div(v-else-if="hedging.limit_type === 'volume'")
        div(v-for="i in monthsInStrategy")
          v-text-field(v-model="hedging.limit_value[i - 1]" v-bind="sharedProps('hedging_data.limit_value')" suffix="MWh")
</template>
