<script>
export default {
  props: {
    value: Array,
    form_data: Object,
    errors: Object
  },
  data() {
    return {
      sensors: this.value
    }
  },
  computed: {
    writable() {
      return this.form_data.permitted_attributes.find((a) => a.tyde_sensors_attributes)
        .tyde_sensors_attributes
    }
  },
  methods: {
    sharedProps(field) {
      return {
        name: `power_plant[tyde_sensors_attributes][${field}]`,
        id: `power_plant_tyde_sensors_attributes_${field}`,
        errorMessages: this.errors[`tyde_sensors.${field}`],
        readonly: false,
        label: this.label(field),
        dense: true,
        outlined: true,
        persistentHint: true,
        hideDetails: 'auto',
        class: `mb-2 ${this.writable.includes(field) ? '' : 'd-none'}`
      }
    },
    label(field) {
      return this.$t(`activerecord.attributes.tyde/sensor.${field}`)
    }
  }
}
</script>
<template lang="pug">
div(style="max-width: 600px")
  h2.mt-3 Tyde Sensors
  div(v-for="sensor in sensors" :key="sensor.id")
    v-row.mt-1
      v-col(cols=8)
        h4 {{ sensor.keyword }}
        | {{ sensor.tyde_id }}
      v-col(cols=4)
        v-icon mdi-tape-measure
        | {{ sensor.latest_value }}
        | {{ sensor.unit }}
    v-row.mt-0
      v-col(cols=6)
        v-text-field(v-model="sensor.lower_alert_limit" v-bind="sharedProps('lower_alert_limit')")
      v-col(cols=6)
        v-text-field(v-model="sensor.upper_alert_limit" v-bind="sharedProps('upper_alert_limit')")
</template>
