<script>
export default {
  props: {
    curve_data: Array,
    plant_type: String
  },
  data() {
    return {
      xLabel: this.plant_type.match(/^Wind/)
        ? this.$t('general.wind_speed')
        : this.$t('power_plants.theoretical_power'),
      xUnit: this.plant_type.match(/^Wind/) ? 'm/s' : 'MW'
    }
  },
  methods: {
    formatTimestamp(ts) {
      return new Date(parseInt(ts)).toLocaleTimeString('en-GB', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        formatMatcher: 'basic'
      })
    }
  },
  computed: {
    chart_options() {
      return {
        chart: {
          height: '100%', // square chart shape
          type: 'scatter',
          zoomType: 'xy'
        },
        title: {
          text: this.$t('power_plants.power_curve')
        },
        xAxis: {
          title: {
            text: this.xLabel
          },
          min: 0,
          max: Math.max.apply(
            Math,
            this.curve_data.map((i) => i[0])
          ),
          labels: {
            format: '{value} ' + this.xUnit
          },
          startOnTick: true,
          endOnTick: true,
          showLastLabel: true
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        yAxis: {
          title: {
            text: this.$t('power_plants.measured_power')
          },
          labels: {
            format: '{value} MW'
          },
          min: 0,
          max: Math.max.apply(
            Math,
            this.curve_data.map((i) => i[1])
          )
        },
        tooltip: {
          headerFormat: '',
          formatter: (p) => {
            let point = p.chart.hoverPoint
            return `<em>${this.formatTimestamp(point.timestamp)}<br />${this.xLabel}: ${point.x} ${
              this.xUnit
            }<br/>${this.$t('power_plants.measured_power')}: ${point.y} MW`
          }
        },
        series: [{ data: this.curve_data, keys: ['x', 'y', 'timestamp'] }]
      }
    }
  }
}
</script>

<template lang="pug">
v-highcharts(:options="chart_options")
</template>
