<script>
import OMDashboardHelpers from '../../mixins/om_dashboard_helpers'
export default {
  props: {
    values: Array,
    additionalNames: Array,
    unit: String
  },
  mixins: [OMDashboardHelpers],
  data() {
    return {
      chartValues: this.values,
      chartTitles: {
        measured: this.$t('om_dashboard.drilldown.measured'),
        theoretical: this.$t('om_dashboard.drilldown.theoretical'),
        potential: this.$t('om_dashboard.drilldown.potential'),
        potential_loss: this.$t('om_dashboard.overview.loss'),
        unavailable: this.$t('om_dashboard.drilldown.unavailable'),
        weather_model: this.$t('om_dashboard.drilldown.weather_model'),
        no_data: this.$t('om_dashboard.alarm_status.no_data')
      }
    }
  },
  watch: {
    values() {
      this.chartValues = this.values
    }
  },
  computed: {
    hasComparisons() {
      return this.chartValues[0].title !== 'measured'
    },
    hasData() {
      return this.chartValues.length > 0
    },
    options() {
      let seriesColours = ['#53B1FD', '#0000AA', '#00FFBB', '#6F8435', '#876F49', '#E1522A']
      let defaultColours = ['#2E90FA', '#0000AA', '#00FFBB']
      const unit = this.unit

      let series = this.chartValues.map((s, i) => {
        let seriesName = this.chartTitles[s.title] || s.title

        return {
          type: 'line',
          name: seriesName,
          marker: { enabled: false },
          color: this.hasComparisons ? seriesColours[i] : defaultColours[i],
          index: 0,
          yAxis: 0,
          data: s.values,
          legendIndex: this.additionalNames.includes(s.title) ? this.additionalNames.indexOf(s.title) + 1 : 0
        }
      })

      return {
        chart: {
          zoomType: 'x'
        },
        tooltip: {
          shared: true,
          split: true,
          pointFormatter: function () {
            const unitText = unit ? ` ${unit}` : ''
            return `${this.series.name}: <b>${this.y.toFixed(3)} ${unitText}</b><br/>`
          }
        },
        title: { text: null },
        xAxis: [
          {
            type: 'datetime'
          }
        ],
        yAxis: [
          {
            title: { text: this.$t('om_dashboard.drilldown.power_y_title', { unit: this.unit }) }
          }
        ],
        boost: {
          enabled: true,
          useGPUTranslations: true,
          seriesThreshold: 0,
          turboThreshold: 500000
        },
        credits: { enabled: false },
        series: series,
        exporting: this.isExportable(this.$t('om_dashboard.drilldown.export_menu.filenames.production_chart'))
      }
    }
  }
}
</script>

<template lang="pug">
  div.production_chart(v-if="hasData")
    v-highcharts(:options="options")
  div(v-else)
    v-progress-circular(indeterminate)
</template>
