<script>
import OMDashboardHelpers from '../../../mixins/om_dashboard_helpers'
export default {
  name: 'ComponentStatus',
  props: {
    tech: String,
    status_of_components: {
      type: Object,
      required: true
    }
  },
  mixins: [OMDashboardHelpers],
}
</script>

<template lang="pug">
  v-container.pa-0.ma-0
    v-row.pa-0.ma-0
      v-chip-group
        v-chip.ml-0.mr-2.my-0.py-0(
          v-for="(count, status) in status_of_components",
          :key="status",
          :color="operationalStatusTypes[status].color",
          style="border-radius: 4px",
          flat,
          text-color="white"
        )
          v-tooltip(bottom)
            template(v-slot:activator="{ on, attrs }")
              div(v-bind="attrs", v-on="on") {{ count }}
            .tooltip-text {{ $t(`om_dashboard.status.${tech}.${operationalStatusTypes[status].title}`) }}
</template>
