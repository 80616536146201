<script>
export default {
  name: 'NotificationSettingsMenuItems',
  props: {
    current_user: {
      type: Object,
      required: true
    },
  },
  computed: {
    admin() {
      return this.current_user.access_level === 'admin'
    },
    editor() {
      return this.current_user.access_level === 'editor'
    }
  },
  methods: {
    navigateTo(routeName) {
      this.$router.push({ name: routeName, query: { current_user: this.current_user } })
    }
  }
}
</script>

<template lang="pug">
v-card
  v-toolbar(dense flat)
    v-icon mdi-cog
    v-toolbar-title(class="pl-7") {{ $t('notification_system.notification_settings') }}

  v-divider
  v-list
    v-list-item(:ripple="false" @click="navigateTo('notification_settings_personal')")
      v-list-item-content
        v-list-item-title {{ $t('notification_system.my_notifications') }}
    div(v-if="admin || editor")
      template(v-if="current_user.current_company_name")
        v-list-item(v-if="admin || editor" :ripple="false" @click="navigateTo('notification_settings_general')")
          v-list-item-content
            v-list-item-title {{ $t('notification_system.company_notifications', { company_name: current_user.current_company_name }) }}
      template(v-else)
        v-list-item
          v-list-item-content
            v-list-item-title {{ $t('navigation.error.no_company_selected') }}
</template>
  