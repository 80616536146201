<script>
function check_headers_type(headers) {
    try {
        if (!headers) {
            return "NO_HEADERS"
        }

        if (headers.every(h => typeof h === "string")) {
            return "UNGROUPED_HEADERS"
        }

        if (headers.every(h => h.length === 2 && typeof h === "object")) {
            return "GROUPED_HEADERS"
        }
    } catch (error) {
        // console.error("check_headers_type :: error", error, headers)
        console.error("Are you sure `headers` is correct?", headers)
        throw error
    }

    throw new Error("check_headers_type :: `headers` variant not recognized")
}

function check_data_type(data) {
    if (Array.isArray(data) && Array.isArray(data[0])) {
        return "ARRAY_OF_ARRAYS"
    }

    if (Array.isArray(data) && typeof data[0] === "object") {
        throw new Error("check_data_type :: ARRAY_OF_OBJECTS is not implemented yet")
        // return "ARRAY_OF_OBJECTS"
    }

    if (typeof data === "object" && Array.isArray(data[Object.keys(data)[0]])) {
        return "OBJECT_OF_ARRAYS"
    }
}

function parse_input_props(data, headers) {
    const headers_type = check_headers_type(headers)
    const data_type = check_data_type(data)

    switch (headers_type) {
        case "NO_HEADERS":
            return {
                new_headers: null,
                new_groups: null,
                new_groups_display: null,
                new_data: Object.values(data),
                num_header_columns: 0,
            }
        case "UNGROUPED_HEADERS":
            return {
                new_headers: headers,
                new_groups: null,
                new_groups_display: null,
                new_data: headers.map(header => data[header]),
                num_header_columns: 1,
            }
        case "GROUPED_HEADERS":
            const new_groups = headers.map(header => header[0])
            const new_groups_display = new_groups.reduce((acc, group) => {
                const non_empty_groups = acc.filter(group => group !== "")
                const previous_group = non_empty_groups.length === 0 ? undefined : non_empty_groups[non_empty_groups.length - 1]
                return [
                    ...acc,
                    previous_group === group ? "" : group
                ]
            }, [])
            const new_headers = headers.map(header => header[1])
            return {
                new_headers: new_headers,
                new_groups: new_groups,
                new_groups_display: new_groups_display,
                new_data: new_headers.map(header => data[header]),
                num_header_columns: 2,
            }
        default:
            throw new Error("parse_input_props :: headers_type not recognized")
    }
}


export default {

    name: 'PortalTable',

    props: {
        data: {
            type: [Array, Object],
            required: true
        },
        headers: {
            type: Array,
            required: false
        },
        units: {
            type: Object,
            required: false,
            default: () => { }
        },
        headers_formatter: {
            type: [Object, Function],
            required: false,
            // default: () => (value) => value
        },
        values_formatter: {
            type: [Object, Function],
            required: false,
            default: () => (value, header) => {
                if (/(_RATE|_FACTOR)$/.test(header)) {
                    return parseFloat(value).toFixed(2).toLocaleString()
                }
                return Math.round(parseFloat(value)).toLocaleString()
            }
        },
        time_periods: {
            type: Array,
            required: false,
            default: () => []
        },
        links: {
            type: Object,
            required: false,
            default: () => {
                return {
                    "variable_revenues": ["full_load_factor"]
                }
            }
        },
        descriptions: {
            type: Object,
            required: false,
            default: () => { }
        }
    },

    mounted() {
        // Just select the first row when mounted:
        this.handle_row_click(null, 0)
    },

    data() {
        return {
            state: {
                selected_rows: []
            },
        }
    },

    computed: {
        // Parsed props:
        parsed_props() { return parse_input_props(this.data, this.headers) },
        new_headers() { return this.parsed_props.new_headers },
        new_groups() { return this.parsed_props.new_groups },
        new_groups_display() { return this.parsed_props.new_groups_display },
        new_data() { return this.parsed_props.new_data },
        num_header_columns() { return this.parsed_props.num_header_columns },

        num_columns() {
            if (!this.new_data[0]) {
                return 0
            }
            return this.new_data[0].length
        },

        selected_rows_data() {
            function stringToEpoch(str) {
                const [year, month] = str.split("-")
                return new Date(year, month - 1).getTime()
            }
            const selected_rows_data = this.state.selected_rows.map(row_index => {
                const header = this.headers_formatter(this.new_headers[row_index])
                const unit = this.units[this.new_headers[row_index]]
                const values = this.new_data[row_index] === undefined || this.new_data[row_index] === null ? this.new_data[row_index] : this.new_data[row_index].map(value => typeof value === 'number' ? Number(value.toFixed(2)) : value);
                const indices = this.time_periods.map(s => stringToEpoch(s))
                const data = _.zip(indices, values)
                return {
                    name: header,
                    unit: unit,
                    data: data
                }
            })
            return selected_rows_data
        },
    },

    methods: {
        handle_row_click(event, row_index) {
            if (event && (event.metaKey || event.ctrlKey)) {
                // If the row index is ALREADY in the selected rows...
                const new_selected_rows = this.state.selected_rows.includes(row_index)
                    // ...remove it,
                    ? this.state.selected_rows.filter(r => r !== row_index)
                    // otherwise add it.
                    : [...this.state.selected_rows, row_index]
                this.state.selected_rows = new_selected_rows
            } else {
                // Clear selected rows and set equal to the clicked row index:
                this.state.selected_rows = [row_index]
            }
        },
    },

    watch: {
        selected_rows_data(newValue, oldValue) {
            // Emit an event when selected_rows_data changes:
            if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                this.$emit("selected-rows-data-change", newValue)
            }
        }
    }

}
</script>

<template lang="pug">
div
    table.captiva-table(width="100%", role="table", aria-label="commercial-scada-table")
        thead(v-if="time_periods")
            tr
                th(:colspan="2")
                th(v-for="(time_period, index) in time_periods", :key="index", :class="`captiva-table-header-${index}`", style="text-align:left;")
                    | {{ time_period }}
        tbody
            tr(v-for="(row, i) in new_data", :key="i", :data-row="row", @click="handle_row_click($event, i)", :class="`captiva-table-row-${i} ${state.selected_rows.includes(i) ? 'row-selected' : ''}`")
                td(v-if="new_groups", :class="`captiva-table-group-${i}`")
                    | {{ new_groups_display[i] }}
                td(v-if="new_headers", :class="`captiva-table-header-${i}`")
                    | {{ headers_formatter(new_headers[i]) }}
                    span(v-if="units[new_headers[i]]", style="font-size:smaller;")  {{ units[new_headers[i]] }}
                td(v-for="(value, j) in row", :key="j")
                    | {{ value ? values_formatter(value, new_headers[i]) : "..." }}
    div(style="text-align:left; max-width: 90%; margin:auto; padding-left: 2rem; margin-top:2rem;")
        div(v-for="header in new_headers", style="font-size: smaller; color: #92959E", v-if="descriptions[header]")
            span(style="font-weight:700") {{ headers_formatter(header) }}:
            span  {{ descriptions[header] }}

</template>
