<script>
    
export default {
  props: {
    value: Object,
  },
  data() {
    return {
      edit: false,
      assetComment: this.value
    }
  },
  methods: {
    async lock() {
      if(!confirm("Are you sure?")) return
      const changed = { ...this.assetComment, locked: true }

      await this.save(changed)
    },
    async save(saved) {
      const response = await this.axios.patch(`/asset_comments/${this.assetComment.id}`, {asset_comment: saved} )
      this.assetComment = response.data
      this.edit = false
    }
  },
  watch: {
    assetComment(newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>


<template lang="pug">
div.ma-4
  h4.d-flex.align-center
    | {{ this.$t(`activerecord.attributes.asset_comment.periods.${assetComment.period}`) }}
    div.ml-auto
      | {{assetComment.date}}
    div(v-if="!assetComment['outdated?']")
      v-btn.ml-1(v-if="!assetComment.locked" @click="lock" elevation='1' fab x-small)
        v-icon mdi-lock-open-outline
      div.ml-1(v-else)
        v-icon mdi-lock-outline

      v-btn.ml-1(@click="edit = !edit" v-if="!assetComment.locked" elevation='1' fab x-small)
        v-icon mdi-pencil
  div
    div(v-if="edit")
      v-textarea.mt-1(v-model="assetComment.content" id="asset_comment_content" rows="2" hide-details="auto" auto-grow dense outlined)
      v-btn.mt-1(@click="save(assetComment)" x-small)
        | {{ $t('general.save') }}

    div(v-else)
      | {{assetComment.content}}
</template>
