<script>
import Effect from './effect.vue'
import EnvFlow from './env_flow.vue'
import FallLoss from './fall_loss.vue'
import Header from './header.vue'
import WaterLevel from './water_level.vue'

export default {
  name: 'OmDrilldownHydro',
  components: {
    Effect,
    EnvFlow,
    FallLoss,
    Header,
    WaterLevel
  },
  props: {
    currency_code: String,
    powerPlant: Object,
    drilldown_facade: Object,
    current_market_prices: Object,
    years: Array,
    currentRange: Array,
    kpis: Object
  },
  data() {
    return {
      productionStatus: [],
      energyProduction: [],
      powerPlantProduction: [],
      powerCurve: {},
    }
  },
  async mounted() {
    this.reset()
  },
  methods: {
    reset() {
    }
  },
  watch: {
    async currentRange() {
      this.reset()
    },
    async drilldown_facade() {
      this.reset()
    }
  }
}
</script>

<template lang="pug">
div.asset_wrapper
  .kpi_wrapper
    v-container.pt-0
      Header(:powerPlant="powerPlant", :drilldownFacade="drilldown_facade", :currentRange="currentRange")
    v-container.pt-0
      Effect(:powerPlant="powerPlant", :drilldownFacade="drilldown_facade", :currentRange="currentRange")
      WaterLevel(:powerPlant="powerPlant", :drilldownFacade="drilldown_facade", :currentRange="currentRange")
      EnvFlow(:powerPlant="powerPlant", :drilldownFacade="drilldown_facade", :currentRange="currentRange")
      FallLoss(:powerPlant="powerPlant", :drilldownFacade="drilldown_facade", :currentRange="currentRange")
</template>
