<script>
import Highcharts from 'highcharts'
import { DateTime } from 'luxon'

export default {
  props: {
    data: Object,
    series_color: { type: Array, default: () => ['#93bbff', '#b6e1f6', '#FFFFFF'] }
  },
  data() {
    return {
      timeRange: 0,
      wind_speed: [],
      chart_options: {
        chart: {
          zoomType: 'x',
        },
        credits: {
          enabled: false
        },
        title: {
          text: this.data.name
        },
        subtitle: {
          text: 'Calculating average...'
        },
        xAxis: {
          categories: []
        },
        yAxis: {
          title: {
            text: 'm/s'
          }
        },
        legend: {
          enabled: false
        },
        tooltip: {
          formatter: function() {
            const threeMonths = 86400000 * 31 * 3
            const timeRange = this.series.chart.options.custom.timeRange
            let dateFormat = 'dd. LL'
            if (timeRange < threeMonths) {
              dateFormat = 'dd. LL yyyy, HH:mm'
            }

            const formattedDate = DateTime.fromMillis(this.x).toLocal().toFormat(dateFormat)
            return `Time: ${formattedDate}<br>Value: ${this.y.toFixed(2)} m/s`
          }
        },
        series: [{
          name: this.data.name,
          data: this.wind_speed,
          type: 'areaspline',
          color: this.series_color[0],
          marker: {
            enabled: false
          },
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [0, this.series_color[1]],
              [1, this.series_color[2]]
            ]
          }
        }]
      },
      plotOptions: {
        series: {
          marker: {
            enabled: true
          }
        }
      },
    }
  },
  watch: {
    'data.values': {
      immediate: true,
      handler(newValues) {
        if (newValues && newValues.length > 0) {
          this.wind_speed = newValues
          this.updateChart()
        }
      }
    }
  },
  computed: {
    hasData() {
      return this.wind_speed.length > 0
    },
  },
  methods: {
    formatDate(timestamp) {
      const date = new Date(timestamp)

      let formattedDate
      const day = date.getDate()
      const month = date.toLocaleString('default', { month: 'short' })
      formattedDate = `${day}. ${month}`

      return formattedDate
    },

    updateChart() {
      this.chart_options.series[0].data = this.wind_speed.map(item => [item[0], item[1]])

      this.timeRange = this.wind_speed[this.wind_speed.length - 1][0] - this.wind_speed[0][0]
      this.chart_options.xAxis = { type: 'datetime', dateTimeLabelFormats: { day: '%e. %b' } }

      const sum = this.chart_options.series[0].data.reduce((acc, item) => acc + item[1], 0)
      const average = sum / this.chart_options.series[0].data.length || 0

      this.chart_options.subtitle.text = `Average of ${average.toFixed(2)} m/s in the selected time period`
      this.$set(this, 'chart_options', { ...this.chart_options })

      if (!this.chart_options.custom) {
        this.chart_options.custom = {}
      }
      this.chart_options.custom.timeRange = this.timeRange
    }
  },
}
</script>

<template lang="pug">
div(v-if="hasData")
  v-highcharts(:options="chart_options")
div(v-else)
  v-progress-circular(indeterminate)
</template>
