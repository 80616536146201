<script>
import AlarmIcon from './elements/alarm_icon.vue'
import OMDashboardHelpers from '../../mixins/om_dashboard_helpers'

export default {
  components: {
    AlarmIcon
  },
  props: ['headers', 'items', 'tech', 'loading', 'page', 'perPage', 'totalEvents', 'numberOfPages'],
  mixins: [OMDashboardHelpers],
  data() {
    return {
      options: {
        page: this.page || 1,
        itemsPerPage: this.perPage || 15,
        sortBy: ['operational_status'],
        sortDesc: [true],
      },
      expanded: []
    }
  },
  methods: {
    additionalAlarms(item) {
      return item.alarm_category_types.length - 1
    }
  },
  computed: {
    alarmIcons() {
      if (this.tech === 'wind') return this.windAlarms
      else return this.solarAlarms
    }
  },
  watch: {
    options: {
      handler(newVal) {
        this.$emit('update:options', newVal)
      },
      deep: true
    }
  }
}
</script>

<template lang="pug">
v-data-table(
  :headers="headers"
  :items="items"
  :items-per-page="perPage"
  :pageCount="numberOfPages"
  :page="page"
  :options.sync="options"
  :server-items-length="totalEvents"
  :loading="loading"
  :expanded.sync="expanded"
  :footer-props="{'items-per-page-options':[15, 30, 50, 100]}"
)
  template(v-slot:item.event_status="{ item }")
    AlarmIcon(:operational_status_severity="item.operational_status.severity" :alarm_category="item.alarm_category" :duration="item.duration" :tech="tech")
  template(v-slot:item.operational_status="{ item }")
    div
      | {{ $t(`om_dashboard.status.${tech}.${item.operational_status?.name}`) }}
  template(v-slot:item.alarm_category="{ item }")
    div(v-if="item.alarm_category")
      | {{ $t(`om_dashboard.alarm_status.${item.alarm_category.name}`) }}
      span(v-if="additionalAlarms(item) > 0")
        strong &nbsp;+&nbsp;{{ additionalAlarms(item) }}
  template(v-slot:item.expand="{ item }")
      v-icon(@click="expanded = [item]" v-if="additionalAlarms(item) > 0 && !expanded.includes(item)") mdi-chevron-down
      v-icon(@click="expanded = []" v-if="additionalAlarms(item) > 0  && expanded.includes(item)") mdi-chevron-up
  template(v-slot:expanded-item="{ item }")
    table.expanded_alarm_categories(v-if="item.alarm_category_types.length")
      tr
        td(v-for="alarm_category in item.alarm_category_types")
          img(v-if="alarm_category.severity > 10" :src="alarmIcons[alarm_category.severity]?.icon")
          | {{ $t(`om_dashboard.alarm_status.${alarm_category.name}`) }}
  template(v-slot:item.component_name="{ item }")
    div
      | {{ item.component_name }}
  template(v-if="tech === 'solar'" v-slot:item.mppt_name="{ item }")
    div
      | {{ item.mppt_name }}
  template(v-slot:item.starts_at="{ item }")
    div
      | {{ formatDateTime(item.starts_at)}}
  template(v-slot:item.ends_at="{ item }")
    div
      | {{ item.ends_at === null ? $t('om_dashboard.alarm_status.still_active') : formatDateTime(item.ends_at) }}
  template(v-slot:item.energy_loss_mwh="{ item }")
    div
      | {{ item.energy_loss_mwh.toFixed(3) }}
  template(v-slot:item.duration="{ item }")
    div
      | {{ formatDuration(item.duration) }}
</template>
