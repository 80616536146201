<script>
export default {
  data() {
    return {
      page: parseInt(this.$attrs.value, 10) || 1,
      total_pages: parseInt(this.$attrs.length, 10) || 1
    }
  },
  mounted() {
    this.adjustPageWithinLimits()
  },
  watch: {
    page(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updatePageInUrl(newVal)
      }
    },
    total_pages(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.adjustPageWithinLimits()
      }
    }
  },
  methods: {
    adjustPageWithinLimits() {
      let currentPage = parseInt(this.page, 10)
      let maxPage = parseInt(this.total_pages, 10)
      if (currentPage < 1 || currentPage > maxPage) {
        this.page = Math.max(1, Math.min(currentPage, maxPage))
        this.updatePageInUrl(this.page)
      }
    },
    updatePageInUrl(page) {
      const currentUrl = new URL(window.location)
      const currentPageInUrl = parseInt(currentUrl.searchParams.get('page'), 10)

      if (page !== currentPageInUrl) {
        currentUrl.searchParams.set('page', page)
        window.location.href = currentUrl.toString()
        this.$emit('update:page', page)
      }
    }
  }
}
</script>
<template lang="pug">
v-pagination(v-if="total_pages > 1" v-model="page" v-bind="$attrs")
</template>
