<script>
export default {
  props: {
    value: Array,
    form_data: Object,
    errors: Object,
  },
  data () {
    return {
      environmental_flow_breakpoints: this.value,
      selectDay: false
    }
  },
  computed: {
    writable() {
      return this.form_data.permitted_attributes.find(a => a.asset_class_attributes).asset_class_attributes.find(a => a.environmental_flow_breakpoints_attributes).environmental_flow_breakpoints_attributes
    },

  },
  methods: {
    sharedProps(field) {
      return {
        name: `power_plant[asset_class_attributes][environmental_flow_breakpoints_attributes][${field}]`,
        id: `power_plant_asset_class_attributes_environmental_flow_breakpoints_attributes_${field}`,
        errorMessages: this.errors[`asset_class.environmental_flow_breakpoints.${field}`],
        readonly: false,
        label: this.label(field),
        dense: true,
        outlined: true,
        persistentHint: true,
        hideDetails: 'auto',
        class: this.writable.includes(field) ? '' : 'd-none',
      }
    },
    label(field) {
      return this.$t(`activerecord.attributes.hydro/environmental_flow_breakpoints.${field}`)
    },
    addNew() {
      this.environmental_flow_breakpoints.push({
        day: 1,
        month: 1,
        flow: null,
        _destroy: false,
      })
    },
    destroy(flow) {
      if(flow.id) {
        flow._destroy = !flow._destroy
      } else {
        this.environmental_flow_breakpoints.splice(this.environmental_flow_breakpoints.indexOf(flow), 1)
      }
    },
    setMonth(month, flow) {
      flow = Object.assign(flow, { month: month })
      
      this.selectDay = true
    },
    setDayOfMonth(day, flow) {
      flow = Object.assign(flow, { day: day })
      
      flow.dialog_open = false
      this.selectDay = false
    },
    daysInMonth(month) {
      return new Date(1970, month, 0).getDate()
    }
  },
}
</script>
<template lang="pug">
#hydro-environmental_flow_breakpoints
  .mb-2
    .overline.d-inline-block Environmental flow
    v-btn.ml-4(@click="addNew" small)
      v-icon(left) mdi-plus
      | Add
  .d-flex.mb-2(v-for="flow in environmental_flow_breakpoints" :key="flow.id" :class="{'opacity-50': flow._destroy}")
    v-menu(v-model="flow.dialog_open" :close-on-content-click="false" max-height="300px" offset-y)
      template(v-slot:activator="{on, attrs}")
        v-text-field.vTextFilled_dateFix(:value="`${flow.day}. ${ $t('date.month_names')[flow.month] }`" v-on="on" v-bind="sharedProps('day')")
      v-card
        div(v-if="!selectDay")
          h4.pt-4.pl-4 {{$t('power_plants.select_month') }}
          v-list-item(v-for="month in 12" :key="month" :value="month" @click="setMonth(month, flow)")
            v-list-item-title {{ $t('date.month_names')[month] }}
        div(v-if="selectDay")
          h4.pt-4.pl-4 {{$t('power_plants.select_day') }}
          v-list-item(v-for="day in daysInMonth(flow.month)" :key="day" :value="day" @click="setDayOfMonth(day, flow)")
            v-list-item-title {{ day }}
    v-text-field.ml-1(v-model="flow.flow" v-bind="sharedProps('flow')" suffix="l/s")
    v-icon(color="error" @click="destroy(flow)") mdi-delete
</template>
