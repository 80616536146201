<script>
export default {
  props: {
    value: Object,
    sharedPropsBase: Function,
    modelTypes: Array,
    thresholdTypes: Array,
    thresholdMethods: Array,
    kpiAdjustments: Array,
    averagingIntervals: Array,
    paymentIntervals: Array,

  },
  data() {
    return {
      fallLease: this.value
    }
  },
  methods: {
    sharedProps(field) {
      return {
        ...this.sharedPropsBase(field),
        label: this.$t(field)
      }
    }
  },
  computed: {
    kpi_end_date_types() {
      return [
        { text: 'End of Last Year', value: 'end_of_last_year' },
        { text: 'Start of Year', value: 'start_of_year' },
        { text: 'Mid-Year', value: 'mid_year' },
        { text: 'End of Year', value: 'end_of_year' }
      ]
    }
  },
  watch: {
    value(newValue) {
      this.fallLease = newValue
    },
    'fallLease.model_rate'(newVal) {
      this.fallLease.model_rate = newVal.replace(/,/g, '.')
    },
    fallLease: {
      handler() {
        this.$emit("input", this.fallLease)
      },
      deep: true
    }
  }
}
</script>

<template lang="pug">
  v-row(v-if="fallLease")
    v-col(cols="12" sm="3")
      v-subheader {{ this.$t('fall_lease.contract_structure') }}
      v-select(v-model="fallLease.model_type" :items="modelTypes" v-bind="sharedProps('fall_lease.model_type')")
      v-select(v-model="fallLease.threshold_type" :items="thresholdTypes" v-bind="sharedProps('fall_lease.threshold_type')")
      v-select(v-model="fallLease.averaging_interval" :items="averagingIntervals" v-bind="sharedProps('fall_lease.calculation_interval')")
      v-select(v-model="fallLease.payment_interval" :items="paymentIntervals" v-bind="sharedProps('fall_lease.payment_interval')" disabled)
    v-col(cols="12" sm="3")
      v-subheader {{ this.$t('fall_lease.rate_structure') }}
      v-select(v-model="fallLease.threshold_method" :items="thresholdMethods" v-bind="sharedProps('fall_lease.threshold_method')" :disabled="fallLease.threshold_type === 'none'" )
      v-text-field(v-model="fallLease.threshold_value" v-bind="sharedProps('fall_lease.threshold_value')" hint="For example: 0, 16000, 50000" :disabled="fallLease.threshold_type === 'none'" )
      v-text-field(v-model="fallLease.threshold_rate" v-bind="sharedProps('fall_lease.threshold_rate')" hint="For example: 10, 15, 25" suffix="%" :disabled="fallLease.threshold_type === 'none'")
      v-text-field(v-model="fallLease.model_rate" v-bind="sharedProps('fall_lease.model_rate')" hint="For example: 5" suffix="%" :disabled="fallLease.threshold_type !== 'none'")
      v-text-field(v-model="fallLease.elcert_share" v-bind="sharedProps('fall_lease.elcert_share')" suffix="%")
      v-text-field(v-model="fallLease.goos_share" v-bind="sharedProps('fall_lease.goos_share')" suffix="%")
      v-text-field(v-model="fallLease.min_price" v-bind="sharedProps('fall_lease.min_price')" :suffix="fallLease.currency")
      v-text-field(v-model="fallLease.fixed_price" v-bind="sharedProps('fall_lease.fixed_price')" :suffix="fallLease.currency")
    v-col(cols="12" sm="3")
      v-subheader {{ this.$t('fall_lease.pp_revenues_costs') }}
      v-text-field(v-model="fallLease.revenue_codes" v-bind="sharedProps('fall_lease.revenue_codes')")
      v-text-field(v-model="fallLease.elcert_codes" v-bind="sharedProps('fall_lease.elcert_codes')")
      v-text-field(v-model="fallLease.goos_codes" v-bind="sharedProps('fall_lease.goos_codes')")
      v-text-field(v-model="fallLease.total_costs_codes" v-bind="sharedProps('fall_lease.total_costs_codes')")
    v-col(cols="12" sm="3")
      v-subheader {{ this.$t('fall_lease.kpi_adjustment') }}
      v-select(v-model="fallLease.kpi_adjustment" :items="kpiAdjustments" v-bind="sharedProps('fall_lease.kpi_adjustment')")
      v-text-field(v-model="fallLease.kpi_ref_date" v-bind="sharedProps('fall_lease.kpi_ref_date')" :disabled="fallLease.kpi_adjustment === 'none'")
      //v-text-field(v-model="fallLease.kpi_end_date" v-bind="sharedProps('fall_lease.kpi_end_date')" :disabled="fallLease.kpi_adjustment === 'none'")
      v-select(
        v-model="fallLease.kpi_end_date_type"
        :items="kpi_end_date_types"
        :label="$t('fall_lease.kpi_end_date_type')"
        :disabled="fallLease.kpi_adjustment === 'none'"
        filled
      )
      v-text-field(v-model="fallLease.payment_dates" v-bind="sharedProps('fall_lease.payment_dates')" disabled)
</template>
