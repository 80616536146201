<script>
import KPI from '../elements/kpi.vue'
import EnergyProduction from './components/energy_production.vue'
import ContractualAvailability from './components/contractual_availability.vue'
import PowerCurve from '../power_curve.vue'
import KpiLosses from '../elements/kpi_losses.vue'
import WindSpeed from './wind_speed.vue'
import WindRose from './rose.vue'
import ProductionChart from '../production_chart.vue'
import CandybarChart from '../candybar_chart.vue'

export default {
  name: 'OmDrilldownWind',
  components: {
    CandybarChart,
    KPI,
    EnergyProduction,
    ContractualAvailability,
    PowerCurve,
    KpiLosses,
    WindSpeed,
    WindRose,
    ProductionChart
  },
  props: {
    currency_code: String,
    powerPlant: Object,
    current_market_prices: Object,
    years: Array,
    currentRange: Array,
    kpis: Object
  },
  data() {
    return {
      productionStatus: [],
      energyProduction: [],
      contractualAvailability: [],
      powerPlantProduction: [],
      powerCurve: {},
      windSpeed: {},
      windDirectionData: []
    }
  },
  async mounted() {
    this.reset()
    await this.loadContractualAvailability()
    await this.loadWindSpeed()
    await this.loadWindDirectionData()
    await this.loadEnergyProduction()
    await this.loadPowerPlantProduction()
    await this.loadProductionStatus()
    await this.loadPowerCurve()
  },
  methods: {
    reset() {
      this.windSpeed = {}
      this.productionStatus = []
      this.energyProduction = []
      this.contractualAvailability = []
      this.powerPlantProduction = []
      this.powerCurve = {}
    },
    async loadWindDirectionData() {
      const response = await this.axios.get(
        `/om_dashboard/wind_direction/${this.powerPlant.id}?start_time=${this.currentRange[0]}&end_time=${this.currentRange[1]}`
      )
      this.windDirectionData = response.data
    },
    async loadProductionStatus() {
      let response = await this.axios.get(
        `/om_dashboard/operational_status/${this.powerPlant.id}?start_time=${this.currentRange[0]}&end_time=${this.currentRange[1]}`
      )
      this.productionStatus = response.data
    },
    async loadEnergyProduction() {
      let response = await this.axios.get(
        `/om_dashboard/energy_production/${this.powerPlant.id}?start_time=${this.currentRange[0]}&end_time=${this.currentRange[1]}`
      )
      this.energyProduction = response.data
    },
    async loadContractualAvailability() {
      let response = await this.axios.get(
        `/om_dashboard/contractual_availability/${this.powerPlant.id}?start_time=${this.currentRange[0]}&end_time=${this.currentRange[1]}`
      )
      this.contractualAvailability = response.data
    },
    async loadPowerPlantProduction() {
      let response = await this.axios.get(
        `/om_dashboard/power_plant_production/${this.powerPlant.id}?start_time=${this.currentRange[0]}&end_time=${this.currentRange[1]}`
      )
      this.powerPlantProduction = response.data
    },
    async loadPowerCurve() {
      let response = await this.axios.get(
        `/om_dashboard/power_curve_for_power_plant/${this.powerPlant.id}?start_time=${this.currentRange[0]}&end_time=${this.currentRange[1]}`
      )
      this.powerCurve = response.data
    },
    async loadWindSpeed() {
      let response = await this.axios.get(
        `/om_dashboard/wind_speed_for_power_plant/${this.powerPlant.id}?start_time=${this.currentRange[0]}&end_time=${this.currentRange[1]}`
      )
      this.windSpeed = response.data
    }
  },
  watch: {
    async currentRange() {
      this.reset()
      await this.loadWindSpeed()
      await this.loadProductionStatus()
      await this.loadEnergyProduction()
      await this.loadContractualAvailability()
      await this.loadPowerPlantProduction()
      await this.loadPowerCurve()
      await this.loadWindDirectionData()
    }
  }
}
</script>

<template lang="pug">
div.asset_wrapper
  v-container
    .kpi_wrapper
      v-row
        v-col.graph_title {{ $t('om_dashboard.drilldown.key_performance') }}
      v-row
        v-col
          KPI(:kpiLabel="$t('om_dashboard.drilldown.energy_produced')" :kpis="kpis.production_in_mw_for_plant" field="eac_meas_mwh" :showPercentage="true" unit=" MWh")
        v-col
          KPI(:kpiLabel="$t('om_dashboard.drilldown.energy_losses')" :kpis="kpis.losses_for_plant" field="eac_loss_mwh" unit=" MWh" :invertArrows="true")
        v-col
          KPI(:kpiLabel="$t('om_dashboard.drilldown.total_availability')" :kpis="kpis.total_availability" field="total_energybased_availability_percent" unit="%")
        v-col(v-if="contractualAvailability && contractualAvailability.tooltip && contractualAvailability.tooltip.length > 0")
          KPI(:kpiLabel="$t('om_dashboard.drilldown.contractual_availability')" :kpis="kpis.contractual_availability" field="contractual_availability_percent" unit="%" :tooltips="contractualAvailability.tooltip")
  v-container
    v-row
      v-col.graph_title {{  $t('general.wind_speed') }}
      v-col.graph_title {{  $t('om_dashboard.drilldown.wind_rose') }}
        .graph_subtitle {{ $t('om_dashboard.drilldown.wind_rose_text') }}
    v-row
      v-col
        WindSpeed(:data="windSpeed")
      v-col
        WindRose(:wind_data="windDirectionData" :tv-if="windDirectionData.length > 0")
        .graph_subtitle(v-if="powerPlant.weather_source === 'nve'") {{ $t('om_dashboard.drilldown.wind_rose_missing') }}
  v-container
    v-row
      v-col.graph_title {{  $t('om_dashboard.drilldown.energy_produced') }}
    v-row
      v-col
    EnergyProduction(:values="energyProduction")
  v-container
    v-row
      v-col.graph_title {{  $t('om_dashboard.drilldown.contractual_availability') }}
    v-row
      v-col
    ContractualAvailability(:values="contractualAvailability.data")
  v-container
    v-row
      v-col.graph_title {{  $t('om_dashboard.drilldown.production_in_mw') }}
    v-row
      v-col
        ProductionChart(:values="powerPlantProduction" :additionalNames="[]" :unit="'MW'")
  v-container
    v-row
      v-col.graph_title {{  $t('om_dashboard.drilldown.operational_status_per_turbine') }}
    v-row
      v-col
    CandybarChart(:title="$t('om_dashboard.drilldown.operational_status')" :values="productionStatus" tech="wind")
  v-container
    v-row
      v-col.graph_title {{  $t('om_dashboard.drilldown.power_curve') }}
    v-row
      KpiLosses.graph_subtitle(:losses="kpis.losses_for_plant.requested.eac_loss_mwh")
    v-row
      v-col
    PowerCurve(
      :values="powerCurve"
      :x_title="$t('om_dashboard.drilldown.power_curve_x_title')"
      :y_title="$t('om_dashboard.drilldown.power_curve_y_title')"
      :unit="$t('om_dashboard.drilldown.power_curve_unit_wind')"
      tech="wind"
    )
</template>
