<script>
export default {
  props: {
    wind_data: Array,
    title: String
  },
  data() {
    return {}
  },
  name: 'WindRose',
  computed: {
    chart_options() {
      return {
        chart: {
          polar: true,
          type: 'column'
        },
        series: this.wind_data,
        title: {
          text: this.title
        },
        credits: {
          enabled: false
        },
        pane: {
          size: '75%'
        },
        legend: {
          align: 'right',
          verticalAlign: 'top',
          y: 100,
          layout: 'vertical'
        },
        xAxis: {
          min: 0,
          max: 16,
          tickInterval: 1,
          labels: {
            formatter: function () {
              return [
                'N',
                'NNE',
                'NE',
                'ENE',
                'E',
                'ESE',
                'SE',
                'SSE',
                'S',
                'SSW',
                'SW',
                'WSW',
                'W',
                'WNW',
                'NW',
                'NNW'
              ][this.pos]
            }
          },
          tickmarkPlacement: 'on'
        },
        yAxis: {
          min: 0,
          tickInterval: 4,
          labels: {
            enabled: false
          },
          reversedStacks: false
        },
        tooltip: {
          formatter: function () {
            return `${this.point.name}<br /><strong>${this.y.toFixed(2)} %</strong>`
          }
        },
        plotOptions: {
          series: {
            stacking: 'normal',
            shadow: false,
            groupPadding: 0,
            pointPlacement: 'on'
          }
        }
      }
    }
  }
}
</script>

<template lang="pug">
  v-highcharts(:options="chart_options")
</template>
