<script>
import VDatePickerInput from '../../components/v_date_picker_input_enhanced.vue'

export default {
  components: {
    VDatePickerInput
  },
  props: {
    create_new: {
      type: [String, Boolean],
      default: () => false,
      required: false,
    },

    contract: {
      type: Object,
      required: false,
    },
    fall_lease: {
      type: Object,
      required: false,
    },

    model_types: Array,
    threshold_types: Array,
    threshold_methods: Array,
    kpi_adjustments: Array,
    averaging_intervals: Array,
    payment_intervals: Array,

    contract_types: Array,
    power_plants: Array,
  },
  data() {
    return {
      local_fall_lease: this.fall_lease,
      local_contract: this.contract,

      contract_id: null,
      fall_lease_id: null,

      form_patch_request_headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },

      form_contract_button_color: "primary",
      form_contract_valid: true,
      form_contract_loading: false,

      form_fall_lease_button_color: "primary",
      form_fall_lease_valid: true,
      form_fall_lease_loading: false,
    }
  },
  methods: {
    async submit_contract() {
      this.form_contract_loading = true;
      const options = {
        contract_fall_lease: this.local_contract,
        headers: this.form_patch_request_headers
      }
      try {
        if (this.create_new && this.contract_id == null) {
          const url = `/admin/contracts.json`
          const response = await this.axios.post(url, options)
          this.local_contract = response.data;
          this.contract_id = response.data.id;
          this.local_fall_lease = response.data.fall_lease;
          this.fall_lease_id = response.data.fall_lease.id;
          this.form_contract_button_color = "success";
          this.form_contract_loading = false;
        } else {
          // TODO: please fix this logic unit, as this.contract.id if null, then cid is null
          let cid = this.contract.id == null ? this.contract_id : this.contract.id;
          const url = `/admin/contracts/${cid}.json`
          await this.axios.patch(url, options)
          this.contract_id = this.contract.id;
          this.form_contract_button_color = "success";
          this.form_contract_loading = false;
        }
      } catch (error) {
        console.error(error);
        this.form_contract_loading = false;
        if (error.response.status === 500) {
          this.form_contract_button_color = "error";
        }
      }
    },
    async submit_fall_lease() {
      this.form_fall_lease_loading = true;
      const options = {
        fall_lease: this.local_fall_lease,
        headers: this.form_patch_request_headers
      }
      try {
        const url = `/admin/fall_leases/${this.local_fall_lease.id}.json`
        await this.axios.patch(url, options)
        this.fall_lease_id = this.local_fall_lease.id;
        this.contract_id = this.local_contract.id;
        this.form_fall_lease_button_color = "success";
        this.form_fall_lease_loading = false;
      } catch (error) {
        console.error(error)
      }
    },
    cancel() {
      // TODO: a better approach is to use $router
      // this.$router.push({ name: 'routename' })
      window.location.href = '/admin/fall_leases/';
    },
    go_to_compute() {
      window.location.href = `/admin/fall_leases/${this.contract_id}`;
    }
  },
  watch: {
    form1_button_color: function(value) {
      let vm = this;
      if (value === 'success') {
        setTimeout(function() {
          vm.form1_button_color = "primary";
          vm.form1_loading = false;
        }, 3000);
      }
    },
    'local_fall_lease.model_rate'(newVal) {
      this.local_fall_lease.model_rate = newVal.replace(/,/g, '.')
    },
  },
  mounted() {
    // this.local_fall_lease = this.fall_lease;
    // this.local_contract = this.contract;
  },
}
</script>

<template lang="pug">
div
  v-container#contract_details
    v-card
      v-card-title
        | {{ this.$t('general.contract_details') }}
      v-form(
        ref="form_contract"
        v-model="form_contract_valid"
        lazy-validation
      )
        v-card-text
          v-row
            v-col
              v-autocomplete(
                :items="power_plants"
                v-model="local_contract.power_plant_id"
                :label="$t('general.power_plant')"
                filled
              )
            v-col
              v-select(
                :items="contract_types"
                v-model="local_contract.type"
                :label="$t('fall_lease.contract_type')"
                filled
              )
            v-col
              v-text-field(
                v-model="local_contract.counterpart"
                :label="$t('fall_lease.counterpart')"
                filled
              )
            v-col
              VDatePickerInput(
                v-model="local_contract.start_date"
                :label="$t('fall_lease.start_date')"
                filled
              )
            v-col
              VDatePickerInput(
                v-model="local_contract.end_date"
                :label="$t('fall_lease.end_date')"
                filled
              )
            v-col
              v-checkbox(
                v-model="local_contract.active"
                :label="$t('fall_lease.active')"
                filled
              )
        v-card-actions

          v-btn(
            @click.prevent="submit_contract"
            :color="form_contract_button_color"
            :disabled="!form_contract_valid"
            :loading="form_contract_loading"
          ) {{ this.$t('general.save') }}

          v-spacer

          v-btn(
            color="error"
            @click="cancel"
          ) {{ this.$t('general.cancel') }}

  v-container#fall_lease_contract_details(
    v-show="local_contract.type === 'Contract::FallLease'"
  )
    v-card
      v-card-title
        | {{ this.$t('fall_lease.fall_lease_contract_details') }}
      v-form(
        ref="form_fall_lease"
        v-model="form_fall_lease_valid"
        lazy-validation
      )
        v-card-text
          v-row
            v-col(cols="12" sm="3")
              v-select(
                v-model="local_fall_lease.model_type"
                :items="model_types"
                :label="$t('fall_lease.model_type')"
                filled
              )
              v-text-field(
                v-model="local_fall_lease.model_rate"
                :label="$t('fall_lease.model_rate')"
                filled
              )
              v-select(
                v-model="local_fall_lease.payment_interval"
                :items="payment_intervals"
                :label="$t('fall_lease.payment_interval')"
                filled
              )
            v-col(cols="12" sm="3")
              v-select(
                v-model="local_fall_lease.threshold_type"
                :items="threshold_types"
                :label="$t('fall_lease.threshold_type')"
                filled
              )
              v-select(
                v-model="local_fall_lease.threshold_method"
                :items="threshold_methods"
                :label="$t('fall_lease.threshold_method')"
                filled
              )
              v-text-field(
                v-model="local_fall_lease.threshold_value"
                :label="$t('fall_lease.threshold_value')"
                filled
              )
              v-text-field(
                v-model="local_fall_lease.threshold_rate"
                :label="$t('fall_lease.threshold_rate')"
                suffix="%"
                filled
              )
            v-col(cols="12" sm="3")
              v-select(
                v-model="local_fall_lease.averaging_interval"
                :items="averaging_intervals"
                :label="$t('fall_lease.calculation_interval')"
                filled
              )
              v-text-field(
                v-model="local_fall_lease.min_price"
                :label="$t('fall_lease.min_price')"
                :suffix="local_fall_lease.currency"
                filled
              )
              v-text-field(
                v-model="local_fall_lease.fixed_price"
                :label="$t('fall_lease.fixed_price')"
                :suffix="local_fall_lease.currency"
                filled
              )
              v-text-field(
                v-model="local_fall_lease.total_costs"
                :label="$t('fall_lease.total_costs')"
                :suffix="local_fall_lease.currency"
                filled
              )
              v-text-field(
                v-model="local_fall_lease.revenue"
                :label="$t('fall_lease.revenue')"
                :suffix="local_fall_lease.currency"
                filled
              )
              v-text-field(
                v-model="local_fall_lease.elcert"
                :suffix="local_fall_lease.currency"
                :label="$t('fall_lease.elcert')"
                filled
              )
              v-text-field(
                v-model="local_fall_lease.goos"
                :suffix="local_fall_lease.currency"
                :label="$t('fall_lease.goos')"
                filled
              )
            v-col(cols="12" sm="3")
              v-select(
                v-model="local_fall_lease.kpi_adjustment"
                :items="kpi_adjustments"
                :label="$t('fall_lease.kpi_adjustment')"
                filled
              )
              VDatePickerInput(
                v-model="local_fall_lease.kpi_ref_date"
                :label="$t('fall_lease.kpi_ref_date')"
                filled
              )
              VDatePickerInput(
                v-model="local_fall_lease.kpi_end_date_type"
                :label="$t('fall_lease.kpi_end_date_type')"
                filled
              )
              v-text-field(
                v-model="local_fall_lease.payment_dates"
                :label="$t('fall_lease.payment_dates')"
                filled
              )

        v-card-actions
          v-btn(
            @click.prevent="submit_fall_lease"
            :color="form_fall_lease_button_color"
            :disabled="!form_fall_lease_valid"
            :loading="form_fall_lease_loading"
          ) {{ this.$t('general.save') }}

          v-btn(
            @click.prevent="go_to_compute"
            color="primary"
            :disabled="!form_fall_lease_valid"
            :loading="form_fall_lease_loading"
            v-if="this.fall_lease_id != null"
          ) {{ this.$t('fall_lease.compute') }}

          v-spacer

          v-btn(
            color="error"
            @click="cancel"
          ) {{ this.$t('general.cancel') }}
</template>
