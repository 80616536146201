import Vue from 'vue'
import Vuetify from 'vuetify/dist/vuetify.min.js'
import en from 'vuetify/lib/locale/en'
import no from 'vuetify/lib/locale/no'
import fr from 'vuetify/lib/locale/fr'
import de from 'vuetify/lib/locale/de'

Vue.use(Vuetify)

const lang_mapping = {
  en: 'en',
  nb: 'no',
  fr: 'fr',
  de: 'de'
}

const options = {
  lang: {
    locales: {en, no, fr, de},
    current: lang_mapping[window.vue_lang]
  },
  icons: {
    iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
  },
  theme: {
    themes: {
      light: {
        primary: '#132634',
      },
    },
  },
}

export default new Vuetify(options)
