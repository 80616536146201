<script>

export default {
  props: {
    user: Object,
  },
  data() {
    return {
      open: false
    }
  },
}
</script>

<template lang="pug">
  v-menu(v-model="open" bottom right transition="scale-transition" origin="top left")
    template(v-slot:activator="{ on }")
      span.pointer(v-on="on")
        slot
    v-card.user-card(width="400")
      v-list(color='primary' dark)
        v-list-item
          v-list-item-avatar
            v-img(:src="user.avatar_url")
          v-list-item-content
            v-list-item-title {{ user.name }}
            v-list-item-subtitle
              | {{ user.company }}
              span(v-if="user.job_title")  | {{ user.job_title }}
          v-list-item-action
            v-btn(icon @click="open = false")
              v-icon mdi-close-circle
      v-list
        v-list-item
          v-list-item-action
            v-icon mdi-email
          v-list-item-subtitle {{ user.email }}
        v-list-item(v-if="user.phone")
          v-list-item-action
            v-icon mdi-phone
          v-list-item-subtitle {{ user.phone }}

      v-card-text(v-if="user.short_bio") {{ user.short_bio }}
</template>
