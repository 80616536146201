<script>
export default {
  props: {
    isLandowner: Boolean,
    runoffActual: Number,
    runoffNormal: Number
  },
  data() {
    return {}
  },
  computed: {
    color() {
      return this.value < 0 ? 'red' : 'green'
    },
    value() {
      if (this.runoffNormal <= 0) return 0
      else if (this.runoffActual == 0) return -100
      else {
        const diff = this.runoffActual / this.runoffNormal - 1
        return parseInt(diff * 100)
      }
    },
    rotation() {
      let r = -90
      if (this.value < 0) r = -90 - 360.0 / (100.0 / Math.abs(this.value))
      return r
    }
  }
}
</script>

<template lang="pug">
v-col.top-widget(cols="12" sm="2")
  .widget-wrapper(:class="isLandowner ? 'opacity-30' : ''")
    strong
      | {{ $t('general.est_resource') }}
      br/
      dynamic-progress-circular(:color="color" :rotate="rotation" :value="Math.abs(value)")
        | {{ parseInt(value) }}%
      .description
        | {{ $t('dashboard.of_ytd_average') }}

</template>
