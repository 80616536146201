<script>
export default {
  props: {
    data: Array,
    title: String,
  },
  data() {
    return {
      chart_options: {
        chart: {
          backgroundColor: 'transparent',
          height: 300,
          scrollablePlotArea: {
            minWidth: (this.data.length * 20) + 100,
          }
        },
        title: {
          text: this.title
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: null
          },
          labels: {
            formatter: function () {
              return this.value + '%';
            }
          },
          plotLines: [{
            color: '#E3AAAA',
            width: 2,
            value: 100
          }]
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [{
          name: 'Result',
          type: 'column',
          data: this.data,
          maxPointWidth: 20,
          tooltip: {
            pointFormat: '{series.name}: {point.y}%',
            valueDecimals: 0,
          }
        }]
      }
    }
  }
}
</script>

<template lang="pug">
  v-highcharts(:options="chart_options")
</template>
