<script>
export default {
  props: {
    value: Array,
    form_data: Object,
    errors: Object,
  },
  data () {
    return {
      rivers: this.value,
    }
  },
  computed: {
    writable() {
      return this.form_data.permitted_attributes.find(a => a.asset_class_attributes).asset_class_attributes.find(a => a.rivers_attributes).rivers_attributes
    },
  },
  methods: {
    sharedProps(field) {
      return {
        name: `power_plant[asset_class_attributes][rivers_attributes][${field}]`,
        id: `power_plant_asset_class_attributes_rivers_attributes_${field}`,
        errorMessages: this.errors[`asset_class.rivers.${field}`],
        readonly: false,
        label: this.label(field),
        dense: true,
        outlined: true,
        persistentHint: true,
        hideDetails: 'auto',
        class: this.writable.includes(field) ? '' : 'd-none',
      }
    },
    label(field) {
      return this.$t(`activerecord.attributes.hydro/river.${field}`)
    },
    addNew() {
      this.rivers.push({
        name: null,
        river_length: null,
        river_gradient: null,
        watercourse: null,
        watercourse_area: null,
        anadromous_fish: null,
        _destroy: false,
      })
    },
    destroy(river) {
      if(river.id) {
        river._destroy = !river._destroy
      } else {
        this.rivers.splice(this.rivers.indexOf(river), 1)
      }
    },
  },
}
</script>
<template lang="pug">
#hydro-rivers
  .mb-2
    .overline.d-inline-block Rivers
    v-btn.ml-4(@click="addNew" small)
      v-icon(left) mdi-plus
      | Add
  span(v-for="river in rivers" :key="river.id" :class="{'opacity-50': river._destroy}")
    .d-flex.mb-2
      v-text-field.ml-1(v-model="river.name" v-bind="sharedProps('name')")
      v-text-field.ml-1(v-model="river.river_length" v-bind="sharedProps('river_length')" suffix="km")
      v-text-field.ml-1(v-model="river.river_gradient" v-bind="sharedProps('river_gradient')" suffix="m/km")
    .d-flex.mb-2
      v-text-field.ml-1(v-model="river.watercourse" v-bind="sharedProps('watercourse')")
      v-text-field.ml-1(v-model="river.watercourse_area" v-bind="sharedProps('watercourse_area')")
      v-checkbox.ml-1(v-model="river.anadromous_fish" v-bind="sharedProps('anadromous_fish')")
      v-icon(color="error" @click="destroy(river)") mdi-delete
</template>
