<script>

export default {
  props: {
    headers: Array,
    rows: Array,
    total: Object,
    highlight: Boolean,
  },
  data() {
    return {
    }
  },
  methods: {
    RowClass () {
      return this.highlight ? 'highlight' : ''
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    formatNumber (value) {
      if (this.isNumeric(value)) return Number(value).toLocaleString('no-NO')
      else return value
    },
  },
}

</script>

<template lang="pug">
  div
    v-data-table(dense :headers="headers" :items="rows" :item-class="RowClass" sort-by="cost" :sort-desc="true" :items-per-page="10")
      template(v-for="header in headers" v-slot:[`item.${header.value}`]="{ header, value }")
        span(v-if="header.value === 'verified'")
          v-icon(v-if="value") mdi-check
        span(v-else) {{ formatNumber(value) }}
      template(v-if="total" slot="body.append")
        tr.highlight
          template(v-for="header in headers")
            td(v-if="header.value === 'name'")
              span.text-left {{ total.name }}
            td(v-else-if="header.value === 'starts_at'")
              span.text-left {{ total.starts_at }}
            td.text-right(v-else)
              span {{ formatNumber(total[header.value]) }}
</template>
