<script>
export default {
  props: {
    value: Array,
    form_data: Object,
    errors: Object,
  },
  data () {
    return {
      reservoirs: this.value,
    }
  },
  computed: {
    writable() {
      return this.form_data.permitted_attributes.find(a => a.asset_class_attributes).asset_class_attributes.find(a => a.reservoirs_attributes).reservoirs_attributes
    },
  },
  methods: {
    sharedProps(field) {
      return {
        name: `power_plant[asset_class_attributes][reservoirs_attributes][${field}]`,
        id: `power_plant_asset_class_attributes_reservoirs_attributes_${field}`,
        errorMessages: this.errors[`asset_class.reservoirs.${field}`],
        readonly: false,
        label: this.label(field),
        dense: true,
        outlined: true,
        persistentHint: true,
        hideDetails: 'auto',
        class: this.writable.includes(field) ? '' : 'd-none',
      }
    },
    label(field) {
      return this.$t(`activerecord.attributes.hydro/reservoir.${field}`)
    },
    addNew() {
      this.reservoirs.push({
        lat: null,
        lng: null,
        reservoir_volume: null,
        name: null,
        surface_area: null,
        highest_reservoir_value: null,
        lowest_reservoir_value: null,
        risk_category: null,
        artificial_reservoir: null,
        gross_dammed_area: null,
        net_dammed_area: null,
        gross_power_density: null,
        net_power_density: null,
        _destroy: false,
      })
    },
    destroy(reservoir) {
      if(reservoir.id) {
        reservoir._destroy = !reservoir._destroy
      } else {
        this.reservoirs.splice(this.reservoirs.indexOf(reservoir), 1)
      }
    },
  },
}
</script>
<template lang="pug">
#hydro-reservoirs
  .mb-2
    .overline.d-inline-block Reservoirs
    v-btn.ml-4(@click="addNew" small)
      v-icon(left) mdi-plus
      | Add
  span(v-for="reservoir in reservoirs" :key="reservoir.id" :class="{'opacity-50': reservoir._destroy}")
    .d-flex.mb-2
      v-text-field.ml-1(v-model="reservoir.name" v-bind="sharedProps('name')")
      v-select.ml-1(v-model="reservoir.risk_category" v-bind="sharedProps('risk_category')" :items= "[0, 1, 2, 3, 4]")
      v-checkbox.ml-1(v-model="reservoir.artificial_reservoir" v-bind="sharedProps('artificial_reservoir')")
    .d-flex.mb-2
      v-text-field.ml-1(v-model="reservoir.reservoir_volume" v-bind="sharedProps('reservoir_volume')" suffix="m^3")
      v-text-field.ml-1(v-model="reservoir.surface_area" v-bind="sharedProps('surface_area')" suffix="km^2")
    .d-flex.mb-2
      v-text-field.ml-1(v-model="reservoir.lat" v-bind="sharedProps('lat')")
      v-text-field.ml-1(v-model="reservoir.lng" v-bind="sharedProps('lng')")
    .d-flex.mb-2
      v-text-field.ml-1(v-model="reservoir.highest_reservoir_value" v-bind="sharedProps('highest_reservoir_value')" suffix="m.a.s.l")
      v-text-field.ml-1(v-model="reservoir.lowest_reservoir_value" v-bind="sharedProps('lowest_reservoir_value')" suffix="m.a.s.l")

    .d-flex.mb-2
      v-text-field.ml-1(v-model="reservoir.gross_dammed_area" v-bind="sharedProps('gross_dammed_area')" suffix="m^2")
      v-text-field.ml-1(v-model="reservoir.net_dammed_area" v-bind="sharedProps('net_dammed_area')" suffix="m^2")
    .d-flex.mb-2
      v-text-field.ml-1(v-model="reservoir.gross_power_density" v-bind="sharedProps('gross_power_density')" suffix="W/m^2")
      v-text-field.ml-1(v-model="reservoir.net_power_density" v-bind="sharedProps('net_power_density')" suffix="W/m^2")
      v-icon(color="error" @click="destroy(reservoir)") mdi-delete
</template>
