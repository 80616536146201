<script>

const LIMIT_TYPE_UNITS = {
  price: 'EUR',
  fixed_price: 'EUR',
}

export default {
  props: {
    value: Object,
    sharedProps: Function,
    revenueTypes: Array,
    strategies: Array,
    limitTypes: Array,
  },
  data() {
    return {
      feed_in_tariff: this.value,
      LIMIT_TYPE_UNITS: LIMIT_TYPE_UNITS
    }
  },
  watch: {
    value(newValue) {
      this.feed_in_tariff = newValue
    },
    feed_in_tariff: {
      handler() {
        this.$emit("input", this.feed_in_tariff)
      },
      deep: true
    }
  },
}
</script>

<template lang="pug">
  v-row(v-if="feed_in_tariff")
    v-col(cols="12" sm="3")
      v-select(v-model="feed_in_tariff.limit_type" :items="limitTypes" v-bind="sharedProps('feed_in_tariff_data.limit_type')")
      v-text-field(v-model="feed_in_tariff.limit_value" v-bind="sharedProps('feed_in_tariff_data.limit_value')" :hint="feed_in_tariff.limit_type == 'percent' ? $t('activerecord.attributes.feed_in_tariff_data.limit_value_hint') : ''" :suffix="LIMIT_TYPE_UNITS[feed_in_tariff.limit_type]")
</template>
