<script>
import OMDashboardHelpers from '../../../../mixins/om_dashboard_helpers'
export default {
  props: {
    values: Array
  },
  mixins: [OMDashboardHelpers],
  data() {
    return { energyProduction: this.values }
  },
  watch: {
    values() {
      this.energyProduction = this.values
    }
  },
  computed: {
    hasData() {
      return this.performance_bars.length > 0
    },
    performance_bars() {
      return this.energyProduction.map((item) => {
        return [item.timestamp, item.energy_production]
      })
    },
    p50_line() {
      let p50_points = this.energyProduction
        .map((item) => {
          if (item.p50_target)
            return {
              x: item.timestamp,
              dataLabels: {
                enabled: false,
                align: 'left',
                color: '#98A2B3',
                verticalAlign: 'middle',
                overflow: true,
                crop: false,
                format: '{series.name}'
              },
              y: item.p50_target / 1000
            }
        })
        .filter(Boolean)
      if (p50_points.length == 12) p50_points[p50_points.length - 1].dataLabels.enabled = true
      return p50_points
    },
    options() {
      let series = [
        {
          type: 'line',
          name: this.$t('om_dashboard.drilldown.p50_target'),
          color: '#434348',
          dashStyle: 'dash',
          marker: { enabled: false },
          data: this.p50_line,
          tooltip: {
            pointFormat: '{series.name}: <b>{point.y} GWh</b>'
          }
        },
        {
          type: 'column',
          color: '#53B1FD',
          name: this.$t('om_dashboard.drilldown.energy_produced'),
          tooltip: {
            pointFormat: '{series.name}: <b>{point.y}GWh</b>'
          },
          data: this.performance_bars
        }
      ]
      return {
        title: { text: null },
        credits: { enabled: false },
        legend: {
          enabled: false
        },
        xAxis: [
          {
            type: 'datetime'
          }
        ],
        yAxis: [
          {
            type: 'linear',
            title: { text: 'GWh' }
          }
        ],
        plotOptions: {
          column: {
            pointWidth: 36
          }
        },
        series: series,
        lang: {
          contextButtonTitle: this.$t('om_dashboard.drilldown.export_menu.tooltip')
        },
        exporting: this.isExportable(
          this.$t('om_dashboard.drilldown.export_menu.filenames.energy_production')
        )
      }
    }
  }
}
</script>

<template lang="pug">
div(v-if="hasData")
  v-highcharts(:options="options")
div(v-else)
  v-progress-circular(indeterminate)
</template>
