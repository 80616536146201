<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    current_user: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      drawer: this.show,
    }
  },
  created() {
    this.$router.push({ name: 'notification_settings_menu_items', query: { current_user: this.current_user }})
  },
  methods: {
    navigateTo(routeName, params = {}) {
      this.$router.push({ name: routeName, query: params })
    }
  },
  watch: {
    show(newVal) {
      this.drawer = newVal
    },
    drawer(newVal) {
      if (newVal !== this.show) {
        this.$emit('update:show', newVal)
      }
    }
  }
}
</script>

<template lang="pug">
v-navigation-drawer(v-model="drawer" fixed disable-route-watcher temporary right width="350" style="max-height: 100vh; height: auto; overflow-y: auto;")
  keep-alive(:include="['NotificationSettingsMenuItems','NotificationSettingsPersonal', 'NotificationSettingsGeneral']")
    router-view
</template>
  
  