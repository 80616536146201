<script>
import Mapbox from 'mapbox-gl'
import { MglMap, MglGeojsonLayer } from 'vue-mapbox/dist/vue-mapbox.umd.min.js'

import Drawer from './drawer.vue'
import News from './news.vue'
import TotalUtilization from './widgets/total_utilization.vue'
import PlantsInOperation from './widgets/plants_in_operation.vue'
import BudgetGwh from './widgets/budget_gwh.vue'
import BudgetNok from './widgets/budget_nok.vue'
import EstResource from './widgets/est_resource.vue'

import { distanceFromLatLng, boundingBox, ZOOM_LEVEL } from './lib/map-functions'
import { createPowerPlantGeoJson, defaultLayer } from './lib/powerplant-geojson'
import { markerLayerDefaults } from './lib/marker-geojson'

export default {
  components: {
    MglMap,
    MglGeojsonLayer,
    Drawer,
    News,
    TotalUtilization,
    PlantsInOperation,
    BudgetGwh,
    BudgetNok,
    EstResource
  },
  props: {
    center_lat: { type: Number, default: 64.802 },
    center_lng: { type: Number, default: 15.732 },
    companies: Array,
    power_plants: Array,
    widgets: Array,
    widget_data: Object
  },
  data() {
    return {
      defaultGeoJson: createPowerPlantGeoJson(this.power_plants),
      accessToken: 'pk.eyJ1IjoibW9nYXJkIiwiYSI6ImNrcGdtYWxidTA2Z2gyb3F6bXM2ZnNsdDUifQ.MxUl8RBD90ajcmwqg3mOqA',
      mapStyle: 'mapbox://styles/mogard/ckpmpem5r1nvs17m4ggk352ag',
      center: [this.center_lng, this.center_lat],
      ZOOM_LEVEL,
      zoom: ZOOM_LEVEL.INIT,
      selectedPowerPlantIds: [],
      selectedCompanyIds: [],
      selectedCounties: [],
      selectedPriceAreas: [],
      drawerPlants: [],
      mapActions: {},
      widgetData: this.widget_data,
      powerplantBarOpen: false,
      companyBarOpen: false,
      priceAreaBarOpen: false,
      countyBarOpen: false,
      drawer: false,
      defaultLayer,
      filters: {},
      filterDialog: false,
      reseting: false,
      zoom_speed: 2.5,
      zoom_curve: 2,
      markerColors: ['#e91e63', '#62e91e', '#e9621e', '#a51ee9', '#e9c71e'],
      markerGeoJson: [
        { type: 'geojson', data: { type: 'FeatureCollection', features: [] } },
        { type: 'geojson', data: { type: 'FeatureCollection', features: [] } },
        { type: 'geojson', data: { type: 'FeatureCollection', features: [] } },
        { type: 'geojson', data: { type: 'FeatureCollection', features: [] } },
        { type: 'geojson', data: { type: 'FeatureCollection', features: [] } }
      ]
    }
  },
  computed: {
    counties() {
      return [...new Set(this.power_plants.map((pp) => pp.county).filter((pp) => !!pp))].sort((a, b) =>
        a.localeCompare(b)
      )
    },
    priceAreas() {
      const areas = this.power_plants.map(({ price_area }) => price_area).sort((a, b) => a.localeCompare(b))
      return [...new Set(areas)]
    },
    currentPadding() {
      let padding = { top: 100, bottom: 50, left: 50, right: 50 }
      if (this.drawer) padding.left += 500
      return padding
    }
  },
  methods: {
    addMarkers(powerPlantIds, index) {
      const sourceId = 'markers_' + index
      const markedFeatures = this.defaultGeoJson.data.features.filter(({ id }) => powerPlantIds.includes(id))
      this.markerGeoJson[index].data.features = JSON.parse(JSON.stringify(markedFeatures))
    },
    removeMarkers() {
      this.markerGeoJson.forEach((g) => (g.data.features = []))
    },
    markerLayer(index) {
      let layer = JSON.parse(JSON.stringify(markerLayerDefaults))
      layer.id = 'markerLayer_' + index
      layer.paint['icon-color'] = this.markerColors[index]
      return layer
    },
    hoverGeoPoint(e) {
      this.map.getCanvas().style.cursor = 'pointer'
    },
    leaveGeoPoint(e) {
      this.map.getCanvas().style.cursor = ''
    },
    async clickGeoPoint({ mapboxEvent }) {
      const { id } = mapboxEvent.features[0]
      let component = this.$refs.drawer
      const powerPlant = component.power_plants.find((p) => p.id == id)
      if (powerPlant) {
        this.changeSelected(powerPlant)
      } else {
        await this.resetMap()
        this.selectedPowerPlantIds = [id]
      }
    },
    async getFilteredWidgets() {
      let queryParams = []
      if (this.selectedPriceAreas.length) queryParams.push(`price_areas=${this.selectedPriceAreas.join(',')}`)
      if (this.selectedCounties.length) queryParams.push(`counties=${this.selectedCounties.join(',')}`)
      const data = await this.axios.get(`/overview_map/widgets?${queryParams.join('&')}`)
      this.widgetData = data.data
    },
    async changeSelected(powerPlant) {
      this.drawer = true
      let component = this.$refs.drawer
      const idx = component.power_plants.map((p) => p.id).indexOf(powerPlant.id)
      if (idx >= 0) {
        component.page = idx + 1
        this.centerTo(powerPlant)
      } else {
        await this.resetMap()
        this.selectedPowerPlantIds = [powerPlant.id]
      }
    },
    flyTo(powerPlants = []) {
      if (powerPlants.length === 0) return

      if (powerPlants.length === 1) {
        const powerplant = powerPlants[0]
        this.mapActions.flyTo({
          center: [powerplant.lng, powerplant.lat],
          zoom: ZOOM_LEVEL.POWER_PLANT,
          padding: this.currentPadding,
          speed: this.zoom_speed,
          curve: this.zoom_curve
        })
      } else {
        const bounds = boundingBox(powerPlants)
        this.map.fitBounds(bounds, { padding: this.currentPadding })
      }
    },
    centerTo(powerPlant) {
      const coords = [powerPlant.lng, powerPlant.lat]
      this.mapActions.easeTo({ center: coords, padding: this.currentPadding })
    },
    async onMapLoaded(event) {
      this.mapActions = event.component.actions
      this.map = event.map
      this.map.loadImage('/power_plant_icon.png', (_, image) =>
        this.map.addImage('hydro', image, { sdf: true })
      )

      // If zooming is too slow, disable scroll zoom
      // event.map.scrollZoom.disable()
    },
    toggleDrawer() {
      this.drawer = !this.drawer
    },
    showPowerplantBar() {
      if (this.selectedCompanyIds.length > 0) this.selectedCompanyIds = []
      if (this.selectedCounties.length > 0) this.selectedCounties = []
      if (this.selectedPriceAreas.length > 0) this.selectedPriceAreas = []
      this.drawer = false
      this.hideFilteringBars()
      this.powerplantBarOpen = true
    },
    showCompanyBar() {
      if (this.selectedCompanyIds.length == 0) this.selectedPowerPlantIds = []
      if (this.selectedCounties.length > 0) this.selectedCounties = []
      if (this.selectedPriceAreas.length > 0) this.selectedPriceAreas = []
      this.drawer = false
      this.hideFilteringBars()
      this.companyBarOpen = true
    },
    showPriceAreaBar() {
      if (this.selectedPowerPlantIds.length > 0) this.selectedPowerPlantIds = []
      if (this.selectedCompanyIds.length > 0) this.selectedCompanyIds = []
      if (this.selectedCounties.length > 0) this.selectedCounties = []
      this.drawer = false
      this.hideFilteringBars()
      this.priceAreaBarOpen = true
    },
    showCountyBar() {
      if (this.selectedPowerPlantIds.length > 0) this.selectedPowerPlantIds = []
      if (this.selectedCompanyIds.length > 0) this.selectedCompanyIds = []
      if (this.selectedPriceAreas.length > 0) this.selectedPriceAreas = []
      this.drawer = false
      this.hideFilteringBars()
      this.countyBarOpen = true
    },
    hideFilteringBars() {
      this.companyBarOpen = false
      this.powerplantBarOpen = false
      this.priceAreaBarOpen = false
      this.countyBarOpen = false
    },
    nearbyPowerPlants(powerplant) {
      return this.power_plants
        .map((pp) => ({
          ...pp,
          distance: distanceFromLatLng(powerplant.lat, powerplant.lng, pp.lat, pp.lng)
        }))
        .sort((a, b) => a.distance - b.distance)
        .slice(1, 5)
    },
    powerPlantsForCompanies(companyIds) {
      return this.power_plants.filter(
        (p) => p.parent_company_ids.filter((id) => companyIds.includes(id)).length > 0
      )
    },
    setFilter(obj) {
      this.filters = obj
    },
    async resetMap() {
      if (this.reseting) return
      this.reseting = true
      this.widgetData = this.widget_data
      await this.clearSelection()
      this.reseting = false
    },
    async clearSelection() {
      this.selectedPowerPlantIds = []
      this.selectedCompanyIds = []
      this.selectedCounties = []
      this.selectedPriceAreas = []
      this.drawerPlants = []
      this.defaultLayer.layout['text-allow-overlap'] = false
      this.removeMarkers()
      this.map.setFilter('defaultLayer')
      await this.resetZoom()
      this.drawer = false
    },
    resetZoom() {
      this.mapActions.flyTo({
        center: [this.center_lng, this.center_lat],
        padding: this.currentPadding,
        zoom: this.ZOOM_LEVEL.INIT,
        speed: this.zoom_speed,
        curve: this.zoom_curve
      })
    }
  },
  watch: {
    async selectedPriceAreas(priceAreas) {
      if (this.reseting) return
      if (priceAreas.length === 0) return this.resetMap()

      this.map.setFilter('defaultLayer', ['match', ['get', 'price_area'], priceAreas, true, false])
      await this.getFilteredWidgets()
      let bounds = new Mapbox.LngLatBounds()
      this.power_plants.forEach((pp) => {
        if (priceAreas.includes(pp.price_area)) bounds.extend([pp.lng, pp.lat])
      })
      this.map.fitBounds(bounds, { padding: this.currentPadding })
      this.hideFilteringBars()
    },
    async selectedCounties(counties) {
      if (this.reseting) return
      if (counties.length === 0) return this.resetMap()
      this.map.setFilter('defaultLayer', ['match', ['get', 'county'], counties, true, false])
      await this.getFilteredWidgets()
      let bounds = new Mapbox.LngLatBounds()
      this.power_plants.forEach((pp) => {
        if (counties.includes(pp.county)) bounds.extend([pp.lng, pp.lat])
      })
      this.map.fitBounds(bounds, { padding: this.currentPadding })
      this.hideFilteringBars()
    },
    selectedPowerPlantIds(powerPlantIds) {
      if (this.reseting) return

      if (!powerPlantIds || powerPlantIds.length === 0) {
        this.resetMap()
        return []
      }

      let powerPlants = this.power_plants.filter((p) => powerPlantIds.includes(p.id))
      if (powerPlants.length == 1) {
        const pp = powerPlants[0]
        powerPlants = [...[pp], ...this.nearbyPowerPlants(pp)]
        this.addMarkers(
          powerPlants.map((p) => p.id),
          0
        )
      }

      this.drawerPlants = powerPlants

      //const visibleDefaultMarkers = powerPlants.map(pp => [pp, this.nearbyPowerPlants(pp)]).flat(2)
      //this.defaultLayer.layout['text-allow-overlap'] = visibleDefaultMarkers.length < 50
      //this.map.setFilter('defaultLayer', [
      //'in',
      //['get', 'id'],
      //['literal', visibleDefaultMarkers.map(p => p.id)],
      //])

      this.drawer = true
      this.flyTo(powerPlants)
      this.hideFilteringBars()
    },
    selectedCompanyIds(companyIds) {
      if (this.reseting) return
      this.$refs.drawer.page = 1
      this.$nextTick(() => {
        // prevent flyTo from being overriden when drawer.page is reset
        this.selectedPowerPlantIds = this.powerPlantsForCompanies(companyIds).map((p) => p.id)
        this.removeMarkers()
        companyIds.forEach((id, index) => {
          const powerPlantIds = this.power_plants
            .filter((p) => p.parent_company_ids.includes(id))
            .map((p) => p.id)
          this.addMarkers(powerPlantIds, index)
        })
      })
    }
  }
}
</script>

<template lang="pug">
v-layout(fill-height id='overview-map')
  MglMap(
    :accessToken='accessToken',
    :mapStyle='mapStyle',
    :center='center',
    :zoom.sync='zoom',
    :hash="true"
    @load='onMapLoaded'
    style="height: 100vh"
  )

    v-dialog(
      v-model="powerplantBarOpen"
      content-class="elevation-0 top-bar"
      attach="#overview-map"
    )
      v-row
        v-spacer
        v-col(cols="12" lg="4")
          v-row(no-gutters)
            v-autocomplete(
              autofocus
              v-model="selectedPowerPlantIds"
              :items="power_plants"
              item-text="name"
              item-value="id"
              :label="$t('general.search_powerplants')"
              solo
              multiple
              chips
              deletable-chips
              clearable
              hide-details
              prepend-inner-icon="mdi-magnify"
            )
              template(v-slot:item="{ item }")
                v-list-item-avatar
                  v-icon(:color="selectedPowerPlantIds.includes(item.id) ? 'green' : 'grey'") mdi-flash
                v-list-item-content
                  v-list-item-title {{ item.name }}&nbsp;
                  v-list-item-subtitle
                    v-chip(small class="mr-1")
                      | Power plant
                    v-chip(v-if="item.owner && item.owner.name" small class="mr-1")
                      | {{ item.owner.name }}
                    v-chip(v-if="item.county" class="mr-1" small)
                      | {{ item.county }}
                    v-chip(v-if="item.price_area" class="mr-1" small)
                      | {{ item.price_area }}
        v-spacer

    v-dialog(
      v-model="companyBarOpen"
      content-class="elevation-0 top-bar"
      attach="#overview-map"
    )
      v-row
        v-spacer
        v-col(cols="12" lg="4")
          v-row(no-gutters)
            v-autocomplete(
              autofocus
              v-model="selectedCompanyIds"
              :items="companies"
              item-text="name"
              item-value="id"
              :label="$t('general.search_companies')"
              solo
              multiple
              chips
              deletable-chips
              clearable
              hide-details
              prepend-inner-icon="mdi-magnify"
            )
              template(v-slot:item="{ item }")
                v-list-item-avatar
                  v-icon(:color="selectedCompanyIds.includes(item.id) ? 'green' : 'grey'") mdi-domain
                v-list-item-content
                  v-list-item-title {{ item.name }}&nbsp;
                  v-list-item-subtitle
                    v-chip(small class="mr-1")
                      | Company
                    v-chip(v-if="item.owner && item.owner.name" small class="mr-1")
                      | {{ item.owner.name }}
                    v-chip(v-if="item.county" class="mr-1" small)
                      | {{ item.county }}
                    v-chip(v-if="item.price_area" class="mr-1" small)
                      | {{ item.price_area }}
        v-spacer

    v-dialog(
      v-model="countyBarOpen"
      content-class="elevation-0 top-bar"
      attach="#overview-map"
    )
      v-row
        v-spacer
        v-col(cols="12" lg="4")
          v-row(no-gutters)
            v-autocomplete(
              autofocus
              v-model="selectedCounties"
              :items="counties"
              :label="$t('general.search_counties')"
              solo
              multiple
              chips
              deletable-chips
              clearable
              hide-details
              prepend-inner-icon="mdi-magnify"
            )
        v-spacer

    v-dialog(
      v-model="priceAreaBarOpen"
      content-class="elevation-0 top-bar"
      attach="#overview-map"
    )
      v-row
        v-spacer
        v-col(cols="12" lg="4")
          v-row(no-gutters)
            v-autocomplete(
              autofocus
              :items="priceAreas"
              v-model="selectedPriceAreas"
              label=""
              solo
              multiple
              chips
              deletable-chips
              clearable
              hide-details
              prepend-inner-icon="mdi-magnify"
            )
        v-spacer

    v-row(class="pt-8")
      v-spacer
      v-col(cols=4 class="text-right" style="min-width: 700px")
        v-btn(
          class="mr-2"
          :color="selectedPowerPlantIds.length > 0 && selectedCompanyIds.length == 0 ? 'blue' : 'pink'"
          dark
          @click="showPowerplantBar"
        )
          | {{ $t('general.power_plant') }}

        v-btn(
          class="mr-2"
          :color="selectedCompanyIds.length > 0 ? 'blue' : 'pink'"
          dark
          @click="showCompanyBar"
        )
          | {{ $t('general.company') }}

        v-btn(
          class="mr-2"
          :color="selectedPriceAreas.length > 0 ? 'blue' : 'pink'"
          dark
          @click="showPriceAreaBar"
        )
          | {{ $t('general.price_area') }}

        v-btn(
          class="mr-2"
          :color="selectedCounties.length > 0 ? 'blue' : 'pink'"
          dark
          @click="showCountyBar"
        )
          | {{ $t('general.county') }}

        v-btn(
          class="mr-2"
          fab
          dark
          small
          color="pink"
          @click.stop="zoom += 1"
        )
          v-icon mdi-magnify-plus

        v-btn(
          class="mr-2"
          fab
          dark
          small
          color="pink"
          @click.stop="zoom -= 1"
        )
          v-icon mdi-magnify-minus

        v-btn(
          class="mr-8"
          fab
          dark
          small
          color="pink"
          @click.stop="resetMap"
        )
          v-icon mdi-arrow-expand-all

    MglGeojsonLayer(
      :source="defaultGeoJson"
      @click="clickGeoPoint"
      @mouseover="hoverGeoPoint"
      @mouseleave="leaveGeoPoint"
      sourceId="powerplants"
      layerId="defaultLayer"
      :layer="defaultLayer"
    )

    MglGeojsonLayer(
      v-for="(_color,index) in markerColors"
      :key="index"
      :source="markerGeoJson[index]"
      @click="clickGeoPoint"
      @mouseover="hoverGeoPoint"
      @mouseleave="leaveGeoPoint"
      :sourceId="`markers_${index}`"
      :layerId="`markerLayer_${index}`"
      :layer="markerLayer(index)"
    )

    Drawer(
      ref="drawer"
      v-model="drawer"
      :power_plants="drawerPlants"
      :toggleDrawer="toggleDrawer"
      :nearbyPowerPlants="nearbyPowerPlants"
      :changeSelected="changeSelected"
    )
    #floating_widgets.side(:class="drawer ? 'shifted' : ''")
      TotalUtilization(v-if="widgets.includes(1)" :total_utilization="widgetData.total_utilization" :current_effect="widgetData.current_effect" :rated_power_mw="widgetData.rated_power_mw")
      PlantsInOperation(v-if="widgets.includes(2)" :outage_count="widgetData.power_plant_outage_count" :total_count="widgetData.power_plant_count")
      BudgetGwh(v-if="widgets.includes(3)" :budget_gwh="widgetData.budget_gwh" :volume_gwh="widgetData.volume_gwh")
      BudgetNok(v-if="widgets.includes(4)" :isLandowner="widgetData.is_landowner" :volumeNok="widgetData.volume_nok" :budgetNok="widgetData.budget_nok")
      EstResource(v-if="widgets.includes(5)" :isLandowner="widgetData.is_landowner" :runoffActual="widgetData.runoff_actual" :runoffNormal="widgetData.runoff_normal")
</template>
