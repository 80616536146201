<script>
export default {
  props: {
    precipitation: Array,
    temperature: Array,
    wind_speed: Array,
  },
  data () {
    return {
      series: [{
        name: this.$t('general.precipitation'),
        type: 'column',
        color: '#38506c',
        data: this.precipitation,
        unit: 'mm',
      }, {
        name: this.$t('general.wind_speed'),
        type: 'column',
        color: '#38506c',
        data: this.wind_speed,
        unit: 'm/s',
      }, {
        name: this.$t('general.temperature'),
        type: 'spline',
        data: this.temperature,
        color: '#00adf1',
        yAxis: 1,
        marker: {
          radius: 0,
        }
      }],
    }
  },
  computed: {
    filtered_series () {
      return this.series.filter(s => s.data && s.data.length > 0)
    },
    chart_options () {
      return {
        title: {
          text: this.$t('power_plants.chart_meteogram_title'),
        },
        xAxis: {
          type: 'datetime',
        },
        yAxis: [{
          min: 0,
          title: null,
          labels: {
            formatter() { return `${this.value.toFixed(1)}${this.chart.series[0].userOptions.unit}` }
          },
        }, {
          title: null,
          labels: {
            formatter() { return `${this.value.toFixed(1)}°` }
          },
          opposite: true,
        }],
        plotOptions: {
          column: {
            minPointLength: 3,
          },
          spline: {
            keys: ['x', 'y', 'marker']
          }
        },
        credits: {
          enabled: false
        },
        series: this.filtered_series,
      }
    }
  },
}
</script>

<template lang="pug">
v-highcharts(
  v-if="filtered_series.length > 0"
  :options="chart_options"
)
</template>
