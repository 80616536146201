<script>
export default {
  props: {
    prices: Object,
    y_title: String,
    currency_code: String,
  },
  data() {
    const series = Object.keys(this.prices).sort().map(key => {
      return {
        name: key,
        type: 'line',
        data: this.prices[key],
        yAxis: 0,
        visible: ['NO1', 'NO2', 'NO3', 'NO4', 'NO5', 'SYS'].includes(key),
        tooltip: {
          pointFormat: `{series.name}: {point.y} ${this.currency_code}`,
          valueDecimals: 0,
          xDateFormat: '%b %e %Y',
          shared: true
        },
        marker: {
          radius: 0,
        }
      }
    })

    return {
      chart_options: {
        title: {
          text: null,
        },
        xAxis: {
          type: 'datetime',
        },
        yAxis: {
          min: 0,
          title: {
            text: this.y_title,
            enabled: true,
        }},
        credits: {
          enabled: false
        },
        series: series,
      }
    }
  },
}
</script>

<template lang="pug">
  v-highcharts(:options="chart_options")
</template>
