<script>
import { debounce } from '../../lib/debounce.js'
import Responsible from './responsible.vue'
import CompanyResponsibleTable from './company_responsibles_table.vue'
import PowerPlantResponsiblesTable from './power_plant_responsibles_table.vue'

const load_delay = process.env.NODE_ENV == "test" ? 10 : 300

export default {
  components: {
    Responsible,
    CompanyResponsibleTable,
    PowerPlantResponsiblesTable,
  },
  props: {
    value: Array,
    existing_responsibles: Array,
    users: Array,
    company_id: Number,
    company_name: String,
    roles: Array,
    compact: {Boolean, default: false}
  },
  data() {
    return {
      company_responsibles: this.value,
      powerPlantSearch: "",
      powerPlantPage: 1,
      currentPowerPlantPage: [],
      totalPages: 0,
      loading: false,
    }
  },
  mounted() {
    let url = new URLSearchParams(window.location.search)
    this.powerPlantSearch = url.get("power_plant_name")
  },
  methods: {
    async fetchForPage() {
      this.loading = true
      try {
        const response = await this.axios.get(`/admin/power_plant_responsibles?company_id=${this.company_id}&page=${this.powerPlantPage}&search=${this.powerPlantSearch || ''}`)
        this.currentPowerPlantPage = JSON.parse(response.data.rows)
        this.totalPages = response.data.total_pages
      }
      catch(exception) {
        console.log(exception)
      }
      this.loading = false
    },
  },
  watch: {
    company_responsibles(new_value) {
      this.$emit("input", new_value);
      this.fetchForPage()
    },
    async powerPlantPage() {
      await this.fetchForPage()
    },
    powerPlantSearch: debounce(async function() {
      await this.fetchForPage()
      this.powerPlantPage = 1
    }, load_delay)
  },
}
</script>

<template lang="pug">
div
  v-card.mb-5(elevation="0")
    v-card-text(:style="compact ? 'overflow: auto; height: 70vh' : ''")
      CompanyResponsibleTable(v-model="company_responsibles" :existing_responsibles="existing_responsibles" :users="users" :company_id="company_id" :company_name="company_name")
      h2.my-10 Power Plants

      div#responsibles_table
        v-text-field.my-2(v-model="powerPlantSearch" :label="$t('general.search')" hideDetails="auto" dense outlined clearable)
        div(v-if="loading")
          v-progress-linear(indeterminate)
        div(v-else)
          PowerPlantResponsiblesTable(v-for="(powerPlant, index) in currentPowerPlantPage" :key="powerPlant.id" v-model="currentPowerPlantPage[index]" :roles="roles")

      v-pagination(v-if="totalPages > 1" v-model="powerPlantPage" :length="totalPages" :total-visible="10")
</template>
