<script>
export default {
  props: {
  },
  data() {
    return {
      ready: false,
      key: 1,
      volume_series: [],
      price_series: [],
      currencyCode: '',
      prices_min_value: 0,
      volume_min_value: 0,
      scope: '1m',
      buttons: [{
        type: 'all',
        text: '24h',
      }, {
        type: 'all',
        text: '7d',
      }, {
        type: 'all',
        text: '1m',
      }, {
        type: 'all',
        text: '3m',
      }, {
        type: 'all',
        text: '6m',
      }, {
        type: 'all',
        text: 'YTD',
      }, {
        type: 'all',
        text: '1y',
      }]
    }
  },
  computed: {
    selected_button_index() {
      return this.buttons.findIndex(b => b.text == this.scope)
    },
    chart_options () {
      let currencyCode = this.currencyCode
      let total_volume_translation = this.$t("dashboard.total_volume")

      return {
        chart: {
          zoomType: '',
        },
        time: {
          timezoneOffset: new Date().getTimezoneOffset()
        },
        title: {
          disabled: true,
        },
        legend: { enabled: true },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            hour: '%b %e %Y %H:%M'
          },
          events: {
            setExtremes: (e) => { // Fat arrow to preserve 'this' scope
              this.scope = e.rangeSelectorButton.text
            }
          }
        },
        yAxis: [{
          title: {
            enabled: false
          },
          labels: {
            formatter() {
              return this.value + ' MW';
            }
          },
          opposite: false,
          dateTimeLabelFormats: {
            hour: '%b %e %Y %H:%M'
          },
          min: this.volume_min_value,
          startOnTick: false,
          endOnTick: false,
          tickAmount: 6,
        },{
          title: {
            enabled: false
          },
          labels: {
            formatter() {
              return this.value + ` ${currencyCode}/MWh`;
            }
          },
          opposite: true,
          dateTimeLabelFormats: {
            hour: '%b %e %Y %H:%M'
          },
          min: this.prices_min_value,
          startOnTick: false,
          endOnTick: false,
          tickAmount: 6,
        }],
        credits: {
          enabled: false
        },
        plotOptions: {
          series: {
            stacking: 'normal',
            animation: false
          },
        },
        rangeSelector: {
          inputEnabled: false,
          allButtonsEnabled: true,
          selected: this.selected_button_index,
          buttons: this.buttons,
        },
        navigator: {
          enabled: false,
        },
        scrollbar: {
          enabled: false,
        },
        series: [
          ...this.price_series,
          ...this.volume_series,
        ],
        tooltip: {
          split: true,
          useHTML: true,
          formatter: function () {
            let total_volume = 0
            let html_price = []
            let html_volume = ""

            this.points.forEach(function (point) {
              let series_type = point.series.userOptions.series_type

              switch(series_type) {
                case 'price':
                  html_price.push(`${point.series.name.toUpperCase()} Price: ${point.y.toFixed(0)} ${currencyCode}/MWh`)
                break;
                case 'volume':
                  html_volume += `<div style="display: flex;">
                                    ${point.series.name}
                                    <div style=" flex-grow: 1;"></div>
                                    ${point.y.toFixed(3)} MW
                                  </div>`
                  total_volume += point.y
                break;
              }
            })

            let options = {
              weekday: 'long',
              month: 'short',
              day: 'numeric',
              hourCycle: 'h23',
              hour: "2-digit",
              minute: "2-digit"
            }

            let date = new Date(this.x).toLocaleString("en-US", options)
            let values = [date]

            if(html_price.length)
              values = [...values, ...html_price]
            if(html_volume)
              html_volume += `<div style="display: flex;">
                                <b> ${ total_volume_translation }:&nbsp;</b>
                                <div style=" flex-grow: 1;"></div>
                                <b> ${total_volume.toFixed(3)} MW </b>
                              </div>`
              values.push(html_volume)

            return values
          },
        },
      }
    }
  },
  methods: {
    async loadData() {
      this.ready = false
      let url = new URL('/dashboard/zoom_graph' + window.location.search, window.location.origin)
      url.searchParams.set('scope', this.scope)
      try {
        const { data } = await this.axios.get(url)
        this.prices_min_value = data.prices_min_value || 0
        this.volume_min_value = data.volume_min_value || 0
        this.volume_series = Array.isArray(data.volume) ? this.prepare_volume_series(data.volume) : []
        this.currencyCode = data.prices.currency_code || ''

        this.price_series = data.prices.series ? Object.entries(data.prices.series).map(([seriesName, seriesData], index) => ({
          name: seriesName,
          type: 'spline',
          data: seriesData,
          yAxis: 1,
          showInLegend: true,
          visible: index === 0,
          series_type: 'price',
          dataGrouping: {
            approximation: 'average',
          },
        })) : []
        this.ready = true
        this.key += 1 // Triggers component re-render
      } catch (error) {
        console.error('Error loading data:', error)
        this.ready = true
      }
    },
    prepare_volume_series(volume) {
      let series = volume.map( ({ data, color, asset_type }) => ({
        name: this.$t("dashboard.volume_for_asset_type", { type: this.$t(`power_plants.asset_class_types.${asset_type}/asset`) }),
        type: 'areaspline',
        color: color,
        data: data,
        yAxis: 0,
        series_type: 'volume'
      }))
      return series
    }
  },
  watch: {
    scope() { this.loadData() }
  },
  mounted() {
    this.loadData()
  },
}
</script>

<template lang="pug">
span
  v-highcharts(v-show="ready" :key="key" :options="chart_options" constructor-type="stockChart")
  v-progress-linear(v-if="!ready" indeterminate)
</template>
