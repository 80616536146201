<script>
export default {
  props: {
    value: Object,
    sensor_types: Array
  },
  data () {
    return {
      sensor: this.value,
      saving: false,
      save_successful: false,
      save_failed: false,
      error_msg: ''
    }
  },
  computed: {
    sensor_type_options () {
      return this.sensor_types.map(s => { return { text: s, value: s } })
    },
    shared_props () {
      return {
        dense: true,
        outlined: true,
        hideDetails: 'auto'
      }
    }
  },
  methods: {
    async save () {
      this.save_failed = false
      this.saving = true
      try {
        const url = `/admin/tyde_sensors/${this.sensor.id}`
        await this.axios.put(url, { tyde_sensor: this.sensor })
        this.save_successful = true
        this.saving = false
      } catch (error) {
        this.errorHandler(error)
      }
    },
    errorHandler(error) {
      this.saving = false
      const response = error.response || {}
      if (response.status === 422) {
        this.error_msg = response.data.errors.join(' ')
        this.save_failed = true
      } else {
        alert('An unexpected error occurred')
      }
    }
  },
  watch: {
    sensor () {
      this.$emit('input', this.sensor)
    }
  }
}
</script>
<template lang="pug">
tr
  td
    | {{ sensor.name }}
  td
    v-select(v-model="sensor.sensor_type" :items="sensor_type_options" v-bind="shared_props" label="Sensor type" clearable)
  td
    v-text-field(v-model="sensor.description" v-bind="shared_props")
  td
    v-btn(@click="save" :disabled="saving" small) {{ $t('general.save') }}
    v-progress-circular.ml-2(v-if="saving" size="20" color="primary" indeterminate)
    v-snackbar(v-model="save_successful" :timeout="2000" color="success") Sensor saved. Reload page to see the effects.
    v-snackbar(v-model="save_failed" color="error") {{ error_msg }}
</template>
