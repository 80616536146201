export const ZOOM_LEVEL = {
  INIT: 4.6,
  POWER_PLANT: 15,
  CITY: 7.5,
  COUNTY: 6.5
}

export const boundingBox = points => {
  if (!Array.isArray(points) || !points.length) return undefined

  let w, s, e, n

  // Calculate the bounding box with a simple min, max of all latitudes and longitudes
  points.forEach((point) => {
    if (w === undefined) {
      n = s = point.lat
      w = e = point.lng
    }

    if (point.lat > n) {
      n = point.lat
    } else if (point.lat < s) {
      s = point.lat
    }
    if (point.lng > e) {
      e = point.lng
    } else if (point.lng < w) {
      w = point.lng
    }
  })

  return [
    [w, s],
    [e, n]
  ]
}

export const distanceFromLatLng = (lat1, lng1, lat2, lng2) => {
  const R = 6371 // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1)  // deg2rad below
  const dLon = deg2rad(lng2 - lng1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c // Distance in km
  return d
}

const deg2rad = deg => deg * (Math.PI/180)
