<script>
export default {
  props: {
    value: Array,
    form_data: Object,
    errors: Object,
  },
  data () {
    return {
      turbines: this.value,
    }
  },
  computed: {
    writable() {
      return this.form_data.permitted_attributes.find(a => a.asset_class_attributes).asset_class_attributes.find(a => a.turbines_attributes).turbines_attributes
    },
  },
  methods: {
    sharedProps(field) {
      return {
        name: `power_plant[asset_class_attributes][turbines_attributes][${field}]`,
        id: `power_plant_asset_class_attributes_turbines_attributes_${field}`,
        errorMessages: this.errors[`asset_class.turbines.${field}`],
        readonly: false,
        label: this.label(field),
        dense: true,
        outlined: true,
        persistentHint: true,
        hideDetails: 'auto',
        class: this.writable.includes(field) ? '' : 'd-none',
      }
    },
    label(field) {
      return this.$t(`activerecord.attributes.hydro/turbine.${field}`)
    },
    addNew() {
      this.turbines.push({
        turbine_type: 'pelton',
        installed_effect_mw: null,
        masl: null,
        q_min_loss_percent: null,
        min_throughput_operational: null,
        max_throughput_operational: null,
        efficiency_percent: null,
        _destroy: false,
      })
    },
    destroy(turbine) {
      if(turbine.id) {
        turbine._destroy = !turbine._destroy
      } else {
        this.turbines.splice(this.turbines.indexOf(turbine), 1)
      }
    },
  },
}
</script>
<template lang="pug">
#hydro-turbines
  .mb-2
    .overline.d-inline-block Turbines
    v-btn.ml-4(@click="addNew" small)
      v-icon(left) mdi-plus
      | Add
  .d-flex.flex-wrap(v-for="turbine in turbines" :key="turbine.id" :class="{'opacity-50': turbine._destroy}")
    v-select.ml-1(v-model="turbine.turbine_type" v-bind="sharedProps('turbine_type')" :items="form_data.turbine_types || []")
    v-text-field.ml-1.mb-2(v-model="turbine.installed_effect_mw" v-bind="sharedProps('installed_effect_mw')")
    v-text-field.ml-1.mb-2(v-model="turbine.masl" v-bind="sharedProps('masl')")
    v-text-field.ml-1.mb-2(v-model="turbine.min_throughput_operational" v-bind="sharedProps('min_throughput_operational')" type="number" step=".001", suffix="m³/s")
    v-text-field.ml-1.mb-2(v-model="turbine.max_throughput_operational" v-bind="sharedProps('max_throughput_operational')" type="number" step=".001", suffix="m³/s")
    v-text-field.ml-1.mb-2(v-model="turbine.q_min_loss_percent" v-bind="sharedProps('q_min_loss_percent')" suffix="%" type="number" step=".1" dense outlined persistent-hint)
    v-text-field.ml-1.mb-2(v-model="turbine.efficiency_percent" v-bind="sharedProps('efficiency_percent')" suffix="%" type="number" step=".1" dense outlined persistent-hint)
    v-icon(color="error" @click="destroy(turbine)") mdi-delete
</template>
