<script>
import VolumeField from './volume_field.vue'
import ValidateDateFormatMixin from '../../mixins/validate_date_format_mixin.js'

export default {
  components: {
    VolumeField
  },
  mixins: [ValidateDateFormatMixin],
  props: {
    scheduled_downtime_period: Object,
    minDate: String,
    minTime: String,
    maxDate: String,
    maxTime: String,
    powerPlant: Object,
    comment: String,
    reason: String,
    component: String,
    edit_mode: Boolean,
    fetchRevenue: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      errors: [],
      starts_at: null,
      ends_at: null,
      scheduled_downtime_data: {
        power_plant_id: this.powerPlant?.id,
        comment: this.comment,
        component: this.component,
        reason: this.reason,
        volume: this.scheduled_downtime_period.volume,
        curtailed_mode: this.scheduled_downtime_period.curtailed_mode,
        operator_email: this.scheduled_downtime_period.operator_email
      },
      send_email_to_operator: false,
      curtailed_mode: {
        value: false
      },
      dataLoaded: false
    }
  },
  methods: {
    updateStartsAndEnds() {
      this.scheduled_downtime_data.starts_at = this.formattedDateAndTime(this.rawMinDate, this.rawMinTime)
      this.scheduled_downtime_data.ends_at = this.formattedDateAndTime(this.rawMaxDate, this.rawMaxTime)
      this.starts_at = this.formattedDateAndTime(this.rawMinDate, this.rawMinTime)
      this.ends_at = this.formattedDateAndTime(this.rawMaxDate, this.rawMaxTime)
    },
    formattedDateAndTime(date, time) {
      return date && time ? `${date} ${time}:00` : null
    },
    setScheduledDowntimeData(newVal) {
      this.scheduled_downtime_data = { ...newVal }
      this.scheduled_downtime_data.comment = this.comment
      this.scheduled_downtime_data.component = this.component
      this.scheduled_downtime_data.reason = this.reason
    },
    sharedProps(field) {
      return {
        readonly: false,
        filled: false,
        dense: true,
        outlined: true,
        hideDetails: 'auto',
        label: this.$t(`activerecord.attributes.scheduled_downtime_period.${field}`),
        id: `scheduled_downtime_period_${field}`,

        errorMessages: this.errors[field]
      }
    },
    async update() {
      const url = `/scheduled_downtime_periods/${this.scheduled_downtime_period.id || ''}`
      const method = this.scheduled_downtime_period.id ? 'patch' : 'post'
      const data = {
        scheduled_downtime_period: this.scheduled_downtime_data,
        send_email_to_operator: this.send_email_to_operator
      }

      try {
        await this.axios[method](url, data)
        window.location.reload()
      } catch (error) {
        if (error.response.status === 403) {
          this.errors['power_plant_id'] = 'invalid power plant'
        }
        if (error.response.data) {
          this.errors = error.response.data
        }
        this.$emit('error', this.errors)
        console.log(error.response)
      }
    }
  },
  mounted() {
    this.setScheduledDowntimeData(this.scheduled_downtime_period)
    this.rawMinDate = this.minDate
    this.rawMinTime = this.minTime
    this.rawMaxDate = this.maxDate
    this.rawMaxTime = this.maxTime

    this.updateStartsAndEnds()

    this.dataLoaded = true
  },
  computed: {
    startsAtForward: {
      get() {
        return this.starts_at ? this.formattedDateAndTime(this.rawMinDate, this.rawMinTime) : null
      },
      set(value) {
        this.starts_at = value
      }
    },
    EndsAtForward: {
      get() {
        return this.ends_at ? this.formattedDateAndTime(this.rawMaxDate, this.rawMaxTime) : null
      },
      set(value) {
        this.ends_at = value
      }
    },
    valid() {
      return !!(
        this.validateDateFormat(this.minDate) &&
        this.minTime &&
        this.validateDateFormat(this.maxDate) &&
        this.maxTime
      )
    }
  },
  watch: {
    minDate(newVal) {
      this.rawMinDate = newVal
      this.updateStartsAndEnds()
    },
    minTime(newVal) {
      this.rawMinTime = newVal
      this.updateStartsAndEnds()
    },
    maxDate(newVal) {
      this.rawMaxDate = newVal
      this.updateStartsAndEnds()
    },
    maxTime(newVal) {
      this.rawMaxTime = newVal
      this.updateStartsAndEnds()
    },
    scheduled_downtime_period(value) {
      this.setScheduledDowntimeData(value)
    },
    comment() {
      this.scheduled_downtime_data.comment = this.comment
    },
    component() {
      this.scheduled_downtime_data.component = this.component
    },
    reason() {
      this.scheduled_downtime_data.reason = this.reason
    },
    'powerPlant.id'() {
      this.scheduled_downtime_data.power_plant_id = this.powerPlant?.id
    },
    powerPlant() {
      if (!this.edit_mode) {
        this.scheduled_downtime_data.volume = this.powerPlant?.rated_power_mw || 0
      }
    }
  }
}
</script>

<template lang="pug">
span
  v-row
    v-col.d-flex
      v-checkbox(v-model="scheduled_downtime_data.curtailed_mode" :label="$t('activerecord.attributes.scheduled_downtime_period.curtailed_mode')" hide-details="auto")
  v-row
    v-col.d-flex.align-center
      v-checkbox(v-model="send_email_to_operator" :label="$t('downtime_periods.email_to_operator')" hide-details="auto" class="mb-1")
    v-col.d-flex.align-center
      v-text-field(v-model="scheduled_downtime_data.operator_email" v-if="send_email_to_operator" type="text" v-bind="sharedProps('operator_email')")
  v-row
    v-col.d-flex.align-center
      div(v-if="dataLoaded")
        VolumeField(
          v-if="scheduled_downtime_period"
          :downtime_data="scheduled_downtime_data"
          v-model="scheduled_downtime_data.volume"
          :starts_at="startsAtForward"
          :ends_at="EndsAtForward"
          :powerPlant="powerPlant"
          :sharedProps="sharedProps('volume')"
          :hint="sharedProps('volume_hint').label"
          :flip="false"
          :fetchRevenue="fetchRevenue"
        )

  v-card-actions(style="padding-top: 24px")
    v-btn(
      color="primary"
      :disabled="!valid"
      @click="update"
    ) {{ $t('general.save') }}
    v-spacer
    v-btn(text @click="$emit('cancel')") {{ $t('general.cancel' )}}

</template>
