<script>
import ChartsOmDashboardWater from "./components/water.vue"

export default {
  name: 'FallLoss',
  components: { ChartsOmDashboardWater },
  props: {
    powerPlant: {
      type: Object,
      required: true
    },
    drilldownFacade: {
      type: Object,
      required: true
    },
    currentRange: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      hourlyFallLossData: null
    }
  },
  computed: {
    fallLossSensor() {
      const sensorId = this.drilldownFacade?.sensor_mapping?.fall_loss
      return sensorId ? this.drilldownFacade.sensors.find(sensor => sensor.tyde_id === sensorId) : null
    },
    fallLossIntake() {
      return this.drilldownFacade?.fall_loss_intake || null
    },
    waterLevelUpstreamSensor() {
      const sensorId = this.drilldownFacade?.sensor_mapping?.water_level_upstream
      return sensorId ? this.drilldownFacade.sensors.find(sensor => sensor.tyde_id === sensorId) : null
    },
    waterLevelDownstreamSensor() {
      const sensorId = this.drilldownFacade?.sensor_mapping?.water_level_downstream
      return sensorId ? this.drilldownFacade.sensors.find(sensor => sensor.tyde_id === sensorId) : null
    },
    penstockPressure() {
      return this.drilldownFacade?.penstock_pressure || null
    },
    intakes() {
      return this.drilldownFacade?.intakes?.intake_names
    }
  },
  methods: {
    omNum(value) {
      return value ? value.toFixed(3) : '0.00'
    },
    async fetchHourlyFallLoss() {
      try {
        if (this.fallLossSensor) {
          const response = await this.axios.get(`/om_dashboard/sensor_data/?sensor_name=${this.fallLossSensor.name}&start_time=${this.currentRange[0]}&end_time=${this.currentRange[1]}`)
          this.hourlyFallLossData = response.data
        } else {
          console.warn('Fall loss sensor is not available')
        }
      } catch (error) {
        console.error('Error fetching hourly fall loss data:', error)
      }
    }
  },
  async mounted() {
    await this.fetchHourlyFallLoss()
  }
}
</script>

<template lang="pug">
div
  .heading
    span.header-text {{ $t('om_dashboard.drilldown.fall_loss') }}
    span.grey--text.pr-4 ({{ drilldownFacade?.chart_range_description }})
    v-chip(v-if="fallLossSensor" :label="true")
      | {{ fallLossSensor.latest_value ? fallLossSensor.latest_value.toFixed(2) : '0.00' }}
      | {{ fallLossSensor.unit || 'm' }}
  v-row(no-gutters)
    v-col(cols="10").pr-16
      ChartsOmDashboardWater(
        v-if="fallLossSensor"
        :data="hourlyFallLossData"
        name="Fall loss"
        :upper_threshold="powerPlant?.tyde_sensors?.fall_loss?.upper_alert_limit"
        unit=""
      )
      div(v-else class="mt-2")
        span.grey--text {{ $t('om_dashboard.help.sensor_type', { sensor_type: 'fall_loss' }) }}
    v-col(cols="2")
      .details
        div
          div {{ $t('om_dashboard.drilldown.fall_loss_intake') }}
          div(v-if="fallLossIntake")
            | {{ omNum(fallLossIntake.latest_value) }} cm
          div(v-else)
            v-tooltip(bottom)
              template(v-slot:activator="{ on, attrs }")
                v-icon.small.grey--text.text--lighten-1(v-bind="attrs" v-on="on") mdi-minus
              span {{ $t('om_dashboard.help.sensor_type', { sensor_type: 'fall_loss_intake' }) }}
        div
          div {{ $t('om_dashboard.drilldown.level_upstream') }}
          div(v-if="waterLevelUpstreamSensor")
            | {{ waterLevelUpstreamSensor.latest_value?.toFixed(2) }} {{ waterLevelUpstreamSensor.unit || 'm' }}
          div(v-else)
            v-tooltip(bottom)
              template(v-slot:activator="{ on, attrs }")
                v-icon.small.grey--text.text--lighten-1(v-bind="attrs" v-on="on") mdi-minus
              span {{ $t('om_dashboard.help.sensor_type', { sensor_type: 'water_level_upstream' }) }}
        div
          div {{ $t('om_dashboard.drilldown.level_downstream') }}
          div(v-if="waterLevelDownstreamSensor")
            | {{ waterLevelDownstreamSensor.latest_value?.toFixed(2) }} {{ waterLevelDownstreamSensor.unit || 'm' }}
          div(v-else)
            v-tooltip(bottom)
              template(v-slot:activator="{ on, attrs }")
                v-icon.small.grey--text.text--lighten-1(v-bind="attrs" v-on="on") mdi-minus
              span {{ $t('om_dashboard.help.sensor_type', { sensor_type: 'water_level_downstream' }) }}
        div
          div {{ $t('om_dashboard.drilldown.penstock_pressure') }}
          div(v-if="penstockPressure")
            | {{ omNum(penstockPressure.latest_value) }} m
          div(v-else)
            v-tooltip(bottom)
              template(v-slot:activator="{ on, attrs }")
                v-icon.small.grey--text.text--lighten-1(v-bind="attrs" v-on="on") mdi-minus
              span {{ $t('om_dashboard.help.sensor_type', { sensor_type: 'penstock_pressure' }) }}
        div
          div {{ $t('om_dashboard.drilldown.fall_loss_penstock') }}
          div
            div(v-if="powerPlant?.asset_class?.penstocks?.length")
              | {{ omNum(powerPlant.asset_class.penstocks.map(penstock => penstock.net_head_loss_percent).reduce((a, b) => a + b, 0) / powerPlant.asset_class.penstocks.length) }} %
            div(v-else)
              v-tooltip(bottom)
                template(v-slot:activator="{ on, attrs }")
                  v-icon.small.grey--text.text--lighten-1(v-bind="attrs" v-on="on") mdi-minus
                span {{ $t('om_dashboard.help.sensor_type', { sensor_type: 'penstock_loss' }) }}
        div
          div {{ $t('activerecord.attributes.hydro/intake.intake_type') }}
          div
            | {{ intakes }}
</template>
