<script>

// https://api4.windy.com/examples/parameters
// https://leafletjs.com/reference-1.4.0.html

export default {
  props: {
    power_plants: Array,
    center_lat: {type: Number, default: 63.4738068},
    center_lng: {type: Number, default: 15.0651226},
    auto_zoom: {type: Boolean, default: false},
    auto_height: {type: Boolean, default: false},
    reduce_height_by: {type: Number, default: 0},
  },
  data() {
    return {
    }
  },
  computed: {
    zoom() { return (this.power_plants.length === 1) ? 8 : 5 },
    lat() { return (this.power_plants.length === 1) ? this.power_plants[0].lat : this.center_lat },
    lng() { return (this.power_plants.length === 1) ? this.power_plants[0].lng : this.center_lng },
    map_height() {
      if (this.auto_height) {
        return "100%"
      }

      let height = Math.min(document.documentElement.clientHeight, window.innerHeight || 0) - this.reduce_height_by
      if (this.power_plants.length === 1) height = height/2
      return height + 'px'
    }
  },
  methods: {
    onMutate() {
      if (this.map != null) {
        this.map.invalidateSize()
      }
    },
  },
  mounted() {
    const options = {
      key: 'jpr0DwDmTBD6Eh5VKYaM50cUTdcQrK2v',
      lat: this.lat,
      lon: this.lng,
      zoom: this.zoom,
    }
    windyInit(options, windyAPI => {
      // windyAPI contain 'map', 'store', 'picker' and more
      this.map = windyAPI.map

      windyAPI.store.set('lang', 'en')
      windyAPI.store.set('particlesAnim', 'off')

      let markers = L.markerClusterGroup({})

      for (let pp of this.power_plants) {
        let icon = L.icon({
          iconUrl: pp.asset_class_url,
          iconSize: [32, 32],
          iconAnchor: [16, 16],
          tooltipAnchor: [32, 0],
        })

        let marker = L.marker([pp.lat, pp.lng], {title: pp.name, icon: icon})
        marker.bindTooltip(pp.name, {permanent: true, className: 'windyTooltip'}).openTooltip()
        marker.on('click', () => {
          window.location.href = `/power_plants/${pp.id}`
        })
        markers.addLayer(marker)
      }
      markers.addTo(this.map)

      // Auto zoom to fit markers if there are any
      if (this.auto_zoom && markers.getLayers().length > 0) {
        this.map.fitBounds(markers.getBounds())
      } else if (this.auto_zoom && markers.getLayers().length === 0) {
        this.map.setView([this.center_lat, this.center_lng], 5)
      }
    })
  }
}
</script>

<template lang="pug">
#windy(v-mutate="onMutate" :style="`width: 100%; height: ${map_height}`")
</template>
