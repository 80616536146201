<script>

export default {
  props: {
    btnText: String,
    expanded: { type: Boolean, default: false },
  },
  data() {
    return {
      expand: this.expanded
    }
  },
}
</script>

<template lang="pug">
span
  slot(v-if="expand")
  v-btn(v-else @click="expand = true" x-small) {{ btnText }}
</template>
