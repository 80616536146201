<script>
import PowerPlantSuppliers from './suppliers.vue'
import NveMetadata from './nve_metadata.vue'
import TydeSensors from './tyde_sensors.vue'
import HydroAsset from './hydro/asset.vue'
import HydroIntakes from './hydro/intakes.vue'
import HydroTurbines from './hydro/turbines.vue'
import HydroGenerators from './hydro/generators.vue'
import HydroTransformers from './hydro/transformers.vue'
import HydroReservoirs from './hydro/reservoirs.vue'
import HydroPenstocks from './hydro/penstocks.vue'
import HydroPrecipitationFields from './hydro/precipitation_fields.vue'
import HydroRivers from './hydro/rivers.vue'
import HydroEnvironmentalFlow from './hydro/environmental_flow.vue'
import SolarAsset from './solar/asset.vue'
import SolarBackend from './solar/backend.vue'
import SolarInverters from './solar/inverters.vue'
import SolarFields from './solar/fields.vue'
import WindAsset from './wind/asset.vue'
import WindBackend from './wind/backend.vue'
import WindTurbines from './wind/turbines.vue'
import WindAvailabilityCalculation from './wind/availability_calculation.vue'
import WindAvailabilityTarget from './wind/availability_target.vue'
import WindContractualAvailabilitySelector from './wind/contractual_availability_selector.vue'

export default {
  components: {
    PowerPlantSuppliers,
    NveMetadata,
    TydeSensors,
    HydroAsset,
    HydroIntakes,
    HydroTurbines,
    HydroReservoirs,
    HydroPenstocks,
    HydroPrecipitationFields,
    HydroRivers,
    HydroEnvironmentalFlow,
    HydroGenerators,
    HydroTransformers,
    SolarAsset,
    SolarBackend,
    SolarInverters,
    SolarFields,
    WindAsset,
    WindBackend,
    WindTurbines,
    WindAvailabilityCalculation,
    WindAvailabilityTarget,
    WindContractualAvailabilitySelector
  },
  props: {
    value: Object
  },
  data() {
    return {
      dialog: true,
      power_plant: null,
      form_data: {},
      errors: {},
      submit_disabled: false,
      simulation_parameter: {
        optimization_model: null,
        periods: null,
        num_segments: null,
        kraftanmelding_uuid: null,
        production_simulation_plan_enabled: false,
      }
    }
  },
  computed: {
    ready() {
      return Object.keys(this.form_data).length > 0 && this.power_plant
    },
    newRecord() {
      return !this.value.id
    },
    admin_access() {
      return this.form_data.access_level === 'admin'
    },
    portfolio_access() {
      return this.form_data.access_level === 'portfolio'
    },
    has_nve_metadata() {
      return !this.newRecord && !!this.power_plant.nve_metadata_attributes
    },
    has_persisted_image() {
      return (
        this.value.image_url &&
        this.value.image_url !== '/default_power_plant_image.jpg' &&
        this.writable('image')
      )
    },
    has_tyde_sensors() {
      return (
        this.admin_access &&
        this.power_plant.tyde_sensors_attributes &&
        this.power_plant.tyde_sensors_attributes.length > 0
      )
    },
    is_hydro_asset() {
      return this.power_plant.asset_class_type === 'Hydro::Asset'
    },
    is_solar_asset() {
      return this.power_plant.asset_class_type === 'Solar::Asset'
    },
    is_wind_asset() {
      return this.power_plant.asset_class_type === 'Wind::Asset'
    },
    show_elhub_link() {
      return (
        this.admin_access &&
        this.power_plant.metering_point_id &&
        this.power_plant.metering_point_id.length > 0
      )
    },
    asset_class_attrs() {
      return this.form_data.permitted_attributes.find((a) => a.asset_class_attributes).asset_class_attributes
    },
    turbines_editable() {
      return this.is_hydro_asset && this.asset_class_attrs.find((a) => a.turbines_attributes)
    },
    wind_turbines_editable() {
      return this.is_wind_asset && this.asset_class_attrs.find((a) => a.wind_turbines_attributes)
    },
    solar_inverters_editable() {
      return this.is_solar_asset && this.asset_class_attrs.find((a) => a.solar_inverters_attributes)
    },
    solar_fields_editable() {
      return this.is_solar_asset && this.asset_class_attrs.find((a) => a.solar_fields_attributes)
    },
    generators_editable() {
      return this.is_hydro_asset && this.asset_class_attrs.find((a) => a.generators_attributes)
    },
    transformers_editable() {
      return this.is_hydro_asset && this.asset_class_attrs.find((a) => a.transformers_attributes)
    },
    reservoirs_editable() {
      return this.is_hydro_asset && this.asset_class_attrs.find((a) => a.reservoirs_attributes)
    },
    penstocks_editable() {
      return this.is_hydro_asset && this.asset_class_attrs.find((a) => a.penstocks_attributes)
    },
    rivers_editable() {
      return this.is_hydro_asset && this.asset_class_attrs.find((a) => a.rivers_attributes)
    },
    environmental_flow_breakpoints_editable() {
      return (
        this.is_hydro_asset && this.asset_class_attrs.find((a) => a.environmental_flow_breakpoints_attributes)
      )
    },
    precipitation_fields_editable() {
      return this.is_hydro_asset && this.asset_class_attrs.find((a) => a.precipitation_fields_attributes)
    },
    show_wiki_link() {
      return (
        this.admin_access &&
        this.power_plant.wikipedia_page_url &&
        this.power_plant.wikipedia_page_url.length > 0
      )
    },
    col_props() {
      return this.portfolio_access ? { cols: 12, class: 'py-0' } : { cols: 12, md: 2 }
    },
    dialog_props() {
      return this.portfolio_access ? { maxWidth: 800 } : { fullscreen: true }
    },
    asset_class_humanized() {
      if (this.power_plant.asset_class_type) {
        return this.power_plant.asset_class_type.split('::')[0].toLowerCase()
      } else {
        return 'power'
      }
    }
  },
  methods: {
    closeDialog(persisted) {
      this.dialog = false
      this.$emit('dialog-closed', persisted)
    },
    formProps(field) {
      return {
        name: `power_plant[${field}]`,
        id: `power_plant_${field}`,
        errorMessages: this.errors[field],
        readonly: false,
        label: this.label(field),
        dense: true,
        outlined: true,
        persistentHint: true,
        hideDetails: 'auto',
        class: `mb-2 ${this.writable(field) ? '' : 'd-none'}`
      }
    },
    label(field) {
      return this.$t(`activerecord.attributes.power_plant.${field}`)
    },
    writable(field) {
      return this.form_data.permitted_attributes.includes(field)
    },
    async submit() {
      this.submit_disabled = true
      try {
        const method = this.newRecord ? 'post' : 'put'
        const url = this.newRecord ? '/power_plants' : `/power_plants/${this.value.id}`
        const options = { headers: { 'Content-Type': 'multipart/form-data' } }

        // cant find a cleaner way to send a multipart request without losing typecasting
        let formdata = new FormData()
        formdata.append('power_plant', JSON.stringify(this.power_plant))
        formdata.append('image', this.power_plant.image)

        await this.axios[method](url, formdata, options)
        this.$emit('input', { ...this.value, ...this.power_plant })
        this.closeDialog(true)
      } catch (error) {
        this.errors = error.response?.data
      }
      this.submit_disabled = false
    },
    async loadFormData() {
      const { data } = await this.axios.get('/power_plants/form_data')
      this.form_data = data
    },
    async loadPowerPlant() {
      if (this.newRecord) {
        this.power_plant = {
          country_id: 1,
          asset_class_type: null,
          settled_volume_source: null,
          unsettled_volume_source: null,
          weather_source: 'nve',
          asset_class_attributes: {},
          biodiversity_sensitive_areas: null,
          co2_emission: null,
          simulation_parameter: this.simulation_parameter
        }
      } else {
        const { data } = await this.axios.get(`/power_plants/${this.value.id}/edit`)
        this.power_plant = data
        if (!this.power_plant.simulation_parameter) {
          this.power_plant.simulation_parameter = this.simulation_parameter
        }
      }
    },
    newHydroPlant() {
      this.power_plant.asset_class_type = 'Hydro::Asset'
      this.power_plant.asset_class_attributes.intakes_attributes = []
      this.power_plant.asset_class_attributes.generators_attributes = []
      this.power_plant.asset_class_attributes.transformers_attributes = []
      this.power_plant.asset_class_attributes.turbines_attributes = []
      this.power_plant.asset_class_attributes.penstocks_attributes = []
      this.power_plant.asset_class_attributes.rivers_attributes = []
      this.power_plant.asset_class_attributes.precipitation_fields_attributes = []
      this.power_plant.asset_class_attributes.environmental_flow_attributes = {}
    },
    newWindPlant() {
      this.power_plant.asset_class_attributes.wind_turbines_attributes = []
      this.power_plant.asset_class_type = 'Wind::Asset'
    },
    newSolarPlant() {
      this.power_plant.asset_class_type = 'Solar::Asset'
      this.power_plant.asset_class_attributes.solar_inverters_attributes = []
      this.power_plant.asset_class_attributes.solar_inverters_attributes.solar_mppts_attributes = []
      this.power_plant.asset_class_attributes.solar_fields_attributes = []
    }
  },
  mounted() {
    this.loadPowerPlant()
    this.loadFormData()
  }
}
</script>

<template lang="pug">
v-dialog(v-model="dialog" @click:outside="closeDialog(false)" @keydown.esc="closeDialog(false)" transition="dialog-bottom-transition" v-bind="dialog_props")
  v-card.d-flex.flex-column(v-if="ready")
    v-card-title.headline.grey.lighten-2
      span(v-if="newRecord") Add {{ asset_class_humanized }} plant
      span(v-else) {{ $t('general.edit') }} {{ power_plant.name }}

    v-card-text.flex-grow-1
      v-alert.ma-0(v-if="portfolio_access" type="info" text dense) {{ $t('power_plants.edit_notice') }}

      div(v-if="!power_plant.asset_class_type")
        h3.mb-4 {{ $t('general.select_asset_type') }}
        v-btn(@click="newHydroPlant()" color="blue" dark) {{ $t('general.hydro') }}
        v-btn.mx-4(@click="newWindPlant()" color="green" dark) {{ $t('general.wind') }}
        v-btn(@click="newSolarPlant()" color="yellow" dark) {{ $t('general.solar') }}

      v-tabs(v-else)
        v-tab {{ $t('general.general') }}
        v-tab(v-if="is_solar_asset") Inverters + fields
        v-tab(v-if="is_solar_asset") {{ $t('general.solar_backend_configuration') }}
        v-tab(v-if="is_wind_asset") {{ $t('general.wind_backend_configuration') }}
        v-tab(v-if="has_nve_metadata") {{ $t('general.nve_metadata') }}
        v-tab(v-if="!newRecord") {{ $t('suppliers.suppliers') }}
        v-tab(v-if="!newRecord && is_wind_asset") {{ $t('general.availability_calculation') }}
        v-tab(v-if="has_tyde_sensors") {{ $t('general.tyde_sensors') }}

        v-tab-item.mt-2
          v-container.px-0(fluid)
            v-row
              v-col(v-bind="col_props")
                .overline(v-if="!portfolio_access") {{ $t('general.basic_details') }}
                v-text-field(v-model="power_plant.name" v-bind="formProps('name')")
                v-select(v-model="power_plant.country_id" v-bind="formProps('country_id')" :items="form_data.country_options || []" :error-messages="errors.country")
                v-text-field(v-model="power_plant.county_name" v-bind="formProps('county_name')" hint="Overrides NVE-value")
                v-text-field(v-model="power_plant.municipality_name" v-bind="formProps('municipality_name')" hint="Overrides NVE-value")
                v-text-field(v-model="power_plant.site_address" v-bind="formProps('site_address')")
                v-text-field(v-model="power_plant.postcode" v-bind="formProps('postcode')")
                .d-flex.align-center
                  v-searchable-select.flex-grow-1(v-model="power_plant.company_id" v-bind="formProps('company_id')" :items="form_data.company_options || []" label="Select company")
                  .mx-1(v-if="newRecord") or
                  v-text-field(v-if="newRecord" v-model="power_plant.company_name" v-bind="formProps('company_name')" label="Add new")
                .d-flex
                  v-text-field(v-model="power_plant.lat" v-bind="formProps('lat')")
                  v-text-field.ml-1(v-model="power_plant.lng" v-bind="formProps('lng')")
                .overline(v-if="!portfolio_access") {{ $t('general.image') }}
                v-file-input(v-model="power_plant.image" v-bind="formProps('image')" prepend-icon='' append-icon='mdi-paperclip')
                v-text-field(v-model="power_plant.image_attribution" v-bind="formProps('image_attribution')")
                v-img.mb-2(v-if="has_persisted_image" :src="value.image_url" max-height="100")
                v-checkbox(v-if="has_persisted_image" v-model="power_plant.remove_image" v-bind="formProps('remove_image')" label="Remove image")

                .overline(v-if="!portfolio_access") {{ $t('general.commercial_details') }}
                v-text-field(v-model="power_plant.yearly_production" v-bind="formProps('yearly_production')")
                v-select(v-model="power_plant.price_area" v-bind="formProps('price_area')" :items="form_data.price_areas || []")
                v-date-picker-input(v-model="power_plant.go_date" v-bind="formProps('go_date')" :filled="false")
                v-checkbox(v-model="power_plant.elcert" v-bind="formProps('elcert')")

              v-col(v-bind="col_props")
                .overline(v-if="admin_access") {{ $t('general.data_source_configuration') }}
                v-text-field(v-model="power_plant.metering_point_id" v-bind="formProps('metering_point_id')" hint="Elhub, Kraftanmelding, Bixia, Kinect")
                  template(v-slot:append-outer)
                    a.text-decoration-none(v-if="show_elhub_link" :href="`/admin/elhub_metering_points?gsrn=${power_plant.metering_point_id}`" title="Elhub MP info" target="_blank")
                      v-icon mdi-information-outline
                v-text-field(v-model="power_plant.wikipedia_page_id" v-bind="formProps('wikipedia_page_id')" hint="wgArticleId in the page source")
                  template(v-slot:append-outer)
                    a.text-decoration-none(v-if="show_wiki_link" :href="power_plant.wikipedia_page_url" title="Open wikipedia page" target="_blank")
                      v-icon mdi-information-outline
                v-text-field(v-model="power_plant.nispera_id" v-bind="formProps('nispera_id')")
                v-text-field(v-model="power_plant.tyde_id" v-bind="formProps('tyde_id')")
                v-text-field(v-model="power_plant.axpo_id" v-bind="formProps('axpo_id')")
                v-text-field(v-model="power_plant.greenbyte_site_id" v-bind="formProps('greenbyte_site_id')")
                v-text-field(v-model="power_plant.scada_int_id" v-bind="formProps('scada_int_id')")
                v-text-field(v-model="power_plant.scada_int_tag" v-bind="formProps('scada_int_tag')")
                v-text-field(v-model="power_plant.bazefield_site_id" v-bind="formProps('bazefield_site_id')")
                v-text-field(v-model="power_plant.xledger_project_id" v-bind="formProps('xledger_project_id')")
                v-text-field(v-model="power_plant.nve_nr" v-bind="formProps('nve_nr')")
                .d-flex
                  v-select(v-model="power_plant.settled_volume_source" v-bind="formProps('settled_volume_source')" :items="form_data.volume_sources || []")
                  v-text-field.ml-1(v-if="admin_access && power_plant.settled_volume_source === 'gurusoft'" v-model="power_plant.gurusoft_tags" v-bind="formProps('gurusoft_tags')")
                .d-flex
                  v-select(v-model="power_plant.unsettled_volume_source" v-bind="formProps('unsettled_volume_source')" :items="form_data.volume_sources || []")
                  v-text-field.ml-1(v-if="admin_access && power_plant.unsettled_volume_source === 'gurusoft'" v-model="power_plant.gurusoft_tags" v-bind="formProps('gurusoft_tags')")
                v-checkbox(v-model="power_plant.collect_weather" v-bind="formProps('collect_weather')")
                v-select(v-model="power_plant.weather_source" v-bind="formProps('weather_source')" :items="form_data.weather_sources || []")

              v-col(v-bind="col_props")
                .overline(v-if="!portfolio_access") {{ $t('general.other_info') }}
                v-checkbox(v-if="is_hydro_asset" v-model="power_plant.reservoir" v-bind="formProps('reservoir')")
                hydro-asset(v-if="is_hydro_asset" v-model="power_plant.asset_class_attributes" :form_data="form_data" :errors="errors")
                v-date-picker-input(v-model="power_plant.commissioning_date" v-bind="formProps('commissioning_date')" :filled="false"  :hint="$t('power_plants.commissioning_date_hint')")
                solar-asset(v-if="is_solar_asset" v-model="power_plant.asset_class_attributes" :form_data="form_data" :errors="errors")
                wind-asset(v-if="is_wind_asset" v-model="power_plant.asset_class_attributes" :form_data="form_data" :errors="errors")
                v-text-field(v-model="power_plant.biodiversity_sensitive_areas" v-bind="formProps('biodiversity_sensitive_areas')")
                v-text-field(v-model="power_plant.co2_emission" v-bind="formProps('co2_emission')" suffix="gCO2e/kWh")

              v-col(v-bind="col_props")
                .overline(v-if="!portfolio_access") {{ $t('general.feature_configuration') }}
                v-checkbox(v-model="power_plant.forecasted_budget" v-bind="formProps('forecasted_budget')")
                v-checkbox(v-model="power_plant.project" v-bind="formProps('project')")
                v-checkbox(v-model="power_plant.downtime_analysis" v-bind="formProps('downtime_analysis')")
                v-text-field(v-model="power_plant.min_production_effect" v-bind="formProps('min_production_effect')")

                .overline(v-if="!portfolio_access") {{ $t('general.capacity') }}
                v-text-field(v-model="power_plant.rated_power_mw" class="mb-2" :label="$t('activerecord.attributes.power_plant.rated_power_mw')" :hint="$t('power_plants.effect_hint')" suffix="MW" dense outlined disabled persistent-hint)
                v-text-field(v-model="power_plant.generator_capacity_mva" class="mb-2" :label="$t('activerecord.attributes.power_plant.generator_capacity_mva')" :hint="$t('power_plants.capacity_hint')" suffix="MVA" dense outlined disabled persistent-hint)

              v-col(v-bind="col_props")
                .overline(v-if="!portfolio_access") {{ $t('activerecord.attributes.power_plant.optimization_model')}}
                v-select(v-model="power_plant.simulation_parameter.optimization_model" v-bind="formProps('optimization_model')" :items="power_plant.optimization_models_list")
                v-text-field(v-model="power_plant.simulation_parameter.periods" v-bind="formProps('periods')" :hint="power_plant.periods_list")
                v-text-field(v-model="power_plant.simulation_parameter.num_segments" v-bind="formProps('num_segments')" :suffix="$t('general.hours')" :hint="power_plant.num_segments_list")
                v-text-field(v-model="power_plant.simulation_parameter.kraftanmelding_uuid" v-bind="formProps('kraftanmelding_uuid')")
                v-checkbox(v-model="power_plant.simulation_parameter.production_simulation_plan_enabled" v-bind="formProps('production_simulation_plan_enabled')")
                v-checkbox(v-model="power_plant.forecast_query_enabled" v-bind="formProps('forecast_query_enabled')")
                v-checkbox(v-model="power_plant.day_ahead_forecast_enabled" v-bind="formProps('day_ahead_forecast_enabled')")
                v-checkbox(:disabled="!power_plant.forecast_query_enabled" v-model="power_plant.use_price_dependent_bid" v-bind="formProps('use_price_dependent_bid')")
                v-text-field(:disabled="!power_plant.use_price_dependent_bid" v-model="power_plant.price_dependent_bid_override" v-bind="formProps('price_dependent_bid_override')")

              v-col(:cols="portfolio_access ? 12 : 6")
                hydro-turbines(v-if="turbines_editable" v-model="power_plant.asset_class_attributes.turbines_attributes" :form_data="form_data" :errors="errors")
                hydro-generators(v-if="generators_editable" v-model="power_plant.asset_class_attributes.generators_attributes" :form_data="form_data" :errors="errors")
                hydro-transformers(v-if="transformers_editable" v-model="power_plant.asset_class_attributes.transformers_attributes" :form_data="form_data" :errors="errors")
                hydro-intakes(v-if="is_hydro_asset" v-model="power_plant.asset_class_attributes.intakes_attributes" :form_data="form_data" :errors="errors")
                hydro-reservoirs(v-if="reservoirs_editable" v-model="power_plant.asset_class_attributes.reservoirs_attributes" :form_data="form_data" :errors="errors")
                hydro-penstocks(v-if="penstocks_editable" v-model="power_plant.asset_class_attributes.penstocks_attributes" :form_data="form_data" :errors="errors")
                hydro-precipitation-fields(v-if="precipitation_fields_editable" v-model="power_plant.asset_class_attributes.precipitation_fields_attributes" :form_data="form_data" :errors="errors")
                hydro-rivers(v-if="rivers_editable" v-model="power_plant.asset_class_attributes.rivers_attributes" :form_data="form_data" :errors="errors")
                hydro-environmental-flow(v-if="environmental_flow_breakpoints_editable" v-model="power_plant.asset_class_attributes.environmental_flow_breakpoints_attributes" :form_data="form_data" :errors="errors")
                wind-turbines(v-if="wind_turbines_editable" v-model="power_plant.asset_class_attributes.wind_turbines_attributes" :form_data="form_data" :errors="errors")


        v-tab-item.mt-2(v-if="is_solar_asset")
          solar-inverters(v-if="solar_inverters_editable" v-model="power_plant.asset_class_attributes.solar_inverters_attributes" :errors="errors" :form_data="form_data" :solar_fields="power_plant.asset_class_attributes.solar_fields_attributes")
          solar-fields(v-if="solar_fields_editable" v-model="power_plant.asset_class_attributes.solar_fields_attributes" :errors="errors" :form_data="form_data")
        v-tab-item.mt-2(v-if="is_solar_asset")
          solar-backend(v-model="power_plant.asset_class_attributes"  :errors="errors" :form_data="form_data")
        v-tab-item.mt-2(v-if="is_wind_asset")
          wind-backend(v-model="power_plant.asset_class_attributes"  :errors="errors" :form_data="form_data")          
        v-tab-item.mt-2(v-if="has_nve_metadata")
          nve-metadata(v-model="power_plant.nve_metadata_attributes" :errors="errors" :form_data="form_data" :col_props="col_props")

        v-tab-item.mt-2(v-if="!newRecord")
          power-plant-suppliers(:power_plant_id="value.id" :suppliers="form_data.suppliers" :roles="form_data.supplier_roles")

        v-tab-item.mt-2(v-if="!newRecord && is_wind_asset")
          wind-availability-calculation(v-model="power_plant.asset_class_attributes.wind_availability_mappings_attributes" :errors="errors" :form_data="form_data")
          wind-availability-target(v-model="power_plant.asset_class_attributes.wind_availability_targets_attributes" :errors="errors" :form_data="form_data")
          wind-contractual-availability-selector(v-model="power_plant.asset_class_attributes.contractual_availability_energy_based" :errors="errors" :form_data="form_data")

        v-tab-item.mt-2(v-if="has_tyde_sensors")
          tyde-sensors(v-model="power_plant.tyde_sensors_attributes" :errors="errors" :form_data="form_data")

    v-card-actions
      v-btn(color="primary" @click='submit' :disabled="submit_disabled") {{ $t('general.save') }}
      v-btn(@click='closeDialog(false)') {{ $t('general.close') }}
      v-spacer
      v-btn(v-if="!newRecord && admin_access" color="orange" :href="`/admin/power_plants/${power_plant.id}/data_sync`" data-method="put" :data-confirm="$t('general.are_you_sure')") Sync
      v-btn(v-if="!newRecord && admin_access" color="error" :href="`/admin/power_plants/${power_plant.id}`" data-method="delete" :data-confirm="$t('general.are_you_sure')")  {{ $t('general.delete') }}
</template>
