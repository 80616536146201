<script>
import Wiki from './wiki.vue'
import { createPowerPlantGeoJson, defaultLayer } from './lib/powerplant-geojson'
import { MglGeojsonLayer } from 'vue-mapbox/dist/vue-mapbox.umd.min.js'

export default {
  components: {
    Wiki,
    MglGeojsonLayer
  },
  props: {
    power_plants: Array,
    toggleDrawer: Function,
    nearbyPowerPlants: Function,
    changeSelected: Function,
    value: Boolean
  },
  data() {
    return {
      page: 1,
      geoJson: createPowerPlantGeoJson([]),
      selectedLayer: JSON.parse(JSON.stringify(defaultLayer))
    }
  },
  computed: {
    powerplant() {
      const index = this.page - 1
      return this.power_plants[index]
    }
  },
  watch: {
    power_plants() {
      this.page = 1
    },
    powerplant() {
      if (this.powerplant) this.geoJson = createPowerPlantGeoJson([this.powerplant])
    },
    page() {
      if (this.powerplant) this.changeSelected(this.powerplant)
    },
    value() {
      if (!this.value) this.geoJson = createPowerPlantGeoJson([])
    }
  },
  created() {
    this.selectedLayer.id = 'highlighted'
    this.selectedLayer.paint['icon-color'] = '#ffffff'
  }
}
</script>
<template lang="pug">
  #left-drawer
    MglGeojsonLayer(
      :source="geoJson"
      sourceId="highlighted"
      layerId="highlighted"
      :layer="selectedLayer"
    )

    v-navigation-drawer(
      absolute
      :hide-overlay="true"
      :permanent="value"
      width="500"
      dark
      class="bg1"
      v-bind:value="value"
      v-on:input="$emit('input', $event)"
      v-if="power_plants.length > 0"
    )
      v-img(v-if="powerplant.image && powerplant.image.url" :src="powerplant.image.url" height="30vh")
        .image-attribution(v-if="powerplant.image_attribution") {{ powerplant.image_attribution }}
        div(
          v-if="powerplant.logo && powerplant.logo.url"
          class="pa-2 logo-overlay"
        )
          v-img(
            max-height="55"
            position="left bottom"
            contain
            :src="powerplant.logo.url"
          )
      v-skeleton-loader(v-else type="image")
      div(class="px-4")
        div(class="text-center mt-2 px-2" v-if="power_plants.length > 1")
          v-pagination(
            v-model="page"
            color="pink"
            :length="power_plants.length"
            circle
          )
      v-list-item(dark)
        v-list-item-content
          v-list-item-title(class="title")
            | {{ powerplant.name }}
        v-btn(
          dark
          icon
          @click.stop="toggleDrawer"
        )
          v-icon(large) mdi-chevron-left
      v-row
        v-col
          .pl-4.white--text
            div(v-if="powerplant.owner")
              | {{ $t('activerecord.attributes.power_plant.owner') }}
              .value.pb-2 {{ powerplant.owner.name }}
            div(v-if="powerplant.grid_owner")
              | {{ $t('activerecord.attributes.power_plant.grid_owner') }}
              .value.pb-2 {{ powerplant.grid_owner }}
            div(v-if="powerplant.rated_power_mw")
              | {{ $t('activerecord.attributes.power_plant.rated_power_mw') }}
              .value.pb-2 {{ powerplant.rated_power_mw }} MW
            div(v-if="powerplant.nve_gwh")
              | {{ $t('activerecord.attributes.power_plant.nve_gwh') }}
              .value.pb-2 {{ powerplant.nve_gwh }} GWh
            div(v-if="powerplant.vertical_drop")
              | {{ $t('activerecord.attributes.power_plant.vertical_drop') }}
              .value.pb-2 {{ powerplant.vertical_drop }} M
            div(v-if="powerplant.url_nve")
              a(:href="powerplant.url_nve" target="_blank" class="white--text") {{ $t('overview_map.nve_concession') }}
            div(v-if="powerplant.owner && powerplant.owner.url_homepage")
              a(:href="powerplant.owner.url_homepage" target="_blank" class="white--text") {{ $t('overview_map.homepage') }}
            div(v-if="powerplant.url_proff")
              a(:href="powerplant.url_proff" target="_blank" class="white--text") Proff
            div(v-if="powerplant.owner && powerplant.owner.url_linked_in")
              a(:href="powerplant.owner.url_linked_in" target="_blank" class="white--text") LinkedIn
        v-col
          Wiki(:powerPlantId="powerplant.id")
      template(v-slot:append)
        v-row(class="ma-2")
          v-col(cols="12")
            div 4 {{ $t('overview_map.nearest') }}:
          v-col(cols="6" v-for="nearbyPowerPlant in nearbyPowerPlants(powerplant)" :key="nearbyPowerPlant.id" class="pa-3")
            v-img(
              height="150"
              class="pointer rounded-xl"
              v-if="nearbyPowerPlant.image && nearbyPowerPlant.image.url" :src="nearbyPowerPlant.image.url"
              @click="changeSelected(nearbyPowerPlant)"
            )
              span(class="title pa-6 white--text text-border text-truncate") {{ nearbyPowerPlant.name }}
            v-skeleton-loader(v-else type="image")
</template>
