<script>
export default {
  props: {
    performance_ratio: Array,
    system_losses: Array,
    energy_performance_index: Array,
  },
  data() {
    return {
      series: [{
        name: this.$t('power_plants.solar_kpi_system_losses'),
        type: 'column',
        data: this.system_losses,
        color: '#6b6a72',
        yAxis: 0,
        stacking: 'normal',
        tooltip: {
          pointFormat: '{series.name}: {point.y} %',
          valueDecimals: 0,
        },
      },
      {
        name: this.$t('power_plants.solar_kpi_pr'),
        type: 'column',
        data: this.performance_ratio,
        color: '#00adf1',
        yAxis: 0,
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          formatter: function() {
            return this.y.toFixed(1) + '%';
          },
          style: {
            fontWeight: 'bold',
            textOutline: 'none'
          }
        },
        tooltip: {
          pointFormat: '{series.name}: {point.y} %',
          valueDecimals: this.performance_ratio[0] && this.performance_ratio[0][1] < 1 ? 1 : 0,
        },
      },
      {
        name: this.$t('power_plants.solar_kpi_epi'),
        type: 'line',
        color: '#f0402f',
        data: this.energy_performance_index,
        tooltip: {
          pointFormat: '{series.name}: {point.y} %',
          valueDecimals: 0,
        },
        yAxis: 0,
      }]
    }
  },
  computed: {
    chart_options() {
      return {
        title: {
          text: this.$t('power_plants.kpi_monthly'),
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            hour: '%b %e %Y %H:%M'
          },
          crosshair: true,
        },
        yAxis: [{
          min: 0,
          title: null,
          labels: {
            formatter() {
              return `${this.value}%`
            }
          },
        }],
        credits: {
          enabled: false
        },
        series: this.series,
        navigator: {
          enabled: false
        }
      }
    },
  },
}
</script>

<template lang="pug">
v-highcharts(:options="chart_options")
</template>
