<script>
import VolumeField from './volume_field.vue'
import ValidateDateFormatMixin from '../../mixins/validate_date_format_mixin.js'

export default {
  components: {
    VolumeField
  },
  mixins: [ValidateDateFormatMixin],
  props: {
    downtime_period: Object,
    minDate: String,
    minTime: String,
    maxDate: String,
    maxTime: String,
    powerPlant: Object,
    comment: String,
    reason: String,
    component: String,
    edit_mode: Boolean,
    fetchRevenue: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      errors: [],
      rawMinDate: null,
      rawMinTime: null,
      rawMaxDate: null,
      rawMaxTime: null,
      downtime_data: {
        starts_at: null,
        ends_at: null,
        verified: false,
        insurance: false,
        volume_set_manually: false,
        volume_should_have_been: null
      },
      dataLoaded: false
    }
  },
  computed: {
    valid() {
      return !!(
        this.validateDateFormat(this.minDate) &&
        this.minTime &&
        this.validateDateFormat(this.maxDate) &&
        this.maxTime
      )
    },
    shouldFetchRevenue: {
      get() {
        return this.downtime_data.volume_set_manually || this.fetchRevenue
      },
      set(value) {
        this.downtime_data.volume_set_manually = value
        this.$emit('update:fetchRevenue', value)
      }
    },
    startsAtForward: {
      get() {
        return this.starts_at ? this.formattedDateAndTime(this.rawMinDate, this.rawMinTime) : null
      },
      set(value) {
        this.starts_at = value
      }
    },
    endsAtForward: {
      get() {
        return this.ends_at ? this.formattedDateAndTime(this.rawMaxDate, this.rawMaxTime) : null
      },
      set(value) {
        this.ends_at = value
      }
    }
  },
  methods: {
    updateStartsAndEnds() {
      this.starts_at = this.formattedDateAndTime(this.rawMinDate, this.rawMinTime)
      this.ends_at = this.formattedDateAndTime(this.rawMaxDate, this.rawMaxTime)
    },
    formattedDateAndTime(date, time) {
      return date && time ? `${date} ${time}:00` : null
    },
    save(timeDate, dialog) {
      this.$refs[dialog].save(timeDate)
    },
    setDowntimeData(newVal) {
      this.downtime_data = { ...newVal }
      this.downtime_data.comment = this.comment
      this.downtime_data.component = this.component
      this.downtime_data.reason = this.reason
    },
    sharedProps(field) {
      return {
        readonly: false,
        filled: false,
        dense: true,
        outlined: true,
        hideDetails: 'auto',
        label: this.$t(`activerecord.attributes.downtime_period.${field}`),
        name: `downtime_period_${field}`,
        errorMessages: this.errors[field]
      }
    },
    handleVolumeSetManuallyChange() {
      this.shouldFetchRevenue = this.downtime_data.volume_set_manually
    },
    async update() {
      this.loading = true
      this.errors = []
      const url = `/downtime_periods/${this.downtime_data.id || ''}`
      const method = this.downtime_data.id ? 'patch' : 'post'

      const formattedData = {
        ...this.downtime_data,
        starts_at: this.formattedDateAndTime(this.rawMinDate, this.rawMinTime),
        ends_at: this.formattedDateAndTime(this.rawMaxDate, this.rawMaxTime)
      }

      const data = {
        downtime_period: formattedData
      }

      try {
        await this.axios[method](url, data)
        this.loading = false
        window.location.reload()
      } catch (error) {
        if (error.response.status === 403) {
          this.errors['power_plant_id'] = 'invalid power plant'
        }
        if (error.response.data) {
          this.errors = error.response.data
        }
        this.$emit('error', this.errors)
        this.loading = false
      }
    }
  },
  mounted() {
    this.setDowntimeData(this.downtime_period)
    this.rawMinDate = this.minDate
    this.rawMinTime = this.minTime
    this.rawMaxDate = this.maxDate
    this.rawMaxTime = this.maxTime

    this.updateStartsAndEnds()

    this.dataLoaded = true
  },
  watch: {
    downtime_period(newVal) {
      this.setDowntimeData(newVal)
    },
    minDate(newVal) {
      this.rawMinDate = newVal
      this.updateStartsAndEnds()
    },
    minTime(newVal) {
      this.rawMinTime = newVal
      this.updateStartsAndEnds()
    },
    maxDate(newVal) {
      this.rawMaxDate = newVal
      this.updateStartsAndEnds()
    },
    maxTime(newVal) {
      this.rawMaxTime = newVal
      this.updateStartsAndEnds()
    },
    comment() {
      this.downtime_data.comment = this.comment
    },
    component() {
      this.downtime_data.component = this.component
    },
    reason() {
      this.downtime_data.reason = this.reason
    },
    'powerPlant.id'() {
      this.downtime_data.power_plant_id = this.powerPlant?.id
    },
    'downtime_data.volume_set_manually'(newVal) {
      if (newVal && !this.downtime_data.volume_should_have_been) {
        this.downtime_data.volume_should_have_been = this.downtime_data.estimated_hourly_volume || 0
      }
    },
    powerPlant() {
      if (!this.edit_mode) {
        this.downtime_data.volume_should_have_been = this.powerPlant?.rated_power_mw || 0
      }
    }
  }
}
</script>

<template lang="pug">
div(v-if="downtime_data")
  v-row
    v-col.d-flex
      v-checkbox(v-model="downtime_data.verified" v-bind="sharedProps('verified')")
  v-row
    v-col.d-flex
      v-checkbox(v-model="downtime_data.insurance" v-bind="sharedProps('insurance')")
  v-row
    v-col.d-flex
      v-checkbox(
        v-model="downtime_data.volume_set_manually"
        v-bind="sharedProps('volume_set_manually')"
        @change="handleVolumeSetManuallyChange"
      )

  v-row(v-if="downtime_data.volume_set_manually")
    v-col.d-flex.align-center
      div(v-if="dataLoaded")
        VolumeField(
          v-model="downtime_data.volume_should_have_been"
          :downtime_data="downtime_data"
          :starts_at="startsAtForward"
          :ends_at="endsAtForward"
          :powerPlant="powerPlant"
          :sharedProps="sharedProps('volume')"
          :hint="sharedProps('volume_should_have_been').label"
          :flip="true"
          :fetchRevenue="shouldFetchRevenue"
        )

  v-card-actions(style="padding-top: 24px")
    v-btn(
      color="primary"
      :disabled="!valid"
      :loading="loading"
      @click="update"
    )
      | {{ $t('general.save') }}
    v-spacer
    v-btn(text @click="$emit('cancel')") {{ $t('general.cancel') }}
</template>
