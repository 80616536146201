<script>
import { DateTime } from 'luxon'
export default {
  props: {
    scope: String,
    availableRanges: Array,
    currentRange: Array,
    plantId: Number,
    allDisabled: false
  },
  data() {
    return {
      setScope: this.scope,
      showVariableDates: false,
      startAsDateTime: DateTime.fromISO(this.currentRange[0], { zone: 'Europe/Oslo' }),
      endAsDateTime: DateTime.fromISO(this.currentRange[1], { zone: 'Europe/Oslo' }),
      customStart: this.scope === 'any' ? this.startAsDateTime : '',
      customEnd: this.scope === 'any' ? this.endAsDateTime : ''
    }
  },
  watch: {
    currentRange() {
      this.startAsDateTime = DateTime.fromISO(this.currentRange[0], { zone: 'Europe/Oslo' })
      this.endAsDateTime = DateTime.fromISO(this.currentRange[1], { zone: 'Europe/Oslo' })
    }
  },
  computed: {
    optionsForTimescope() {
      let options = this.availableRanges.map((range) => {
        return { value: range, name: this.$t(`om_dashboard.drilldown.time_scope.${range}`) }
      })
      options.push({ value: 'any', name: this.$t('om_dashboard.drilldown.any') })
      return options
    },
    showForwardArrow() {
      return this.scope !== 'any' && this.endAsDateTime.startOf('day') !== DateTime.now().startOf('day')
    }
  },
  methods: {
    arrowPath(direction = 1) {
      let newStart = this.startAsDateTime
      let newEnd = this.endAsDateTime
      switch (this.scope) {
        case 'week':
          newStart = newStart.plus({ weeks: direction })
          newEnd = newEnd.plus({ weeks: direction })
          break
        case 'month':
          newStart = newStart.plus({ months: direction }).startOf('month')
          newEnd = this.endAsDateTime.plus({ months: direction }).endOf('month')
          break
        case 'quarter':
          newStart = newStart.plus({ months: 3 * direction }).startOf('month')
          newEnd = newStart.plus({ months: 2 }).endOf('month')
          break
        case 'year':
          newStart = newStart.plus({ years: direction }).startOf('year')
          newEnd = this.endAsDateTime.plus({ years: direction }).endOf('year')
          break
      }

      const isoStart = newStart.toISO()
      let isoEnd = newEnd
      const today = DateTime.now()
      if (isoEnd.endOf('day') > today.endOf('day')) isoEnd = today
      isoEnd = isoEnd.toISO()

      this.$emit('changedScope', [isoStart, isoEnd, this.scope])
    },
    loadCustomDates() {
      const start_time = new Date(this.customStart).toISOString()
      const end_time = new Date(this.customEnd).toISOString()
      if (this.validateStartAndEndRange() === false) {
        return
      }

      this.$emit('changedScope', [start_time, end_time, 'any'])
      this.showVariableDates = false
    },
    cleanDate(datetime) {
      const timeZone = 'Europe/Oslo'
      return DateTime.fromISO(datetime, { zone: timeZone }).toFormat('yyyy-MM-dd')
    },
    changeScope(scope) {
      if (scope !== this.scope) {
        this.$emit('changedScope', [this.currentRange[0], this.currentRange[1], scope])
      } else if (scope !== 'any') this.showVariableDates = false
    },
    checkAny() {
      if (this.setScope === 'any') this.showVariableDates = true
      else this.changeScope(this.setScope)
    },
    toggleAnyDates() {
      this.showVariableDates = !this.showVariableDates
      if ((!this.customStart || !this.customEnd) && this.scope !== 'any') this.setScope = this.scope
    },
    validateStartAndEndRange() {
      if (this.customStart > this.customEnd) {
        this.$toast(this.$t('om_dashboard.drilldown.start_time_must_be_before_end_time'), { type: 'error' })
        return false
      }
    }
  }
}
</script>

<template lang="pug">
div.time_scope.text-right.scope_wrapper
  v-col.pa-0.ma-0
    v-btn.back(v-if="scope !== 'any'" @click="arrowPath(-1)" :elevation="0" active="false" :disabled="allDisabled")
      v-icon mdi-arrow-left-bold-outline
    v-icon(v-if="setScope === 'any'" @click="toggleAnyDates") mdi-calendar
    v-select(:class="setScope === 'any' ? 'any' : ''" @change="checkAny" v-model="setScope" :items="optionsForTimescope" ref="timeScope" item-text="name" item-value="value")
      option(value="any" :disabled="allDisabled")  {{ this.$t('om_dashboard.drilldown.any') }}
    v-btn.forward(v-if="showForwardArrow" @click="arrowPath(1)" :elevation="0")
      v-icon mdi-arrow-right-bold-outline
  .active_dates(v-show="!showVariableDates && !allDisabled")
    | {{ cleanDate(currentRange[0]) }} &#45;&#45; {{ cleanDate(currentRange[1]) }}
  .filters(v-show="showVariableDates")
    v-date-picker-input(v-model="customStart" id="drilldown_custom_start" :label="this.$t('om_dashboard.drilldown.start_time')")
    v-date-picker-input(v-model="customEnd" id="drilldown_custom_end" :label="this.$t('om_dashboard.drilldown.end_time')")
    v-btn.filter(@click="loadCustomDates") {{ this.$t('om_dashboard.drilldown.filter') }}
</template>
