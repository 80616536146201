<script>
import OverviewCardHeader from './header.vue'
import BasicCardContent from './basic.vue'
import AdvancedCardContent from './advanced.vue'

export default {
  components: {
    OverviewCardHeader,
    BasicCardContent,
    AdvancedCardContent,
  },
  created() {
    this.redirectToDefaultParams()
    this.loadAllPowerPlants()
    this.timer = setInterval(this.loadAllPowerPlants, 600000)
  },
  data () {
    const defaultVariant = ["xs", "sm"].includes(this.$vuetify.breakpoint.name) ? "basic" : "advanced"

    return {
      sort_by: 'name',
      sort_dir: -1,
      query: '',
      loaded_power_plants: [],
      max_tyde_sensors: 0,
      timer: '',
      defaultParams: {
        price_area: "ALL",
        operational_state: "ALL",
        sort: "name",
        dir: "asc",
        variant:  defaultVariant,
      }
    }
  },
  methods: {
    redirectToDefaultParams() {
      const params = new URLSearchParams(window.location.search);
      let reloadRequired = false

      Object.entries(this.defaultParams).forEach(([key, value]) => {
        if (!params.has(key)) {
          params.set(key, value)
          reloadRequired = true
        }
      })

      if (reloadRequired) window.location.search = params
    },
    async loadAllPowerPlants () {
      const params = new URLSearchParams(window.location.search);
      const priceArea = params.get('price_area') || 'ALL'; 
      const operationalState = params.get('operational_state') || 'ALL';
      const sort = params.get('sort') || 'name';
      const dir = params.get('dir') || 'asc'

      const url = `/overview/power_plants.json?price_area=${priceArea}&operational_state=${operationalState}&sort=${sort}&dir=${dir}`
      const response = await this.axios.get(url)
      this.loaded_power_plants = response.data
      this.max_tyde_sensors = Math.max(...response.data.map(power_plant => power_plant.tyde_sensors.length))
    },
  },
  computed: {
    advancedCard () {
      const params = new URLSearchParams(window.location.search);

      return params.get('variant') == 'advanced'
    },
    cardWidth () {
      return this.advancedCard ? 450 + 150 * this.max_tyde_sensors : 280
    },
    cardHeight () {
      return this.advancedCard ? 410 : 310
    }
  },
}
</script>
<template lang="pug">
div
  v-card.d-flex.flex-wrap(flat tile)
    v-card(outlined tile :width="cardWidth" :height="cardHeight" v-for="power_plant in loaded_power_plants" :key="power_plant.id")
      overview-card-header(:power_plant="power_plant")
      advanced-card-content(v-if="advancedCard" :power_plant="power_plant" :cardWidth="cardWidth" :max_tyde_sensors="max_tyde_sensors")
      basic-card-content(v-else :power_plant="power_plant")
</template>
