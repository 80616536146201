<script>
import ValidateDateTimeFormatMixin from '../../mixins/validate_date_time_format_mixin.js'
export default {
  mixins: [ValidateDateTimeFormatMixin],
  props: {
    value: Number,
    downtime_data: Object,
    powerPlant: Object,
    sharedProps: Object,
    starts_at: String,
    ends_at: String,
    hint: String,
    flip: Boolean,
    fetchRevenue: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      rawVolume: this.value || 0,
      downtime_dataStartsAt: null,
      downtime_dataEndsAt: null,
      fetchingRevenue: false
    }
  },
  computed: {
    formattedValue: {
      get() {
        return this.rawVolume !== null ? Number(parseFloat(this.rawVolume).toFixed(2)) : ''
      },
      set(val) {
        this.setFormattedValue(val)
      }
    },
    unavailablePercentage() {
      return (this.rawVolume / this.powerPlant.rated_power_mw) * 100
    },
    availablePercentage() {
      return 100 - this.unavailablePercentage
    },
    estimatedRevenue() {
      const revenue = this.downtime_data?.revenue || 0
      if (revenue !== null && this.rawVolume !== null) {
        return revenue * this.rawVolume
      }
      return 0
    },
    powerPlantAvailable() {
      return this.powerPlant && this.powerPlant.id
    }
  },
  mounted() {
    this.downtime_dataStartsAt = this.starts_at
    this.downtime_dataEndsAt = this.ends_at

    if (this.fetchRevenue && !this.downtime_data.hasOwnProperty('revenue')) {
      this.setRevenue()
    }
  },
  methods: {
    async setRevenue() {
      if (this.isDataInvalid()) {
        return
      }
      this.fetchingRevenue = true
      const url = '/forward_prices/sum_forward_prices'
      try {
        let response = await this.axios.post(url, {
          starts_at: this.downtime_dataStartsAt,
          ends_at: this.downtime_dataEndsAt,
          price_area: this.powerPlant.price_area
        })
        this.$set(this.downtime_data, 'revenue', response.data)
      } catch (error) {
        if (error.response.data) this.errors = error.response.data
        this.$emit('error', this.errors)
      } finally {
        this.fetchingRevenue = false
      }
    },
    isDataInvalid() {
      return !!(
        !this.fetchRevenue ||
        this.fetchingRevenue ||
        !this.downtime_data ||
        !this.validateDateTimeFormat(this.downtime_dataStartsAt) ||
        !this.validateDateTimeFormat(this.downtime_dataEndsAt) ||
        !this.powerPlant?.id
      )
    },
    setFormattedValue(val) {
      const number = Number(val)
      this.rawVolume = !isNaN(number) ? number : null
      this.updateValue()
    },
    updateValue() {
      this.$emit('input', this.rawVolume)
    }
  },
  watch: {
    starts_at(newVal) {
      this.downtime_dataStartsAt = newVal
      this.setRevenue()
    },
    ends_at(newVal) {
      this.downtime_dataEndsAt = newVal
      this.setRevenue()
    },
    fetchRevenue(newValue) {
      if (newValue && !this.downtime_data.hasOwnProperty('revenue')) {
        this.setRevenue()
      }
    }
  }
}
</script>

<template lang="pug">
  div(v-if="powerPlantAvailable")
    div.d-flex
      v-text-field(
        v-model="formattedValue"
        @input="setFormattedValue"
        prepend-icon="mdi-cylinder"
        type="number"
        step=".01"
        :style="{ 'max-width': '160px', 'min-width': '160px' }"
        v-bind="sharedProps"
        :hint="hint"
        suffix="MW"
      )
      div.d-flex(class="pl-6 pr-6")
        div.pr-3
          span.font-weight-medium {{ $t('downtime_periods.available') }}
          span.d-block.font-weight-light(:id="'percentage_available'") {{ availablePercentage.toFixed(2) }} %
        div.pr-3
          span.font-weight-medium {{ $t('downtime_periods.unavailable') }}
          span.d-block.font-weight-light(:id="'percentage_unavailable'") {{ unavailablePercentage.toFixed(2) }} %
        div
          span.font-weight-medium
            | {{ `${flip ? $t('downtime_periods.est_revenue') : $t('downtime_periods.est_loss')}: ` }}
          span.d-block.font-weight-light(id='estimated_revenue') {{ estimatedRevenue.toFixed(1) + ' EUR' }}
  div(v-else)
    div.d-flex
      v-text-field(
        v-model="formattedValue"
        @input="setFormattedValue"
        prepend-icon="mdi-cylinder"
        type="number"
        step=".01"
        :style="{ 'max-width': '160px', 'min-width': '160px' }"
        v-bind="sharedProps"
        :hint="hint"
        suffix="MW"
      )
      div(class="pl-3")
        div.red--text.text--lighten-1 {{ $t('downtime_periods.see_stats') }}
</template>
