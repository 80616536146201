<script>
export default {
  props: {
    budget_gwh: Number,
    volume_gwh: Number
  },
  data() {
    return {}
  },
  computed: {
    color() {
      return this.value < 0 ? 'red' : 'green'
    },
    value() {
      if (this.budget_gwh <= 0) return 0
      else if (this.volume_gwh == 0) return -100
      else {
        const diff = this.volume_gwh / this.budget_gwh - 1
        return parseInt(diff * 100)
      }
    },
    rotation() {
      let r = -90
      if (this.value < 0) r = -90 - 360.0 / (100.0 / Math.abs(this.value))
      return r
    }
  }
}
</script>

<template lang="pug">
v-col.top-widget(cols="12" sm="2")
  .widget-wrapper
    strong
      | {{ $t('dashboard.gwh_ytd') }}
      br/
      dynamic-progress-circular(:color="color" :rotate="rotation" :value="Math.abs(value)")
        | {{ parseInt(value) }}%
      .description
        | {{ volume_gwh.toFixed(1) }} / {{ budget_gwh.toFixed(1) }}

</template>
