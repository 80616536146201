<script>
export default {
  props: {
    precipitation: Array,
    temperature: Array,
    categories: Array,
    maximum: Number,
  },
  data() {
    return {
      chart_options: {
        title: {
          text: '7 day forecast',
        },
        xAxis: {
          categories: this.categories,
        },
        yAxis: [{
          min: 0,
          max: this.maximum,
          title: null,
          labels: {
            formatter() { return `${this.value.toFixed(1)}mm` }
          },
        }, {
          title: null,
          labels: {
            formatter() { return `${this.value.toFixed(1)}°` }
          },
          opposite: true,
        }],
        plotOptions: {
          column: {
            minPointLength: 3,
          },
        },
        credits: {
          enabled: false
        },
        series: [{
          name: 'Precipitation',
          type: 'column',
          color: '#00adf1',
          data: this.precipitation,
        }, {
          name: 'Temperature',
          type: 'spline',
          color: '#6b6a72',
          data: this.temperature,
          yAxis: 1,
        }],
      }
    }
  }
}
</script>

<template lang="pug">
  v-highcharts(:options="chart_options")
</template>
