import { DateTime, Duration } from 'luxon'
import Highcharts from 'highcharts' // needed for 'View fullscreen' to work
export default {
  data() {
    return {
      operationalStatusTypes: {
        10: { title: 'normal', color: '#53B1FD', outline: 'none', class_color: 'blue-alarm' },
        25: { title: 'low_natural_resource', color: '#A4D5FD', outline: 'none', class_color: 'low-alarm' },
        30: {
          title: 'missing_resource',
          color: 'rgba(83, 177, 253, 0.1)',
          outline: '1px solid rgba(83, 177, 253, 0.3)',
          class_color: 'grey-alarm'
        },
        40: { title: 'underperforming', color: '#FDB022', outline: 'none', class_color: 'yellow-alarm' },
        50: { title: 'no_data', color: '#505050', outline: 'none', class_color: 'dark-grey-alarm' },
        55: { title: 'no_data', color: '#505050', outline: 'none', class_color: 'dark-grey-alarm' },
        60: { title: 'outage', color: '#F97066', outline: 'none', class_color: 'red-alarm' }
      },

      windAlarms: {
        // 20: {
        //   icon: '/om_dashboard/alarm_category/no_data.svg',
        //   title: this.$t('om_dashboard.alarm_status.no_data')
        // },
        30: {
          icon: '/om_dashboard/alarm_category/environment.svg',
          title: this.$t('om_dashboard.alarm_status.environmental')
        },
        40: {
          icon: '/om_dashboard/alarm_category/grid.svg',
          title: this.$t('om_dashboard.alarm_status.grid_failure')
        },
        50: {
          icon: '/om_dashboard/alarm_category/manufacturer.svg',
          title: this.$t('om_dashboard.alarm_status.manufacturer')
        },
        60: {
          icon: '/om_dashboard/alarm_category/owner.svg',
          title: this.$t('om_dashboard.alarm_status.stopped_by_owner')
        },
        70: {
          icon: '/om_dashboard/alarm_category/force_majeure.svg',
          title: this.$t('om_dashboard.alarm_status.force_majeure')
        }
      },
      solarAlarms: {
        20: {
          icon: '/om_dashboard/alarm_category/snow_coverage.svg',
          title: this.$t('om_dashboard.alarm_status.snow_coverage'),
          opStatColor: '#FDB022'
        },
        30: {
          icon: '/om_dashboard/alarm_category/power_limitation.svg',
          title: this.$t('om_dashboard.alarm_status.power_limitation'),
          opStatColor: '#FDB022'
        },
        40: {
          icon: '/om_dashboard/alarm_category/string_anomaly.svg',
          title: this.$t('om_dashboard.alarm_status.string_anomaly'),
          opStatColor: '#FDB022'
        },
        50: {
          icon: '/om_dashboard/alarm_category/mppt_outage.svg',
          title: this.$t('om_dashboard.alarm_status.mppt_outage'),
          opStatColor: '#F97066'
        },
        60: {
          icon: '/om_dashboard/alarm_category/Inverter_outage.svg',
          title: this.$t('om_dashboard.alarm_status.inverter_outage'),
          opStatColor: '#F97066'
        },
        70: {
          icon: '/om_dashboard/alarm_category/grid_failure.svg',
          title: this.$t('om_dashboard.alarm_status.grid_failure'),
          opStatColor: '#F97066'
        }
      }
    }
  },

  methods: {
    calculateMinutes(duration) {
      return Math.round(60 * (duration - parseInt(duration)))
    },
    formatDateTime(isoString) {
      return DateTime.fromISO(isoString).setLocale(this.$i18n.locale).toLocaleString(DateTime.DATETIME_MED)
    },
    formatDate(isoString) {
      return DateTime.fromISO(isoString).setLocale(this.$i18n.locale).toLocaleString(DateTime.DATE_MED)
    },
    formatDateFromTimestamp(timestamp) {
      return DateTime.fromSeconds(timestamp).setLocale(this.$i18n.locale).toLocaleString(DateTime.DATE_MED)
    },
    parseDuration(seconds) {
      return Duration.fromObject({ seconds: seconds }).shiftTo('days', 'hours', 'minutes').toObject()
    },
    formatDuration(seconds) {
      const duration = this.parseDuration(seconds)
      let durationString = []
      if (duration['days'] > 0)
        durationString.push(
          this.$t(`om_dashboard.duration.${duration['days'] > 1 ? 'plural' : 'singular'}.day`, {
            value: duration['days']
          })
        )
      if (duration['hours'] > 0)
        durationString.push(
          this.$t(`om_dashboard.duration.${duration['hours'] > 1 ? 'plural' : 'singular'}.hour`, {
            value: duration['hours']
          })
        )
      if (duration['minutes'] > 0)
        durationString.push(
          this.$t(`om_dashboard.duration.${duration['minutes'] > 1 ? 'plural' : 'singular'}.minute`, {
            value: parseInt(duration['minutes'])
          })
        )
      return durationString.join(', ')
    },
    shortDuration(seconds) {
      const duration = this.parseDuration(seconds)
      if (duration['days'] > 0) return this.$t(`om_dashboard.duration.short.day`, { value: duration['days'] })
      else if (duration['hours'] > 0)
        return this.$t(`om_dashboard.duration.short.hour`, { value: duration['hours'] })
      else if (duration['minutes'] > 0)
        return this.$t(`om_dashboard.duration.short.minute`, { value: parseInt(duration['minutes']) })
    },
    isExportable(filename = 'chart') {
      return {
        enabled: true,
        filename: filename,
        buttons: {
          contextButton: {
            menuItems: [
              {
                textKey: 'printChart',
                text: this.$t('om_dashboard.drilldown.export_menu.print_chart'),
                onclick: function () {
                  this.print()
                }
              },
              'separator',
              {
                textKey: 'downloadPNG',
                text: this.$t('om_dashboard.drilldown.export_menu.download_png'),
                onclick: function () {
                  this.exportChart({
                    sourceWidth: 1600,
                    sourceHeight: 1200,
                    scale: 1
                  })
                }
              },
              {
                textKey: 'downloadJPEG',
                text: this.$t('om_dashboard.drilldown.export_menu.download_jpg'),
                onclick: function () {
                  this.exportChart({
                    type: 'image/jpeg',
                    sourceWidth: 1600,
                    sourceHeight: 1200,
                    scale: 1
                  })
                }
              },
              {
                textKey: 'downloadPDF',
                text: this.$t('om_dashboard.drilldown.export_menu.download_pdf'),
                onclick: function () {
                  this.exportChart({
                    type: 'application/pdf',
                    scale: 2
                  })
                }
              },
              {
                textKey: 'downloadSVG',
                text: this.$t('om_dashboard.drilldown.export_menu.download_svg'),
                onclick: function () {
                  this.exportChart({
                    type: 'image/svg+xml',
                    sourceWidth: 1600,
                    sourceHeight: 1200,
                    scale: 1
                  })
                }
              },
              'separator',
              {
                textKey: 'downloadCSV',
                text: this.$t('om_dashboard.drilldown.export_menu.download_csv'),
                onclick: function () {
                  this.downloadCSV()
                }
              },
              {
                textKey: 'downloadXLS',
                text: this.$t('om_dashboard.drilldown.export_menu.download_xls'),
                onclick: function () {
                  this.downloadXLS()
                }
              }
            ]
          }
        }
      }
    }
  }
}
