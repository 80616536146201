<script>
export default {
  props: {
    sort_by: Array,
  },
  data: function() {
    const sort = new URLSearchParams(window.location.search).get("sort")
    const dir = new URLSearchParams(window.location.search).get("dir")
    const selected = this.sort_by.findIndex(item => item.sort == sort && item.dir == dir)

    return {
      sort: sort,
      dir: dir,
      selected: selected,
    }    
  },
  methods: {
    changeSort (sort_item) {
      const url = new URL(window.location.href)
      url.searchParams.set("sort", sort_item.sort)
      url.searchParams.set("dir", sort_item.dir)
      
      window.location.href = url.href
    },
    title () {
      return this.$t("general.sort_by") + ': ' + this.$t("overview.sort." + this.sort) + ' ' + this.$t("overview.dir." + this.dir)
    },
    itemTitle (sort_item) {
      return this.$t("overview.sort." + sort_item.sort) + ' ' + this.$t("overview.dir." + sort_item.dir)
    }
  },
}
</script>
<template lang="pug">
v-menu
  template(v-slot:activator="{ on }")
    a(v-on="on")
      v-chip.pointer.ma-2(color="light-blue" text-color="white")
        | {{ title() }} 
  v-list(dense)
    v-list-item-group(v-model="selected")
      v-list-item(v-for="sort_item in sort_by" :key="sort_item.sort + sort_item.dir" @click="changeSort(sort_item)")
        v-list-item-title
          | {{ itemTitle(sort_item) }}
</template>
