<script>
export default {
  props: {
    volume_measured: Array,
    volume_theoretical: Array,
    selected_scope: Number,
  },
  data() {
    return {
      series: [{
        name: this.$t('general.production_forecast'),
        type: 'areaspline',
        data: this.volume_theoretical,
        color: '#f0402f',
        yAxis: 0,
        tooltip: {
          pointFormat: '{series.name}: {point.y} MW',
          valueDecimals: 3,
        },
        dataGrouping: {
          approximation: 'high',
        },
      }, {
        name: this.$t('general.production'),
        type: 'areaspline',
        data: this.volume_measured,
        color: '#38506c',
        yAxis: 0,
        tooltip: {
          pointFormat: '{series.name}: {point.y} MW',
          valueDecimals: 3,
        },
        dataGrouping: {
          approximation: 'high',
        },
      },
      ]
    }
  },
  computed: {
    chart_options() {
      return {
        chart: {
          events: {
            load: function() {
              this.rangeSelector.clickButton(3)
            }
          },
          zoomType: 'x'
        },
        time: {
          timezoneOffset: new Date().getTimezoneOffset()
        },
        title: {
          text: this.$t('power_plants.measure_theor_production'),
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            hour: '%b %e %Y %H:%M'
          },
          crosshair: true,
          ordinal: false,
        },
        yAxis: [{
          min: 0,
          title: null,
          labels: {
            formatter() {
              return `${this.value} MW`
            }
          },
        }, {
          title: null,
          labels: {
            formatter() {
              return `${this.value} MW`
            }
          },
          opposite: true,
        }],
        credits: {
          enabled: false
        },
        rangeSelector: {
          inputEnabled: false,
          selected: this.selected_scope,
        },
        series: this.series,
      }
    },
  },
}
</script>

<template lang="pug">
  v-highcharts(:options="chart_options" constructor-type="stockChart")
</template>
