<script>
export default {
  props: {
    volume: Array,
    runoff: Array,
  },
  data () {
    return {
      series: [{
        name: this.$t('general.runoff'),
        type: 'column',
        color: '#6b6a72',
        data: this.runoff,
        tooltip: {
          pointFormat: '{series.name}: {point.y} mm',
          valueDecimals: 0,
        },
        yAxis: 1,
      }, {
        name: this.$t('general.production'),
        type: 'spline',
        data: this.volume,
        color: '#00adf1',
        yAxis: 0,
        tooltip: {
          pointFormat: '{series.name}: {point.y} MWh',
          valueDecimals: this.volume[0] && this.volume[0][1] < 1 ? 1 : 0,
        },
        marker: {
          radius: 0,
        }
      }],
    }
  },
  computed: {
    complete_data_set () { return this.series.filter(s => s.data.length > 0).length == this.series.length },
    chart_options () {
      return {
        title: {
          text: this.$t('power_plants.runoff_ytd'),
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            hour: '%b %e %Y %H:%M'
          },
          crosshair: true,
        },
        yAxis: [{
          min: 0,
          title: null,
          labels: {
            formatter() { return this.value > 1000 ? `${(this.value/1000)}GWh` : `${(this.value)}MWh` }
          },
        }, {
          title: null,
          labels: {
            formatter() { return `${this.value}mm` }
          },
          opposite: true,
        }],
        credits: {
          enabled: false
        },
        series: this.series,
      }
    },
  },
}
</script>

<template lang="pug">
v-highcharts(
  v-if="complete_data_set"
  :options="chart_options"
  )
</template>
