<script>
export default {
  props: {
    actual_fill_percentages: Array,
    min_max_range_fill_percentages: Array,
    median_fill_percentages: Array,
    title: String,
    y_axis_title: String,
  },
  computed: {
    chart_options() {
      return {
        title: {
          text: this.title,
        },
        xAxis: {
          type: 'datetime',
        },
        yAxis: {
          labels: {
            format: '{value}%'
          },
          title: {
            text: this.y_axis_title,
          }
        },
        credits: {
          enabled: false
        },
        tooltip: {
          split: true
        },
        series: [
          {
            name: "Min-max",
            type: 'arearange',
            data: this.min_max_range_fill_percentages,
            yAxis: 0,
            marker: {
              radius: 0,
            }
          },
          {
            name: "Median",
            type: 'line',
            color: '#003a52',
            data: this.median_fill_percentages,
            dashStyle: 'Dash',
            visible: true,
            yAxis: 0,
            marker: {
              radius: 0,
            }
          },
          {
            name: "Actual",
            type: 'line',
            data: this.actual_fill_percentages,
            showInLegend: false,
            yAxis: 0,
            marker: {
              radius: 0,
            }
          },
        ]
      }
    }
  },
}
</script>

<template lang="pug">
  v-highcharts(:options="chart_options")
</template>
