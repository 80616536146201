<script>
import OMDashboardHelpers from '../../../../mixins/om_dashboard_helpers'
export default {
  props: {
    values: {
      type: Array,
      default: () => []
    }
  },
  mixins: [OMDashboardHelpers],
  data() {
    return { contractualAvailability: this.values }
  },
  watch: {
    values() {
      this.contractualAvailability = this.values
    }
  },
  computed: {
    hasData() {
      return this.performance_bars.length > 0
    },
    performance_bars() {
      return this.contractualAvailability.map((item) => {
        return [item.timestamp, item.contractual_availability]
      })
    },
    target_line() {
      let target_points = this.contractualAvailability
        .map((item) => {
          if (item.availability_target)
            return {
              x: item.timestamp,
              dataLabels: {
                enabled: false,
                align: 'left',
                color: '#98A2B3',
                verticalAlign: 'middle',
                overflow: true,
                crop: false,
                format: '{series.name}'
              },
              y: item.availability_target
            }
        })
        .filter(Boolean)
      if (target_points.length == 12) target_points[target_points.length - 1].dataLabels.enabled = true
      return target_points
    },
    options() {
      let series = [
        {
          type: 'line',
          name: this.$t('om_dashboard.drilldown.availability_target'),
          color: '#434348',
          dashStyle: 'dash',
          marker: { enabled: false },
          data: this.target_line,
          tooltip: {
            pointFormat: '{series.name}: <b>{point.y} %</b>'
          }
        },
        {
          type: 'column',
          color: '#53B1FD',
          name: this.$t('om_dashboard.drilldown.contractual_availability'),
          tooltip: {
            pointFormat: '{series.name}: <b>{point.y}%</b>'
          },
          data: this.performance_bars
        }
      ]
      return {
        title: { text: null },
        exporting: this.isExportable(
          this.$t('om_dashboard.drilldown.export_menu.filenames.contractual_availability')
        ),
        credits: { enabled: false },
        legend: {
          enabled: false
        },
        xAxis: [
          {
            type: 'datetime'
          }
        ],
        yAxis: [
          {
            type: 'linear',
            title: { text: '%' },
            min: 0,
            max: 100
          }
        ],
        plotOptions: {
          column: {
            pointWidth: 36
          }
        },
        series: series
      }
    }
  }
}
</script>

<template lang="pug">
div(v-if="hasData")
  v-highcharts(:options="options")
div(v-else)
  v-progress-circular(indeterminate)
</template>
