<script>
export default {
  props: ['weather', 'tag', 'heading'],
  methods: {
    checkIcon(url) {
      const isPng = new RegExp(/\.png|\.svg/)
      return isPng.test(url)
    }
  }
}
</script>

<template lang="pug">
component(:is="tag" class="weather" :class="{'col': tag === 'div', 'no-padding': tag === 'div'}")
  .heading_name(v-if="heading") {{ heading }}
  img(:src="weather.icon" width="40" height="40" v-if="checkIcon(weather.icon)")
  img(src="/om_dashboard/spacer.png" width="40" height="40" v-else)
  .weather_text
    | {{ weather.temp}}°C
    | {{ weather.precipitation }}mm
</template>
