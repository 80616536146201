<script>
import EditDowntimeDialog from './edit.vue'
import DeleteDowntimeDialog from './delete.vue'

export default {
  components: {
    EditDowntimeDialog,
    DeleteDowntimeDialog
  },
  props: {
    all_power_plants: Boolean,
    power_plants: Array,
    totals: Object,
    scheduled_downtime_totals: Object,
    total_pages: Number,
    page: {
      type: Number,
      default: 1
    },
    downtime_periods: Array,
    scheduled_downtime_periods: Array,
    power_plant: Object,
    selectedAreaFrom: Number,
    selectedAreaTo: Number,
    zoomFrom: Number,
    zoomTo: Number,
    currency_code: String,
    reasons_scheduled: Array
  },
  mounted() {
    window.addEventListener('resize', this.updateViewportSize)
    const urlParams = new URLSearchParams(window.location.search)
    const sort = urlParams.get('sort')
    const direction = urlParams.get('direction')
    if (sort && direction) this.sorting = { sortBy: sort, descending: direction === 'desc' }
    this.query = this.downtime_periods.length ? urlParams.get('query') || '' : ''
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateViewportSize)
  },
  methods: {
    submitQuery() {
      const searchParams = new URLSearchParams(window.location.search)
      searchParams.set('query', this.query)
      searchParams.set('page', 1)
      window.history.pushState({}, '', `${window.location.pathname}?${searchParams.toString()}`)
    },
    clearQuery() {
      this.query = ''
      this.submitQuery()
    },
    updateViewportSize() {
      this.isMobileView = window.innerWidth <= 600
    },
    changeSort(column) {
      const url = new URL(window.location)
      url.searchParams.set('sort', column)
      if (this.sorting.sortBy === column)
        url.searchParams.set('direction', this.sorting.descending ? 'asc' : 'desc') // toggle direction
      else url.searchParams.set('direction', 'asc')

      location.href = url.toString()
    },
    parseDate(date) {
      if (date.split('T').length > 1) {
        return date.split('T')[0] + ' ' + date.match(/\d\d:\d\d/)
      } else {
        return date
      }
    },
    formatNumber(num, decimals = 0) {
      return new Intl.NumberFormat('nb-no', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
      }).format(num)
    },
    deleteDowntime(downtime) {
      this.showDeleteDowntimeDialog = true
      this.currentDowntime = downtime
      this.currentScheduledDowntime = {}
    },
    deleteScheduledDowntime(scheduled_downtime) {
      this.showDeleteDowntimeDialog = true
      this.currentScheduledDowntime = scheduled_downtime
      this.currentDowntime = {}
    },
    editDowntime(downtime) {
      this.showEditDowntimeDialog = true
      this.currentDowntime = { ...downtime }
      this.currentScheduledDowntime = {}
    },
    editScheduledDowntime(scheduled_downtime) {
      this.showEditDowntimeDialog = true
      this.currentScheduledDowntime = { ...scheduled_downtime }
      this.currentDowntime = {}
    },
    newDowntime() {
      this.currentDowntime = {
        verified: true,
        hour_count: 0,
        volume_should_have_been: this.power_plant?.rated_power_mw || 0,
        cost: 0,
        power_plant_id: this.power_plant.id,
        starts_at: this.newDate(this.selectedAreaFrom),
        ends_at: this.newDate(this.selectedAreaTo)
      }
      this.currentScheduledDowntime = {
        volume: this.power_plant?.rated_power_mw || 0,
        power_plant_id: this.power_plant.id,
        starts_at: this.newDate(this.selectedAreaFrom),
        ends_at: this.newDate(this.selectedAreaTo)
      }
      this.showNewDowntimeDialog = true
    },
    newDate(int) {
      let date = int ? new Date(int) : new Date()
      date.setMinutes(0)
      date.setSeconds(0)
      date.setUTCMilliseconds(0)
      return date
    },
    toggleRow(rowData) {
      if (this.selectedDowntimeId === rowData.id) {
        this.selectedDowntimeId = null
        this.$emit('update:zoomFrom', null)
        this.$emit('update:zoomTo', null)
      } else {
        this.selectedDowntimeId = rowData.id
        this.$emit('update:zoomFrom', Date.parse(rowData.starts_at))
        this.$emit('update:zoomTo', Date.parse(rowData.ends_at) + 60 * 1000)
      }
    },
    rowClass(rowData) {
      return rowData.id === this.selectedDowntimeId ? 'light-blue white--text pointer' : 'pointer'
    }
  },
  data() {
    return {
      showEditDowntimeDialog: false,
      showNewDowntimeDialog: false,
      showDeleteDowntimeDialog: false,
      currentDowntime: {},
      currentScheduledDowntime: {},
      isMobileView: window.innerWidth <= 600,
      selectedDowntimeId: null,
      query: '',
      sorting: {
        sortBy: null,
        descending: false
      },
      headers_scheduled_downtime: [
        {
          text: '#',
          value: 'num',
          sortable: true
        },
        {
          text: this.$t('general.power_plant'),
          value: 'name',
          sortable: true
        },
        {
          text: this.$t('activerecord.attributes.downtime_period.starts_at'),
          value: 'starts_at',
          sortable: true
        },
        {
          text: this.$t('activerecord.attributes.downtime_period.ends_at'),
          value: 'ends_at',
          sortable: true
        },
        {
          text: `${this.$t('downtime_periods.est_lost_prod_vol')} [MWh]`,
          value: 'volume',
          align: 'right',
          sortable: true
        },
        {
          text: this.$t('activerecord.attributes.downtime_period.reason'),
          value: 'reason',
          sortable: true
        },
        {
          text: this.$t('activerecord.attributes.downtime_period.component'),
          value: 'component',
          sortable: true
        },
        {
          text: this.$t('activerecord.attributes.downtime_period.comment'),
          value: 'comment',
          sortable: true
        },
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false
        }
      ],
      headers: [
        {
          text: '#',
          value: 'num',
          sortable: true
        },
        {
          text: this.$t('general.power_plant'),
          value: 'name',
          sortable: true
        },
        {
          text: this.$t('activerecord.attributes.downtime_period.starts_at'),
          value: 'starts_at',
          sortable: true
        },
        {
          text: this.$t('activerecord.attributes.downtime_period.ends_at'),
          value: 'ends_at',
          sortable: true
        },
        {
          text: this.$t('downtime_periods.no_hours'),
          value: 'hour_count',
          align: 'right',
          sortable: true
        },
        {
          text: `${this.$t('downtime_periods.est_lost_prod_vol')} [MWh]`,
          value: 'volume',
          align: 'right',
          sortable: true
        },
        {
          text: `${this.$t('downtime_periods.est_downtime_cost')} [${this.currency_code}]`,
          value: 'cost',
          align: 'right',
          sortable: true
        },
        {
          text: this.$t('downtime_periods.est_vol_hr'),
          value: 'estimated_hourly_volume',
          align: 'right',
          sortable: true
        },
        {
          text: this.$t('activerecord.attributes.downtime_period.reason'),
          value: 'reason',
          sortable: true
        },
        {
          text: this.$t('activerecord.attributes.downtime_period.component'),
          value: 'component',
          sortable: true
        },
        {
          text: this.$t('activerecord.attributes.downtime_period.comment'),
          value: 'comment',
          sortable: true
        },
        {
          text: this.$t('activerecord.attributes.downtime_period.verified'),
          value: 'verified',
          align: 'center',
          sortable: true
        },
        {
          text: this.$t('activerecord.attributes.downtime_period.insurance'),
          value: 'insurance',
          align: 'center',
          sortable: true
        },
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false
        }
      ]
    }
  },
  computed: {
    filteredHeaders() {
      if (this.all_power_plants) {
        return this.headers
      } else {
        const copy = this.headers.slice()
        copy.splice(1, 1)
        return copy
      }
    },
    itemsPerPage() {
      return this.isMobileView ? 5 : 10
    },
    filteredScheduledDowntimeHeaders() {
      if (this.all_power_plants) {
        return this.headers_scheduled_downtime
      } else {
        const copy = this.headers_scheduled_downtime.slice()
        copy.splice(1, 1)
        return copy
      }
    }
  }
}
</script>

<template lang="pug">
div
  .d-flex.align-end.mb-1.mt-8
    v-text-field.mr-2.mt-0.pt-0(v-model="query" :clearable="true" :label="$t('general.filter_data')" @keydown.enter="submitQuery()" @click:clear="clearQuery()" single-line hide-details)
    v-btn.align-self-end(small: '' @click="submitQuery()") {{ $t('general.search')}}
  v-card#downtime-table
    v-card-title 
      | {{ $t("downtime_periods.model_name_plural") }}
    v-card-text
      v-data-table(
        :items="downtime_periods"
        :items-per-page="itemsPerPage"
        :headers="filteredHeaders"
        :hideDefaultFooter="true"
        :hideDefaultHeader="true"
        :item-class="rowClass"
        @click:row="toggleRow"
        :mobile-breakpoint="0"
      )

        template(slot="header" :headers="filteredHeaders")
          thead#downtime-header(class="v-data-table-header")
            tr
              th(v-for="item in filteredHeaders"
                  :key="item.value"
                  @click="item.sortable ? changeSort(item.value) : ''") {{ item.text }}

                v-icon(v-if="item.sortable && !sorting.descending && sorting.sortBy === item.value" small) mdi-arrow-up
                v-icon(v-else-if="item.sortable && sorting.descending && sorting.sortBy === item.value" small) mdi-arrow-down

        template(v-slot:item.num="{ item }")
          | {{ item.id }}

        template(v-slot:item.volume="{ item }")
          | {{ item.volume.toFixed(2).toLocaleString('no') }}

        template(v-slot:item.cost="{ item }")
          | {{ item.cost ? item.cost.toFixed(1).toLocaleString('no') : '' }}

        template(v-slot:item.estimated_hourly_volume="{ item }")
          | {{ item.estimated_hourly_volume ? item.estimated_hourly_volume.toFixed(2) : ''}}

        template(v-slot:item.verified="{ item }")
          v-simple-checkbox(v-model="item.verified" color="success" :disabled="true")

        template(v-slot:item.insurance="{ item }")
          v-simple-checkbox(v-model="item.insurance" color="success" :disabled="true")

        template(v-slot:item.actions="{ item }")
          div(v-if="item.can_edit_downtime_period")
            v-btn(v-if="item.can_edit_downtime_period" color="primary" title="Edit downtime period" @click.stop="editDowntime({ ...item })" x-small fab)
              v-icon mdi-pencil
            | &nbsp
            v-btn(v-if="item.can_destroy_downtime_period" title="Delete downtime period" @click.stop="deleteDowntime(item)" x-small fab)
              v-icon mdi-delete
          div(v-else="item.can_edit_scheduled_downtime_period")
            v-btn(v-if="item.can_edit_scheduled_downtime_period" color="primary" title="Edit downtime period" @click.stop="editScheduledDowntime({ ...item })" x-small fab)
              v-icon mdi-pencil
            | &nbsp
            v-btn(v-if="item.can_destroy_scheduled_downtime_period" title="Delete downtime period" @click.stop="deleteScheduledDowntime(item)" x-small fab)
              v-icon mdi-delete

        template(v-slot:item.reason="{ item }")
          | {{ item.reason_humanized }}

        template(v-slot:item.component="{ item }")
          | {{ item.component_humanized }}

        template(v-slot:item.starts_at="{ item }")
          | {{ parseDate(item.starts_at) }}

        template(v-slot:item.ends_at="{ item }")
          | {{ parseDate(item.ends_at) }}
          v-chip(v-if="item.overlap" color="error" x-small) Overlap

        template(v-slot:item.comment="{ item }")
          span.comment {{ item.comment }}

        template(slot="body.append")
          tr
            th(:colspan="this.all_power_plants ? 4 : 3") Total
            th(class="text-right") {{ this.formatNumber(this.totals['hour_count'], 0) }}
            th(class="text-right") {{ this.formatNumber(this.totals['volume'], 2)  }}
            th(class="text-right") {{ this.formatNumber(this.totals['cost'], 1) }}
            th(colspan="5")

    Paginate(:length="total_pages", total-visible=6, :value="page", class="my-8")

  v-card.mt-8#scheduled-downtime-table
    v-card-title
      | {{ $t("downtime_periods.scheduled_downtime_periods_name") }}
    v-card-text
      v-data-table(
        :items="scheduled_downtime_periods" 
        :items-per-page="itemsPerPage" 
        :headers="filteredScheduledDowntimeHeaders" 
        :item-class="rowClass" 
        @click:row="toggleRow"
        :mobile-breakpoint="0"
        )
        template(v-slot:item.volume="{ item }")
          | {{ item.volume.toFixed(2).toLocaleString('no') }}

        template(v-slot:item.actions="{ item }")
          v-btn(v-if="item.can_edit_scheduled_downtime_period" color="primary" title="Edit downtime period" @click.stop="editScheduledDowntime({ ...item })" x-small fab)
            v-icon mdi-pencil
          | &nbsp
          v-btn(v-if="item.can_edit_scheduled_downtime_period" title="Delete downtime period" @click.stop="deleteScheduledDowntime(item)" x-small fab)
            v-icon mdi-delete

        template(v-slot:item.starts_at="{ item }")
          | {{ parseDate(item.starts_at) }}

        template(v-slot:item.ends_at="{ item }")
          | {{ parseDate(item.ends_at) }}
        template(v-slot:item.reason="{ item }")
          | {{ $t(`downtime_periods.reasons.${item.reason}`) }}

        template(v-slot:item.component="{ item }")
          | {{ item.component ? $t(`downtime_periods.components.${item.component}`) : '' }}

        template(slot="body.append")
          tr
            th(:colspan="this.all_power_plants ? 4 : 3") Total
            th(class="text-right") {{ this.formatNumber(this.scheduled_downtime_totals['volume'], 2)  }}

  div(v-if="showEditDowntimeDialog")
    EditDowntimeDialog(
      v-model="showEditDowntimeDialog"
      :edit_mode='true'
      :power_plant='power_plant'
      :power_plants="power_plants.filter(power_plant => power_plant.can_edit_power_plant)"
      :downtime_period="currentDowntime"
      :scheduled_downtime_period="currentScheduledDowntime"
      :reasons_scheduled="reasons_scheduled"
      :selectedAreaTo="selectedAreaTo"
      :selectedAreaFrom="selectedAreaFrom"
    )

  div(v-if="showNewDowntimeDialog")
    EditDowntimeDialog(
      v-model="showNewDowntimeDialog"
      :edit_mode='false'
      :power_plant='power_plant'
      :power_plants="power_plants.filter(power_plant => power_plant.can_edit_power_plant)"
      :downtime_period="currentDowntime"
      :scheduled_downtime_period="currentScheduledDowntime"
      :reasons_scheduled="reasons_scheduled"
      :selectedAreaTo="selectedAreaTo"
      :selectedAreaFrom="selectedAreaFrom"
    )

  div(v-if="showDeleteDowntimeDialog")
    DeleteDowntimeDialog(
      v-model="showDeleteDowntimeDialog"
      :downtime_period="currentDowntime"
      :scheduled_downtime_period="currentScheduledDowntime"
    )

  div#new-button
    v-btn(:disabled="!all_power_plants && !power_plant.can_edit_power_plant" @click="newDowntime" color="light-blue" title="Add new downtime period" fixed dark fab right bottom)
      v-icon(large) mdi-plus
</template>
