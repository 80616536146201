// index.js
import Vue from 'vue'
import VueRouter from 'vue-router'
import NotificationSettingsPersonal from '../components/navigation/notification_settings/personal_settings.vue'
import NotificationSettingsGeneral from '../components/navigation/notification_settings/general_settings.vue'
import NotificationSettingsMenuItems from '../components/navigation/notification_settings/menu_items.vue'
import NotificationSettingsRuleForm from '../components/navigation/notification_settings/rule_form.vue'

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'abstract', // This tells Vue Router to not use the URL.
  routes: [
    {
      path: '/notification_settings_menu_items',
      name: 'notification_settings_menu_items',
      component: NotificationSettingsMenuItems,
      props: route => ({current_user: route.query.current_user})
    },
    {
      path: '/notification_settings_personal',
      name: 'notification_settings_personal',
      component: NotificationSettingsPersonal,
      props: route => ({current_user: route.query.current_user})
    },
    {
      path: '/notification_settings_general',
      name: 'notification_settings_general',
      component: NotificationSettingsGeneral,
      props: route => ({current_user: route.query.current_user})
    },
    {
      path: '/notification_settings_rule',
      name: 'notification_settings_rule',
      component: NotificationSettingsRuleForm,
      props: route => ({ 
        current_rule: route.query.rule,
        power_plants: route.query.power_plants,
        responsibles: route.query.responsibles,
        operational_status_types: route.query.operational_status_types,
        currency_codes: route.query.currency_codes
      })
    }
  ]
});

export default router
