<script>

export default {
  props: {
    expanded: Boolean,
  },
  data() {
    return {
      expand: this.expanded
    }
  },
}
</script>

<template lang="pug">
.expandable-info
  .expand-button(class="mt-n6")
    v-icon(v-if="expand" @click="expand = !expand" large) mdi-arrow-up-thick
    v-icon(v-else @click="expand = !expand" large) mdi-arrow-down-thick

  v-expand-transition
    .expandable-content(v-if="expand")
      slot
</template>
