<script>
import ValueMixin from './value_mixins.vue'

export default {
  props: {
    value: [Number, String],
    label: String,
    disabled: Boolean,
    currency: String,
    codes: String,
    hasProjectId: Boolean,
  },
  mixins: [ValueMixin],
  data() {
    return {
      inputValue: this.value,
      data_codes: this.codes
    }
  },
  methods: {
    inputValueFormatted() {
      return this.formatNumber(this.inputValue)
    },
    setInputValue(formattedValue) {
      if (formattedValue !== null) {
        formattedValue = formattedValue.replace(/−/g, '-')
        this.inputValue = this.parseFormattedNumber(formattedValue)
      }
    },
    customClearClick() {
      if (confirm(this.$t('fall_lease.confirm_code_list_clear'))) {
        this.inputValue = ''
        this.data_codes = ''
      }
    },
    sendClick() {
      this.$emit('click', {
        inputValue: this.inputValue,
        dataCodes: this.data_codes
      })
    },
  },
  watch: {
    value() {
      this.inputValue = this.value
    },
    inputValue() {
      this.$emit('input', this.inputValue)
    },
  },
}
</script>
<template lang="pug">
div
  v-chip-group(
    v-if="!isEmpty(this.data_codes)"
  )
    v-chip(
      v-for="(code, index) in this.data_codes.split(',')" :key="index"
      v-if="!isEmpty(code)"
      color="primary"
      x-small
      label
      outlined
    ) {{ code }}
  v-text-field(
    :value="inputValueFormatted()"
    @input="setInputValue"
    :label="label"
    :disabled="disabled"
    :suffix="currency"
    filled
  )
    template(v-slot:append)
      v-icon(
        @click="customClearClick"
        :disabled="disabled"
      ) mdi-pen
      v-icon(
        @click="sendClick()"
        :disabled="!hasProjectId"
      ) mdi-magnify
</template>