<script>
export default {
  // Flag source: https://hatscripts.github.io/circle-flags/
  props: {
    current_user: Object,
  },
  data() {
    return {
      menu_opened: false
    }
  },
  methods: {
    async changeLang (lang) {
      const data = {
        user: {
          locale: lang
        }
      }
      const url = `/users/${this.current_user.id}.js`
      const response = await this.axios.put(url, data)
      if(response.status == 200) window.location.reload()
    }
  },
}
</script>
<template lang="pug">
v-menu#language-selector(v-model="menu_opened")
  template(v-slot:activator="{ on }")
    a(v-on="on")
      v-chip.pointer.mr-2#language-chip(:title="$t('help_texts.navigation.change_language')")
        img(v-if="current_user.locale == 'nb'" src='/flags/no.svg')
        img(v-if="current_user.locale == 'en'" src='/flags/gb.svg')
        img(v-if="current_user.locale == 'fr'" src='/flags/fr.svg')
        img(v-if="current_user.locale == 'de'" src='/flags/de.svg')
  v-list#language-menu
    v-list-item
      b {{ $t('navigation.language.select') }}
    v-list-item(@click="changeLang('nb')")
      img(src='/flags/no.svg') 
      .lang-name {{ $t('navigation.language.nb') }}
    v-list-item(@click="changeLang('en')")
      img(src='/flags/gb.svg') 
      .lang-name {{ $t('navigation.language.en') }}
    v-list-item(@click="changeLang('fr')")
      img(src='/flags/fr.svg') 
      .lang-name {{ $t('navigation.language.fr') }}
    v-list-item(@click="changeLang('de')")
      img(src='/flags/de.svg') 
      .lang-name {{ $t('navigation.language.de') }}
</template>
