<script>
export default {
  props: ['item']
}
</script>

<template lang="pug">
v-list-item(:href="item.href")
  v-list-item-icon
    v-icon {{ item.icon }}
  v-list-item-content
    v-list-item-title
      | {{ item.title }}
      v-chip(v-if="item.beta" color='purple' x-small dark) BETA
</template>
