<script>
export default {
  props: {
    categories: Array,
    power_plants: Array,
    selected_category: String,
    selected_power_plant_id: String,
    selected_scope: String,
    allow_community_posts: Boolean
  },
  data() {
    const scopes = this.allow_community_posts ?
      { all: this.$t('news_items.filter_all'), internal: this.$t('news_items.filter_internal'), community: this.$t('news_items.filter_community') } :
      { all: this.$t('news_items.filter_all'), internal: this.$t('news_items.filter_internal') }

    return {
      category: this.selected_category,
      power_plant_id: parseInt(this.selected_power_plant_id),
      scope: this.selected_scope,
      scopes: scopes
    }
  },
  computed: {
    url() {
      const cat = this.category || ''
      const ppid = this.power_plant_id || ''
      const sc = this.scope || ''
      return `?category=${cat}&power_plant_id=${ppid}&scope=${sc}`
    },
  },
  watch: {
    url() { window.location = this.url }
  }
}
</script>

<template lang="pug">
.d-flex
  v-btn-toggle(v-model="scope" style="background-color: inherit")
    v-btn(v-for="(text, value) in scopes" :value="value" :key="value.id") {{ text }}
  v-select.ml-3(v-model="category" name='category' :items= "categories" :placeholder="$t('news_items.filter_category')" :clearable='true' solo)
  v-select.ml-3(v-model="power_plant_id" name='power_plant_id' :items="power_plants" :placeholder="$t('news_items.filter_power_plant')" :clearable='true' solo)
</template>
