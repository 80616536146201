<script>
import OMDashboardHelpers from '../../mixins/om_dashboard_helpers'
export default {
  props: {
    values: Array,
    additionalNames: Array,
    unit: String
  },
  mixins: [OMDashboardHelpers],
  data() {
    return {
      chartValues: this.values,
      chartColors: {
        'measured': '#53B1FD',
        'potential': '#00FFBB',
        'potential_loss': 'rgba(255, 0, 0, 0.5)', // Semi-transparent for the area
        'weather_model': '#0000AA',
        'no_data': 'rgba(0,0,0,0.5)', // Semi-transparent for the area
      },
      seriesColors: ['#53B1FD', '#0000AA', '#00FFBB', '#6F8435', '#876F49', '#E1522A']
    }
  },
  watch: {
    values() {
      this.chartValues = this.values
    }
  },
  methods: {
    processDataForSeries(chartValues) {
      const measuredData = chartValues.find(series => series.title.toLowerCase() === 'measured')?.values
      const potentialData = chartValues.find(series => series.title.toLowerCase() === 'potential')?.values
      const weatherModelData = chartValues.find(series => series.title.toLowerCase() === 'weather_model')?.values
      const noData = chartValues.find(series => series.title.toLowerCase() === 'no_data')?.values

      let processedSeries = []

      if (this.hasComparisons) {
        let colorIndex = 0
        chartValues.forEach((series) => {
          const color = this.seriesColors[colorIndex % this.seriesColors.length]
          processedSeries.push(this.createLineSeries(series.title, series.values, color, true))
          colorIndex++
        })
      } else {
        if (measuredData) {
          processedSeries.push(this.createLineSeries(this.$t('om_dashboard.drilldown.measured'), measuredData, this.chartColors['measured'], true))
        }

        if (potentialData && measuredData) {
          processedSeries.push(this.createPotentialGapSeries(this.$t('om_dashboard.overview.loss'), potentialData, measuredData))
        }

        if (noData) {
          processedSeries.push(this.createAreaSeries(this.$t('om_dashboard.alarm_status.no_data'), noData, this.chartColors['no_data'], true))
        }

        if (weatherModelData) {
          processedSeries.push(this.createLineSeries(this.$t('om_dashboard.drilldown.weather_model'), weatherModelData, this.chartColors['weather_model'], false))
        }

        if (potentialData) {
          processedSeries.push(this.createLineSeries(this.$t('om_dashboard.drilldown.potential'), potentialData, this.chartColors['potential'], false))
        }
      }

      return processedSeries
    },

    createLineSeries(title, data, color, isVisible) {
      const unit = this.unit

      return {
        type: 'line',
        name: title,
        color: color,
        data: data,
        marker: {enabled: false},
        zIndex: 10, // Ensure this series is above the area series
        visible: isVisible,
        tooltip: {
          pointFormatter: function() {
            const unitText = unit ? ` ${unit}` : ''
            return `${title}: <b>${this.y.toFixed(3)}${unitText}</b>`
          }
        }
      }
    },
    createAreaSeries(title, data, color, isVisible) {
      const unit = this.unit

      return {
        type: 'area',
        name: title,
        data: data,
        color: color,
        fillColor: color,
        marker: {enabled: false},
        zIndex: 0, // Ensure this series is below the line series
        visible: isVisible,
        tooltip: {
          pointFormatter: function() {
            const unitText = unit ? ` ${unit}` : ''
            return `${title}: <b>${this.y.toFixed(3)}${unitText}</b>`
          }
        }
      }
    },
    createPotentialGapSeries(title, potentialData, measuredData) {
      const unit = this.unit

      return {
        type: 'arearange',
        name: title,
        data: potentialData.map((potentialPoint, i) => {
          const measuredPoint = measuredData[i]
          // If potential is greater than measured, return a point with [x, low, high]
          if (measuredPoint && potentialPoint[1] > measuredPoint[1]) {
            return [potentialPoint[0], potentialPoint[1], measuredPoint[1]]
          }
          // Otherwise, return [x, null, null] to skip drawing the area
          return [potentialPoint[0], null, null]
        }),
        fillColor: 'rgba(255, 0, 0, 0.5)',
        color: 'rgba(255, 0, 0, 0.5)',
        marker: { enabled: false },
        linewidth: 0,
        zIndex: 1,
        tooltip: {
          pointFormatter: function () {
            const potentialValue = this.low.toFixed(3)
            const deviation = Math.abs(this.high - this.low).toFixed(3)
            const unitText = unit ? ` ${unit}` : ''
            return `Potential: <b>${potentialValue} ${unitText}</b><br/>${title}: <b>${deviation} ${unitText}</b>`
          }
        },
        line: {
          zIndex: 1,
          marker: { enabled: false }
        }
      }
    },
},
  computed: {
    hasComparisons() {
      return this.chartValues[0].title !== 'measured'
    },
    hasData() {
      return this.chartValues.length > 0
    },
    options() {

      let series = this.processDataForSeries(this.chartValues)

      return {
        chart: {
          zoomType: 'x'
        },
        tooltip: {
          shared: true,
          split: true,
        },
        xAxis: [
          {
            type: 'datetime'
          }
        ],
        yAxis: [
          {
            title: { text: this.$t('om_dashboard.drilldown.power_y_title', { unit: this.unit }) }
          }
        ],
        title: {text: null},
        boost: {
          enabled: false,
          useGPUTranslations: true,
          seriesThreshold: 0,
          turboThreshold: 500000
        },
        exporting: this.isExportable(
          this.$t('om_dashboard.drilldown.export_menu.filenames.production_chart')
        ),
        credits: { enabled: false },
        series: series
      }
    }
  }
}
</script>

<template lang="pug">
div.production_chart(v-if="hasData")
  v-highcharts(:options="options")
div(v-else)
  v-progress-circular(indeterminate)
</template>
