<script>

export default {
  props: {
    item_type: String,
    item_id: Number,
  },
  data() {
    return {
      subscribe: false,
      content: '',
      saving: false
    }
  },
  computed: {
    disable_submit() {
      return this.content.length == 0  || this.saving
    }
  },
  methods: {
    async save() {
      this.saving = true
      const url = `/comments?commented_on_type=${this.item_type}&commented_on_id=${this.item_id}`
      const data = {
        comment: {
          content: this.content
        }
      }
      let response = await this.axios.post(url, data)
      this.saving = false
      if(response.status == 200) {
        this.content = ''
        this.$emit('comments-updated')
      } else {
        alert('Something went wrong. Please try again or notify the staff if the problem persists.')
      }
    },
    item_route() {
      switch(this.item_type) {
        case "FeatureRequest":
          return "feature_requests"
        case "NewsItem":
          return "news_items"
        case "WorkItem":
          return "work_items"
      }
    },
    async checkSubscription() {
      const url = `/${this.item_route()}/${this.item_id}/subscribed`
      const response = await this.axios.get(url)

      this.subscribe = response.data
    },
    toggleSubscription() {
      const url = `/${this.item_route()}/${this.item_id}/mark_as_read?subscribe=${this.subscribe}`
      this.axios.post(url)
    },
  },
  watch: {
    subscribe() { this.toggleSubscription() }
  },
  mounted() { this.checkSubscription() }
}
</script>

<template lang="pug">
.reply
  v-textarea(v-model="content" hide-details="auto" :placeholder="$t('comments.add_comment')" outlined :auto-grow="true")
  v-btn(color="primary" :disabled="disable_submit" @click="save" small) {{ $t('general.create') }}
  v-checkbox(v-model="subscribe" :label="$t('comments.notify_replies')")
</template>
