<script>
export default {
  props: {
    power_plants: Array,
    price_area: String,
    country: String,
    asset_class: String,
    currency_code: String
  },
  data() {
    return {
      sort_by: 'name',
      sort_dir: -1,
      loading: false,
      all_loaded: false,
      query: '',
      loaded_power_plants: this.power_plants
    }
  },
  computed: {
    sorted_power_plants() {
      return this.loaded_power_plants
        .sort((first, second) => {
          if (first[this.sort_by] < second[this.sort_by]) {
            return this.sort_dir
          }
          if (first[this.sort_by] > second[this.sort_by]) {
            return -this.sort_dir
          }
          return 0
        })
        .filter((p) => p.name.toLowerCase().includes(this.query.toLowerCase()))
    },
    production_bar_width() {
      return 'width: 20rem'
    }
  },
  methods: {
    round(value, decimals) {
      return Number(value).toFixed(decimals)
    },
    sort(field) {
      this.loadAllPowerPlants()
      if (this.sort_by == field) {
        this.sort_dir *= -1
      } else {
        this.sort_dir = field == 'name' ? -1 : 1
      }
      this.sort_by = field
    },
    async loadAllPowerPlants() {
      if (this.all_loaded) return
      this.all_loaded = true
      this.loading = true
      window.removeEventListener('scroll', this.loadAllPowerPlants)
      const url = `/dashboard/power_plants.json?price_area=${this.price_area}&country=${this.country}&asset_class=${this.asset_class}`
      const response = await this.axios.get(url)
      this.loaded_power_plants = response.data
      this.loading = false
    },
    checkScroll() {
      const list = document.querySelector('#power-plant-details')
      const window_bottom = window.innerHeight + window.scrollY
      const element_bottom = list.offsetTop + list.clientHeight
      if (element_bottom - window_bottom <= 0) this.loadAllPowerPlants()
    }
  },
  mounted() {
    window.addEventListener('scroll', this.checkScroll)
  }
}
</script>
<template lang="pug">
div.overflow-auto
  .element
    input#filter(@click="loadAllPowerPlants" v-model="query" type="text" :placeholder="$t('general.filter')")
  #power-plant-details
    a.power-plant
      .element
      .element
        span.pointer.uppercase(@click="sort('name')") {{ $t('general.power_plant') }}
      .element.center.pointer(@click="sort('rated_power_mw')")
        tooltip(:tip="$t('help_texts.dashboard.table_mw')" bottom) MW
      .element.pointer.uppercase(@click="sort('utilization')")
        tooltip(:tip="$t('help_texts.dashboard.table_prod')" bottom) {{ $t('general.production') }}
      .element.center.pointer(@click="sort('trend_24h')")
        tooltip(:tip="$t('help_texts.dashboard.trend_24h')" bottom) {{ $t('general.trend_last_24_hours') }}
      .element.center.pointer(@click="sort('average_24h')")
        tooltip(:tip="$t('help_texts.dashboard.average_24h')" bottom) {{ $t('general.average_last_24_hours') }}
      .element.center.pointer(@click="sort('price_area')")
        tooltip(:tip="$t('help_texts.dashboard.price_area')" bottom) {{ $t('general.price_area') }}
      .element.center.pointer(@click="sort('current_elspot_price')")
        tooltip(:tip="$t('help_texts.dashboard.table_price')" bottom) {{ `${currency_code}/MWh` }}
      .element.center.pointer.uppercase(@click="sort('met_temp')")
        tooltip(:tip="$t('help_texts.dashboard.table_now')" bottom) {{ $t('general.now') }}
      .element.right.pointer(@click="sort('two_day_precipitation')")
        tooltip(:tip="$t('help_texts.dashboard.table_mm')" bottom) {{ $t('dashboard.mm_48h') }}

    a.power-plant.nostyle(v-for="power_plant in sorted_power_plants" :href="`/power_plants/${power_plant.id}`")
      .element
        v-list-item-avatar
          v-img(:src="power_plant.image_url" class="asset-class" :style="`border-color: ${power_plant.asset_color}`")
      .element
        v-list-item-avatar#flag(size="1em")
          v-img(:src="power_plant.flag_url")
        span.weak(v-if="power_plant.project") Project 
        | {{ power_plant.name }}
      .element.center {{ round(power_plant.rated_power_mw, 1) }}
      .element(:style="production_bar_width")
        .current {{ round(power_plant.current_effect || 0, 2) }}MW
        .utilization {{ round(power_plant.utilization, 0) }}%
        .timestamp {{ power_plant.current_effect_updated }}
        v-progress-linear(:color="power_plant.utilization > 0 ? '#38506c' : 'red'" height="16" :value="power_plant.utilization")
      .element.center
        span(v-if="power_plant.trend_24h == 'positive'")
          v-icon mdi-arrow-up-thick
        span(v-else-if="power_plant.trend_24h == 'negative'")
          v-icon mdi-arrow-down-thick
        span(v-else style="font-weight: bold; font-size: 40px; color: #757575") {{"-"}}
      .element.center
        | {{ `${ round(power_plant.average_24h,0)}%` }}
      .element.center
        | {{ power_plant.price_area }}
      .element.center
        span(v-if="power_plant.current_elspot_price")
          | {{ round(power_plant.current_elspot_price, 0) }}
      .element.center
        span(v-if="power_plant.weather_source == 'meteomatics'")
          span(v-if="power_plant.asset_class_humanized == 'wind'")
            v-icon(:title="`Wind direction ${power_plant.meteomatics_wind_direction}`" :style="`transform:rotate(${power_plant.meteomatics_wind_direction}deg)`") mdi-ray-start-arrow
            | {{ round(power_plant.meteomatics_wind_speed, 1) }}
          div(v-else)
            img(:src="power_plant.meteomatics_icon")
            span(v-if="power_plant.asset_class_humanized == 'hydro'")
              | {{ power_plant.meteomatics_temperature }}°
            span(v-if="power_plant.asset_class_humanized == 'solar'")
              | {{ power_plant.meteomatics_temperature }}°
        span(v-if="(power_plant.weather_source != 'meteomatics') && power_plant.met_icon")
          img(:src="power_plant.met_icon")
          | {{ power_plant.met_temp }}°
          span(v-if="power_plant.asset_class_humanized == 'wind'")
            v-icon(:title="`Wind direction ${power_plant.met_wind_direction}`" :style="`transform:rotate(${power_plant.met_wind_direction}deg)`") mdi-ray-start-arrow
            | {{ round(power_plant.meteomatics_wind_speed, 1) }}
        
      .element.right
        span(v-if="power_plant.weather_source == 'meteomatics'")
          span(v-if="power_plant.asset_class_humanized == 'hydro'")
            | {{ round(power_plant.meteomatics_precipitation_two_day, 1) }} mm
          span(v-if="power_plant.meteomatics_solar_energy_two_day && power_plant.asset_class_humanized == 'solar'")
            | {{ round(power_plant.meteomatics_solar_energy_two_day, 2) }} MWh
          span(v-else-if="power_plant.asset_class_humanized == 'solar'")
            | {{ round(power_plant.meteomatics_radiation_two_day, 2) }} MWh
          span(v-if="power_plant.meteomatics_wind_energy_two_day && power_plant.asset_class_humanized == 'wind'")
            | {{ round(power_plant.meteomatics_wind_energy_two_day, 1) }} MWh
          span(v-else-if="power_plant.asset_class_humanized == 'wind'")
            | {{ round(power_plant.meteomatics_wind_speed_two_day_avg, 1) }} m/s
        span(v-else)
          span(v-if="power_plant.asset_class_humanized == 'hydro'") {{ round(power_plant.two_day_precipitation, 1) }}mm
          span(v-if="power_plant.asset_class_humanized == 'wind'") {{ round(power_plant.two_day_wind_speed_avg, 1) }}m/s

  v-progress-linear(v-if="loading" color="green" indeterminate)
</template>
