<script>
import OMDashboardHelpers from '../../mixins/om_dashboard_helpers'
import Highcharts from 'highcharts'
export default {
  props: {
    values: Object,
    x_title: String,
    y_title: String,
    name: String,
    additionalNames: Array,
    additionalValues: Array,
    unit: String,
    tech: String
  },
  mixins: [OMDashboardHelpers],
  data() {
    return {
      tooltipLimit: 20000,
      points: this.values,
      additionalColours: ['#53B1FD', '#0000AA', '#00FFBB', '#6F8435', '#876F49', '#E1522A']
    }
  },
  watch: {
    values() {
      this.points = this.values
    }
  },
  methods: {
    buildSeries() {
      let tooltip = {
        enabled: this.hasTooltip,
        headerFormat: '<b>{series.name}</b><br>',
        pointFormat: `<br>Measured: {point.y} ${this.unit}<br>Theoretical: {point.x} ${this.unit} <br>Timestamp: {point.timestamp}`
      }

      let marker = {
        radius: 1,
        symbol: 'circle'
      }

      let series = []

      if (Array.isArray(this.additionalValues) && this.additionalValues.length) {
        // Combine all points from all status codes for the main inverter into one series
        let mainInverterData = Object.values(this.values).flat()
        series.push({
          turboThreshold: 0,
          type: 'scatter',
          name: this.name,
          enableMouseTracking: this.hasTooltip,
          boostThreshold: mainInverterData.length + 1,
          color: this.additionalColours[0],
          data: mainInverterData.map((p) => ({
            x: parseFloat(p.theoretical) || 0,
            y: parseFloat(p.measured) || 0,
            timestamp: this.formatDateTime(p.timestamp)
          })),
          tooltip: tooltip,
          marker: marker
        })

        // Combine all points from all status codes for additional inverters into one series each
        this.additionalValues.forEach((inverterDataObject, index) => {
          let inverterDataPoints = Object.values(inverterDataObject).flat()
          series.push({
            turboThreshold: 0,
            type: 'scatter',
            enableMouseTracking: this.hasTooltip,
            name: this.additionalNames[index],
            color: this.additionalColours[(index + 1) % this.additionalColours.length],
            data: inverterDataPoints.map((p) => ({
              x: parseFloat(p.theoretical) || 0.0,
              y: parseFloat(p.measured) || 0.0,
              timestamp: this.formatDateTime(p.timestamp)
            })),
            boostThreshold: inverterDataPoints.length + 1,
            tooltip: tooltip,
            marker: marker
          })
        })
      } else {
        // If additionalValues is not provided, create a series for each status code
        Object.entries(this.values).forEach(([status, points], _index) => {
          const name = this.operationalStatusTypes[status]?.title
            ? this.operationalStatusTypes[status].title
            : 'missing_status'
          series.push({
            turboThreshold: 0,
            type: 'scatter',
            enableMouseTracking: this.hasTooltip,
            name: this.$t(`om_dashboard.status.${this.tech}.${name}`),
            color: this.operationalStatusTypes[status]?.color || '#1890FF',
            data: points.map((p) => ({
              x: parseFloat(p.theoretical) || 0.0,
              y: parseFloat(p.measured) || 0.0,
              timestamp: this.formatDateTime(p.timestamp)
            })),
            boostThreshold: this.dataLength + 1,
            tooltip: tooltip,
            marker: marker
          })
        })
      }

      // Add the reference line
      series.push({
        type: 'line',
        color: '#D0D5DD',
        index: 1000,
        name: 'Reference',
        enableMouseTracking: false,
        marker: marker,
        data: this.referenceLine,
        tooltip: { formatter: false }
      })
      return series
    }
  },
  computed: {
    dataLength() {
      let initial = Object.values(this.points).flat().length
      if (Array.isArray(this.additionalValues) && this.additionalValues.length) {
        this.additionalValues.forEach((add) => {
          initial += Object.values(add).flat().length
        })
      }
      return initial || 0
    },
    hasTooltip() {
      return this.dataLength <= this.tooltipLimit
    },
    hasData() {
      return Object.keys(this.points).length > 0
    },
    referenceLine() {
      let measuredMax = 0.0
      let theoreticalMax = 0.0

      Object.values(this.points).forEach((values) => {
        values.forEach((value) => {
          measuredMax = Math.max(measuredMax, parseFloat(value.measured) || 0.0)
          theoreticalMax = Math.max(theoreticalMax, parseFloat(value.theoretical) || 0.0)
        })
      })

      let maxOfMax = parseFloat(Math.max(measuredMax, theoreticalMax).toFixed(2))

      return [
        { x: 0, y: 0 },
        { x: maxOfMax, y: maxOfMax }
      ]
    },
    options() {
      let measuredMax = 0.0
      let theoreticalMax = 0.0

      // Calculate the maximum values
      Object.values(this.points).forEach((values) => {
        values.forEach((value) => {
          measuredMax = Math.max(measuredMax, parseFloat(value.measured) || 0.0)
          theoreticalMax = Math.max(theoreticalMax, parseFloat(value.theoretical) || 0.0)
        })
      })

      let maxOfMax = Math.max(measuredMax, theoreticalMax).toFixed(2)

      if (maxOfMax >= 2000) {
        maxOfMax = Math.round(maxOfMax / 500 + 1) * 500
      } else if (maxOfMax >= 200) {
        maxOfMax = Math.round(maxOfMax / 50 + 1) * 50
      } else if (maxOfMax >= 20) {
        maxOfMax = Math.round(maxOfMax / 5 + 1) * 5
      } else {
        maxOfMax = Math.round(maxOfMax / 2 + 0.5) * 2
      }

      // let tickInterval = Math.floor(maxOfMax / 50 ) * 5
      let tickInterval = parseFloat((maxOfMax / 10).toFixed(2))

      let series = this.buildSeries()

      return {
        title: { text: null },
        credits: { enabled: false },
        xAxis: [
          {
            gridLineWidth: 0,
            min: 0,
            max: maxOfMax,
            tickInterval: tickInterval,
            title: { text: this.x_title + ' [' + this.unit + ']' },
            lineWidth: 1
          }
        ],
        yAxis: [
          {
            gridLineWidth: 0,
            min: 0,
            max: maxOfMax,
            tickInterval: tickInterval,
            title: { text: this.y_title + ' [' + this.unit + ']' }
          }
        ],
        exporting: this.isExportable(this.$t('om_dashboard.drilldown.export_menu.filenames.power_curve')),
        chart: {
          events: {
            load: function () {
              // Use load event to dynamically adjust height to maintain aspect ratio
              let w = this.chartWidth - 10 - 10 // width - left - right spacing
              this.setSize(w, w, false) // Set height equal to the adjusted width
            }
          }
        },
        spacingTop: 10,
        spacingBottom: 10,
        spacingLeft: 10,
        spacingRight: 10,
        plotBorderWidth: null,
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'bottom',
          useHTML: true,
          symbolHeight: 0,
          symbolWidth: 0,
          labelFormatter: function () {
            return `<span style="display:inline-block; width: 12px; height: 12px; background-color: ${this.color}; border-radius: 0;"></span> <span style="font-weight:bold;">${this.name}</span>`
          }
        },
        series: series
      }
    }
  }
}
</script>

<template lang="pug">
v-responsive(height="1100" width="900")
  div.power_curve(v-if="hasData")
    v-highcharts(:options="options")
    em(v-if="!hasTooltip") {{ $t('om_dashboard.drilldown.tooltip_hidden') }} 
  div(v-else)
    v-progress-circular(indeterminate)
</template>
