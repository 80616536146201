<script>

export default {
  props: {
    value: Boolean,
    assetComments: Array,
    power_plants: Array,
    power_plant: Object,
    availableAssetCommentPeriods: Array
  },
  data() {
    return {
      errors: {},
      show: this.value,
      assetCommentsData: this.assetComments,
      powerPlantItems: this.power_plants.length === 0 ? [this.power_plant] : this.power_plants
    }
  },
  mounted() {
    this.resetForm()
  },
  methods: {
    resetForm() {
      const urlParams = new URLSearchParams(window.location.search)
      const period = urlParams.get('period')

      const date = period?.includes('y') ? `1.1.${period.split('-')[1]}` : period
      
      this.assetComment = { 
        date: date || new Date().toISOString(),
        period: this.availableAssetCommentPeriods.length === 1 ? this.availableAssetCommentPeriods[0] : '',
        power_plant_id: this.powerPlantItems.length === 1 ? this.powerPlantItems[0].id : null,
      }
    },
    async save() {
      try {
        const response = await this.axios.post(`/asset_comments/`, { asset_comment: this.assetComment } )
        this.assetCommentsData.push(response.data)
        this.resetForm()
        this.show = false
      } catch(error) {
        console.log(error)
        this.errors = error.response.data
      }
    },
    sharedProps(field) {
      return {
        name: `asset_comment[${field}]`,
        id: `asset_comment_${field}`,
        errorMessages: this.errors[field],
        readonly: false,
        label: this.$t(`activerecord.attributes.asset_comment.${field}`),
        hideDetails: 'auto',
        dense: true,
        outlined: true,
      }
    },
  },
  computed: {
    periods() {
      return this.availableAssetCommentPeriods.map(p => ({ value: p, text: this.$t(`activerecord.attributes.asset_comment.periods.${p}`) }))
    },
  },
  watch: {
    value(newValue) {
      this.show = newValue
    },
    show(newValue) {
      this.$emit("input", newValue)
    },
    assetCommentsData(newValue) {
      this.$emit("change", newValue)
    },
  },
}
</script>

<template lang="pug">
v-scale-transition(v-if="show" origin="bottom right 0")
  v-card(style="width: 350px; position: fixed; right: 5px; bottom: 120px; z-index: 5")
    v-card-title
      | {{ $t(`asset_comments.create_title`) }}
    v-card-text
      v-select(v-model="assetComment.power_plant_id" :items="powerPlantItems" item-value="id" item-text="name" v-bind="sharedProps('power_plant_id')" :readonly="this.powerPlantItems.length <= 1")
      v-select.mt-2(v-model="assetComment.period" v-bind="sharedProps('period')" :items="periods")
      v-textarea.mt-2(v-model="assetComment.content" v-bind="sharedProps('content')" rows="2" auto-grow)

    v-card-actions
      v-btn(color="primary" @click="save")
        | {{ $t('general.save') }}
      v-spacer
      v-btn(@click="show = false")
        | {{ $t('general.cancel') }}
  
</template>

