<script>
import ChipRow from './chip_row.vue'

export default {
  name: 'TechChips',
  components: {
    ChipRow
  },
  props: {
    powerPlant: {
      type: Object,
      required: true
    }
  },
  computed: {
    alarmObject() {
      return this.powerPlant.technical_alarm
    },
    dataUrl() {
      return ''
    }
  }
}
</script>

<template lang="pug">
div
  ChipRow(v-if="alarmObject" :alarm-object="alarmObject" :data-url="dataUrl")
</template>
