<script>
import OMDashboardHelpers from '../../../mixins/om_dashboard_helpers'
export default {
  props: [
    'operational_status_severity',
    'alarm_category',
    'duration',
    'tech',
    'mobile_title',
    'last_updated'
  ],
  mixins: [OMDashboardHelpers],
  computed: {
    isCircle() {
      return !(this.alarm_category && (this.tech === 'solar' || this.alarm_category.severity > 20))
    },
    alarmIcons() {
      if (this.tech === 'wind') return this.windAlarms
      else return this.solarAlarms
    },
    tooltipText() {
      let severity = this.operational_status_severity ? this.operational_status_severity : 55
      let severity_title = this.operationalStatusTypes[severity].title
      let category = this.tech ? this.$t(`om_dashboard.status.${this.tech}.${severity_title}`) : 'N/A'
      let tooltip = ''
      if (this.duration) {
        let durationText = this.duration ? `${this.formatDuration(this.duration)}` : 'N/A'
        tooltip += `${this.$t(
          'om_dashboard.drilldown.operational_status'
        )}: ${category}<br>Duration: ${durationText}`
      } else tooltip += `${this.$t('om_dashboard.drilldown.operational_status')}: ${category}`
      if (this.last_updated)
        tooltip += `<br />${this.$t('om_dashboard.overview.last_update')}: ${this.formatDateTime(
          this.last_updated
        )}`
      return tooltip
    }
  },
  methods: {
    getIconPath(name) {
      if (!name) return
      return `/om_dashboard/alarm_category/${name}.svg`
    },

    getAlarmColor(severity) {
      return this.operationalStatusTypes[severity].class_color || 'grey-alarm'
    }
  }
}
</script>

<template lang="pug">
div.alarm_circle
  v-tooltip(bottom)
    template(v-slot:activator="{ on, attrs }")
      div.d-flex.justify-center.alarm-icon(v-on="on" v-bind="attrs")
        div(:class="[isCircle ? 'circle' : 'oval', getAlarmColor(operational_status_severity)]")
          div(v-if="isCircle")
            v-img.middle-icon(v-if="alarm_category" :src="alarmIcons[alarm_category.severity]?.icon")
            div.middle-icon.duration-text(v-if="duration > 1") {{ shortDuration(duration) }}
          div(v-else)
            v-img.left-icon(v-if="alarm_category" :src="alarmIcons[alarm_category.severity]?.icon")
            div.right-icon.duration-text {{ shortDuration(duration) }}
    div(v-html="tooltipText")
  .hidden-md-and-up.heading_title {{ mobile_title }}
</template>
