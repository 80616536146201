<script>
export default {
  props: ['downtime_hour_reasons', 'title'],
  data() {
    return {
      options: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          height: '300px'
        },
        title: {
          text: this.title,
          style: {
            fontSize: '14px'
          }
        },
        tooltip: {
          pointFormat: '<b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            size: '80%',
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              alignTo: 'connectors',
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
              style: {
                fontSize: '12px'
              }
            }
          }
        },
        legend: {
          enabled: true,
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'bottom',
          itemStyle: {
            fontSize: '12px'
          }
        },
        credits: {
          enabled: false
        },
        series: [
          {
            colorByPoint: true,
            data: this.downtime_hour_reasons
          }
        ],
        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              chart: {
                height: '300px'
              },
              plotOptions: {
                pie: {
                  size: '60%'
                }
              },
              legend: {
                itemStyle: {
                  fontSize: '10px'
                }
              }
            }
          }]
        }
      }
    }
  }
}
</script>

<template lang="pug">
  VHighcharts(:options="options")
</template>
