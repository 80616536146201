<script>
export default {
  props: {
    portfolio: Array,
    industry: Array,
    portfolio_color: String,
    industry_color: String,
    portfolio_name: String,
  },
  data() {
    return {
      chart_options: {
        title: {
          text: null,
        },
        chart: {
          height: 300,
        },
        xAxis: {
          type: 'datetime',
        },
        yAxis: {
          min: null,
          title: null,
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [{
          name: this.portfolio_name,
          type: 'spline',
          data: this.portfolio,
          color: this.portfolio_color,
          marker: {
            radius: 0,
          }
        }, {
          name: 'Industry',
          type: 'spline',
          data: this.industry,
          color: this.industry_color,
          marker: {
            radius: 0,
          }
        }],
      }
    }
  },
}
</script>

<template lang="pug">
  v-highcharts(:options="chart_options")
</template>
