<script>
import OMDashboardHelpers from '../../mixins/om_dashboard_helpers'

export default {
  name: 'OperationalStatusHours',
  props: {
    values: Array,
    tech: String
  },
  mixins: [OMDashboardHelpers],
  data() {
    return { hidingNormal: false }
  },
  computed: {
    hasData() {
      return this.values.length > 0
    },
    opstatHours() {
      if (this.hidingNormal)
        return this.values.filter((v) => {
          return v[0] >= 40
        })
      else return this.values
    },
    filteredValues() {
      if (this.hidingNormal) {
        return this.values.filter((value) => value[0] >= 40) // Exclude everything except underperforming, no_data and outage
      }
      return this.values
    },

    summedSeries() {
      const byStatus = this.opstatHours.map((osh) => {
        return {
          y: osh[1],
          category: this.$t(`om_dashboard.status.${this.tech}.${this.operationalStatusTypes[osh[0]].title}`),
          hours: parseInt(osh[1]),
          color: this.operationalStatusTypes[osh[0]] ? this.operationalStatusTypes[osh[0]].color : '',
          minutes: this.calculateMinutes(osh[1]),
          isNormal: osh[0] === 10
        }
      })
      const summed = this.opstatHours.map((b) => b[1]).reduce((a, b) => a + b)
      return [
        byStatus.sort(function (a, b) {
          if (a.isNormal === b.isNormal) return a.y - b.y
          return a.isNormal ? -1 : 1
        }),
        {
          isSum: true,
          y: summed,
          hours: parseInt(summed),
          minutes: this.calculateMinutes(summed),
          category: this.$t('power_plants.total_hours'),
          color: '#999'
        }
      ].flat()
    },
    options() {
      let series = [
        {
          type: 'waterfall',
          name: this.$t('om_dashboard.drilldown.accumulated_hours_of_operational_statuses'),
          color: '#53B1FD',
          data: this.summedSeries,
          tooltip: {
            pointFormat: '{point.hours}h {point.minutes}m'
          }
        }
      ]
      return {
        title: { text: null },
        credits: { enabled: false },

        xAxis: {
          categories: this.summedSeries.map((i) => i.category),
          labels: {
            style: {
              color: '#00000061',
              fontFamily: 'Inter'
            }
          },
          reversed: true,
          title: { text: null }
        },
        yAxis: [
          {
            type: 'linear',
            title: { text: this.$t('om_dashboard.drilldown.hours') }
          }
        ],
        plotOptions: {
          waterfall: {
            pointWidth: 64,
            colorByPoint: true,
            colors: this.summedSeries.map((i) => i.color)
          }
        },
        legend: { enabled: false },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.y}%</b>'
        },
        series: series
      }
    }
  },
  methods: {}
}
</script>
<template lang="pug">
div(v-if="hasData")
  .d-flex
    .legend(style="width: 25%")
      div(style="float:left; height: 40px")
        v-switch(v-model="hidingNormal")
      div(style="float:left; height: 40px; padding-top: 4px; padding-left: 5px")
        p {{ $t('om_dashboard.drilldown.show_abnormal') }}

  v-highcharts(:options="options")
div(v-else)
  v-progress-circular(indeterminate)
</template>
