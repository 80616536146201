<script>
export default {
  props: {
    value: Array,
    form_data: Object,
    errors: Object,
  },
  data() {
    return {
      precipitation_fields: this.value,
    }
  },
  computed: {
    writable() {
      return this.form_data.permitted_attributes.find(a => a.asset_class_attributes).asset_class_attributes.find(a => a.precipitation_fields_attributes).precipitation_fields_attributes
    },
  },
  methods: {
    sharedProps(field) {
      return {
        name: `power_plant[asset_class_attributes][precipitation_fields_attributes][${field}]`,
        id: `power_plant_asset_class_attributes_precipitation_fields_attributes_${field}`,
        errorMessages: this.errors[`asset_class.precipitation_fields.${field}`],
        readonly: false,
        label: this.label(field),
        dense: true,
        outlined: true,
        persistentHint: true,
        hideDetails: 'auto',
        class: this.writable.includes(field) ? '' : 'd-none',
      }
    },
    label(field) {
      return this.$t(`activerecord.attributes.hydro/precipitation_field.${field}`)
    },
    addNew() {
      this.precipitation_fields.push({
        area: null,
        glacier_size_percent: null,
        swamp_size_percent: null,
        sea_size_percent: null,
        forest_size_percent: null,
        mountain_size_percent: null,
        avg_flow_area: null,
        field_length: null,
        lat: null,
        lng: null,
        aborigines: null,
        _destroy: false,
      })
    },
    destroy(precipitation_field) {
      if (precipitation_field.id) {
        precipitation_field._destroy = !precipitation_field._destroy
      } else {
        this.precipitation_fields.splice(this.precipitation_fields.indexOf(precipitation_field), 1)
      }
    },
  },
}
</script>
<template lang="pug">
#hydro-precipitation_fields
  .mb-2
    .overline.d-inline-block Precipitation fields
    v-btn.ml-4(@click="addNew" small)
      v-icon(left) mdi-plus
      | Add
  span(v-for="precipitation_field in precipitation_fields" :key="precipitation_field.id" :class="{'opacity-50': precipitation_field._destroy}")
    .d-flex.mb-2
      v-text-field.ml-1(v-model="precipitation_field.area" v-bind="sharedProps('area')" suffix="km^2")
      v-text-field.ml-1(v-model="precipitation_field.field_length" v-bind="sharedProps('field_length')" suffix="km")
      v-text-field.ml-1(v-model="precipitation_field.lat" v-bind="sharedProps('lat')")
      v-text-field.ml-1(v-model="precipitation_field.lng" v-bind="sharedProps('lng')")
      v-text-field.ml-1(v-model="precipitation_field.avg_flow_area" v-bind="sharedProps('avg_flow_area')" suffix="l/s")
    .d-flex.mb-2
      v-text-field.ml-1(v-model="precipitation_field.glacier_size_percent" v-bind="sharedProps('glacier_size_percent')" suffix="%" type="number" step=".1")
      v-text-field.ml-1(v-model="precipitation_field.swamp_size_percent" v-bind="sharedProps('swamp_size_percent')" suffix="%" type="number" step=".1")
      v-text-field.ml-1(v-model="precipitation_field.sea_size_percent" v-bind="sharedProps('sea_size_percent')" suffix="%" type="number" step=".1")
      v-text-field.ml-1(v-model="precipitation_field.forest_size_percent" v-bind="sharedProps('forest_size_percent')" suffix="%" type="number" step=".1")
      v-text-field.ml-1(v-model="precipitation_field.mountain_size_percent" v-bind="sharedProps('mountain_size_percent')" suffix="%" type="number" step=".1")      
    .d-flex.mb-2
      v-text-field.ml-1(v-model="precipitation_field.aborigines" v-bind="sharedProps('aborigines')")
      v-icon(color="error" @click="destroy(precipitation_field)") mdi-delete
</template>
