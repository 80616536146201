<script>
import NewsItemCard from './card.vue'

export default {
  components: {
    NewsItemCard
  },
  props: {
    news_item: Object,
    news_item_read: Boolean,
    comments_enabled: Boolean,
  },
  data() {
    return {
      dialog: false,
      unread: !this.news_item_read,
    }
  },
  watch: {
    dialog() {
      if(this.dialog && this.unread) {
        this.unread = false
        const url = `/news_items/${this.news_item.id}/mark_as_read`
        this.axios.post(url)
      }
    }
  }
}
</script>

<template lang="pug">
v-dialog(v-model='dialog' max-width="800px")
  template(v-slot:activator='{ on }')
    div(v-on='on')
      v-badge(v-model="unread" color='green' left transition='scale-transition' :content="$t('general.new').toUpperCase()" :bordered='true' offset-y='30')
        slot
  news-item-card(:news_item="news_item" :comments_enabled="comments_enabled")
    v-divider
    v-card-actions
      .flex-grow-1
      v-btn(color='grey' text='' @click='dialog = false') {{ $t('general.close') }}
</template>
