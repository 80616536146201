<script>
export default {
  props: {
    current_user: Object,
  },
  data() {
    return {
      menu_opened: false
    }
  },
  methods: {

  },
}
</script>
<template lang="pug">
v-menu#language-selector(v-model="menu_opened")
  template(v-slot:activator="{ on }")
    a(v-on="on")
      v-btn(color="orange" style="width: 36px; height: 36px" elevation="0" fab x-small)
        v-icon(color="white") mdi-help
  v-list#language-menu
    v-list-item(v-if="current_user.access_level != 'landowner'" href="/feature_requests/")
      v-icon() mdi-clipboard-text-outline
      .lang-name {{ this.$t('feature_requests.plural') }}
    v-list-item(href="mailto:support@captiva.no")
      v-icon() mdi-mail
      .lang-name {{ this.$t('feature_requests.mail_support') }}
</template>
