<script>
export default {
  name: 'VDatePickerInput',
  props: {
    value: {
      type: String,
      default: ''
    },

    id: String,
    name: String,
    label: String,
    hint: String,
    errors: Boolean,
    disabled: Boolean,
    errorMessages: Array,
  },
  data() {
    return {
      menu: false,
      date_value: null
    }
  },
  watch: {
    date_value: function() {
      this.menu = false;
      this.$emit('input', this.date_value);
    },
    value: function(newVal, oldVal) {
      this.date_value = newVal;
    },
  },
  mounted() {
    this.date_value = this.value;
  }
}
</script>

<template lang="pug">
v-menu(
  ref="menu"
  v-model="menu"
  :close-on-content-click="false"
  transition="scale-transition"
  offset-y
  min-width="auto"
  )
  template(v-slot:activator="{ on, attrs }")
    v-text-field(
      v-model="date_value"
      v-on="on"
      :label="label"
      :filled="true"
      :id="id"
      :name="name"
      :clearable="true"
      :disabled="disabled"
      )
  v-date-picker(
    v-model="date_value"
    no-title
    scrollable
    )
</template>
