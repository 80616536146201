<script>
import SensorRow from './sensor_row.vue'

export default {
  components: {
    SensorRow
  },
  props: {
    power_plant_id: String,
    sensor_types: Array
  },
  data () {
    return {
      dialog: false,
      loading: true,
      sensors: [],
      query: ''
    }
  },
  computed: {
    filtered_sensors () {
      return this.sensors.filter(s => s.name && s.name.toLowerCase().includes(this.query.toLowerCase()))
    }
  },
  methods: {
    async load_sensors () {
      try {
        this.sensors = []
        this.loading = true
        const url = `/admin/power_plants/${this.power_plant_id}/tyde_sensors`
        const { data } = await this.axios.get(url)
        this.sensors = data
        this.loading = false
      } catch(error) {
        alert((error.response || {}).statusText || 'Unexpected error')
      }
    }
  },
  watch: {
    dialog () {
      if (this.dialog) this.load_sensors()
    }
  }
}
</script>
<template lang="pug">
v-list-item(@click.stop="dialog = true") Sensor configuration
  v-dialog(v-model="dialog" content-class="auto-width sensor-mapping-dialog")
    v-card
      v-card-title.grey.lighten-2
        | Tyde sensor mapping
      v-card-text
        div(v-if="loading")
          | Loading Tyde sensors
          v-progress-linear(indeterminate)

        div(v-if="sensors.length > 0")
          .d-inline-block {{ sensors.length }} sensors
          v-text-field.d-inline-block.ml-2(v-model="query" placeholder="Name search" dense outlined autofocus)
          v-simple-table( dense)
            template(v-slot:default)
              thead
                tr
                  th
                    | Name
                  th
                    | O&M-dash mapping
                    v-tooltip(bottom)
                      template(v-slot:activator="{ on, attrs }")
                        v-icon.ml-1(v-bind="attrs" v-on="on" small) mdi-information-outline
                      span Determines which data source in the O&M dashboard this sensor represents
                  th
                    | Description
                    v-tooltip(bottom)
                      template(v-slot:activator="{ on, attrs }")
                        v-icon.ml-1(v-bind="attrs" v-on="on" small) mdi-information-outline
                      span Overrides the sensor description in the alarm list
                  th
              tbody
                sensor-row(v-for="(sensor, index) in filtered_sensors" :key="sensor.id" v-model="filtered_sensors[index]" :sensor_types="sensor_types")
  </template>
