<script>
import BasicMenuItem from './basic_menu_item.vue'
import NestedMenuItem from './nested_menu_item.vue'
import CompanySelector from './company_selector.vue'
import LanguageSelector from './language_selector.vue'
import FeatureRequestHeaderButton from '../feature_requests/header_button.vue'
import SettingsDrawer from './notification_settings/drawer.vue'

export default {
  components: {
    BasicMenuItem,
    NestedMenuItem,
    CompanySelector,
    LanguageSelector,
    FeatureRequestHeaderButton,
    SettingsDrawer
  },
  props: {
    current_user: Object,
    current_company: Object,
    logo_url: String,
    exception_count: Number,
    white_labeling: Boolean,
    staging: Boolean,
    hide_portfolio_selector: Boolean
  },
  data() {
    return {
      drawer: null,
      drawer_settings: false,
      companies: [],
      power_plants: [],
      expanded: false,
      editor_items: [
        {
          title: this.$t('navigation.main_menu.power_plants'),
          icon: 'mdi-factory',
          href: '/admin/power_plants'
        },
        {
          title: this.$t('navigation.main_menu.responsibles'),
          icon: 'mdi-contacts',
          href: '/admin/responsibles'
        },
        {
          title: this.$t('navigation.main_menu.elcert_and_goos'),
          icon: 'mdi-certificate-outline',
          href: '/admin/green'
        },
        {
          title: this.$t('navigation.main_menu.fall_lease'),
          icon: 'mdi-file-certificate-outline',
          href: '/admin/fall_leases',
          beta: true
        },
        {
          title: this.$t('navigation.main_menu.contracts'),
          icon: 'mdi-file-document-edit-outline',
          href: '/admin/contracts'
        },
        {
          title: this.$t('navigation.main_menu.users'),
          icon: 'mdi-account-supervisor-outline',
          href: '/admin/users'
        }
      ]
    }
  },
  computed: {
    admin_items() {
      return [
        {
          title: this.$t('navigation.main_menu.power_plants'),
          icon: 'mdi-factory',
          href: '/admin/power_plants'
        },
        {
          title: this.$t('navigation.main_menu.budget_forecasts'),
          icon: 'mdi-cash-multiple',
          href: `/admin/budget_forecasts?year=${new Date().getFullYear() + 1}`
        },
        {
          title: this.$t('navigation.main_menu.marketplace'),
          icon: 'mdi-cart',
          href: `/marketplace_products`
        },
        {
          title: this.$t('navigation.main_menu.companies'),
          icon: 'mdi-office-building',
          href: '/admin/companies'
        },
        {
          title: this.$t('navigation.main_menu.suppliers'),
          icon: 'mdi-dump-truck',
          href: '/admin/suppliers'
        },
        {
          title: this.$t('navigation.main_menu.components'),
          icon: 'mdi-saw-blade',
          href: '/admin/components'
        },
        {
          title: this.$t('navigation.main_menu.users'),
          icon: 'mdi-account-supervisor-outline',
          href: '/admin/users'
        },
        {
          title: this.$t('navigation.main_menu.responsibles'),
          icon: 'mdi-contacts',
          href: '/admin/responsibles'
        },
        {
          title: this.$t('navigation.main_menu.elcert_and_goos'),
          icon: 'mdi-certificate-outline',
          href: '/admin/green'
        },
        {
          title: this.$t('navigation.main_menu.nve_plants'),
          icon: 'mdi-police-badge-outline',
          href: '/admin/nve_plants'
        },
        {
          title: this.$t('navigation.main_menu.elhub'),
          icon: 'mdi-vector-polyline',
          href: '/admin/elhub_metering_points'
        },
        {
          title: this.$t('navigation.main_menu.api_access'),
          icon: 'mdi-api',
          href: '/admin/api_keys'
        },
        {
          title: this.$t('navigation.main_menu.api_docs'),
          icon: 'mdi-api',
          href: '/api-docs'
        },
        {
          title: this.$t('navigation.main_menu.tyde'),
          icon: 'mdi-api',
          href: '/admin/tyde'
        },
        {
          title: this.$t('navigation.main_menu.full_map'),
          icon: 'mdi-map',
          href: '/overview_map?all_plants=true'
        },
        {
          title: this.$t('navigation.main_menu.fall_lease'),
          icon: 'mdi-file-certificate-outline',
          href: '/admin/fall_leases',
          beta: true
        },
        {
          title: this.$t('navigation.main_menu.contracts'),
          icon: 'mdi-file-document-edit-outline',
          href: '/admin/contracts'
        }
      ]
    },
    dev_items() {
      return [
        {
          title: `Sentry Logs`,
          icon: 'mdi-tools',
          href: 'https://captiva-digital-solutions-as.sentry.io/issues/'
        },
        {
          title: 'Background jobs',
          icon: 'mdi-tools',
          href: '/admin/jobs'
        },
        {
          title: 'Import',
          icon: 'mdi-application-import',
          href: '/admin/import/new'
        },
        {
          title: 'Time series trackers',
          icon: 'mdi-chart-timeline-variant',
          href: '/admin/time_series_trackers'
        },
        {
          title: 'Data',
          icon: 'mdi-database',
          sub_items: this.data_items
        },
        {
          title: this.$t('navigation.main_menu.api_docs'),
          icon: 'mdi-api',
          href: '/api-docs'
        },
        {
          title: 'Vuetify docs',
          icon: 'mdi-tools',
          href: 'https://vuetifyjs.com/en/components/alerts/'
        },
        {
          title: 'MDI icons',
          icon: 'mdi-tools',
          href: 'https://materialdesignicons.com/'
        }
      ]
    },
    data_items() {
      return [
        {
          name: 'Volumes',
          href: '/admin/production_days'
        },
        {
          name: this.$t('navigation.main_menu.market_prices'),
          href: '/admin/market_prices'
        },
        {
          name: 'MET forecasts',
          href: '/admin/weather'
        }
      ]
    },
    isMobile() {
      return window.innerWidth < 950
    },
    shared_items() {
      return [
        ...[
          {
            title: this.$t('navigation.main_menu.dashboard'),
            icon: 'mdi-monitor-dashboard',
            href: '/',
            landowner: true,
            developer: true
          },
          {
            title: this.$t('navigation.main_menu.power_plants'),
            icon: 'mdi-factory',
            sub_items: this.power_plants,
            landowner: true,
            developer: true
          },
          {
            title: this.$t('navigation.main_menu.portfolio_stats'),
            icon: 'mdi-chart-bar',
            href: `/portfolio_stats?year=${new Date().getFullYear()}`,
            developer: true
          },
          {
            title: this.$t('navigation.main_menu.benchmarking'),
            icon: 'mdi-scale-balance',
            href: '/benchmarking'
          },
          this.current_company && this.current_company.production_simulation_plan_enabled
            ? {
                title: this.$t('navigation.main_menu.production_simulation_plan'),
                icon: 'mdi-trending-up',
                href: `/power_plants/${this.current_company.first_production_simulation_plan_power_plant_id}/production_simulation_plan`
              }
            : null,
          {
            title: this.$t('navigation.main_menu.availability'),
            icon: 'mdi-chart-timeline',
            href: '/downtime_periods',
            developer: true
          },
          {
            title: this.$t('navigation.main_menu.map'),
            icon: 'mdi-map',
            href: '/overview_map',
            landowner: true,
            developer: true
          },
          {
            title: this.$t('navigation.main_menu.market_prices'),
            icon: 'mdi-chart-bell-curve-cumulative',
            href: '/market_prices',
            landowner: true,
            developer: true
          },
          {
            title: this.$t('navigation.main_menu.market_outlook'),
            icon: 'mdi-wallet',
            href: '/market_outlook'
          },
          {
            title: "Commercial SCADA", //this.$t('navigation.main_menu.market_outlook'),
            icon: 'mdi-wallet',
            href: '/commercial_scada'
          },
          {
            title: this.$t('navigation.main_menu.task_management'),
            icon: 'mdi-check-decagram',
            href: '/work_items'
          },
          {
            title: this.$t('navigation.main_menu.om_dashboard'),
            icon: 'mdi-apps',
            href: '/om_dashboard',
            developer: true
          },
          {
            title: this.$t('navigation.main_menu.news_items'),
            icon: 'mdi-email-newsletter',
            href: '/news_items',
            landowner: true,
            developer: true
          },
          {
            title: this.$t('navigation.main_menu.pp_info'),
            icon: 'mdi-factory',
            href: `/power_plants/edit_all`
          },
          {
            title: this.$t('navigation.main_menu.feature_requests'),
            icon: 'mdi-clipboard-text-outline',
            href: `/feature_requests`,
            developer: true
          },
          {
            title: this.$t('navigation.main_menu.profile'),
            icon: 'mdi-account',
            href: `/profile`,
            landowner: true,
            developer: true
          },
          {
            title: this.$t('navigation.main_menu.data_export'),
            icon: 'mdi-file-excel-outline',
            href: '/data_export/new',
            developer: true
          },
          {
            title: this.$t('navigation.main_menu.log_out'),
            icon: 'mdi-logout',
            href: '/logout',
            landowner: true,
            developer: true
          }
        ].filter((el) => el !== null)
      ]
    },
    shared_items_filtered() {
      let items = this.shared_items

      if (this.landowner) items = items.filter((item) => item.landowner)
      if (this.developer) items = items.filter((item) => item.developer)
      return items
    },
    admin() {
      return this.current_user.access_level === 'admin'
    },
    developer() {
      return this.current_user.access_level === 'developer'
    },
    landowner() {
      return this.current_user.access_level === 'landowner'
    },
    portfolio() {
      return this.current_user.access_level === 'portfolio'
    },
    editor() {
      return this.current_user.access_level === 'editor'
    },
    benchmarking() {
      return (
        this.current_user.access_level === 'admin' ||
        (this.current_company && this.current_company.benchmarking)
      )
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {

    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    async loadCompanies() {
      if (this.companies.length > 0) return
      if (this.landowner) return
      const { data } = await this.axios.get('/companies')
      this.companies = data
    }, 
    toggleCard() {
      this.expanded = !this.expanded;
    },
    handleResize() {
      const newIsMobile = window.innerWidth < 950;

      if (this.isMobile !== newIsMobile) {
        location.reload();
      }
    },
  },
  watch: {
    async drawer() {
      if (this.power_plants.length > 0) return
      const { data } = await this.axios.get('/power_plants')
      this.power_plants = data
    }
  },
  created() {
    this.loadCompanies()
  }
}
</script>

<template lang="pug">
#navigation
  v-app-bar(app)
    v-app-bar-nav-icon(@click.stop="drawer = !drawer")
    v-toolbar-title#portal
      a.nostyle.mr-2(v-if="!white_labeling" href="/") Captiva portal
    template(v-if="!isMobile")
      slot(name='country')
      company-selector(v-if="companies.length > 1 && !hide_portfolio_selector" :companies="companies" :current_user="current_user" :current_company="current_company")
      slot

    .flex-grow-1
    v-badge#staging-badge(v-if="staging" color='red' content='STAGING' :offset-y="45" :offset-x="-34")
    img#logo(:src="logo_url", style="max-height: 60%")
    language-selector(:current_user="current_user")
    .mr-2
    feature-request-header-button(:current_user="current_user")
    v-app-bar-nav-icon(@click.stop="drawer_settings = !drawer_settings")
      v-icon mdi-cog

  v-navigation-drawer.pb-4(v-model='drawer' app temporary)
    v-list-item
      v-list-item-avatar
        v-img(:src='current_user.avatar_url')
      v-list-item-content
        v-list-item-title {{ current_user.name }}
    v-list-item(v-if="isMobile")
      v-list-item-content
        div#navbarfilter
          v-icon {{ 'mdi-filter' }}
          button.btn-toggle-card(@click="toggleCard" icon) Filters
          v-icon {{ expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        v-card(v-if="expanded")
          slot(name='country')
          company-selector(v-if="companies.length > 1 && !hide_portfolio_selector" :companies="companies" :current_user="current_user" :current_company="current_company") 
          slot     
    v-divider

    v-list(dense)
      component(v-for='item in shared_items_filtered' v-bind:is="item.sub_items ? 'nested-menu-item' : 'basic-menu-item'" :item="item" :key="item.href")

    div(v-if="admin")
      v-divider
      v-list(dense)
        v-subheader.px-4.grey--text.text--darken-1 ADMIN
        component(v-for='item in admin_items' v-bind:is="item.sub_items ? 'nested-menu-item' : 'basic-menu-item'" :item="item" :key="item.href")

    div(v-if="editor")
      v-divider
      v-list(dense)
        v-subheader.px-4.grey--text.text--darken-1 EDITOR
        basic-menu-item(v-for='item in editor_items' :item="item" :key="item.href")

    div(v-if="admin || developer")
      v-divider
      v-list(dense)
        v-subheader.px-4.grey--text.text--darken-1 DEV
        basic-menu-item(v-for='item in dev_items' v-bind:is="item.sub_items ? 'nested-menu-item' : 'basic-menu-item'" :item="item" :key="item.href")

  settings-drawer( :show="drawer_settings" :current_user="current_user" @update:show="drawer_settings = $event")
</template>
