export const markerLayerDefaults = {
  id: 'markerLayer',
  type: 'symbol',
  layout: {
    'text-field': ['get', 'name'],
    'text-offset': [0, 1],
    'icon-image': 'hydro',
    'text-anchor': 'top',
    'text-justify': 'auto',
    'icon-allow-overlap': true,
    'text-allow-overlap': true
  },
  paint: {
    'icon-color': '#e91e63',
    'text-color': '#FFFFFF'
  }
}
