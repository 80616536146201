<script>
export default {
  props: {
    value: Object,
    errors: Object,
    form_data: Object,
    col_props: Object,
  },
  data() {
    return {
      data: this.value,
    }
  },
  computed: {
    writable() {
      return this.form_data.permitted_attributes.find(a => a.nve_metadata_attributes).nve_metadata_attributes
    },
  },
  methods: {
    sharedProps(field) {
      return {
        name: `power_plant[nve_metadata_attributes][${field}]`,
        id: `power_plant_nve_meta_data_attributes_${field}`,
        errorMessages: this.errors[`nve_metadata.${field}`],
        readonly: false,
        label: this.label(field),
        dense: true,
        outlined: true,
        persistentHint: true,
        hideDetails: 'auto',
        class: `mb-2 ${this.writable.includes(field) ? '' : 'd-none'}`,
      }
    },
    label(field) {
      return this.$t(`activerecord.attributes.nve_metadata.${field}`)
    },
  },
}
</script>

<template lang="pug">
v-container.px-0(fluid)
  v-row
    v-col(v-bind="col_props")
      v-date-picker-input(v-model="data.date_oldest_power_producing_part" v-bind="sharedProps('date_oldest_power_producing_part')" :filled="false")
      v-date-picker-input(v-model="data.date_inital_utilization_of_waterfall" v-bind="sharedProps('date_inital_utilization_of_waterfall')" :filled="false")
      v-text-field(v-model="data.regine_nr" v-bind="sharedProps('regine_nr')")
      v-text-field(v-model="data.precipitation_area" v-bind="sharedProps('precipitation_area')")
    v-col(v-bind="col_props")
      v-text-field(v-model="data.min_throughput" v-bind="sharedProps('min_throughput')")
      v-searchable-select(v-model="data.nve_production_downstream_powerplant" v-bind="sharedProps('nve_production_downstream_powerplant')" :items="form_data.nve_plant_options")
      v-searchable-select(v-model="data.nve_floodwater_downstream_powerplant" v-bind="sharedProps('nve_floodwater_downstream_powerplant')" :items="form_data.nve_plant_options")
      v-searchable-select(v-model="data.nve_bypasswater_downstream_powerplant" v-bind="sharedProps('nve_bypasswater_downstream_powerplant')" :items="form_data.nve_plant_options")
    v-col(v-bind="col_props")
      v-text-field(v-model="data.sp_nr_watercourse" v-bind="sharedProps('sp_nr_watercourse')")
      v-text-field(v-model="data.sp_nr_point_reference" v-bind="sharedProps('sp_nr_point_reference')")
      v-select(v-model="data.building_type" v-bind="sharedProps('building_type')" :items="form_data.nve_building_types")
      v-select(v-model="data.powerplant_type" v-bind="sharedProps('powerplant_type')" :items="form_data.nve_power_plant_types")
    v-col(v-bind="col_props")
      v-text-field(v-model="data.energy_equivalent" v-bind="sharedProps('energy_equivalent')")
</template>
