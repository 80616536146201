<script>
import VolumeWaterChart from './charts/volume_and_water.vue'
import ReasonChart from './charts/reasons.vue'
import DowntimeTable from './table.vue'
import AssetComment from './asset_comment.vue'
import CreateAssetComment from './create_asset_comment.vue'

export default {
  components: {
    VolumeWaterChart,
    ReasonChart,
    DowntimeTable,
    AssetComment,
    CreateAssetComment
  },
  props: {
    all_power_plants: Boolean,
    totals: Object,
    scheduled_downtime_totals: Object,
    total_pages: Number,
    page: {
      type: Number,
      default: 1
    },
    power_plant: Object,
    power_plants: Array,
    downtime_periods: Array,
    scheduled_downtime_periods: Array,
    volume_series: Object,
    water_level_series: Array,
    unit: String,
    name: String,
    upper_threshold: Number,
    lower_threshold: Number,
    stats_reasons: Object,
    asset_comments: Array,
    available_asset_comment_periods: Array,
    currency_code: String,
    reasons_scheduled: Array
  },
  data() {
    return {
      isDataReady: false,
      selectedPowerplant: '',
      selectedAreaFrom: null,
      selectedAreaTo: null,
      zoomFrom: null,
      zoomTo: null,
      assetCommentsData: this.asset_comments,
      showAssetComments: true,
      showCreateAssetComment: false
    }
  },
  computed: {
    assetCommentsByPowerPlant() {
      return this.groupAssetCommentsByPowerPlant(this.assetCommentsData)
    },
    isHydroAsset() {
      return this.power_plant.asset_class_humanized === 'hydro';
    },
    hasMonthlyReasons() {
      return this.stats_reasons.month && this.stats_reasons.month.length > 0;
    },
    hasYearlyReasons() {
      return this.stats_reasons.year && this.stats_reasons.year.length > 0;
    },
  },
  mounted() {
    this.isDataReady = true
  },
  methods: {
    groupAssetCommentsByPowerPlant(assetComments) {
      let result = []
      assetComments.forEach((asset_comment) => {
        let existing = result.find((a) => a.power_plant === asset_comment.power_plant_name)
        if (existing) {
          existing.items.push(asset_comment)
        } else {
          result.push({ power_plant: asset_comment.power_plant_name, items: [asset_comment] })
        }
      })
      return result
    },
    getTurbineEffect() {
      if (this.all_power_plants) return null
      return this.power_plant.rated_power_mw
    },
    getMax() {
      if (this.all_power_plants) return null
      return this.power_plant.max_effect_or_external_limitation
    },
    filter(checked, item) {
      const url = new URL(window.location)
      if (checked) url.searchParams.set(item, true)
      else url.searchParams.delete(item)
      location.href = url.toString()
    },
    getParams() {
      const urlParams = new URLSearchParams(window.location.search)
      const withoutNoWater = urlParams.get('no_water')
      return { withoutNoWater }
    },
    setRange(event) {
      if (event.xAxis) {
        const { min, max } = event.xAxis[0]
        this.selectedAreaFrom = min
        this.selectedAreaTo = max
      }
    }
  }
}
</script>

<template lang="pug">
div
  v-card(v-if="assetCommentsData.length > 0" elevation="2" style="z-index: 2; position: fixed; right: 5px; top: 55px; width: 350px")
    h4.py-1.px-2.ma-2.mt-4.d-flex(style="cursor: pointer" @click="showAssetComments = !showAssetComments")
      | {{ $t('asset_comments.title') }}
      v-icon.ml-auto {{ showAssetComments ? 'mdi-menu-up' : 'mdi-menu-down' }}
    v-expand-transition
      div(v-if="all_power_plants")
        div.px-1.pb-1.pt-1(v-if="showAssetComments")
          div(v-for="(forPlant, index) in assetCommentsByPowerPlant")
            h3.ml-4 {{ forPlant.power_plant }}
            AssetComment(
              v-for="(item, index2) in forPlant.items"
              :key="item.id"
              v-model="assetCommentsByPowerPlant[index].items[index2]"
            )
      div(v-else)
        div.px-1.pb-1.pt-1(v-if="showAssetComments")
          AssetComment(
            v-if="isDataReady"
            v-for="(item, index) in assetCommentsData"
            :key="item.id"
            v-model="assetCommentsData[index]"
          )

  CreateAssetComment(
    v-if="isDataReady"
    v-model="showCreateAssetComment"
    :assetComments="assetCommentsData"
    @change="v => assetCommentsData = v"
    :power_plants="power_plants"
    :power_plant="power_plant"
    :availableAssetCommentPeriods="available_asset_comment_periods"
  )
  v-btn(
    v-if="!showCreateAssetComment"
    @click="showCreateAssetComment = true"
    color="purple"
    title="Add new asset comment"
    id="create-asset-comment"
    style="bottom: 120px"
    fixed
    dark
    fab
    right
  )
    v-icon(large) mdi-message-plus

  v-container.mt-2#downtime_periods
    v-card
      v-row.ma-0
        v-col.pl-0#prod_and_downtime(cols="8")
          VolumeWaterChart(
            v-if="volume_series && isDataReady"
            :volume_series="volume_series"
            :water_series="water_level_series"
            :rated_power_mw="getTurbineEffect()"
            :max_effect="getMax()"
            :setRange="setRange"
            :zoomFrom="zoomFrom"
            :zoomTo="zoomTo"
            :unit="' ' + $t('downtime_periods.water_level_unit')"
            :upper_threshold="upper_threshold"
            :lower_threshold="lower_threshold"
          )
          v-row.pa-8
            v-checkbox(
              :label="$t('downtime_periods.filter_out_no_water')"
              v-model="getParams().withoutNoWater === 'true'"
              @change="(checked) => filter(checked, 'no_water')"
            )
        v-col.pl-0#pie_charts(cols="4")
          v-row.pt-3#chart_monthly_downtime(
            v-if="hasMonthlyReasons || hasYearlyReasons"
            :class="{'justify-center': !hasMonthlyReasons || !hasYearlyReasons}"
          )
            ReasonChart(
              v-if="isDataReady && hasMonthlyReasons"
              :title="$t('downtime_periods.chart_monthly_heading')"
              :downtime_hour_reasons="stats_reasons.month"
            )
            ReasonChart(
              v-if="isDataReady && hasYearlyReasons"
              :title="$t('downtime_periods.chart_yearly_heading')"
              :downtime_hour_reasons="stats_reasons.year"
            )
    v-row.ma-0
      v-col.pa-0
        DowntimeTable(
          v-if="isDataReady"
          :all_power_plants="all_power_plants"
          :power_plant="power_plant"
          :totals="totals"
          :scheduled_downtime_totals="scheduled_downtime_totals"
          :total_pages="total_pages"
          :page="page"
          :power_plants="power_plants"
          :downtime_periods="downtime_periods"
          :scheduled_downtime_periods="scheduled_downtime_periods"
          :reasons_scheduled="reasons_scheduled"
          :selectedAreaFrom="selectedAreaFrom"
          :selectedAreaTo="selectedAreaTo"
          :zoomFrom.sync="zoomFrom"
          :zoomTo.sync="zoomTo"
          :currency_code="currency_code"
        )
</template>
