<script>
export default {
  props: {
    power_plant_id: Number,
    suppliers: Array,
    roles: Array,
  },
  data () {
    return {
      dialog: false,
      selected_role_id: null,
      selected_supplier: null,
      selected_category: null,
      new_supplier: false,
      power_plant_suppliers: [],
      new_supplier_name: '',
      search_supplier: '',
    }
  },
  computed: {
    filtered_suppliers() {
      let suppliers = this.suppliers
      if (this.search_supplier.length > 0) {
        suppliers = this.suppliers.filter(supplier => supplier.name.toLowerCase().includes(this.search_supplier.toLowerCase()))
      }

      if (this.selected_category) {
        return suppliers.filter(supplier => supplier.category === this.selected_category)
      } else {
        return suppliers
      }
    },
    categories() {
      return this.suppliers.map(supplier => { return {value: supplier.category, text: supplier.category_name}})
        .filter((value, index, self) => self.findIndex(t => t.value === value.value) === index)
    },

    endpoint () { return `/power_plants/${this.power_plant_id}/power_plant_suppliers` },
    saveable () { return this.selected_role_id && (this.selected_supplier || this.new_supplier_name.length > 0) },
  },
  methods: {
    async save () {
      const data = {
        power_plant_supplier: {
          supplier_id: (this.selected_supplier || {}).id,
          supplier_role_id: this.selected_role_id,
          supplier_attributes: {
            name: this.new_supplier_name,
            category: this.new_supplier_name.length > 0 ? this.selected_category : null,
          },
        }
      }
      try {
        const response = await this.axios.post(this.endpoint, data)
        this.power_plant_suppliers = response.data
        this.dialog = false
      } catch (error) {
        alert(error.response.data.errors.join('\n'))
      }
    },
    async remove (pps) {
      if(!confirm('Are you sure?')) return
      const url = `${this.endpoint}/${pps.id}`
      const response = await this.axios.delete(url)
      this.power_plant_suppliers = response.data
    },
    addSupplier () {
      this.selected_supplier = null
      this.new_supplier = true
    },
    reset () {
      this.selected_role_id = null
      this.selected_supplier = null
      this.new_supplier = false
      this.new_supplier_name = ''
    },
    showCategories() {
      return this.categories.length > 1
    },
    categoryColor(category) {
      switch(category) {
        case 'equipment_supplier':
          return 'green'
        case 'consultant':
          return 'orange'
        case 'grid_owner':
          return 'blue'
        case 'association':
          return 'purple'
        case 'journalist':
          return 'red'
        default:
          return 'grey'
      }
    }
  },
  watch: {
    dialog () { if(this.dialog) this.reset() },
  },
  async mounted () {
    this.reset()
    const response = await this.axios.get(this.endpoint)
    this.power_plant_suppliers = response.data
  },
}
</script>

<template lang="pug">
#power-plant-suppliers
  h2
    | {{ $t('power_plants.supplier_form_title') }}
    v-btn(@click="dialog = true" x-small) {{ $t('general.add_new') }}

  div(v-for="pps in power_plant_suppliers")
    v-icon(@click="remove(pps)" title="Delete" style="vertical-align:baseline" small) mdi-trash-can-outline
    b {{ pps.supplier.name }}
    v-chip.capitalize(color="indigo darken-3" outlined x-small) {{ pps.supplier_role.name }}

  v-dialog(v-model='dialog' max-width='1200px')
    v-card#supplier-dialog
      v-card-title.headline.grey.lighten-2(primary-title='')
        | {{ $t('power_plants.supplier_add') }}
      v-card-text
        v-select(v-model="selected_role_id" :items="roles", item-value="id" item-text="name" :label="$t('activerecord.attributes.supplier.type')" hide-details dense outlined)

        div(v-if="!new_supplier")
          .d-flex.align-center.mt-2
            h4.mr-4 Select Supplier
            v-text-field.mr-2(v-model="search_supplier" :label="$t('general.search')" hide-details dense outlined)
            v-select.mr-2(v-if="showCategories()" v-model="selected_category" :items="categories" :label="$t('activerecord.attributes.supplier.category')" hide-details dense outlined clearable)
            v-btn.mr-2(v-if="!new_supplier" @click="addSupplier()") {{ $t('activerecord.attributes.supplier.add_new') }}

          v-radio-group#supplier-list(v-model="selected_supplier")
            v-radio(v-for="supplier in filtered_suppliers" :value="supplier" :key="supplier.id")
              template(v-slot:label)
                | {{ supplier.name }}
                v-chip(v-if="showCategories()" :color="categoryColor(supplier.category)" small dark) {{ supplier.category_name }}

        div(v-if="new_supplier && selected_role_id")
          v-select.mt-1(v-model="selected_category" :items="categories" :label="$t('activerecord.attributes.supplier.category')" hide-details dense outlined)
          v-text-field.mt-1(v-if="new_supplier" v-model="new_supplier_name" ref="new_supplier_name" type="text" placeholder="Name of new supplier" hide-details dense outlined)
      v-card-actions
        v-btn(color="primary" @click="save" :disabled="!saveable") {{ $t('general.save') }}
        v-spacer
        v-btn(v-if="!new_supplier" color='grey' text='' @click='dialog = false') {{ $t('general.cancel') }}
        v-btn(v-else="new_supplier" color='gray' text='' @click='new_supplier = false') {{ $t('general.back') }}
</template>
