<script>
export default {
  props: {
    user: {
      type: Object,
      required: true
    },
    users: {
      type: Array,
      required: true
    },
    recently_active: {
      type: Number,
      required: true
    },
    company_and_powerplant_names: {
      type: Array,
      required: true
    },
    access_levels: {
      type: Array,
      required: true
    },
    companies: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      sort_by: 'firstname',
      sort_dir: -1,
      loading: false,
      all_loaded: false,
      loaded_users: this.users,
      query: '',
      company_id: '',
      currentPage: 1,
      itemsPerPage: 15,
      companyOptions: this.companies.map(company => ({ name: company[0], id: company[1] }))
                      .sort((a, b) => a.name.localeCompare(b.name)),
      headers: [
        { text: '', value: 'avatar' },
        { text: '#', value: 'id' },
        { text: this.$t("general.first_name"), value: 'firstname' },
        { text: this.$t("general.last_name"), value: 'lastname' },
        { text: this.$t("general.email"), value: 'email' },
        { text: this.$t("general.login_failures"), value: 'failed_login_attempts' },
        { text: this.$t("general.last_activity"), value: 'last_request_at' },
        { text: this.$t("general.access_rights"), value: 'access_level' },
        { text: this.$t("general.company_access"), value: 'companies_and_power_plants' },
        { text: '', value: 'edit' }
      ]
    }
  },
  computed: {
    exportUrl() {
      return window.location.href.split('?')[0] + ".csv"
    },
    sorted_users() {
      let filtered = this.loaded_users || []
      if (this.query) {
        const searchWords = this.query.trim().toLowerCase().split(/\s+/).filter(word => word)

        filtered = filtered.filter(user => {
          const userString = [
            user.firstname.toLowerCase(),
            user.lastname.toLowerCase(),
            user.email.toLowerCase(),
            user.access_level.toString().toLowerCase()
          ].join(' ')

          return searchWords.every(word => userString.includes(word))
        })
      }
      return filtered
    },
    usersWithCompanies() {
      return this.sorted_users.map(user => {
        const index = this.users.findIndex(originalUser => originalUser.id === user.id);

        return {
          ...user,
          company_and_powerplant_name: this.company_and_powerplant_names[index]
        };
      });
    },
  },
  methods: {
    newAdminUserPath() {
      return `/admin/users/new`
    },
    navigateToNewAdminUser() {
      window.location.href = this.newAdminUserPath()
    },
    clearSearch() {
      this.query = ''
    },
    editAdminUserPath(user) {
      window.location.href = `/admin/users/${user.id}/edit`
    },
    accessLevelColor(level) {
      switch(level) {
        case 'admin': return 'orange'
        case 'editor': return 'default'
        case 'developer': return 'green'
        case 'portfolio': return 'blue'
        case 'landowner': return 'purple'
        default: return 'default'
      }
    },
    getChipText(level) {
      return this.$t(`general.access_level.${level}`)
    },
    getRowClass(item) {
    return item.deactivated ? 'grey-row' : ''
    },
    timeAgoInWords(dateString) {
      if (!dateString) {
        return ''
      }

      const MINUTE = 60
      const HOUR = 3600
      const DAY = 86400
      const MONTH = 2592000
      const YEAR = 31536000

      const pluralize = (num, unit) => `${num} ${unit}${num === 1 ? '' : 's'} ago`

      const date = new Date(dateString)
      const now = new Date()
      const secondsPast = (now.getTime() - date.getTime()) / 1000

      if (secondsPast < MINUTE) return 'less than a minute'
      if (secondsPast < HOUR) return pluralize(parseInt(secondsPast / MINUTE), 'minute')
      if (secondsPast < DAY) return pluralize(parseInt(secondsPast / HOUR), 'hour')
      if (secondsPast < MONTH) return pluralize(parseInt(secondsPast / DAY), 'day')
      if (secondsPast < YEAR) return pluralize(parseInt(secondsPast / MONTH), 'month')

      return pluralize(parseInt(secondsPast / YEAR), 'year')
    },
  },
}
</script>

<template  lang="pug">
v-app
  v-container
    .custom-tabs
      a.custom-tab.custom-tab--active(href="/admin/users" id="users-tab") {{ $t('page_hits.admin.tabs.users') }}
      a.custom-tab(href="/admin/page_hits" id="page-hits-tab") {{ $t('page_hits.admin.tabs.page_hits') }}
    br
    v-row(align="center")
      v-col(cols="auto")
        h1 {{ users.length }} {{ $t('users.users') }}
          v-icon(tag="a" :href="exportUrl" color="green") mdi-file-excel
      v-col(cols="auto")
        v-chip.font-weight-regular(color="green" text-color="white")
          v-avatar(class="green darken-4" left="")
            | {{ recently_active }}
          | Active 24hrs
    v-form(method="get")
      v-row.mb-5
        v-col
          v-text-field.mr-3(
            v-model="query"
            append-icon="mdi-magnify"
            :append-inner-icon="query ? 'mdi-close' : ''"
            @click:append-inner="clearSearch"
            name="query",
            :label="$t('general.search')"
            single-line
            :placeholder="$t('general.search_by')",
            hide-details="auto",
            clearable,
          )
        v-col(class="d-flex align-center")
          v-autocomplete.mr-3(
            :items="companyOptions"
            item-text="name"
            item-value="id"
            v-model="company_id"
            :label="$t('general.filter_by_company')"
            name="company_id"
            hide-details="auto"
            clearable
          )
        v-col
          v-btn.small(type="submit") {{ $t('general.search') }}
    v-card#admin-users-table
      v-data-table(
        :headers="headers"
        :items="usersWithCompanies"
        :items-per-page="itemsPerPage"
        :item-class="getRowClass"
        sort-by="last_request_at"
        :sort-desc="true"
        @update:page="currentPage = $event"
        @update:items-per-page="itemsPerPage = $event"
      )
        template(v-slot:item.last_request_at="{ item }")
          | {{ timeAgoInWords(item.last_request_at) }}
        template.avatar-img(v-slot:item.avatar="{ item }")
          v-img.avatar-img(:src="item.avatar.url" alt="User Avatar" :width="40" :height="40"  :style="{ borderRadius: '50%' }")
        template(v-slot:item.access_level="{ item }")
          v-chip(:color="accessLevelColor(item.access_level)"  dark small) {{ getChipText(item.access_level) }}
        template(v-slot:item.companies_and_power_plants="{ item }")
          | {{ item.company_and_powerplant_name }}
        template(v-slot:item.failed_login_attempts="{ item }")
          | {{ item.failed_login_attempts }}&nbsp;
          v-chip(v-if="item.failed_login_attempts >= 10" color="error" small) Locked
        template(v-slot:item.edit="{ item }")
          v-btn(
            v-if="user.access_level === 'admin'"
            color="primary"
            small
            @click="editAdminUserPath(item)"
            id="edit_user"
          ) {{ $t("general.edit") }}
      v-btn(
        v-if="user.access_level === 'admin'"
        fixed
        color="light-blue"
        id="add_user"
        dark
        fab
        right
        bottom
        @click="navigateToNewAdminUser")
        v-icon(large) mdi-plus
</template>
