<script>
export default {
  props: {
    power_plant: Object
  },
  data() {
    return {
      gaugeOptions: {
        chart: {
          type: 'solidgauge',
          height: 200,
          marginTop: 0
        },
        title: null,
        credits: {
          enabled: false
        },
        pane: {
          center: [126, 130],
          size: 253,
          startAngle: -90,
          endAngle: 90,
          background: {
            backgroundColor: this.power_plant.current_effect > 0 ? '#EEE' : 'red',
            innerRadius: '60%',
            outerRadius: '100%',
            shape: 'arc'
          }
        },
        exporting: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        yAxis: {
          min: 0,
          max: this.power_plant.rated_power_mw,
          tickPositions: [0, this.power_plant.rated_power_mw],
          stops: [
            [1, '#55BF3B'] // green
          ],
          lineWidth: 0,
          tickWidth: 0,
          minorTickInterval: null,
          tickAmount: 2,
          title: {
            y: -70
          },
          labels: {
            y: 20,
            formatter: function () {
              if (this.value > 0) {
                return '<span class="basic-limit">' + this.value.toFixed(2) + '</span>'
              } else {
                return '<span class="basic-limit">0</span>'
              }
            }
          }
        },
        plotOptions: {
          solidgauge: {
            dataLabels: {
              y: 5,
              borderWidth: 0,
              useHTML: true
            }
          }
        },
        series: [
          {
            data: [this.power_plant.current_effect],
            dataLabels: {
              format:
                `<div class="v-avatar v-list-item__avatar basic-logo" style="height: 136px; min-width: 136px; width: 136px;"><div style="border-color: ${this.power_plant.asset_color}" class="v-image v-responsive theme--light asset-class"><div class="v-responsive__sizer" style="padding-bottom: 65.7303%;"></div><div class="v-image__image v-image__image--cover" style="background-image: url(&quot;` +
                this.power_plant.image_url +
                '&quot;); background-position: center center;"></div><div class="v-responsive__content" style="width: 356px;"></div></div></div>'
            }
          }
        ]
      }
    }
  }
}
</script>
<template lang="pug">
.element.mt-3.mx-1
  div
    span.center(style='width: 100%')
      .effect {{ this.power_plant.current_effect_human || 0 }} / {{ this.power_plant.installed_effect_human }}
    v-highcharts.mt-0.pt-0(:options="gaugeOptions")
    v-container
      v-row.pt-0
        v-col.income.py-0.px-0.left(cols=7) {{ this.power_plant.current_income_human }}
        v-col.px-0.py-0(cols=2)
          v-img.ml-auto(v-if="this.power_plant.met_temp" eager :src="this.power_plant.met_icon" :max-height="30" :max-width="30")
        v-col.right.income.px-0.py-0(cols=3) {{ this.power_plant.met_temp ? this.power_plant.met_temp.toFixed(1) + '°' : ' ' }}
</template>
