<script>
export default {
  name: 'KPI',
  props: {
    kpis: Object,
    field: String,
    tooltips: {
      type: Array,
      default: () => []
    },
    kpiLabel: String,
    unit: String,
    invertArrows: false,
    showPercentage: false
  },
  data() {
    return {
      extraData: this.tooltips || [],
      requested: this.kpis.requested[this.field],
      historical: this.kpis.historical[this.field]
    }
  },
  watch: {
    kpis() {
      this.requested = this.kpis.requested[this.field]
      this.historical = this.kpis.historical[this.field]
    }
  },
  computed: {
    tagColour() {
      if (this.historical) {
        if (this.requested - this.historical > 0) return this.invertArrows ? 'negative' : 'positive'
        else if (this.requested - this.historical === 0) return 'same'
        else return this.invertArrows ? 'positive' : 'negative'
      }
    },
    icon() {
      if (this.tagColour === 'positive') return this.invertArrows ? 'mdi-arrow-down' : 'mdi-arrow-up'
      else if (this.tagColour === 'negative') return this.invertArrows ? 'mdi-arrow-up' : 'mdi-arrow-down'
      else if (this.tagColour === 'same') return 'mdi-checkbox-blank-circle-outline'
    }
  },
  methods: {
    getDifference(current, old) {
      if (old) {
        if (this.showPercentage) return `${parseFloat(((current - old) / old) * 100).toFixed(2)}%`
        else return `${parseFloat(current - old).toFixed(2)} ${this.unit}`
      }
    },
    getColor(diff) {
      if (diff > 0) return this.invertArrows ? 'negative' : 'positive'
      else return this.invertArrows ? 'positive' : 'negative'
    },
    assembleTooltip() {
      let tooltipOutText = ''
      this.extraData.forEach((field) => {
        let translation = `om_dashboard.drilldown.${field}`
        if (this.kpis.requested[field] !== undefined) {
          tooltipOutText += `${this.$t(translation)}: ${this.kpis.requested[field]}<br />`
        } else {
          tooltipOutText += `${this.$t(translation)}<br />`
        }
      });
      return tooltipOutText;
    }
  }
}
</script>
<template lang="pug">
.om_kpi
  .kpi_name {{ kpiLabel }}
  .value(v-if="requested === null || requested === undefined")
    | --
  div(v-else)
    v-tooltip(v-if="extraData.length > 0" top)
      template(v-slot:activator="{ on }")
        .value(v-on="on") {{ parseFloat(requested).toFixed(2) }}{{ unit }}
      span(v-html="assembleTooltip()")
    .value(v-else) {{ parseFloat(requested).toFixed(2) }}{{ unit }}
  v-tooltip(v-if="historical && requested" top)
    template(v-slot:activator="{ on }")
      .change_tag(v-on="on" :class="tagColour")
          v-icon {{ icon }}
          span(v-if="tagColour === 'same'") {{ $t('om_dashboard.status.hydro.no_change') }}
          span(v-else) {{  getDifference(requested, historical) }}
    span {{ parseFloat(historical).toFixed(2) }} {{ unit }} {{ $t('om_dashboard.status.hydro.one_year_ago') }}
</template>
