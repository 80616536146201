<script>

export default {
  props: {
    persisted_companies: Array,
    all_companies: Array,
  },
  data() {
    return {
      selected_companies: this.persisted_companies,
      dialog: false,
      query: '',
    }
  },
  computed: {
    selectable_companies() {
      const unselected = this.all_companies.filter(c => !this.selected_companies.map(s => s.id).includes(c.id))
      return unselected.filter( (x) => x.name.toLowerCase().includes(this.query.toLowerCase()) )
    },
  },
  methods: {
    add(company) {
      this.selected_companies.push(company)
      this.query = ''
      this.dialog = false
    },
    remove(company) {
      this.selected_companies = this.selected_companies.filter(s => s.id != company.id)
    },
    focusInput() {
      setTimeout(()=>{
        this.$refs.query.focus()
      },300);
    },
  },
  watch: {
    dialog() {
      if(this.dialog) this.focusInput()
    }
  }
}
</script>

<template lang="pug">
#parent-companies
  input(type="hidden" name="company[parent_company_ids][]")
  #selected-companies(v-if="selected_companies.length > 0")
    h3 {{ $t("parent_companies") }}
    .selected(v-for="company in selected_companies")
      v-btn(@click="remove(company)" elevation="2" x-small icon)
        v-icon mdi-close
      input(type="hidden" name="company[parent_company_ids][]" v-model="company.id")
      | {{ company.name }}
      br

  v-dialog(v-model="dialog" max-width="500px" scrollable)
    template(v-slot:activator="{ on, attrs }")
      v-btn(v-bind="attrs" v-on="on" x-small) {{ $t("add_parent_company") }}
    v-card
      v-card-title {{ $t("select_parent_company") }}
      v-divider
      v-card-text
        v-text-field(v-model="query" placeholder="Filter" ref="query")
        div(v-for="company in selectable_companies")
          v-btn(@click="add(company)" x-small) {{$t("general.select")}}
          | &nbsp;{{ company.name }}
          span(v-if="company.root_node") &nbsp;(top-level)
</template>
