<script>
export default {
  props: {
    outage_count: Number,
    total_count: Number
  },
  data() {
    return {}
  },
  computed: {
    value() {
      if (this.total_count > 0) return (this.outage_count / this.total_count) * 100
      else return 0
    }
  }
}
</script>

<template lang="pug">
v-col.top-widget(cols="12" sm="2")
  .widget-wrapper
    strong
      | {{ $t('dashboard.plants_in_operation') }}
      br/
      dynamic-progress-circular(color="blue" :rotate="-90" :value="value")
        | {{ parseInt(value) }}%
      .description
        | {{ outage_count }} / {{ total_count }}

</template>
