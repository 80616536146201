<script>
export default {
  props: {
    volume: Array,
    prices: Array,
    downtime: Array,
    downtime_bool: Boolean,
    min_value: Number,
    selected_scope: Number,
    currency_code: String,
    max_effect: {
      type: Number,
      default: 99
    },
    rated_power_mw: { Number, default: 99 }
  },
  data() {
    return {
      chart_options: {
        chart: {
          events: {
            load: function () {
              this.rangeSelector.clickButton(3)
            }
          },
          zoomType: 'x'
        },
        time: {
          timezoneOffset: new Date().getTimezoneOffset()
        },
        title: {
          text: this.$t('power_plants.price_prod_history'),
          margin: 40,
          style: {
            fontSize: '18px'
          }
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            hour: '%b %e %Y %H:%M'
          }
        },
        yAxis: [
          {
            title: {
              enabled: false
            },
            labels: {
              formatter: function () {
                return this.value + ' MW'
              }
            },
            opposite: false,
            dateTimeLabelFormats: {
              hour: '%b %e %Y %H:%M'
            },
            min: 0,
            max: this.rated_power_mw,
            startOnTick: false,
            endOnTick: false,
            tickAmount: 4,
            plotLines: [
              {
                color: '#888888',
                width: 1,
                dashStyle: 'LongDash',
                label: {
                  text: `${this.$t('activerecord.attributes.power_plant.plant_effect_mw')} ${
                    this.rated_power_mw
                  }MW`,
                  style: {
                    color: '#444444',
                    fontSize: '0.9em'
                  },
                  align: 'right'
                },
                value: this.rated_power_mw
              },
              {
                color: '#00adf1',
                width: 1,
                dashStyle: 'LongDash',
                label: {
                  text: `${this.$t('activerecord.attributes.power_plant.max_effect')} ${this.max_effect}MW`,
                  style: {
                    color: '#444444',
                    fontSize: '0.9em'
                  }
                },
                value: this.max_effect
              }
            ]
          },
          {
            title: {
              enabled: false
            },
            labels: {
              format: '{value} ' + this.currency_code + '/MWh'
            },
            opposite: true,
            dateTimeLabelFormats: {
              hour: '%b %e %Y %H:%M'
            },
            min: this.min_value,
            startOnTick: false,
            endOnTick: false,
            tickAmount: 4
          },
          {
            title: {
              enabled: false
            },
            labels: {
              format: '{value} ' + this.currency_code
            },
            opposite: true,
            dateTimeLabelFormats: {
              hour: '%b %e %Y %H:%M'
            },
            min: this.min_value,
            startOnTick: false,
            endOnTick: false,
            tickAmount: 4
          }
        ],
        credits: {
          enabled: false
        },
        rangeSelector: {
          inputEnabled: false,
          selected: this.selected_scope
        },
        plotOptions: {
          series: {
            stacking: 'normal',
            animation: false
          }
        },
        series: [
          {
            name: this.$t('downtime_periods.model_name_short'),
            type: 'areaspline',
            color: '#f0402f',
            data: this.downtime,
            visible: this.downtime_bool,
            yAxis: 0,
            showInNavigator: false,
            tooltip: {
              pointFormat: '{series.name} {point.y} MWh',
              valueDecimals: 3
            },
            dataGrouping: {
              approximation: 'low'
            }
          },
          {
            name: this.$t('general.production'),
            type: 'areaspline',
            color: '#38506c',
            data: this.volume,
            yAxis: 0,
            showInNavigator: true,
            tooltip: {
              pointFormat: '{series.name} {point.y} MW',
              valueDecimals: 3
            }
          },
          {
            name: this.$t('general.area_price'),
            type: 'spline',
            color: '#00adf1',
            data: this.prices,
            zIndex: 1,
            yAxis: 1,
            showInNavigator: false,
            tooltip: {
              pointFormat: '{series.name} {point.y} ' + this.currency_code,
              valueDecimals: 0
            },
            dataGrouping: {
              approximation: 'average'
            }
          }
        ]
      }
    }
  }
}
</script>

<template lang="pug">
  v-highcharts(:options="chart_options" constructor-type="stockChart")
</template>
