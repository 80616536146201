<script>
import MpptForm from './mppts.vue'
export default {
  props: {
    value: Array,
    form_data: Object,
    errors: Object,
    solar_fields: Array
  },
  components: {
    MpptForm
  },
  data() {
    return {
      solar_inverters: this.value,
      solar_module_types: [],
      solar_inverter_types: []
    }
  },
  computed: {
    writable() {
      return this.form_data.permitted_attributes
        .find((a) => a.asset_class_attributes)
        .asset_class_attributes.find((a) => a.solar_inverters_attributes).solar_inverters_attributes
    }
  },
  async mounted() {
    const getModuleTypes = await this.axios.get('/admin/solar/module_types')
    const getInverterTypes = await this.axios.get('/admin/solar/inverter_types')
    if (getModuleTypes.data) this.solar_module_types = getModuleTypes.data
    if (getInverterTypes.data) this.solar_inverter_types = getInverterTypes.data
  },
  methods: {
    sharedProps(field, index) {
      return {
        name: `power_plant[asset_class_attributes][solar_inverters_attributes][${field}]`,
        id: `power_plant_asset_class_attributes_solar_inverters_attributes_${field}`,
        errorMessages: this.errors[`asset_class.solar_inverters.${field}`],
        readonly: false,
        label: this.label(field),
        dense: true,
        outlined: true,
        persistentHint: true,
        hideDetails: 'auto',
        class: this.writable.includes(field) ? 'mr-1 mb-2' : 'd-none'
      }
    },
    label(field) {
      return this.$t(`activerecord.attributes.solar/inverter.${field}`)
    },
    addMppt(inverterIndex) {
      this.solar_inverters[inverterIndex].solar_mppts_attributes.push({
        input_number: this.solar_inverters[inverterIndex].solar_mppts_attributes.length + 1
      })
    },
    addNew() {
      this.solar_inverters.push({ solar_mppts: [] })
    },
    destroy(inverter) {
      if (inverter.id) {
        inverter._destroy = !inverter._destroy
      } else {
        this.solar_inverters.splice(this.solar_inverters.indexOf(inverter), 1)
      }
    }
  }
}
</script>
<template lang="pug">
#solar-inverters
  .mb-2
    .overline.d-inline-block Inverters
    v-btn.ml-4(@click="addNew" small)
      v-icon(left) mdi-plus
      | Add

  v-row(v-for="(inverter, index) in solar_inverters" :key="inverter.id" :class="{'opacity-50': inverter._destroy}")
    v-col.ml-2 
      | Inverter
      .d-flex.flex-wrap
        v-text-field(v-model="inverter.name" v-bind="sharedProps('name', index)")
        v-text-field(v-model="inverter.installed_power_kwp" v-bind="sharedProps('installed_power_kwp', index)" suffix="kWp")
        v-text-field(v-model="inverter.modules_count" v-bind="sharedProps('modules_count', index)")
        v-text-field(v-model="inverter.external_id" v-bind="sharedProps('external_id', index)")
        v-select(v-model="inverter.solar_inverter_type_id" v-bind="sharedProps('solar_inverter_type_id', index)" :items="solar_inverter_types"  item-value="id" item-text="combined_name")
        .d-flex
          v-btn(color="error" @click="destroy(inverter)" small) {{ $t('general.delete') }}
      MpptForm(v-model="inverter.solar_mppts_attributes" :form_data="form_data" :errors="errors" :solar_fields="solar_fields" :solar_module_types="solar_module_types")
      v-btn.ml-4(@click="addMppt(index)" small)
        v-icon(left) mdi-plus
        | Add MPPT
    
</template>
