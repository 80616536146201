import Vue from 'vue'

import HighchartsVue from 'highcharts-vue'
import highchartsMore from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
highchartsMore(Highcharts)
import stockInit from 'highcharts/modules/stock'
stockInit(Highcharts)
import loadBullet from 'highcharts/modules/bullet'
loadBullet(Highcharts)
import loadSolidGauge from 'highcharts/modules/solid-gauge'
loadSolidGauge(Highcharts)
import loadBoost from 'highcharts/modules/boost'
loadBoost(Highcharts)
import HighchartsHeatmap from 'highcharts/modules/heatmap'
HighchartsHeatmap(Highcharts)
import exportingInit from 'highcharts/modules/exporting'
exportingInit(Highcharts)
import exportData from 'highcharts/modules/export-data'
exportData(Highcharts)

Highcharts.setOptions({
  global: {
    time: {}
  },
  title: {
    style: {
      fontWeight: 'normal'
    }
  },
  lang: JSON.parse(window.hs_lang),
  plotOptions: {
    series: {
      dataGrouping: {
        approximation: 'high'
      }
    }
  }
})

Vue.use(HighchartsVue)
