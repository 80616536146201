<script>
export default {
  props: {
    volume: Array,
    theoretical_vol: Array,
    runoff: Array,
    snow: Array,
    precipitation: Array,
    avg_runoff: Array,
  },
  data () {
    return {
      series: [{
        name: this.$t('general.snow'),
        type: 'column',
        color: '#38506c',
        data: this.snow,
        tooltip: {
          pointFormat: '{series.name}: {point.y} mm',
          valueDecimals: 0
        },
        visible: false,
        yAxis: 0,
      }, {
        name: this.$t('general.runoff'),
        type: 'column',
        color: '#6b6a72',
        data: this.runoff,
        tooltip: {
          pointFormat: '{series.name}: {point.y} mm',
          valueDecimals: 0
        },
        yAxis: 0,
      }, {
        name: this.$t('power_plants.runoff_avg'),
        type: 'column',
        color: '#d6d5db',
        data: this.avg_runoff,
        tooltip: {
          pointFormat: '{series.name}: {point.y} mm',
          valueDecimals: 0
        },
        yAxis: 0,
      }, {
        name: this.$t('general.precipitation'),
        type: 'column',
        color: '#97989d',
        data: this.precipitation,
        tooltip: {
          pointFormat: '{series.name}: {point.y} mm',
          valueDecimals: 0
        },
        visible: false,
        yAxis: 0,
      }, {
        name: this.$t('general.production'),
        type: 'line',
        data: this.volume,
        color: '#00adf1',
        yAxis: 1,
        tooltip: {
          pointFormat: '{series.name}: {point.y} MWh',
          valueDecimals: 0
        }
      }, {
        name: this.$t('power_plants.est_production'),
        type: 'line',
        data: this.theoretical_vol,
        color: '#dbb40c',
        yAxis: 1,
        visible: false,
        tooltip: {
          pointFormat: '{series.name}: {point.y} MWh',
          valueDecimals: 0
        }
      }]
    }
  },
  computed: {
    filtered_series () {
      return this.series.filter(
        s => s.data && s.data.filter(d => d != null).length > 0
      )
    },
    enough_data () { return this.filtered_series.length > 1 },
    chart_options () {
      return {
        title: {
          text: this.$t('power_plants.runoff_ytd'),
        },
        xAxis: {
          categories: this.$t("date.abbr_month_names").slice(1),
          crosshair: true,
        },
        yAxis: [{
          min: 0,
          title: null,
          labels: {
            formatter() {
              return `${this.value}mm`
            }
          },
        }, {
          title: null,
          labels: {
            formatter() {
              return `${(this.value/1000)}GWh`
            }
          },
          opposite: true,
        }],
        credits: {
          enabled: false
        },
        series: this.filtered_series,
      }
    }
  }
}
</script>

<template lang="pug">
v-highcharts(
  v-if="enough_data"
  :options="chart_options"
)
</template>
