<script>
export default {
  props: {
    isLandowner: Boolean,
    volumeNok: Number,
    budgetNok: Number
  },
  data() {
    return {}
  },
  computed: {
    color() {
      return this.value < 0 ? 'red' : 'green'
    },
    value() {
      if (this.budgetNok <= 0) return 0
      else if (this.volumeNok == 0) return -100
      else {
        const diff = this.volumeNok / this.budgetNok - 1
        return parseInt(diff * 100)
      }
    },
    rotation() {
      let r = -90
      if (this.value < 0) r = -90 - 360.0 / (100.0 / Math.abs(this.value))
      return r
    }
  }
}
</script>

<template lang="pug">
v-col.top-widget(cols="12" sm="2")
  .widget-wrapper(:class="isLandowner ? 'opacity-30' : ''")
    strong
      | {{ $t('dashboard.m_currency_ytd', { currency: 'NOK' }) }}
      br/
      dynamic-progress-circular(:color="color" :rotate="rotation" :value="Math.abs(value)")
        | {{ parseInt(value) }}%
      .description
        | {{ volumeNok.toFixed(1) }} / {{ budgetNok.toFixed(1) }}

</template>
