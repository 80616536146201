<script>
import PortalPlantSelectors from './components/plant_selectors.vue'
import PortalTable from './components/table.vue'
import PortalMetrics from './components/metrics.vue'
import PortalMetric from './components/metric.vue'
import PortalChart from './components/chart.vue'
import PortalRadioButtons from './components/radio_buttons.vue'
import PortalDownloadButton from './components/download_button.vue'
import {
  MODUS,
  DESCRIPTIONS,
  HISTORIC_SERIES_NAMES,
  RISK_SERIES_NAMES,
  FORECAST_SERIES_NAMES,
  FORMATTED_SERIES_NAMES,
  UNITS,
  flattenResponses,
  createPermutations,
  calc_merchant_capture_rates,
  calc_merchant_variable_capture_rate,
} from './utils.js'


export default {
  name: 'CommercialScada',
  components: {
    PortalRadioButtons,
    PortalChart,
    PortalTable,
    PortalPlantSelectors,
    PortalMetrics,
    PortalMetric,
    PortalDownloadButton
  },
  props: {
    company: {
      type: Object,
      required: true
    },
    power_plants: {
      type: Array,
      required: true,
    },
    year: {
      type: Number,
      required: true,
    },
    price_area: {
      type: String,
      required: true
    }
  },

  async created() {
    // The IDs for all the plants in the price area
    const all_ids = this.power_plants.filter(plant => plant.price_area === this.price_area)
      .map(plant => plant.id)
    const permutations = createPermutations(this.plant_ids, [this.year]);
    this.data = await this.fetchAllData(permutations)
    this.all_periods = this.data[0].periods
  },

  data() {
    return {
      state: {
        selected_plants_ids: [],
        selected_rows_data: [],
        selected_tab: "historic",
      },
      currentMonthIndex: new Date().getMonth(),
      data: [],
      all_periods: []
    }
  },

  computed: {
    plants() {
      return this.power_plants.filter(plant => plant.price_area === this.price_area)
    },

    plant_ids() {
      return this.plants.map(plant => plant.id)
    },

    plant_merchant_capture_rates() {
      return this.plants.reduce((acc, plant) => {
        acc[plant.id] = calc_merchant_capture_rates(plant.asset_class_type, plant.price_area)
        return acc
      }, {})
    },

    flattened_data() {
      return flattenResponses(this.data)
    },

    aggregated_metrics() {
      const series_names = [
        "REVIEW__VOLUME",
        "REVIEW__TOTAL_REVENUE",
        "REVIEW__BUDGET_REVENUE"
      ]
      const title_mapping = {
        "REVIEW__VOLUME": "Volume YTD",
        "REVIEW__TOTAL_REVENUE": "Revenue YTD",
        "REVIEW__BUDGET_REVENUE": "Budget Revenue YTD"
      }

      const unit_mapping = {
        "REVIEW__VOLUME": "MWh",
        "REVIEW__TOTAL_REVENUE": "EUR",
        "REVIEW__BUDGET_REVENUE": "EUR"
      }
      const relevant_data = this.flattened_data.filter(([data_tab, plant_id, year, series_name, values]) => {
        return series_names.includes(series_name) && data_tab === MODUS.HISTORIC && this.state.selected_plants_ids.includes(plant_id)
      })

      if (_.isEmpty(relevant_data)) {
        return []
      }

      const aggregated = relevant_data.reduce((acc, [data_tab, plant_id, year, series_name, values]) => {
        if (!acc[series_name]) {
          acc[series_name] = {
            series_name: series_name,
            series_data: values
          }
        } else {
          acc[series_name] = {
            ...acc[series_name],
            series_data: acc[series_name].series_data.map((value, i) => value + values[i])
          }
        }
        return acc
      }, {})

      const return_values = series_names.map((s) => {
        const { series_name, series_data } = aggregated[s]
        const title = title_mapping[series_name]
        const unit = unit_mapping[series_name]
        const value = series_data.slice(12, 12 + this.currentMonthIndex).reduce((acc, v) => {
          return acc + v
        }, 0)
        const prevValue = series_data.slice(0, this.currentMonthIndex).reduce((acc, v) => {
          return acc + v
        }, 0)
        const change = (value / prevValue) - 1
        return {
          title: title,
          value: value,
          unit: unit,
          change: change,
          period: "y/y",
          series_data: series_data.slice(0, 12 + this.currentMonthIndex),
        }
      })

      return return_values
    },

    aggregated_data() {
      let data = this.flattened_data.reduce((acc, [data_tab, plant_id, year, series_name, values]) => {
        if (year !== this.year) return acc
        if (!this.state.selected_plants_ids.includes(plant_id)) return acc
        const slicer = (v) => {
          if (data_tab === MODUS.HISTORIC || data_tab === MODUS.RISK) {
            return v.slice(this.currentMonthIndex, 12 + this.currentMonthIndex)
          } else {
            return v.slice(this.currentMonthIndex)
          }
        }
        if (!acc[series_name]) {
          acc[series_name] = slicer(values)
        } else if (series_name === "REVIEW__SPOT_PRICE") {
          acc[series_name] = slicer(values)
        } else if (series_name === "FCAST__SPOT_PRICE") {
          acc[series_name] = slicer(values)
        } else {
          acc[series_name] = acc[series_name].map((value, i) => value + slicer(values)[i])
        }

        // Adding merchant captured budget volume, used in merchant capture rate calcs:
        if (series_name === "FCAST__BUDGET_VOLUME") {
          const fcast__budget_volume = slicer(values)
          const merchant_capture_rates = slicer(this.plant_merchant_capture_rates[plant_id])
          const merchant_captured_budget_volume = fcast__budget_volume.map((v, i) => v * merchant_capture_rates[i])
          if (!acc["FCAST__MERCHANT_CAPTURED_BUDGET_VOLUME"]) {
            acc["FCAST__MERCHANT_CAPTURED_BUDGET_VOLUME"] = merchant_captured_budget_volume
          } else {
            acc["FCAST__MERCHANT_CAPTURED_BUDGET_VOLUME"] = acc["FCAST__MERCHANT_CAPTURED_BUDGET_VOLUME"].map((value, i) => value + merchant_captured_budget_volume[i])
          }
        }

        return acc
      }, {})

      // REVIEW CALCULATIONS:
      // Budget Price
      if (data["REVIEW__BUDGET_REVENUE"] && data["REVIEW__BUDGET_VOLUME"]) {
        data["REVIEW__BUDGET_PRICE"] = data["REVIEW__BUDGET_REVENUE"].map((v, i) => v / data["REVIEW__BUDGET_VOLUME"][i])
      }
      // Achieved Price
      if (data["REVIEW__TOTAL_REVENUE"] && data["REVIEW__VOLUME"]) {
        data["REVIEW__ACHIEVED_PRICE"] = data["REVIEW__TOTAL_REVENUE"].map((v, i) => v / data["REVIEW__VOLUME"][i])
      }
      // Merchant Price
      if (data["REVIEW__MERCHANT_REVENUE"] && data["REVIEW__VOLUME"]) {
        data["REVIEW__MERCHANT_PRICE"] = data["REVIEW__MERCHANT_REVENUE"].map((v, i) => v / data["REVIEW__VOLUME"][i])
      }
      // Merchant Capture Rate
      if (data["REVIEW__MERCHANT_PRICE"] && data["REVIEW__SPOT_PRICE"]) {
        data["REVIEW__MERCHANT_CAPTURE_RATE"] = data["REVIEW__MERCHANT_PRICE"].map((v, i) => v / data["REVIEW__SPOT_PRICE"][i])
      }
      // Achieved Capture Rate
      if (data["REVIEW__ACHIEVED_PRICE"] && data["REVIEW__SPOT_PRICE"]) {
        data["REVIEW__ACHIEVED_CAPTURE_RATE"] = data["REVIEW__ACHIEVED_PRICE"].map((v, i) => v / data["REVIEW__SPOT_PRICE"][i])
      }
      // Full Load Factor
      if (data["REVIEW__VOLUME_MEAN"] && data["REVIEW__VOLUME_MAX"]) {
        data["REVIEW__FULL_LOAD_FACTOR"] = data["REVIEW__VOLUME_MEAN"].map((v, i) => v / data["REVIEW__VOLUME_MAX"][i])
      }
      // Hedge Revenue
      if (data["REVIEW__HEDGED_VOLUME"] && data["REVIEW__HEDGE_PRICE"]) {
        data["REVIEW__HEDGE_REVENUE"] = data["REVIEW__HEDGED_VOLUME"].map((v, i) => v * data["REVIEW__HEDGE_PRICE"][i])
      }
      // Total Revenue
      if (data["REVIEW__NET_VARIABLE_REVENUE"] && data["REVIEW__HEDGE_REVENUE"]) {
        data["REVIEW__TOTAL_REVENUE"] = data["REVIEW__NET_VARIABLE_REVENUE"].map((v, i) => v + data["REVIEW__HEDGE_REVENUE"][i])
      }
      // Hedged Volume Factor
      if (data["REVIEW__BUDGET_VOLUME"] && data["REVIEW__HEDGED_VOLUME"]) {
        data["REVIEW__HEDGED_VOLUME_FACTOR"] = data["REVIEW__HEDGED_VOLUME"].map((v, i) => v / data["REVIEW__BUDGET_VOLUME"][i])
      }
      // Missing Volume Price
      if (data["REVIEW__MISSING_VOLUME"] && data["REVIEW__MISSING_VOLUME_COST"]) {
        data["REVIEW__MISSING_VOLUME_PRICE"] = data["REVIEW__MISSING_VOLUME_COST"].map((v, i) => v / data["REVIEW__MISSING_VOLUME"][i])
      }
      // Excess Volume Price
      if (data["REVIEW__EXCESS_VOLUME"] && data["REVIEW__EXCESS_VOLUME_COST"]) {
        data["REVIEW__EXCESS_VOLUME_PRICE"] = data["REVIEW__EXCESS_VOLUME_COST"].map((v, i) => v / data["REVIEW__EXCESS_VOLUME"][i])
      }


      // FORECAST CALCULATIONS:
      // Budget Price
      if (data["FCAST__BUDGET_REVENUE"] && data["FCAST__BUDGET_VOLUME"]) {
        data["FCAST__BUDGET_PRICE"] = data["FCAST__BUDGET_REVENUE"].map((v, i) => v / data["FCAST__BUDGET_VOLUME"][i])
      }
      // Merchant Capture Rate
      if (data["FCAST__MERCHANT_CAPTURED_BUDGET_VOLUME"] && data["FCAST__BUDGET_VOLUME"]) {
        data["FCAST__MERCHANT_CAPTURE_RATE"] = data["FCAST__MERCHANT_CAPTURED_BUDGET_VOLUME"].map((v, i) => v / data["FCAST__BUDGET_VOLUME"][i])
      }
      // Merchant Revenue
      if (data["FCAST__MERCHANT_CAPTURE_RATE"] && data["FCAST__BUDGET_VOLUME"] && data["FCAST__SPOT_PRICE"]) {
        data["FCAST__MERCHANT_REVENUE"] = data["FCAST__MERCHANT_CAPTURE_RATE"].map((v, i) => (
          v * data["FCAST__BUDGET_VOLUME"][i] * data["FCAST__SPOT_PRICE"][i]
        ))
      }
      // Hedged Volume Factor
      if (data["FCAST__HEDGED_VOLUME"] && data["FCAST__BUDGET_VOLUME"]) {
        data["FCAST__HEDGED_VOLUME_FACTOR"] = data["FCAST__HEDGED_VOLUME"].map((v, i) => v / data["FCAST__BUDGET_VOLUME"][i])
      }
      // Hedge Revenue
      if (data["FCAST__HEDGED_VOLUME"] && data["FCAST__HEDGE_PRICE"]) {
        data["FCAST__HEDGE_REVENUE"] = data["FCAST__HEDGED_VOLUME"].map((v, i) => v * data["FCAST__HEDGE_PRICE"][i])
      }
      // Net Variable Volume
      if (data["FCAST__HEDGED_VOLUME"] && data["FCAST__BUDGET_VOLUME"]) {
        data["FCAST__NET_VARIABLE_VOLUME"] = data["FCAST__BUDGET_VOLUME"].map((v, i) => v - data["FCAST__HEDGED_VOLUME"][i])
      }
      // Net Variable Revenue
      if (
        data["FCAST__NET_VARIABLE_VOLUME"] &&
        data["FCAST__SPOT_PRICE"] &&
        data["FCAST__HEDGED_VOLUME_FACTOR"] &&
        data["FCAST__MERCHANT_CAPTURE_RATE"]
      ) {
        data["FCAST__NET_VARIABLE_REVENUE"] = data["FCAST__NET_VARIABLE_VOLUME"]
        data["FCAST__NET_VARIABLE_REVENUE"] = data["FCAST__NET_VARIABLE_VOLUME"].map((v, i) => (
          v * data["FCAST__SPOT_PRICE"][i] *
          calc_merchant_variable_capture_rate(
            data["FCAST__HEDGED_VOLUME_FACTOR"][i],
            data["FCAST__MERCHANT_CAPTURE_RATE"][i]
          )
        ))
      }
      // Total Revenue
      if (data["FCAST__NET_VARIABLE_REVENUE"] && data["FCAST__HEDGE_REVENUE"]) {
        data["FCAST__TOTAL_REVENUE"] = data["FCAST__NET_VARIABLE_REVENUE"].map((v, i) => v + data["FCAST__HEDGE_REVENUE"][i])
      }

      return data
    },

    periods() {
      if (this.state.selected_tab === MODUS.HISTORIC) {
        return this.all_periods.slice(this.currentMonthIndex, 12 + this.currentMonthIndex)
      } else if (this.state.selected_tab === MODUS.RISK) {
        return this.all_periods.slice(this.currentMonthIndex, 12 + this.currentMonthIndex)
      } else if (this.state.selected_tab === MODUS.FORECAST) {
        return this.all_periods.slice(12 + this.currentMonthIndex)
      } else {
        throw Error("Invalid selected tab")
      }
    },

    chart_data() {
      let selected_rows_data = this.state.selected_rows_data || []
      const primary_series = selected_rows_data[0]
      const secondary_series = selected_rows_data.slice(1)
      const series_unit = primary_series ? primary_series["unit"] : ""
      const return_value = {
        seriesUnit: series_unit,
        mainSeries: primary_series,
        secondarySeries: secondary_series
      }
      return return_value
    },

    series_names() {
      if (this.state.selected_tab === MODUS.HISTORIC) {
        return HISTORIC_SERIES_NAMES
      } else if (this.state.selected_tab === MODUS.RISK) {
        return RISK_SERIES_NAMES
      } else if (this.state.selected_tab === MODUS.FORECAST) {
        return FORECAST_SERIES_NAMES
      } else {
        throw Error("Invalid selected tab")
      }
    },

    units() {
      return UNITS
    },

    descriptions() {
      return DESCRIPTIONS[this.state.selected_tab]
    }
  },

  methods: {
    on_selected_rows_data_change(selected_rows_data) {
      this.state.selected_rows_data = selected_rows_data
    },
    on_selected_plants_change(selected_plants_ids) {
      this.state.selected_plants_ids = selected_plants_ids
    },
    on_tab_change(new_tab) {
      this.state.selected_tab = new_tab
    },
    series_name_formatter(series_name) {
      return FORMATTED_SERIES_NAMES[series_name]
    },
    async fetchData(plantId, year, endpoint) {
      try {
        const url = `/commercial_scada/${endpoint}.json?power_plant_id=${plantId}&year=${year}`;
        const response = await this.axios.get(url);
        if (response.status !== 200) {
          throw new Error('Network response was not ok');
        }
        return response.data;
      } catch (error) {
        console.error('There was a problem with your fetch operation:', error);
        throw error;
      }
    },
    async fetchAllData(permutations) {
      try {
        const promises = permutations.map(([plantId, year, endpoint]) => this.fetchData(plantId, year, endpoint));
        const responses = await Promise.all(promises);
        return responses;
      } catch (error) {
        console.error('Error with one of the fetch calls:', error);
      }
    }
  },

}
</script>


<template lang="pug">
div(style="background-color: #fafbfc")
  div#commercial-scada.commercial-scada-layout(style="padding-top:2.5rem; padding-bottom:3.5rem; min-height:100vh")
    portal-plant-selectors(:plants="plants", v-on:selected-plants-change="on_selected_plants_change")
    portal-metrics
      portal-metric(v-for="m in aggregated_metrics", :key="m.title", :title="m.title", :value="m.value", :unit="m.unit", :change="m.change", period="y/y", :series_data="m.series_data")
    portal-radio-buttons(:choices="['historic', 'risk', 'forecast']", :formatter="(value) => ({'historic': 'Historic review', 'risk': 'Risk review', 'forecast': 'Forecast'}[value])", v-on:radio-buttons-change="on_tab_change")
    portal-chart(v-if="chart_data.mainSeries", style="width:90%; margin:auto", :unit="chart_data.seriesUnit", :primary_series="chart_data.mainSeries", :secondary_series="chart_data.secondarySeries", :loading_timeout="100")
    portal-table(:data="aggregated_data", :headers="series_names", :headers_formatter="series_name_formatter", :time_periods="periods", :units="units", :descriptions="descriptions", v-on:selected-rows-data-change="on_selected_rows_data_change")
</template>
