<script>
import { DateTime } from 'luxon'
export default {
  props: {
    power_plant_id: Number,
    date: String
  },
  data() {
    return {
      production_periods: [],
      open_dialog: false,
      current_date: this.date,
      loading: false
    }
  },
  computed: {
    prev_date() {
      return DateTime.fromFormat(this.current_date, 'yyyy-MM-dd').minus({ days: 1 }).toFormat('yyyy-MM-dd')
    },
    next_date() {
      return DateTime.fromFormat(this.current_date, 'yyyy-MM-dd').plus({ days: 1 }).toFormat('yyyy-MM-dd')
    }
  },
  methods: {
    async open() {
      this.current_date = this.date
      this.open_dialog = true
      this.loadData()
    },
    async loadData() {
      this.loading = true
      let url = `/admin/production_periods?power_plant_id=${this.power_plant_id}&date=${this.current_date}`
      let response = await this.axios.get(url)
      this.production_periods = response.data
      this.loading = false
    },
    formatTs(timestamp) {
      return DateTime.fromISO(timestamp).toFormat('yyyy-MM-dd HH:mm')
    },
    summarize(field) {
      return this.production_periods.reduce((a, b) => a + (b[field] || 0), 0).toFixed(3)
    }
  },
  watch: {
    current_date() {
      this.loadData()
    }
  },
  mounted() {
    const params = new URLSearchParams(window.location.search)
    if (params.get('date') == this.date) this.open()
  }
}
</script>

<template lang="pug">
div
  v-btn(@click.prevent="open" x-small) Hourly values
  v-dialog(v-if="open_dialog" v-model='open_dialog' content-class="auto-width")
    v-card.show-news-item
      v-card-title.headline.grey.lighten-2
        v-icon.light-blue--text(@click="current_date = prev_date") mdi-chevron-left
        span.mx-2 {{ current_date }}
        v-icon.light-blue--text(@click="current_date = next_date") mdi-chevron-right
        v-spacer
        v-progress-circular(v-if="loading" color="light-blue" indeterminate)
      v-card-text
        v-simple-table.fit-content(v-if="production_periods.length > 0" dense)
          template(v-slot:default)
            thead
              tr
                th.text-center Hour
                th.text-center Timestamp
                th.text-right Volume
                th.text-right Settled volume
                th.text-right Unsettled volume
                th.text-right Revenue
                th.text-center #
            tbody
              tr(v-for="production_period in production_periods")
                td.text-center {{ production_period.hour }}
                td.text-center.text-no-wrap {{ formatTs(production_period.timestamp)  }}
                td.text-right
                  div(v-if="production_period.volume != null")
                    | {{ production_period.volume }} 
                    span.weak MWh
                td.text-right
                  div(v-if="production_period.settled_volume != null")
                    | {{ production_period.settled_volume }} 
                    span.weak MWh
                td.text-right
                  div(v-if="production_period.unsettled_volume != null")
                    | {{ production_period.unsettled_volume }} 
                    span.weak MWh
                td.text-right
                  | {{ production_period.revenue }} 
                  span.weak NOK
                td.text-center.weak {{ production_period.id }}
              tr.font-weight-bold
                td.text-center SUM
                td
                td.text-right
                  | {{ summarize('volume') }} 
                  span.weak MWh
                td.text-right
                  | {{ summarize('settled_volume') }} 
                  span.weak MWh
                td.text-right
                  | {{ summarize('unsettled_volume') }} 
                  span.weak MWh
                td.text-right
                  | {{ summarize('revenue') }} 
                  span.weak NOK
                td
        div(v-else) No data
      v-divider
      v-card-actions
        .flex-grow-1
        v-btn(color='grey' text='' @click='open_dialog = false') {{ $t('general.close') }}
</template>
