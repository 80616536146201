<script>
export default {
  props: {
    value: Array,
    form_data: Object,
    errors: Object,
  },
  data () {
    return {
      penstocks: this.value,
    }
  },
  computed: {
    writable() {
      return this.form_data.permitted_attributes.find(a => a.asset_class_attributes).asset_class_attributes.find(a => a.penstocks_attributes).penstocks_attributes
    },
  },
  methods: {
    sharedProps(field) {
      return {
        name: `power_plant[asset_class_attributes][penstocks_attributes][${field}]`,
        id: `power_plant_asset_class_attributes_penstocks_attributes_${field}`,
        errorMessages: this.errors[`asset_class.penstocks.${field}`],
        readonly: false,
        label: this.label(field),
        dense: true,
        outlined: true,
        persistentHint: true,
        hideDetails: 'auto',
        class: this.writable.includes(field) ? '' : 'd-none',
      }
    },
    label(field) {
      return this.$t(`activerecord.attributes.hydro/penstock.${field}`)
    },
    addNew() {
      this.penstocks.push({
        net_head_loss_percent: null,
        length: null,
        diameter: null,
        risk_category: null,
        material: null,
        _destroy: false,
      })
    },
    destroy(penstock) {
      if(penstock.id) {
        penstock._destroy = !penstock._destroy
      } else {
        this.penstocks.splice(this.penstocks.indexOf(penstock), 1)
      }
    },
  },
}
</script>
<template lang="pug">
#hydro-penstocks
  .mb-2
    .overline.d-inline-block Penstocks
    v-btn.ml-4(@click="addNew" small)
      v-icon(left) mdi-plus
      | Add
  .d-flex.mb-2(v-for="penstock in penstocks" :key="penstock.id" :class="{'opacity-50': penstock._destroy}")
    v-text-field.ml-1(v-model="penstock.net_head_loss_percent" v-bind="sharedProps('net_head_loss_percent')" suffix="%" type="number" step=".1")
    v-text-field.ml-1(v-model="penstock.length" v-bind="sharedProps('length')" suffix="m")
    v-text-field.ml-1(v-model="penstock.diameter" v-bind="sharedProps('diameter')" suffix="m")
    v-text-field.ml-1(v-model="penstock.material" v-bind="sharedProps('material')")
    v-select.ml-1(v-model="penstock.risk_category" v-bind="sharedProps('risk_category')" :items="[0, 1, 2, 3, 4]")
    v-icon(color="error" @click="destroy(penstock)") mdi-delete
</template>
