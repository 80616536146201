<script>
import KPI from '../elements/kpi.vue'
import ProductionChart from '../production_chart.vue'
import CandybarChart from '../candybar_chart.vue'
import TurbinePowerCurve from './components/turbine_power_curve.vue'

export default {
  name: 'OmWindTurbines',
  components: { TurbinePowerCurve, KPI, ProductionChart, CandybarChart },
  props: {
    powerPlant: Object,
    turbines: Array,
    currentRange: Array,
    activeComponent: Object,
    comparingFromUrl: Array
  },
  async mounted() {
    if (!this.comparingTurbines.length) await this.load()
  },
  data() {
    return {
      activeTurbine: this.activeComponent,
      comparingTurbines: [],
      copiedRange: this.currentRange,
      tmpComparison: [],
      kpis: {},
      turbineProduction: [],
      alarmChart: [{ title: 'NONE', values: [] }],
      powerCurve: {},
      measuredVsProduction: [],
      comparingPowerCurves: []
    }
  },
  created() {
    if (this.comparingFromUrl.length) {
      this.comparingTurbines = this.turbines
        .filter((i) => this.comparingFromUrl.includes(i.id))
        .sort((a, b) => a.name.localeCompare(b.name))
      this.tmpComparison = this.comparingTurbines
    } else {
      this.comparingTurbines = []
      this.tmpComparison = []
    }
  },
  methods: {
    syncComparisons() {
      if (this.tmpComparison.length > 5) this.tmpComparison = this.tmpComparison.slice(0, 5)
      this.comparingTurbines = this.tmpComparison
      this.$emit(
        'changedComparisons',
        this.comparingTurbines.map((i) => i.id)
      )
    },
    reset() {
      this.kpis = {}
      this.turbineProduction = []
      this.alarmChart = [{ title: 'NONE', values: [] }]
      this.powerCurve = {}
    },
    async load() {
      this.reset()
      await this.loadKPIs()
      await this.loadAlarmChart()
      await this.loadTurbineProduction()
      await this.loadMeasuredVsProduction()
      if (this.isComparing && this.comparingTurbines.length > 0) {
        let powerCurveCache = []
        this.comparingPowerCurves = []
        for await (const comparingTurbine of this.comparingTurbines.sort((a, b) =>
          a.name.localeCompare(b.name)
        )) {
          if (comparingTurbine.id) {
            const pcData = await this.loadPowerCurve(comparingTurbine.id)
            powerCurveCache.push({ id: comparingTurbine.id, data: pcData })
          }
        }
        this.comparingPowerCurves = powerCurveCache.map((i) => i.data)
      }
      this.powerCurve = await this.loadPowerCurve(this.activeTurbine.id)
    },
    async loadKPIs() {
      const response = await this.axios.get(
        `/om_dashboard/wind_turbine_kpis/${this.activeTurbine.id}/?start_time=${this.currentRange[0]}&end_time=${this.currentRange[1]}`
      )
      this.kpis = response.data
    },
    async loadTurbineProduction() {
      let response = await this.axios.get(
        `/om_dashboard/production_in_mw_for_turbines/${this.powerPlant.id}?start_time=${this.currentRange[0]}&end_time=${this.currentRange[1]}`
      )
      this.turbineProduction = response.data.sort((a, b) => a.title.localeCompare(b.title))
    },
    async loadPowerCurve(turbineId) {
      let response = await this.axios.get(
        `/om_dashboard/power_curve_for_wind_turbine/${turbineId}?start_time=${this.currentRange[0]}&end_time=${this.currentRange[1]}`
      )
      return response.data
    },
    changeActiveTurbine(turbineId) {
      this.activeTurbine = this.turbines.filter((i) => i.id === turbineId)[0]
    },
    async loadAlarmChart() {
      let response = await this.axios.get(
        `/om_dashboard/alarm_chart?${this.activeTurbineIdsUrl()}&start_time=${
          this.currentRange[0]
        }&end_time=${this.currentRange[1]}`
      )
      this.alarmChart = response.data
    },
    async loadMeasuredVsProduction() {
      let response = await this.axios.get(
        `/om_dashboard/wind_turbine_production/${this.activeTurbine.id}?start_time=${this.currentRange[0]}&end_time=${this.currentRange[1]}`
      )
      this.measuredVsProduction = response.data
    },
    activeTurbineIdsUrl() {
      let id1 = `turbine_ids[]=${this.activeTurbine.id}`
      let addOn = '&'
      if (this.isComparing) {
        this.comparingTurbines.forEach((cm) => {
          addOn += `turbine_ids[]=${cm.id}&`
        })
      }

      return id1 + addOn.replace(/\&$/, '')
    },
    limiter(e) {
      const selectRef = this.$refs.comparisonSelect
      if (selectRef) selectRef.blur()
      if (e.length > 5) e.pop()
    }
  },
  computed: {
    otherTurbines() {
      return this.turbines.filter((i) => i.id !== this.activeTurbine.id)
    },
    filteredTurbineProduction() {
      return this.turbineProduction.filter(
        (turbineData) =>
          turbineData.title === this.activeTurbine.name ||
          this.comparingTurbines.map((t) => t.name).includes(turbineData.title)
      )
    },
    assembledNames() {
      return this.comparingTurbines.map((ct) => ct.name)
    },
    powerCurveHasData() {
      return Object.keys(this.powerCurve).length > 0
    },
    isComparing() {
      return this.comparingLength !== 0
    },
    comparingLength() {
      return this.comparingTurbines.filter((e) => Object.keys(e).length).length
    }
  },
  watch: {
    async activeComponent() {
      this.activeTurbine = this.activeComponent
      await this.load()
    },
    async currentRange() {
      if (this.currentRange[0] !== this.copiedRange[0] || this.currentRange[1] !== this.copiedRange[1]) {
        this.copiedRange = this.currentRange
        await this.load()
      }
    },
    comparingTurbines: {
      async handler(newValue, oldValue) {
        if (newValue !== oldValue) await this.load()
      }
    }
  }
}
</script>

<template lang="pug">
div.asset_wrapper
  v-container.comparison_selector
    v-row
      v-col.d-flex(cols="2")
        div(style="align-self:flex-start") {{ $t('om_dashboard.drilldown.compare_with_turbine', { turbine_name: activeTurbine.name }) }}
      v-col.d-flex(cols="4")
        v-select.compare_turbine(:items="otherTurbines" ref="comparisonSelect" multiple v-model="tmpComparison" item-value="id" item-text="name" hide-details="auto" @blur="syncComparisons" return-object outlined)

  v-container
    .kpi_wrapper(v-if="Object.keys(kpis).length > 0")
      v-row
        v-col.graph_title {{ $t('om_dashboard.drilldown.key_performance_for', { name: activeTurbine.name }) }}
      v-row
        v-col
          KPI(:kpiLabel="$t('om_dashboard.drilldown.energy_produced')" :kpis="kpis.energy_produced" field="eac_meas_mwh" unit=" MWh" :showPercentage="true")
        v-col
          KPI(:kpiLabel="$t('om_dashboard.drilldown.energy_losses')" :kpis="kpis.losses" field="eac_loss_mwh" :invertArrows="true" unit=" MWh")
        v-col
          KPI(:kpiLabel="$t('om_dashboard.drilldown.total_availability')" :kpis="kpis.total_availability" field="total_energybased_availability_percent" unit="%")
        v-col
          KPI(:kpiLabel="$t('om_dashboard.drilldown.contractual_availability')" :kpis="kpis.contractual_availability" field="contractual_availability_percent" unit="%")

  v-container
    v-row
      v-col.graph_title {{ $t('om_dashboard.drilldown.turbine_timeline') }}
    v-row
      v-col
        CandybarChart(title="" :values="alarmChart" :showAlarmLegend="true" tech="wind")
  v-container(v-if="isComparing")
    v-row
      v-col.graph_title {{ $t('om_dashboard.drilldown.wind_production_for') }}
    v-row
      v-col
        ProductionChart(:values="filteredTurbineProduction" :additionalNames="assembledNames" unit="MW")
  v-container(v-else)
    v-row
      v-col.graph_title {{ $t('om_dashboard.drilldown.production_in_mw') }}
    v-row
      v-col
        ProductionChart(:values="measuredVsProduction" :additionalNames="[]" unit="MW")
  v-container(v-if="powerCurveHasData")
    v-row
      v-col.left-aligned(cols="6")
        v-row
          v-col.graph_title {{ $t('om_dashboard.drilldown.power_curve') }}
        v-row
          v-col
            TurbinePowerCurve(
              :power_curve_data="powerCurve"
              :x_title="$t('om_dashboard.drilldown.turbine_power_curve_x_title')"
              :y_title="$t('om_dashboard.drilldown.turbine_power_curve_y_title')"
              :x_unit="$t('om_dashboard.drilldown.turbine_power_curve_x_unit')"
              :y_unit="$t('om_dashboard.drilldown.turbine_power_curve_y_unit')"
            )
      v-col.right-aligned(cols="6" v-if="powerCurveHasData && isComparing")
        v-row
          v-col.graph_title(class="text-right")
            | {{ $t('om_dashboard.drilldown.compared_power_curves') }} {{ activeTurbine.name }}
            span(v-if="comparingLength === 1") &nbsp;& {{ assembledNames[0] }}
            span(v-else) &nbsp;+ {{ comparingLength }}
        v-row
          v-col
            TurbinePowerCurve(
              :power_curve_data="powerCurve"
              :name="activeTurbine.name"
              :additionalNames="assembledNames"
              :additionalValues="comparingPowerCurves"
              :x_title="$t('om_dashboard.drilldown.turbine_power_curve_x_title')"
              :y_title="$t('om_dashboard.drilldown.turbine_power_curve_y_title')"
              :x_unit="$t('om_dashboard.drilldown.turbine_power_curve_x_unit')"
              :y_unit="$t('om_dashboard.drilldown.turbine_power_curve_y_unit')"
            )
</template>
